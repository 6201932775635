import { EOrderStatus } from '../../types/orders'

export interface IStatusSelector {
	onChange?: (status: EOrderStatus) => void
	orderId: string
	status: EOrderStatus
	refetch?: () => void
}

export enum EOrderStatusLabels {
	PENDING = 'Pendente', // PENDENTE (PAGAMENTO)
	PAID = 'Pago', // PAGO
	PRODUCTION = 'Em Produção', // EM PRODUÇAO
	ON_ROUTE = 'A Caminho', // EM ROTA (A CAMINHO)
	DELIVERED = 'Entregue', // ENTREGUE
	CANCELED = 'Cancelar Pedido', // CANCELADO
	FAILED = 'Falha no Pagamento' // FALHA NO PAGAMENTO
}
