//redux
import { useDispatch } from 'react-redux'
import { useTypedSelector } from './useTypedSelector'
import { Creators as TokenActions } from '../store/redux/ducks/token'
import { Creators as UserActions } from '../store/redux/ducks/user'
import { ITokenActionCreators, IUserActionCreators } from '../types/action'

//zustand
import {
	ICreateSellerFormActions,
	ICreateSellerFormValue,
	useCreateSellerFormStore
} from '../store/zustand/useCreateSellerFormStore'

//types
import { IUser } from '../types'
import { IToken } from '../types/token'

interface IStore<Value, Actions> {
	value: Value
	actions: Actions
}

export function useStores() {
	const dispatch = useDispatch()
	const { user, token } = useTypedSelector(['user', 'token'])
	const { createSellerForm, addCreateSellerForm, removeCreateSellerForm } =
		useCreateSellerFormStore()

	const userStore: IStore<IUser, IUserActionCreators> = {
		value: user,
		actions: {
			setUser: (user: IUser) => dispatch(UserActions.setUser(user)),
			resetUser: () => dispatch(UserActions.resetUser())
		}
	}
	const tokenStore: IStore<IToken, ITokenActionCreators> = {
		value: token,
		actions: {
			setToken: (token: IToken) => dispatch(TokenActions.setToken(token)),
			resetToken: () => dispatch(TokenActions.resetToken())
		}
	}
	const createSellerFormStore: IStore<
		ICreateSellerFormValue,
		ICreateSellerFormActions
	> = {
		value: createSellerForm,
		actions: {
			addCreateSellerForm,
			removeCreateSellerForm
		}
	}

	return {
		userStore,
		tokenStore,
		createSellerFormStore
	}
}
