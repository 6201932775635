import React, { useState } from 'react'
import * as S from './styles'

//icons
import filterOff from '../../assets/icons/filter.svg'
import filterOn from '../../assets/icons/filter-on.svg'
import options from '../../assets/icons/options.svg'

//types
import { IListItem, ISellerList } from './types'

//components
import { ColumnHead } from './ColumnHead'
import { ListPagination } from '../ListPagination'
import { Typography } from '../Typography'

import { Status } from '../Status'

//services

//utils
import { parseItemPrice, toNumber } from '../../utils/currency'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from '../../routes/routes'
import { Filter } from './Filter'

function AdminOrdersList({
	orders,
	filter,
	setFilter,
	handleChangePage
}: ISellerList) {
	const [isFilterOpen, setIsFilterOpen] = useState(false)

	function handleOpenAndCloseFilter() {
		setIsFilterOpen(!isFilterOpen)
	}

	return (
		<S.Container>
			<S.TableContainer>
				<S.Table>
					<thead>
						<S.TableHeader>
							<S.HeaderPadding />
							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Pedido'
								value='items'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Seller'
								value='store'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Data'
								value='createdAt'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Valor Bruto'
								value='amount'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Status'
								value='status'
							/>

							<S.TableHeaderItem>
								<S.ItemsContainer>
									<S.Icons
										src={isFilterOpen ? filterOn : filterOff}
										alt=''
										onClick={handleOpenAndCloseFilter}
									/>
								</S.ItemsContainer>
							</S.TableHeaderItem>

							<S.HeaderPadding />
						</S.TableHeader>
					</thead>

					<tr>
						<th colSpan={9}>
							<Filter
								isOpen={isFilterOpen}
								setFilter={(filters) => setFilter({ ...filter, ...filters })}
							/>
						</th>
					</tr>

					<tbody>
						{React.Children.toArray(
							orders?.docs.map((props) => <ListItem {...props} />)
						)}
					</tbody>
				</S.Table>
			</S.TableContainer>

			<ListPagination
				showingItems={orders?.docs.length}
				itemsLength={orders?.totalDocs}
				itemsPerPage={orders?.limit}
				onChange={handleChangePage}
			/>
		</S.Container>
	)
}

const ListItem = React.memo(
	({ items, amount, createdAt, store, code, status, _id }: IListItem) => {
		const product = items[0]

		const date = new Date(createdAt)
		const buyDate = `${date.toLocaleDateString()} - ${date
			.toLocaleTimeString()
			.substring(0, 5)}`

		const navigate = useNavigate()

		return (
			<S.TableRow>
				<S.BodyPadding />

				<S.TableItem>
					<S.ItemsContainer>
						<S.PhotoContainer>
							<img src={product.photo} alt={product.name} />
						</S.PhotoContainer>
						<S.ProductContent>
							<Typography as={'p2'} color={'grafitti'}>
								{code}
							</Typography>
							<Typography as={'p2'} color={'grafitti'}>
								{product.name}
							</Typography>
						</S.ProductContent>
					</S.ItemsContainer>
				</S.TableItem>

				<S.TableItem>{store?.name}</S.TableItem>

				<S.TableItem>{buyDate}</S.TableItem>

				<S.TableItem>
					{parseItemPrice(toNumber(amount?.toString()))}
				</S.TableItem>

				<S.TableItem>
					<Status status={status} />
				</S.TableItem>

				<S.TableItem>
					<S.ItemsContainer>
						<S.Icons
							src={options}
							onClick={() =>
								navigate(ERoutes.ADMIN_ORDER_DETAILS.replace(':id', _id))
							}
						/>
					</S.ItemsContainer>
				</S.TableItem>

				<S.BodyPadding />
			</S.TableRow>
		)
	}
)

export { AdminOrdersList }
