import { createElement } from 'react'
import { ResetPasswordView } from './view'

//utils
import { yupResolver } from '@hookform/resolvers/yup'
import { emailSchema } from './schema'

//hooks
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

//types
import { IResetPasswordForm, IPageProps } from './types'

//services
import { useResetPassword } from '../../services/user.service'

//utils
import { formatResetPasswordPayload } from '../../utils/formatPayload'
import { ERoutes } from '../../routes/routes'
import { Toaster } from '../../utils/toaster'

function ResetPassword() {
	const navigate = useNavigate()

	const {
		control,
		handleSubmit,
		formState: { errors }
	} = useForm<IResetPasswordForm>({
		resolver: yupResolver(emailSchema),
		mode: 'onChange',
		delayError: 1000 * 1 //1 second
	})

	const { mutateAsync: resetPassword } = useResetPassword()

	const handleSignIn = handleSubmit(async (data: IResetPasswordForm) => {
		const payload = formatResetPasswordPayload(data)
		try {
			await resetPassword(payload)

			Toaster({
				type: 'success',
				title: 'Um link de verificação foi enviado para o e-mail informado'
			})
		} catch (error: any) {
			Toaster({
				type: 'error',
				title: 'Erro ao enviar e-mail',
				text: error.response.data.message
			})
		}
	})

	function handleReturn() {
		navigate(ERoutes.LOGIN)
	}

	const pageProps: IPageProps = {
		control,
		errors,
		handleSignIn,
		handleReturn
	}

	return createElement(ResetPasswordView, pageProps)
}

export { ResetPassword }
