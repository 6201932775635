import { useCallback, useMemo, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { routes } from '../../routes/routes'
import { IRoute } from '../../routes/types'
import Header from './components/Header'
import SideNav from './components/SideNav'
import { Container, PageWrapper } from './styles'
import { IPageProps } from './types'

function Layout({ children }: IPageProps): JSX.Element {
	const {
		user,
		user: { store }
	} = useTypedSelector(['user'])
	const [isOpen, setIsOpen] = useState(false)

	const handleOpenSideNav = useCallback(() => {
		setIsOpen(!isOpen)
	}, [isOpen])

	const sideNavRoutes = useMemo((): IRoute[] => {
		const menus = routes.filter((route) => {
			if (!route.menu) return false

			if (route.secure && !route.accessType?.includes(user.type)) return false

			return route
		})

		return menus
	}, [user.type])

	// const sideNavRoutes = listAllowedMenus()

	const isSeller = user.type === 'SELLER'

	const headerFilterProps = {
		isOpen,
		handleOpenSideNav,
		user: {
			name: isSeller ? user.fullName : user.fullName,
			isSeller,
			photoURL: user?.photoURL
		},
		store
	}

	const sideNavProps = {
		isOpen,
		handleOpenSideNav,
		routes: sideNavRoutes
	}

	return (
		<PageWrapper>
			<Header {...headerFilterProps} />

			<SideNav {...sideNavProps} />
			<Container>{children}</Container>
		</PageWrapper>
	)
}

export { Layout }
