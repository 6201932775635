import styled from 'styled-components'
import { mediaQuery } from '../../styles/function'
import { css } from 'styled-components'

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	padding-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	hr {
		border-top: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
		margin-bottom: 40px;
		width: 100%;
	}
`

export const LoadingContainer = styled.div`
	height: calc(100vh - 88px);
	display: flex;
	align-items: center;
	justify-content: center;

	* {
		color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	}
`

export const Head = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 1000px;
`

export const BackContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	cursor: pointer;
	width: fit-content;
`

export const BackIcon = styled.img`
	margin-right: 4px;
`

export const BackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const PageTitle = styled.h1`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 40px;
`

export const Content = styled.div`
	width: 100%;
	max-width: 650px;
	display: flex;
	flex-direction: column;
`

export const SectionTitle = styled.h3`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 16px;
`

export const RadioContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 16px;
	margin-bottom: 40px;

	${mediaQuery('sm')(`
		flex-direction: column;
	`)}
`

export const MediaSubTitle = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 32px;

	strong {
		color: ${({ theme }) => theme.COLORS.DARK_GRAY_MENU};
	}
`

export const MediaContainer = styled.div`
	display: flex;
	gap: 40px;
	margin-bottom: 40px;

	${mediaQuery('sm')(`
		justify-content:center;
	`)}
`

export const MediaGroup = styled.div`
	display: flex;
	flex-direction: column;
`

export const MediaLabel = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 22px;
`

export const MediaRow = styled.div`
	display: flex;
	gap: 8px;

	${mediaQuery('sm')(`
		flex-direction: column;	
	`)}
`

export const FormRow = styled.div`
	display: flex;
	width: 100%;
	gap: 16px;
	margin-bottom: 24px;
	position: relative;

	${mediaQuery('sm')(`

		flex-direction: column;

		> div {
			flex: 1;
		}
	`)}
`

export const WeightInputWrapper = styled.div`
	position: relative;
`

export const WeightInputBadge = styled.div`
	position: absolute;
	top: ${50/2 - 27/2 + 30}px; // metade_da_altura_do_input - metade_da_altura_da_badge + espaço_da_label_do_input
	right: 12px;
	height: 27px;
	min-width: 40px;
	padding: 0 6px;
	border-radius: 50px;
	background-color: ${({ theme }) => theme.COLORS.SOFT_BLUE};

	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	}
`

export const MassUnitOptionsWrapper = styled.div<{ hasError: boolean }>`
	${({ hasError }) => hasError ? css`
		display: grid;
		grid-template-rows: auto auto;
		grid-template-columns: auto auto;
		grid-column-gap: 10px;
		align-items: flex-start;
		padding-top: ${50/2 - 22/2 + 30}px; // metade_da_altura_do_input - metade_da_altura_do_radio_input + espaço_da_label_dos_inputs


		& > :nth-child(1), & > :nth-child(2) {
			grid-row: 1;
		}
		.error-message {
			grid-row: 2;
			grid-column: span 2;
		}
	` : css`
		display: flex;
		gap: 16px;
		align-items: flex-start;
		padding-top: ${50/2 - 22/2 + 30}px; // metade_da_altura_do_input - metade_da_altura_do_radio_input + espaço_da_label_dos_inputs
	`}
`

export const MassUnitErrorMessage = styled.p.attrs({ className: 'error-message' })`
	margin-top: 2px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.RED_ERROR};
	width: max-content;
`

export const FormColl = styled.div<{ flex?: number }>`
	flex: ${({ flex }) => flex};
	display: flex;
	flex-direction: column;
`

export const ShippingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`

export const PickupStoreContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

export const WarningPickupStoreContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
`

export const ContainerShippingPickup = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 94px;
	margin-bottom: 38px;
	margin-top: 24px;
`

export const Shipping = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
export const PickUpStore = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
export const InfoPickUpStore = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const ShippingItem = styled.div`
	display: flex;
	flex-direction: column;
`

export const ShippingItemHead = styled.div`
	display: flex;
	margin-bottom: 24px;
`

export const ShippingItemBody = styled.div`
	display: flex;
	margin-left: 28px;
	flex-direction: column;
`

export const InfoContainer = styled.div`
	margin-top: -15px;
	display: flex;
	margin-bottom: 20px;

	img {
		margin-right: 8px;
	}
`

export const Info = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.MEDIUM_GRAY};
	max-width: 380px;
`

export const AproveContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
`

export const AproveText = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 120px;
	gap: 40px;
	align-items: center;

	button {
		white-space: nowrap;
	}
`

export const ExcludeButton = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	cursor: pointer;
	user-select: none;

	:active {
		opacity: 0.5;
	}
`

export const ToolTipContainer = styled.div`
	position: absolute;
	top: 48%;
	left: -50px;
`

export const WarningContainer = styled.div`
	display: flex;
`

export const Warning = styled.img`
	width: 18px;
	margin-right: 8px;
`

export const WarningText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`
export const MediaContent = styled.div`
	display: flex;
	flex-direction: column;
`

export const CountdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	padding-bottom: 46px;

	> div {
		margin: 0;
	}
`
