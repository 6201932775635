import React from 'react'
import { FormControlLabel } from '@mui/material'

// types
import { CheckboxProps } from './types'

// styles
import * as S from './styles'

const Checkbox = ({ label, containerStyle, ...props }: CheckboxProps) => {
	return (
		<FormControlLabel
			label={label}
			style={containerStyle}
			control={<S.Checkbox {...props} />}
		/>
	)
}

export { Checkbox }
