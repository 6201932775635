//components
import { AboutContentBox, AuthHeader, Checkbox, Button } from '../../components'
import { Container } from '../../styles'
import * as S from './styles'

//icons
import arrowBack from '../../assets/icons/arrow-left.svg'

//types
import { IPageProps } from './types'
import { EAboutType } from '../../components/AboutContentBox/types'

function AcceptTermsView({
	goBackToCreateSeller,
	handleChangeAcceptTerms,
	handleAcceptTerms,
	acceptedTerms,
	isLoadingCreate,
	isLoadingUpdate
}: IPageProps) {
	return (
		<>
			<AuthHeader title='Cadastro de Perfil' />
			<Container>
				<S.Container>
					<S.Content>
						<S.GoBackContainer onClick={goBackToCreateSeller}>
							<S.ArrowBack src={arrowBack} />
							<S.GoBackText>Voltar</S.GoBackText>
						</S.GoBackContainer>
						<AboutContentBox
							type={EAboutType.TERMS}
							contentContainerStyle={{
								height: '40vh',
								overflowY: 'scroll'
							}}
						/>
						<Checkbox
							onChange={(_, checked) => handleChangeAcceptTerms(checked)}
							containerStyle={{
								marginTop: 56,
								marginBottom: 32,
								alignSelf: 'flex-start'
							}}
							label={
								<S.CheckboxLabel>
									Li e aceito os{' '}
									<S.CheckboxLabel highlight>Termos de Uso</S.CheckboxLabel> da{' '}
									<S.CheckboxLabel highlight>Harv Brasil</S.CheckboxLabel> e
									desejo concluir meu cadastro.
								</S.CheckboxLabel>
							}
						/>

						<S.DividingLine />

						<Button
							label='Finalizar Cadastro'
							onClick={handleAcceptTerms}
							disabled={!acceptedTerms}
							loading={isLoadingCreate || isLoadingUpdate}
						/>
					</S.Content>
				</S.Container>
			</Container>
		</>
	)
}

export { AcceptTermsView }
