import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	padding: 48px 24px;
`

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ContentHead = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 875px;
`

export const GoBackContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	width: min-content;
	cursor: pointer;
	margin-bottom: 16px;

	:active {
		opacity: 0.8;
	}
`

export const ArrowBack = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`

export const GoBackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const HeadTitle = styled.h1`
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0 0 32px;
`

export const Form = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 650px;
`

export const FormTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 46px;
`

export const FormRow = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	margin-bottom: 20px;

	@media screen and (max-width: 540px) {
		flex-direction: column;

		div {
			flex: 1;
		}
	}
`

export const Divisor = styled.hr`
	background: ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	width: 100%;
	margin-bottom: 40px;
`

export const PaymentTitle = styled(FormTitle)`
	margin-bottom: 16px;
`

export const PaymentSubtitle = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 54px;
`

export const AproveContainer = styled.div`
	align-items: center;
	justify-content: space-between;
	display: flex;
`

export const InativeContainer = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
`

export const StoreStatusContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
	gap: 22px;

	> div {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
`

export const StoreStatusTitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const AproveText = styled.h3`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const RefuseButton = styled.button`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	background: none;
	outline: none;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-right: 40px;
	cursor: pointer;
	user-select: none;
	border: none;

	:active {
		opacity: 0.6;
	}
`

export const InativeButton = styled.span`
	justify-self: center;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-right: 40px;
	cursor: pointer;
	user-select: none;

	:active {
		opacity: 0.6;
	}
`

export const ContainerStore = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 650px;
	background-color: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	margin-bottom: 40px;
`
export const ImgStore = styled.img`
	border-radius: 10px;
	object-fit: cover;
	width: 80px;
	height: 80px;
	margin: 16px;
	cursor: pointer;
`

export const Type = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`
export const InfoStore = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`
export const Title = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const ContainerStatus = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
