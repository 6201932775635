import { logout } from './session'

import { ERROR_TEXT } from './text'
import { Toaster } from './toaster'

export interface IError {
	statusCode: number
	message: string
}
function handleError(e: any): void {
	const error: IError = e?.request?.data || e?.response?.data
	const message = Array.isArray(error?.message)
		? error?.message[0]
		: error?.message

	if (error && error?.statusCode && error?.statusCode === 401) {
		Toaster({
			type: 'error',
			title: ERROR_TEXT.SESSION_EXPIRED
		})
		logout()

		return
	}

	if (!error) {
		Toaster({
			type: 'error',
			title: ERROR_TEXT.SERVER_UNAVAILABLE
		})

		return
	}

	if (error && error?.statusCode && message) {
		Toaster({
			type: 'error',
			title: message
		})

		return
	}
}

export default handleError
