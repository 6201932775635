import * as S from './styles'
import { ISearchInputProps } from './types'

//icons
import search from '../../assets/icons/search.svg'

function SearchInput({ placeholder = 'Buscar', ...rest }: ISearchInputProps) {
	return (
		<S.Container>
			<S.Input placeholder={placeholder} {...rest} />
			<S.Search src={search} alt='' />
		</S.Container>
	)
}

export { SearchInput }
