import { useCallback } from 'react'
import * as S from './styles'

//icons
import imageLogo from '../../../../assets/icons/harv-logo.svg'
import menuIcon from '../../../../assets/icons/menu.svg'
import closeIcon from '../../../../assets/icons/close.svg'
import editIcon from '../../../../assets/icons/edit.svg'

//types
import { IHeaderProps } from './types'
import { ERoutes } from '../../../../routes/routes'

//components
import { Avatar } from '@mui/material'
import { Notifications } from '../Notification'

//hooks
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

function stringToColor(string: string) {
	let hash = 0
	let i

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash)
	}

	let color = '#'

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff
		color += `00${value.toString(16)}`.slice(-2)
	}
	/* eslint-enable no-bitwise */

	return color
}

function stringAvatar(name: string) {
	if (!name) return undefined

	return {
		sx: {
			bgcolor: stringToColor(name)
		},
		children: `${name
			?.split(' ')
			.map((item) => item[0])
			.join('')}`
	}
}

function Header(props: IHeaderProps) {
	const navigate = useNavigate()
	const { user, handleOpenSideNav, isOpen, store } = props

	const handleRedirect = useCallback(() => {
		if (user.isSeller) {
			navigate(ERoutes.PRODUCTS)
		} else {
			navigate(ERoutes.ADMIN_SELLERS_LIST)
		}
	}, [user.isSeller, navigate])

	const displayName = store?.name ?? user?.name

	return (
		<S.Wrapper>
			<S.Menu>
				<button onClick={handleOpenSideNav}>
					{isOpen ? (
						<img src={closeIcon} alt='Icone para fechar o menu hamburguer' />
					) : (
						<img src={menuIcon} alt='Icone do menu hamburguer' />
					)}
				</button>
			</S.Menu>

			<S.Separator />

			<S.Container>
				<img src={imageLogo} alt='logo-icon' onClick={() => handleRedirect()} />

				<S.ActionsContent>
					<Link to={user.isSeller ? ERoutes.EDIT_SELLER : ''}>
						<S.AccountInfo>
							<Avatar
								alt={user.name}
								src={store?.photoURL ?? user?.photoURL}
								{...stringAvatar(displayName)}
							/>

							<S.Info>
								<span>{user.isSeller ? 'Minha Loja' : 'Admin'}</span>
								<p>{displayName}</p>
							</S.Info>

							{user.isSeller && (
								<S.EditButton>
									<img src={editIcon} alt='Icone para alterar informações' />
								</S.EditButton>
							)}
						</S.AccountInfo>
					</Link>
					<Notifications />
				</S.ActionsContent>
			</S.Container>
		</S.Wrapper>
	)
}

export default Header
