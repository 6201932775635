import { createElement, useCallback, useEffect } from 'react'
import { LoginView } from './view'

//utils
import { yupResolver } from '@hookform/resolvers/yup'
import { emailSchema } from './schema'

//hooks
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useIsLogged } from '../../hooks/useIsLogged'

//types
import { ILoginForm, IPageProps } from './types'
import { UserTypesEnum } from '../../types/user'

//services
import { useLogin } from '../../services/user.service'

//utils
import { formatLoginPayload } from '../../utils/formatPayload'

import { ERoutes } from '../../routes/routes'
import { useStores } from '../../hooks/useStore'

function Login() {
	const { userStore, tokenStore } = useStores()
	const navigate = useNavigate()
	const { isLogged } = useIsLogged()
	const { user } = useTypedSelector(['user'])

	const {
		control,
		handleSubmit,
		formState: { errors }
	} = useForm<ILoginForm>({
		resolver: yupResolver(emailSchema),
		mode: 'onChange',
		delayError: 1000 * 1 //1 second
	})

	const { mutateAsync: login, isLoading } = useLogin()

	const handleSignIn = handleSubmit(async (data: ILoginForm) => {
		const payload = formatLoginPayload(data)

		const loginResponse = await login(payload)

		const { user, accessToken } = loginResponse

		userStore.actions.setUser(user)
		tokenStore.actions.setToken({ accessToken })
	})

	const goToCreateSeller = useCallback(() => {
		navigate(ERoutes.CREATE_SELLER)
		// eslint-disable-next-line
	}, [])

	function redirect() {
		if (!isLogged) return

		if (user.type === UserTypesEnum.ADMIN) {
			navigate(ERoutes.ADMIN_SELLERS_LIST)
			return
		}

		if (user.type === UserTypesEnum.SELLER) {
			navigate(ERoutes.PRODUCTS)
			return
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(redirect, [isLogged])

	const pageProps: IPageProps = {
		control,
		errors,
		loading: isLoading,
		handleSignIn,
		goToCreateSeller
	}

	return createElement(LoginView, pageProps)
}

export { Login }
