export const parseItemPrice = (price: string | number): string => {
	const opts = { minimumFractionDigits: 2 }

	const formattedPrice = Number(price).toLocaleString('pt-BR', opts)

	return `R$ ${formattedPrice}`
}

export const MONEY_FORMATTER = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL'
})

export function toMoney(value: number): string {
	if (isNaN(value)) return ''

	// According to MDN and Node documentation itself, Node.js support only en-US locale
	// https://stackoverflow.com/questions/60011347/intl-numberformat-doesnt-convert-to-pt-br-locale
	return MONEY_FORMATTER.format(value)
}

export function toNumber(value: string): number {
	return +(+value.replace(MONEY_REGEX, '') / 100).toFixed(2)
}

export const MONEY_REGEX = /[R$,.]+/g

export const moneyMask = (value: number | string) => {
	return (Number(String(value).replace(/\D/g, '')) / 100).toLocaleString(
		'pt-BR',
		{
			style: 'currency',
			currency: 'BRL'
		}
	)
}

export const unmaskMoney = (value: number | string) => {
	return typeof value === 'number'
		? value
		: Number(value.replace(/\D/g, '')) / 100
}
