import { Controller } from 'react-hook-form'
import { TextArea } from '.'

import { IControlledTextArea } from './types'

function ControlledTextArea({
	control,
	name,
	maskFunction,
	...rest
}: IControlledTextArea) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<TextArea
					onChange={(e) => {
						maskFunction
							? onChange(maskFunction(e.target.value))
							: onChange(e.target.value)
					}}
					onBlur={onBlur}
					value={value || ''}
					name={name}
					{...rest}
				/>
			)}
		/>
	)
}

export { ControlledTextArea }
