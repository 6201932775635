import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	padding: 48px 0px;
`

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ContentHead = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 1000px;
`


export const HeadTitle = styled.h1`
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0 0 32px;
`

export const HeadSubtitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin: 0;
`

export const SellerEmail = styled(HeadSubtitle)`
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	margin-bottom: 72px;
`

export const ResendCodeLabel = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 24px 0 40px;
`

export const ResendCodeLink = styled(ResendCodeLabel)`
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	text-decoration: underline;
	&:hover {
		cursor: pointer;
	}
`
