import React from 'react'
import * as S from './styles'

//components
import { Link, useLocation } from 'react-router-dom'
import { Redirect } from '../../routes/redirect'

//hooks
import { useParams } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useIsLogged } from '../../hooks/useIsLogged'

//types
import { ERoutes } from '../../routes/routes'
import { EAboutType } from '../AboutContentBox/types'

import { AboutContentBox } from '../AboutContentBox'

export const navItems = [
	{ label: 'Conheça a Harv', name: EAboutType.HOME },
	{ label: 'Dúvidas Frequentes', name: EAboutType.DOUBTS },
	{ label: 'Vender na Harv', name: EAboutType.SELL },
	{ label: 'Fale Conosco', name: EAboutType.CONTACT },
	{ label: 'Termos de Uso', name: EAboutType.TERMS },
	{ label: 'Política de Privacidade', name: EAboutType.PRIVACY }
]

function About({ route }: { route: ERoutes }) {
	const { type } = useParams<{ type: EAboutType }>()
	const { search } = useLocation()
	const { user } = useTypedSelector(['user'])
	const { isLogged } = useIsLogged()

	const isAdmin = user?.type === 'ADMIN'

	if (!Object.values(EAboutType).includes(type as EAboutType)) {
		return <Redirect isAdmin={isAdmin} isLogged={isLogged} />
	}
	const isApp = search === '?app=true'

	return (
		<S.Container isApp={isApp}>
			{!isApp && (
				<>
					<S.NavigationLabel>Categorias</S.NavigationLabel>

					<S.NavigationContainer>
						{React.Children.toArray(
							navItems.map(({ label, name }) => {
								const isActive = type === name

								return (
									<Link to={`${route.replace(':type', name)}`}>
										<S.NavItem isActive={isActive}>
											<span>{label}</span>
										</S.NavItem>
									</Link>
								)
							})
						)}
					</S.NavigationContainer>
				</>
			)}

			<AboutContentBox type={type} isApp={isApp} />
		</S.Container>
	)
}

export { About }
