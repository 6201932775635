import * as S from './styles'

//components
import { SearchInput } from '../../components/SearchInput'
import { SellerOrderList } from '../../components/SellerOrdersList'

//types
import { IPageProps } from './types'

function OrdersView({
	orders,
	refetch,
	isLoading,
	isFetchedAfterMount,
	searchQuery,
	setSearchQuery,
	receivable,
	accountBalance,
	handleChangePage,
	filter,
	setFilter,
	dateFilter,
	setDateFilter,
	setStatus,
	status,
	totalItemsData
}: IPageProps) {
	return (
		<S.Container>
			<S.Head>
				<S.SearchContainer>
					<S.Title>Pedidos</S.Title>

					<SearchInput
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</S.SearchContainer>
			</S.Head>

			<S.CardsContainer>
				<S.Card>
					<S.ReceivableContainer>
						<S.ReceivableTitle>A receber</S.ReceivableTitle>
						<S.Receivable>{receivable}</S.Receivable>
					</S.ReceivableContainer>
				</S.Card>
				<S.Card>
					<S.BalanceContainer>
						<S.AccountBalanceTitle>Saldo em Conta</S.AccountBalanceTitle>
						<S.AccountBalance>{accountBalance}</S.AccountBalance>
					</S.BalanceContainer>
					<S.BalanceContainer>
						<S.AccountBalanceTitle>Taxa Harv</S.AccountBalanceTitle>
						<S.AccountBalance>15%</S.AccountBalance>
					</S.BalanceContainer>
				</S.Card>
			</S.CardsContainer>

			<S.Body>
				<SellerOrderList
					orders={orders}
					searchQuery={searchQuery}
					refetch={refetch}
					isLoading={isLoading}
					isFetchedAfterMount={isFetchedAfterMount}
					handleChangePage={handleChangePage}
					filter={filter}
					setFilter={setFilter}
					dateFilter={dateFilter}
					setDateFilter={setDateFilter}
					setStatus={setStatus}
					status={status}
					totalItemsData={totalItemsData}
				/>
			</S.Body>
		</S.Container>
	)
}

export { OrdersView }
