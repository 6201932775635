import React, { useCallback, useMemo } from 'react'
import * as S from './styles'

//icons
import options from '../../assets/icons/options.svg'

//types
import { ERoutes } from '../../routes/routes'
import { IAdminProductList, ISellerList } from './types'

//components
import { Status } from '../Status'
import { ListPagination } from '../ListPagination'

//hooks
import { useNavigate } from 'react-router-dom'
import { ColumnHead } from './ColumnHead'

function AdminProductsList({
	products,
	handleChangePage,
	filter,
	setFilter
}: ISellerList) {
	return (
		<S.Container>
			<S.TableContainer>
				<S.Table>
					<thead>
						<S.TableHeader>
							<S.HeaderPadding />
							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Produto ou Serviço'
								value='name'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Seller'
								value='store'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Tipo'
								value='type'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Categoria'
								value='category'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Status'
								value='status'
							/>

							<S.TableHeaderItem />
							<S.HeaderPadding />
						</S.TableHeader>
					</thead>

					<tbody>
						{products?.docs.map((props) => (
							<ListItem {...props} key={props._id} />
						))}
					</tbody>
				</S.Table>
			</S.TableContainer>

			<ListPagination
				showingItems={products?.docs.length}
				itemsLength={products?.totalDocs}
				itemsPerPage={products?.limit}
				onChange={handleChangePage}
			/>
		</S.Container>
	)
}

const ListItem = React.memo((products: IAdminProductList) => {
	const navigator = useNavigate()

	const moveToAproveProducts = useCallback(() => {
		navigator(ERoutes.ADMIN_PRODUCT_DETAILS.replace(':id', products._id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products._id])

	const productType = useMemo(() => {
		if (products.type === 'PRODUCT') return 'Produto'
		if (products.type === 'SERVICE') return 'Anúncio ou serviço'
		return ''
	}, [products.type])

	return (
		<S.TableRow>
			<S.BodyPadding />
			<S.TableItem>
				<S.ItemsContainer>
					{products.type === 'PRODUCT' ? (
						<S.ProductImage loading='lazy' src={products.imagesURL[0]} />
					) : (
						<S.ProductVideo muted loop src={products.videoURL} />
					)}
					{products.name}
				</S.ItemsContainer>
			</S.TableItem>

			<S.TableItem>{products.store.name}</S.TableItem>

			<S.TableItem>{productType}</S.TableItem>

			<S.TableItem>{products.category.label}</S.TableItem>
			<S.TableItem>
				<S.ItemsContainer>
					<Status status={products.status} />
				</S.ItemsContainer>
			</S.TableItem>

			<S.TableItem>
				<S.ItemsContainer>
					<S.Icons src={options} alt='' onClick={moveToAproveProducts} />
				</S.ItemsContainer>
			</S.TableItem>
			<S.BodyPadding />
		</S.TableRow>
	)
})

export { AdminProductsList }
