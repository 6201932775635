import { useEffect, useState } from 'react'
import * as S from './styles'

//components
import { Button } from '../Button'
import { Select } from '../Select'

//types
import { IFilter, ISellerListFilterProps } from './types'
import {
	getCities,
	getState,
	ISelectCity,
	ISelectState
} from '../../utils/handleAddress'

type FilterKey = keyof IFilter

function SellerListFilter({
	isOpen,
	filter,
	setFilter,
	onClick
}: ISellerListFilterProps) {
	const [states, setStates] = useState<ISelectState[]>()
	const [cities, setCities] = useState<ISelectCity[]>()

	function handleChangeFilter(value: string, key: FilterKey) {
		if (key === 'city') setFilter({ ...filter, city: value })
		if (key === 'state') setFilter({ ...filter, state: value })
		if (key === 'status') setFilter({ ...filter, status: value })
	}

	function handleResetFilter() {
		setFilter({ ...filter, city: '', state: '', status: '' })
	}

	//fetch Cities and clean
	useEffect(() => {
		filter.state &&
			getCities(filter.state).then((res) => {
				setCities(res)
			})
	}, [filter.state])

	//fetch states
	useEffect(() => {
		getState().then((res) => {
			setStates(res)
		})
	}, [])

	//clean city
	useEffect(() => {
		if (!filter.state) setFilter({ ...filter, city: '' })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter.state])

	return (
		<S.Container isOpen={isOpen}>
			<S.FiltersContainer>
				<Select
					flex={0.25}
					placeholder={'Estado'}
					items={states?.map((res) => ({ value: res.sigla }))}
					value={filter.state}
					onChange={(e: any) => handleChangeFilter(e, 'state')}
					floatingLabel='Estado'
				/>
				<Select
					flex={0.25}
					placeholder={'Cidade'}
					items={cities?.map((res) => ({ value: res.nome }))}
					value={filter.city}
					onChange={(e: any) => handleChangeFilter(e, 'city')}
					disabled={!filter.state}
					floatingLabel='Cidade'
				/>

				<Select
					flex={0.25}
					placeholder={'Selecione um Status'}
					items={[
						{ value: 'Pendente' },
						{ value: 'Recusado' },
						{ value: 'Aprovado' }
					]}
					value={filter.status}
					onChange={(e: any) => handleChangeFilter(e, 'status')}
					floatingLabel='Status'
				/>

				<Button label='Aplicar' onClick={onClick} />
				<S.Reset onClick={handleResetFilter}>Limpar</S.Reset>
			</S.FiltersContainer>
		</S.Container>
	)
}

export { SellerListFilter }
