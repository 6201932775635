import { useMemo } from 'react'
import * as S from './styles'

//types
import { EOrderStatus } from '../../types/orders'
import { EGatewayStatus, ISellerStatus } from '../../types/seller'

export interface IStatusProps {
	status?: ISellerStatus | EOrderStatus | EGatewayStatus
	isApproved?: boolean
}

function Status({ status, isApproved }: IStatusProps) {
	const statusName = useMemo(() => {
		if (status === 'DECLINED') return 'Recusado'
		if (status === 'ACTIVE') return 'Aprovado'
		if (status === 'PENDING') return 'Pendente'
		if (status === 'BLOCKED') return 'Bloqueado'
		if (status === 'DELETED') return 'Deletado'

		if (status === EOrderStatus.CANCELED) return 'Cancelado'
		if (status === EOrderStatus.FAILED) return 'Falhou'
		if (status === EOrderStatus.PAID) return 'Pago'
		if (status === EOrderStatus.ON_ROUTE) return 'A Caminho'
		if (status === EOrderStatus.PRODUCTION) return 'Em Produção'
		if (status === EOrderStatus.DELIVERED) return 'Entregue'

		if (status === EGatewayStatus.REGISTRATION) return 'Pendente'
		if (status === EGatewayStatus.INACTIVE) return 'Inativo'
		if (status === EGatewayStatus.REFUSED) return 'Recusado'
		if (status === EGatewayStatus.AFFILIATION) return 'Afiliação'
		if (status === EGatewayStatus.BLOCKED) return 'Bloqueado'

		if (isApproved !== undefined) {
			if (isApproved) return 'Aprovado'
			if (!isApproved) return 'Recusado'
		}
	}, [status, isApproved])

	return <S.Container status={status}>{statusName}</S.Container>
}

export { Status }
