import * as S from './styles'

//icons
import arrowBack from '../../assets/icons/arrow-left.svg'

//components
import { Button, InputText } from '../../components'
import { Status } from '../../components/Status'
import { DeclineSellerModal } from '../../components/DeclineSellerModal'

//types
import { IPageProps } from './types'
import { InativeSellerModal } from '../../components/InativeSellerModal'
import { useCallback } from 'react'
import { EAccountType } from '../CreateSeller/types'

function AdminSellerDetailsView({
	changeSellerStatus,
	goBackListSeller,
	sellerDetails: sd,
	openingHours,
	disabledByStatusGateway,
	isLoading,
	handleOpenAndCloseModal,
	resendForGateway,
	isModalOpen,
	resendLoading
}: IPageProps) {
	const handleShowPreview = useCallback(() => {
		sd?.store.photoURL && window.open(sd.store.photoURL.toString(), '_blank')
	}, [sd?.store.photoURL])

	return (
		<S.Container>
			{sd?.status === 'PENDING' ? (
				<DeclineSellerModal
					changeSellerStatus={changeSellerStatus}
					handleOpenAndCloseModal={handleOpenAndCloseModal}
					isModalOpen={isModalOpen}
					isLoading={isLoading}
				/>
			) : (
				<InativeSellerModal
					changeSellerStatus={changeSellerStatus}
					handleOpenAndCloseModal={handleOpenAndCloseModal}
					isModalOpen={isModalOpen}
					isLoading={isLoading}
				/>
			)}

			<S.Content>
				<S.ContentHead>
					<S.GoBackContainer onClick={goBackListSeller}>
						<S.ArrowBack src={arrowBack} />
						<S.GoBackText>Voltar</S.GoBackText>
					</S.GoBackContainer>
					<S.HeadTitle>Informações do Seller</S.HeadTitle>
				</S.ContentHead>
				<S.ContainerStore>
					<S.ImgStore src={sd?.store.photoURL} onClick={handleShowPreview} />
					<S.InfoStore>
						<S.Type>Seller</S.Type>
						<S.Title>{sd?.store.name}</S.Title>
					</S.InfoStore>
				</S.ContainerStore>
				<S.Form>
					<S.StoreStatusContainer>
						<div>
							<S.StoreStatusTitle>Situação do Cadastro</S.StoreStatusTitle>
							<Status isApproved={sd?.isApproved} status={sd?.status} />
						</div>
						<div>
							<S.StoreStatusTitle>Situação com o Gateway</S.StoreStatusTitle>
							<S.ContainerStatus>
								<Status
									isApproved={sd?.isApproved}
									status={sd?.statusGateway}
								/>
								{sd?.statusGateway === 'REFUSED' && (
									<Button
										label={'Reenviar'}
										onClick={resendForGateway}
										loading={resendLoading}
									/>
								)}
							</S.ContainerStatus>
						</div>
					</S.StoreStatusContainer>

					<S.Divisor />

					<S.FormTitle>Informações sobre a Loja</S.FormTitle>

					{(sd?.store.type === 'company' ||
						sd?.store.type === 'Pessoa Jurídica') && (
						<S.FormRow>
							<InputText
								disabled={true}
								value={sd?.store.companyRegistration || ''}
								floatingLabel={'Digite seu CNPJ'}
								placeholder={'Digite seu CNPJ'}
							/>

							<InputText
								disabled={true}
								value={sd?.store.companyName || ''}
								floatingLabel={'Informe a Razão Social'}
								placeholder={'Informe a Razão Social'}
							/>
						</S.FormRow>
					)}

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.store.name || ''}
							floatingLabel={'Nome do Perfil'}
							placeholder={'Ex. Panificadora Pão Puro'}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.store.bio || ''}
							floatingLabel={'Bio do seu Perfil'}
							placeholder={'Bio do seu Perfil'}
							maxLength={140}
							showMaxLength={true}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.store.address?.postalCode || ''}
							floatingLabel={'Informe o CEP'}
							placeholder={'CEP'}
							flex={0.4}
						/>

						<InputText
							disabled={true}
							value={sd?.store.address?.street || ''}
							floatingLabel={'Endereço'}
							placeholder={'Nome da Rua'}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.store.address?.number || ''}
							floatingLabel={'Número'}
							placeholder={'Número'}
							flex={0.35}
						/>

						<InputText
							disabled={true}
							value={sd?.store.address?.neighborhood || ''}
							floatingLabel={'Bairro'}
							placeholder={'Nome do Bairro'}
						/>

						<InputText
							disabled={true}
							name={'complement'}
							value={sd?.store?.address?.complement}
							floatingLabel={'Complemento ( Opcional )'}
							placeholder={'Bloco ou Apartamento'}
							flex={0.45}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.store.address?.state || ''}
							floatingLabel={'Estado'}
							placeholder={'Estado'}
							flex={0.19}
						/>

						<InputText
							disabled={true}
							value={sd?.store.address?.city || ''}
							floatingLabel={'Cidade'}
							placeholder={'Cidade'}
							flex={0.64}
						/>
					</S.FormRow>
					<S.Divisor />

					<S.PaymentTitle>Dados Bancários</S.PaymentTitle>
					<S.PaymentSubtitle>
						Usaremos estes dados para realizar o repasse de suas vendas
						diretamente na sua Conta Bancária.
					</S.PaymentSubtitle>

					<S.FormRow>
						<InputText
							disabled={true}
							value={
								sd?.store.bankAccount.bankCode +
									' - ' +
									sd?.store.bankAccount.bankName || ''
							}
							floatingLabel={'Banco'}
							placeholder={'Selecione o Banco'}
						/>

						<InputText
							disabled={true}
							value={sd?.store.bankAccount.agency || ''}
							floatingLabel={'Agência'}
							placeholder={'0000'}
							flex={0.4}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.store.bankAccount.account || ''}
							floatingLabel={'Número da Conta + Dígito Verificador'}
							placeholder={'000000'}
							flex={0.3}
						/>

						<InputText
							disabled={true}
							value={sd?.store.bankAccount.accountCheckDigit || ''}
							style={{ maxWidth: 50, marginRight: 'auto' }}
							placeholder={'0'}
							maxLength={1}
						/>

						<InputText
							disabled={true}
							value={sd?.store.bankAccount.holderName || ''}
							floatingLabel={'Nome do Titular da Conta'}
							placeholder={'Nome Completo'}
							flex={0.6}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={
								sd?.store.companyRegistration || sd?.personalRegistration || ''
							}
							floatingLabel={'CPF ou CNPJ'}
							placeholder={'Somente Números'}
							flex={0.5}
						/>

						<InputText
							disabled={true}
							value={
								sd?.store.bankAccount.type === 'checking'
									? EAccountType.CHECKING
									: EAccountType.SAVINGS || ''
							}
							floatingLabel={'Tipo de conta'}
							placeholder={'Tipo de conta'}
							flex={0.5}
						/>
					</S.FormRow>

					<S.Divisor />

					{/* <S.PixTitle>Chave Pix (Opcional)</S.PixTitle> 
					 <S.FormRow>
						<InputText
							disabled={true}
							value={sd?.store.bankAccount.pix?.type || ''}
							floatingLabel={'Tipo de Chave'}
							placeholder={'Selecione o Tipo'}
							flex={0.4}
						/>
						<InputText
							disabled={true}
							value={sd?.store.bankAccount.pix?.key || ''}
							floatingLabel={'Chave Pix'}
							placeholder={'Informe a Chave Pix relacionada ao seu banco'}
						/>
					</S.FormRow>
					<S.Divisor /> */}

					<S.FormRow>
						<S.PaymentTitle>Horário de Funcionamento</S.PaymentTitle>
					</S.FormRow>

					{openingHours &&
						openingHours?.map((openingHour, i) => (
							<S.FormRow key={i}>
								<InputText
									disabled={true}
									value={openingHour.startDay || ''}
									floatingLabel={'Inicia em:'}
									placeholder={'Data'}
									flex={0.5}
								/>
								<InputText
									disabled={true}
									value={openingHour.startTime || ''}
									placeholder={'Data'}
									flex={0.5}
								/>
								<InputText
									disabled={true}
									value={openingHour.endDay || ''}
									floatingLabel={'Termina em:'}
									placeholder={'Data'}
									flex={0.5}
								/>
								<InputText
									disabled={true}
									value={openingHour.endTime || ''}
									placeholder={'Data'}
									flex={0.5}
								/>
							</S.FormRow>
						))}

					<S.Divisor />

					<S.FormRow>
						<S.PaymentTitle>Informações Pessoais</S.PaymentTitle>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.firstName || ''}
							floatingLabel={'Nome'}
							placeholder={'Nome'}
						/>
						<InputText
							disabled={true}
							value={sd?.lastName || ''}
							floatingLabel={'Sobrenome'}
							placeholder={'Sobrenome'}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.personalRegistration || ''}
							floatingLabel={'CPF'}
							placeholder={'Somente Números'}
						/>

						<InputText
							disabled={true}
							value={sd?.email || ''}
							floatingLabel={'E-mail'}
							placeholder={'Digite um e-mail válido'}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							disabled={true}
							value={sd?.phone || ''}
							floatingLabel={'Telefone para Contato'}
							placeholder={'Número do Celular'}
							flex={0.5}
						/>
					</S.FormRow>

					<S.Divisor />

					{sd?.status === 'PENDING' ? (
						<S.AproveContainer>
							<S.AproveText>Aprovar Cadastro?</S.AproveText>

							<div>
								<S.RefuseButton
									onClick={handleOpenAndCloseModal}
									disabled={disabledByStatusGateway}
								>
									Recusar
								</S.RefuseButton>

								<Button
									label={'Aprovar'}
									onClick={() => changeSellerStatus('aprove')}
									loading={isLoading}
									disabled={disabledByStatusGateway || isLoading}
								/>
							</div>
						</S.AproveContainer>
					) : (
						<S.InativeContainer>
							<div>
								<S.InativeButton onClick={handleOpenAndCloseModal}>
									Inativar Seller
								</S.InativeButton>
							</div>
						</S.InativeContainer>
					)}
				</S.Form>
			</S.Content>
		</S.Container>
	)
}

export { AdminSellerDetailsView }
