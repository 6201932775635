import { createElement, useEffect } from 'react'
import { ChangePasswordView } from './view'

//utils
import { yupResolver } from '@hookform/resolvers/yup'
import { passwordSchema } from './schema'

//hooks
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

//types
import { ERoutes } from '../../routes/routes'
import { IChangePasswordForm, IPageProps } from './types'

//services
import {
	useChangePassword,
	useValidateResetToken
} from '../../services/user.service'

//utils
import { formatChangePasswordPayload } from '../../utils/formatPayload'
import { Toaster } from '../../utils/toaster'

function ChangePassword() {
	const navigate = useNavigate()
	const { resetToken } = useParams()

	const {
		control,
		handleSubmit,
		formState: { errors }
	} = useForm<IChangePasswordForm>({
		resolver: yupResolver(passwordSchema),
		mode: 'onChange',
		delayError: 1000 * 1 //1 second
	})

	const { mutateAsync: changePassword, isLoading: loading } =
		useChangePassword()

	const { error, isFetching: validateLoading } = useValidateResetToken({
		resetToken: resetToken as string
	})

	useEffect(() => {
		if (!error) return

		navigate(ERoutes.LOGIN)
	}, [error, navigate])

	const handleChangePassword = handleSubmit(
		async (data: IChangePasswordForm) => {
			const payload = formatChangePasswordPayload(data, resetToken!)
			try {
				await changePassword(payload)
				Toaster({
					type: 'success',
					title: 'Senha cadastrada com sucesso.'
				})

				handleReturn()
			} catch (error: any) {
				Toaster({
					type: 'error',
					title: 'Erro ao cadastrar a senha.',
					text: error.response.data.message
				})
			}
		}
	)

	function handleReturn() {
		navigate(ERoutes.LOGIN)
	}

	const pageProps: IPageProps = {
		control,
		errors,
		loading,
		validateLoading,
		handleReturn,
		handleChangePassword
	}

	return createElement(ChangePasswordView, pageProps)
}

export { ChangePassword }
