import React from 'react'
import * as S from './styles'

//icons
import Logo from '../../assets/icons/harv-logo.svg'

//types
import { HeaderProps } from './types'
import { Container } from '../../styles/index'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from '../../routes/routes'

function AuthHeader({ title }: HeaderProps) {
	const navigate = useNavigate()

	return (
		<S.Wrapper>
			<Container>
				<S.Content>
					<S.Image
						src={Logo}
						alt='img'
						onClick={() => navigate(ERoutes.LOGIN)}
					/>
					<S.Title>{title}</S.Title>
				</S.Content>
			</Container>
		</S.Wrapper>
	)
}

export { AuthHeader }
