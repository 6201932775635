import { ISeller, IStore } from './seller'

export type Login = {
	email: string
	password: string
}

export enum UserTypesEnum {
	'SELLER' = 'SELLER',
	'ADMIN' = 'ADMIN'
}

export type IUser = {
	firstName: string
	lastName: string
	email: string
	phone: string
	personalRegistration: string
	isApproved: boolean
	status: string
	type: UserTypesEnum
	verified: boolean
	createdAt: string
	updatedAt: string
	store: Pick<IStore, 'name' | 'photoURL' | '_id' | 'status'>
	fullName: string
	photoURL?: string
	id: string
}

export interface ILoginRequest {
	email: string
	password: string
}

export interface ILoginResponse {
	accessToken: string
	user: IUser
}

export interface IResetPasswordRequest {
	email: string
}

export interface IResetPasswordResponse {
	email: string
	name: string
	token: string
}

export interface IChangePasswordRequest {
	resetToken: string
	password: string
}

export interface IChangePasswordResponse {}

export interface ITokenRequest {
	token?: string
}

export type ITokenResponse = ISeller

export interface IValidateTokenRequest {
	resetToken: string
}

export interface IValidateTokenResponse {}
