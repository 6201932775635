import styled, { css } from 'styled-components'
import bg from '../../assets/background-pattern.png'
import { mediaQuery } from '../../styles/function'
import { Link } from 'react-router-dom'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`

export const Header = styled.div`
	width: 100%;
	height: 232px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
	padding: 0 50px;
	background-image: url(${bg});
	background-position: right;
	background-size: 300px;
	background-repeat: no-repeat;
	margin-bottom: 48px;
`

export const Row = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
`

export const HelpText = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const AboutContainer = styled.div<{ isApp: boolean }>`
	width: 100%;
	padding: 0 135px;
	margin-bottom: 120px;
	${({ isApp }) => {
		return (
			isApp &&
			css`
				padding: 0;
				margin: 0;
			`
		)
	}}

	${mediaQuery('sm')(`
				display: flex;
				flex-direction: column;
				padding: 0px;

    `)}
`
export const HeaderAbout = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 112px;
	padding: 0 134px;
	width: 100%;
	justify-content: space-between;

	img {
		cursor: pointer;
	}

	${mediaQuery('sm')(`
        margin-bottom: 16px;
        align-items: center;
				justify-content: space-between;
				padding: 0px 24px;
    `)}
`
export const Divisor = styled.hr`
	background: ${({ theme }) => theme.COLORS.LIGHT_WHITE};
	width: 100%;
	margin-bottom: 48px;
	height: 1px;
`

export const About = styled(Link)`
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`
