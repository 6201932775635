import * as S from './styles'

//icons
import { INotificationProductsResponse } from '../../types/notifications'

//components
//types
interface IProductCardProps {
	product: INotificationProductsResponse | undefined
}

function ProductCard({ product }: IProductCardProps) {
	return (
		<>
			{product ? (
				<S.Container>
					<S.ImageWrapper>
						<img src={product.imagesURL[0]} alt={product.name} />
					</S.ImageWrapper>

					<S.InfosWrapper>
						<S.CardTitle>Produto</S.CardTitle>
						<S.CardSbutitle>{product.name}</S.CardSbutitle>
					</S.InfosWrapper>
				</S.Container>
			) : null}
		</>
	)
}

export { ProductCard }
