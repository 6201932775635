import * as S from './styles'

//types
import { IPageProps } from './types'
import { ERoutes } from '../../routes/routes'

//icons
import help from '../../assets/icons/help.svg'

//components
import { Typography } from '../../components/Typography'
import { About } from '../../components/About'

function SellerAboutView(props: IPageProps) {
	return (
		<S.Container>
			<S.Header>
				<S.Row>
					<img src={help} alt='' />
					<Typography as='h2' color='grafitti'>
						Ajuda
					</Typography>
				</S.Row>

				<S.Row>
					<S.HelpText>Está com alguma dúvida? A Harv responde!</S.HelpText>
				</S.Row>
			</S.Header>

			<S.AboutContainer>
				<About route={ERoutes.SELLER_ABOUT} />
			</S.AboutContainer>
		</S.Container>
	)
}
export { SellerAboutView }
