export const ProductFormHours = Array.from({ length: 46 }).map((_, i) => {
	const value = Math.round(i / 2)
	return {
		value: `${value < 10 ? '0' + value : value}:${i % 2 !== 0 ? '00' : '30'}`
	}
})

export const ProductFormWeekDays = [
	{ value: 'Domingo' },
	{ value: 'Segunda' },
	{ value: 'Terça' },
	{ value: 'Quarta' },
	{ value: 'Quinta' },
	{ value: 'Sexta' },
	{ value: 'Sábado' }
]

export const ProductFormHoursFormated = Array.from({ length: 46 }).map((_, i) => {
	const value = Math.round(i / 2)
	return {
		value: `${value < 10 ? '0' + value : value}:${i % 2 !== 0 ? '00' : '30'}h`
	}
})

export const TimeToDelivery = {
	TODAY_30M_50M: 'Hoje: 30min - 50min',
	TODAY_51M_1H30M: 'Hoje: 51min - 1h30min',
	UNTIL_1D: 'Receba em até 1 dia útil',
	UNTIL_2D: 'Receba em até 2 dias úteis',
	UNTIL_7D: 'Receba em até 7 dias úteis ',
	UNTIL_15D: 'Receba em até 15 dias úteis'
}

export const ProductFormDays = Object.values(TimeToDelivery).map((value) => ({
	value
}))
