import styled from 'styled-components'
import { mediaQuery } from '../../styles/function'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 32px;
	margin-bottom: 120px;
`

export const Head = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;

	${mediaQuery('sm')(`
	flex-direction: column;
	align-items: start;
	
	`)}
`

export const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 450px;

	${mediaQuery('sm')(`
        flex-direction: column;
        margin-bottom: 16px;
        align-items: start;
    `)}
`

export const Title = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-right: 24px;
`

export const CardsContainer = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 56px;
`

export const Card = styled.div`
	width: 318px;
	height: 110px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`

export const ReceivableContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: 100%;
`

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
`

export const ReceivableTitle = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	margin-left: 20px;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const Receivable = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	margin-left: 20px;
	font-size: 24px;
	line-height: 32px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const AccountBalanceTitle = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	margin-left: 20px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const BalanceContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 5px 0;
	width: 100%;
	/* height: 100%; */
`

export const AccountBalance = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	margin-right: 20px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
