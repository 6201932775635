import styled, { css } from 'styled-components'

export const Container = styled.div`
	width: 100%;
	position: relative;
`

export const Table = styled.table`
	width: 100%;
	border-spacing: 8px;
	border-collapse: collapse;
`

export const TableRow = styled.tr`
	height: 70px;
	width: 100%;
	padding: 0 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-bottom: 2px solid ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
`

export const TableHeader = styled.tr`
	height: 70px;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	padding: 0 24px;

	th:nth-child(2) {
		min-width: 170px;
	}

	th:nth-child(3) {
		min-width: 160px;
	}
`

export const HeaderPadding = styled.th`
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	width: 16px;
`

export const BodyPadding = styled.td`
	width: 16px;
`

export const TableHeaderItem = styled.th`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const ItemsContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: flex-start;
	gap: 8px;
	a {
		display: flex;
		margin: 0px;
		margin-right: 16px;
		justify-content: flex-end;
	}
`

export const TableItem = styled.td`
	justify-content: center;
	align-items: center;
	height: 100%;
	min-width: 150px;
`

export const Icons = styled.img`
	margin: auto;
	cursor: pointer;

	:active {
		opacity: 0.6;
	}
`

export const TableContainer = styled.div`
	background: ${({ theme }) => theme.COLORS.WHITE};
`

export const PhotoContainer = styled.div`
	min-width: 38px;
	width: 38px;
	height: 38px;
	border-radius: 10px;
	overflow: hidden;
	margin-right: 4px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`

export const ProductContent = styled.div`
	padding-top: 16px;
	padding-bottom: 16px;
	padding-right: 16px;
`

export const TabContainer = styled.div`
	width: 100%;
	border-bottom: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	margin-bottom: 32px;
	gap: 40px;
	display: flex;
	align-items: flex-start;
`

export const TabItemContainer = styled.div`
	gap: 8px;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
`

export const TabItem = styled.span<{ active: boolean }>`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	color: ${({ theme, active }) =>
		active ? theme.COLORS.DARK_GRAFFITI : theme.COLORS.DARK_GRAY};
	padding-bottom: 18px;

	${({ theme, active }) =>
		active &&
		css`
			border-bottom: 2px solid ${theme.COLORS.PRIMARY_COLOR};
		`}
`

export const TabCounter = styled.div`
	padding: 2px 6px;
	background: ${({ theme }) => theme.COLORS.SOFT_BLUE};
	border-radius: 11px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	display: flex;
	align-items: center;
	margin-bottom: 18px;
	justify-content: center;
`

export const ButtonContainer = styled.div`
	margin-left: auto;
`
