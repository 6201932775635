import { Controller } from 'react-hook-form'
import { InputDate } from '.'

import { IControlledInputDate } from './types'

function ControlledInputDate({
	control,
	name,
	maskFunction,
	disabled = false,
	...rest
}: IControlledInputDate) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<InputDate
					onChange={(e) => {
						maskFunction
							? onChange(maskFunction(e.target.value))
							: onChange(e.target.value)
					}}
					onBlur={onBlur}
					disabled={disabled}
					value={value || ''}
					name={name}
					{...rest}
				/>
			)}
		/>
	)
}

export { ControlledInputDate }
