import { ISeller, ISellerListItem } from '../types/seller'

export function parseSellerList(
	sellers?: ISeller[]
): ISellerListItem[] | undefined {
	return sellers?.map((seller) => {
		const { fullName, status, store, id, isApproved } = seller

		return {
			fullName,
			status,
			city: store?.address?.city || '',
			state: store?.address?.state || '',
			profileName: store?.name,
			isApproved,
			id
		}
	})
}
