import { createElement, useEffect, useState } from 'react'
import { OrdersView } from './view'

//types
import { IFilter, IPageProps, ITotalItemsData, OrderStatus } from './types'
import { useReceivable, useSellerOrders } from '../../services/orders.service'
import { IPaginationResponse } from '../../types/pagination'
import { ISellerOrdersResponse } from '../../types/orders'
import { useTypedSelector } from '../../hooks/useTypedSelector'

function Orders() {
	const { data } = useReceivable()
	const [searchQuery, setSearchQuery] = useState('')
	const [receivable, setReceivable] = useState('0')
	const [accountBalance, setAccountBalance] = useState('0')
	const [pageIndex, setPageIndex] = useState(1)
	const [dateFilter, setDateFilter] = useState<Date | undefined>()
	const [status, setStatus] = useState<OrderStatus | undefined>(
		OrderStatus.PENDING
	)
	const [orders, setOrders] = useState<
		IPaginationResponse<ISellerOrdersResponse> | undefined
	>(undefined)

	const [totalItemsData, setTotalItemsData] = useState<
		ITotalItemsData | undefined
	>(undefined)
	const [filter, setFilter] = useState<IFilter>({
		sort: {
			by: '',
			sort: ''
		},
		order: -1
	})

	const { user } = useTypedSelector(['user'])

	const {
		data: ordersData,
		refetch,
		isLoading,
		isFetchedAfterMount
	} = useSellerOrders({
		maxItemsInPage: 5,
		pageIndex,
		searchString: searchQuery,
		order: filter.order,
		sort: filter.sort.sort,
		date: dateFilter,
		status: status,
		storeID: user.store._id
	})

	useEffect(() => {
		if (!ordersData) return

		setOrders(ordersData.paginationData)
		setTotalItemsData(ordersData.totalOrdersData)
	}, [ordersData])

	useEffect(() => {
		const receivableConvert = data?.waiting_funds_amount
			? (data?.waiting_funds_amount / 100).toString().replace('.', ',')
			: '0'
		const formatReceivable = `R$ ${receivableConvert}`

		const accountBalanceConvert = data?.available_amount
			? (data?.available_amount / 100).toString().replace('.', ',')
			: '0'
		const formatBalance = `R$ ${accountBalanceConvert}`
		setReceivable(formatReceivable)
		setAccountBalance(formatBalance)
	}, [data?.waiting_funds_amount, data?.available_amount])

	function handleChangePage(page: number) {
		setPageIndex(page)
	}

	const pageProps: IPageProps = {
		orders,
		refetch,
		isLoading,
		isFetchedAfterMount,
		searchQuery,
		setSearchQuery,
		receivable,
		setReceivable,
		accountBalance,
		setAccountBalance,
		handleChangePage,
		filter,
		setFilter,
		dateFilter,
		setDateFilter,
		setStatus,
		status,
		totalItemsData
	}

	return createElement(OrdersView, pageProps)
}

export { Orders }
