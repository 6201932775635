import axios from 'axios'

async function getCep(cep: string) {
	const url = await `https://viacep.com.br/ws/${cep}/json`

	const response = await axios.get(url)
	const address: IViaCepReturn = response.data

	return address
}

export interface IViaCepReturn {
	bairro: string
	cep: string
	complemento: string
	localidade: string
	logradouro: string
	uf: string
	//  erro: boolean
}

async function getState() {
	const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/`

	const response = await axios.get(url)

	return response.data as ISelectState[]
}

async function getCities(stateId: string) {
	const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`

	const response = await axios.get(url)

	return response.data as ISelectCity[]
}

export interface ISelectState {
	id?: string
	sigla: string
	nome: string
}

interface ISelectCity {
	id?: string
	nome: string
}
export { getCep, getState, getCities }
export type { ISelectCity }
