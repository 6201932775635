import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	padding: 48px 0px;
`

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ContentHead = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 1000px;
`

export const GoBackContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	width: min-content;
	cursor: pointer;
	margin-bottom: 16px;

	:active {
		opacity: 0.8;
	}
`

export const ArrowBack = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`

export const GoBackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const HeadTitle = styled.h1`
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0 0 32px;
`

export const HeadSubtitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin: 0 0 48px;
	max-width: 652px;
`

export const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 800px;
`

export const FormTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 46px;
`

export const FormRow = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	margin-bottom: 20px;

	@media screen and (max-width: 540px) {
		flex-direction: column;

		div {
			flex: 1;
		}
	}
`

export const Divisor = styled.hr`
	background: #e0e0e0;
	width: 100%;
	margin-bottom: 40px;
`

export const PaymentTitle = styled(FormTitle)`
	margin-bottom: 16px;
`

export const OpeningHourTitle = styled(FormTitle)`
	margin-bottom: 16px;
`

export const OpeningHourSubtitle = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 54px;
`

export const PaymentSubtitle = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 54px;
`

export const ButtonContainer = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;

	button {
		width: 160px;
	}
`

export const AddHourButtonContainer = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
	height: 30px;
	width: 30px;
	margin-top: 40px;
	button {
		width: 30px;
		height: 30px;
		border: none;
	}
`

export const AddHour = styled.button`
	background-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	border-radius: 21px;
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	> img {
		width: 18px;
		height: 18px;
	}
`

export const RemoveHour = styled.button`
	background-color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-radius: 21px;
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	> img {
		width: 18px;
		height: 18px;
	}
`

// export const PixTitle = styled(FormTitle)`
// 	margin-bottom: 54px;
// `

export const TutorialContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 76px;
	flex-wrap: wrap;
	gap: 57px;
`

export const TutorialItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	max-width: 200px;
	margin-bottom: 24px;
`

export const TutorialImg = styled.img`
	margin-bottom: 16px;
`

export const TutorialTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const PasswordText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 16px;
`

export const PhotoTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 8px;
`

export const PhotoSubTitle = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 16px;
`

export const PhotoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
`
