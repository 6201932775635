import React from 'react'
//icons
import Logo from '../../assets/icon-harv.png'

//components
import { AuthHeader } from '../../components'
import * as S from './styles'

//types

function SuccessRegistrationView() {
	return (
		<>
			<AuthHeader title={'Cadastrar Conta'} />
			<S.Wrapper>
				<S.Logo>
					<img src={Logo} alt='' />
				</S.Logo>
				<S.Title>Cadastro Concluído</S.Title>

				<S.Description>
					Agradecemos pelo seu interesse em fazer parte da Comunidade Harv!{' '}
				</S.Description>

				<S.Paragraph>
					Agora é só verificar seu e-mail para ativar seu cadastro e aguardar a
					aprovação pela nossa equipe!
				</S.Paragraph>
			</S.Wrapper>
		</>
	)
}

export { SuccessRegistrationView }
