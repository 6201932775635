import { ISellerStatus, IStore } from './seller'

export interface IProductsRequest {
	id: string
	accessToken: string
}

export interface IDeadline {
	startDate: string
	endDate: string
}

export interface IDelivery {
	type: string
	freeShipping: boolean
	pickupInStore: boolean
	deadline: number
	timeToDelivery: string
	startTime: string
	endTime: string
	weekDay?: number
	priceShipping: number
}

export enum EProductType {
	PRODUCT = 'PRODUCT',
	SERVICE = 'SERVICE'
}

export interface IProduct {
	_id: string
	videoURL: string
	imagesURL: string[]
	name: string
	description: string
	weight: string
	massUnit: MassUnitProduct
	type: EProductType
	category: string
	store: string
	isApproved: boolean
	quantity: number
	status: ISellerStatus
	delivery: IDelivery
	price: number
	priceDiscount: number
	createdAt: string
	updatedAt: string
	__v: number
	deadline: IDeadline
	videoStreamURL: string
	showInFeed: boolean
	readyToApprove: boolean
}

export interface IProductData extends Omit<IProduct, 'category' | 'store'> {
	category: ICategory
	store: IStore
}

export interface ICategory {
	_id: string
	slug: string
	label: string
}

export interface IProductsResponse {
	category: ICategory
	products: IProduct[]
}

export interface IRegisterProductRequest {
	formData: FormData
	id?: string
}
export interface IRegisterProductResponse {
	formData: FormData
}

export interface IRegisterProductResponse {}

export interface ICategoryResponse {
	_id: string
	label: string
	slug: string
}

export interface IPatchProductRequest {
	formData?: FormData
	id?: string
}

export interface IPatchProductResponse {}

export interface IAdminProductsResponse {
	_id: string
	name: string
	store: { _id: string; name: string }
	type: string
	category: { _id: string; label: string }
	status: ISellerStatus
	imagesURL: string[]
	videoURL: string
}

export type IChangeProductStatusAction = 'decline' | 'approve'

export interface IChangeProductStatusRequest {
	id: string
	message?: string
	action: IChangeProductStatusAction
}

export interface IChangeProductStatusResponse {}

export type IToggleProductResponse = Pick<IProduct, 'showInFeed'>

export enum MassUnitProduct {
	GRAMAS = 'GRAMAS',
	MILILITROS = 'MILILITROS'
}
