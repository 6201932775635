import React, { useMemo, useState } from 'react'
import * as S from './styles'

//icons
import options from '../../assets/icons/options.svg'
import filterOff from '../../assets/icons/filter.svg'
import filterOn from '../../assets/icons/filter-on.svg'

//types
import { ERoutes } from '../../routes/routes'
import { ISellerList } from './types'
import { IFilter } from '../SellerListFilter/types'
import { ISellerListItem } from '../../types/seller'

//components
import { Status } from '../Status'
import { SellerListFilter } from '../SellerListFilter'
import { ColumnHead } from '../ColumnHead'
import { ListPagination } from '../ListPagination'

//hooks
import { Link } from 'react-router-dom'

function SellersList({ sellers, searchQuery, itemsPerPage = 10 }: ISellerList) {
	const [isFilterOpen, setIsFilterOpen] = useState(false)
	const [filterControl, setFilterControl] = useState(false) //used to trigger filter memo function

	const [filter, setFilter] = useState<IFilter>({
		state: '',
		city: '',
		status: '',
		sort: {
			by: 'fullName',
			order: 'desc'
		},
		page: 1
	})

	const renderList = useMemo(() => {
		const {
			city,
			page,
			sort: { by, order },
			state,
			status
		} = filter
		let newList = sellers
		let filteredLength = 0

		//filter by search
		if (searchQuery) {
			newList = newList?.filter((item) => {
				return (
					item.fullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					item.profileName?.toLowerCase().includes(searchQuery.toLowerCase())
				)
			})

			setFilter({ ...filter, page: 1 })

			filteredLength = newList?.length || 0

			//paginate list
			newList = newList?.slice((page - 1) * itemsPerPage, itemsPerPage * page)
		}

		//filter by state
		if (state) {
			newList = newList?.filter(({ state: item }) => {
				return item === state
			})
		}

		//filter city
		if (city) {
			newList = newList?.filter(({ city: item }) => {
				return item === city
			})
		}

		//filter status
		if (status) {
			// eslint-disable-next-line array-callback-return
			newList = newList?.filter(({ status: item }) => {
				if (status === 'Pendente') return item === 'PENDING'
				if (status === 'Aprovado') return item === 'ACTIVE'
				if (status === 'Recusado')
					return item === 'BLOCKED' || item === 'DECLINED'
			})
		}

		//sort list
		newList = newList?.sort((a, b) => {
			if (order === 'asc') return a[by] < b[by] ? 1 : -1

			return a[by]?.toString().toLowerCase() > b[by]?.toString().toLowerCase()
				? 1
				: -1
		})

		if (!searchQuery && newList) {
			const listLength = newList.length
			newList = newList?.slice((page - 1) * itemsPerPage, itemsPerPage * page)

			return { list: newList, pages: listLength }
		}

		if (!newList?.length) return

		const searchPages =
			filteredLength % itemsPerPage > 0 ? filteredLength + 1 : filteredLength

		return { list: newList, pages: searchPages }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterControl, filter.page, filter.sort, sellers, searchQuery])

	function handleChangePage(page: number) {
		setFilter({ ...filter, page })
	}

	function handleOpenAndCloseFilter() {
		setIsFilterOpen((prev) => !prev)
	}

	function handleFilter() {
		setFilterControl((prev) => !prev)
		setIsFilterOpen(false)
	}

	return (
		<S.Container>
			<S.TableContainer>
				<S.Table>
					<thead>
						<S.TableHeader>
							<S.HeaderPadding />
							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Nome'
								value='fullName'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Nome do Perfil'
								value='profileName'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Aprovação Cadastro'
								value='status'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Estado'
								value='state'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Cidade'
								value='city'
							/>

							<S.TableHeaderItem>
								<S.ItemsContainer>
									<S.Icons
										src={isFilterOpen ? filterOn : filterOff}
										alt=''
										onClick={handleOpenAndCloseFilter}
									/>
								</S.ItemsContainer>
							</S.TableHeaderItem>
							<S.HeaderPadding />
						</S.TableHeader>

						<tr>
							<th colSpan={8}>
								<SellerListFilter
									isOpen={isFilterOpen}
									filter={filter}
									setFilter={setFilter}
									onClick={handleFilter}
								/>
							</th>
						</tr>
					</thead>

					<tbody>
						{renderList?.list?.map((props) => (
							<ListItem {...props} key={props.id} />
						))}
					</tbody>
				</S.Table>
			</S.TableContainer>

			<ListPagination
				showingItems={renderList?.list.length}
				itemsLength={renderList?.pages}
				itemsPerPage={itemsPerPage}
				onChange={handleChangePage}
			/>
		</S.Container>
	)
}

const ListItem = React.memo(
	({ fullName, profileName, status, city, state, id }: ISellerListItem) => {
		return (
			<S.TableRow>
				<S.BodyPadding />
				<S.TableItem>{fullName}</S.TableItem>

				<S.TableItem>{profileName}</S.TableItem>

				<S.TableItem>
					<S.ItemsContainer>
						<Status status={status} />
					</S.ItemsContainer>
				</S.TableItem>

				<S.TableItem>{state}</S.TableItem>

				<S.TableItem>{city}</S.TableItem>

				<S.TableItem>
					<S.ItemsContainer>
						<Link
							to={ERoutes.ADMIN_SELLER_DETAILS.replace(':id', id)}
							style={{ marginLeft: 'auto' }}
						>
							<S.Icons src={options} alt='' />
						</Link>
					</S.ItemsContainer>
				</S.TableItem>
				<S.BodyPadding />
			</S.TableRow>
		)
	}
)

export { SellersList }
