import { useState, useEffect, useRef } from 'react'
import * as S from './styles'

//icons
import close from '../../assets/icons/close.svg'

//components
import { Button } from '../Button'
import { InputText } from '../InputText'

//services
import { useChangeOrderStatus } from '../../services/orders.service'
import { Toaster } from '../../utils/toaster'

//types
import { EOrderStatus } from '../../types/orders'
import { ICancelOrderModalProps } from './types'

//hooks
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

function CancelOrderModal({
	handleOpenAndCloseModal,
	isModalOpen,
	orderId,
	onSuccess
}: ICancelOrderModalProps) {
	const [message, setMessage] = useState('')

	const modalRef = useRef<HTMLDivElement>(null)

	const { mutateAsync: changeStatusAsync, isLoading } = useChangeOrderStatus()

	async function handleCancelOrder(message: string) {
		if (orderId) {
			await changeStatusAsync({
				id: orderId,
				status: EOrderStatus.CANCELED,
				message
			})

			onSuccess && onSuccess()

			handleOpenAndCloseModal()

			Toaster({
				type: 'success',
				title: 'Pedido cancelado.',
				text: 'O pedido foi cancelado com sucesso!'
			})
		}
	}

	useEffect(() => {
		if (!isModalOpen) setMessage('')
	}, [isModalOpen])

	useOnClickOutside(modalRef, () => {
		isModalOpen && handleOpenAndCloseModal()
	})

	return (
		<S.Container isModalOpen={isModalOpen}>
			<S.Modal ref={modalRef}>
				<S.MoldaHead>
					<S.ModalTitle>Cancelar Pedido</S.ModalTitle>
					<img src={close} alt='' onClick={handleOpenAndCloseModal} />
				</S.MoldaHead>

				<S.ModalParagraph>
					Informe abaixo o motivo de recusa deste pedido para sinalizarmos ao
					comprador.
				</S.ModalParagraph>

				<S.InputContainer>
					<InputText
						placeholder='Escreva o motivo'
						onChange={(e) => setMessage(e.target.value)}
					/>
				</S.InputContainer>

				<S.ButtonsContainer>
					<S.Keep onClick={handleOpenAndCloseModal}>Manter</S.Keep>
					<Button
						label='Enviar'
						onClick={() => handleCancelOrder(message)}
						disabled={!message}
						loading={isLoading}
					/>
				</S.ButtonsContainer>
			</S.Modal>
		</S.Container>
	)
}

export { CancelOrderModal }
