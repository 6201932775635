import { Controller } from 'react-hook-form'
import { RadioText } from '.'

import { IControlledRadioText } from './types'

function ControlledRadioText({ control, name, ...rest }: IControlledRadioText) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<RadioText
					onChange={onChange}
					onBlur={onBlur}
					value={value || ''}
					name={name}
					{...rest}
				/>
			)}
		/>
	)
}

export { ControlledRadioText }
