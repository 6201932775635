import styled, { css } from 'styled-components'
import { IStatusProps } from '.'
import { EOrderStatus } from '../../types/orders'
import { EGatewayStatus } from '../../types/seller'

export const Container = styled.div<IStatusProps>`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 2px 8px;
	gap: 10px;
	min-width: 76px;
	width: fit-content;
	height: 22px;
	border-radius: 11px;

	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	align-items: center;

	${({ status, theme }) => {
		if (
			status === 'DECLINED' ||
			status === 'BLOCKED' ||
			status === 'CANCELED' ||
			status === 'REFUSED' ||
			status === EGatewayStatus.INACTIVE
		)
			return css`
				color: ${theme.COLORS.DARK_GRAY};
				background: ${theme.COLORS.LIGHT_GRAY};
			`

		if (
			status === 'ACTIVE' ||
			status === EOrderStatus.PRODUCTION ||
			status === EOrderStatus.ON_ROUTE ||
			status === EOrderStatus.DELIVERED ||
			status === EGatewayStatus.REGISTRATION ||
			status === EGatewayStatus.AFFILIATION
		)
			return css`
				color: ${theme.COLORS.PRIMARY_COLOR};
				background: ${theme.COLORS.SOFT_BLUE};
			`
		if (status === 'PAID')
			return css`
				color: ${theme.COLORS.PRIMARY_COLOR};
				background: ${theme.COLORS.SOFT_BLUE};
				min-width: 0px;
			`
		if (status === 'PENDING')
			return css`
				color: ${theme.COLORS.PRIMARY_COLOR};
				background: ${theme.COLORS.WHITE};
				border: 1px solid ${theme.COLORS.PRIMARY_COLOR};
			`
	}}
`
