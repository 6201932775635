import React, { useState } from 'react'
import * as S from './styles'

//icons
import EyeOpen from '../../assets/icons/eye-open.svg'
import EyeClose from '../../assets/icons/eye-close.svg'

//types
import { InputTextProps } from './types'
import { Info } from '../Info'

const InputText = ({
	label,
	placeholder = ' ',
	flex = 1,
	type = 'text',
	adornment,
	floatingLabel,
	errorMessage,
	disabled = false,
	showMaxLength,
	maxLength,
	value,
	info,
	style,
	...rest
}: InputTextProps) => {
	const [isFieldVisible, setIsFieldVisible] = useState(false)

	function handleSetFieldVisibility() {
		setIsFieldVisible((prev) => !prev)
	}

	return (
		<S.Container flex={flex} style={style}>
			<S.FloatingLabelContainer>
				<S.FloatingLabel>{floatingLabel}</S.FloatingLabel>

				{info?.title && <Info title={info.title} text={info.text} />}
			</S.FloatingLabelContainer>

			<S.InputContainer
				hasError={!!errorMessage}
				disabled={disabled}
				hasValue={!!value}
			>
				<S.Input
					type={isFieldVisible ? 'text' : type}
					placeholder={placeholder}
					maxLength={maxLength}
					value={value}
					disabled={disabled}
					{...rest}
				/>

				{type === 'password' && (
					<S.Eye
						src={isFieldVisible ? EyeOpen : EyeClose}
						onClick={handleSetFieldVisibility}
					/>
				)}

				{adornment}
			</S.InputContainer>

			{errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}

			{maxLength && showMaxLength && (
				<S.MaxLength>
					{value?.toString().length || 0}/{maxLength}
				</S.MaxLength>
			)}
		</S.Container>
	)
}

export { InputText }
