import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	position: relative;
`

export const Table = styled.table`
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
`

export const TableRow = styled.tr`
	height: 70px;
	width: 100%;
	padding: 0 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-bottom: 2px solid ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
	border-left: 16px solid ${({ theme }) => theme.COLORS.WHITE};
	border-right: 16px solid ${({ theme }) => theme.COLORS.WHITE};
`

export const TableHeader = styled.tr`
	height: 70px;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	padding: 0 24px;
	border-left: 16px solid ${({ theme }) => theme.COLORS.OFF_WHITE};
	border-right: 16px solid ${({ theme }) => theme.COLORS.OFF_WHITE};
`

export const FilterRow = styled.tr`
	border-left: 16px solid ${({ theme }) => theme.COLORS.OFF_WHITE};
	border-right: 16px solid ${({ theme }) => theme.COLORS.OFF_WHITE};
`

export const TableHeaderItem = styled.th`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const ItemsContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
`

export const TableItem = styled.td`
	justify-content: center;
	align-items: center;
	height: 100%;
`

export const Icons = styled.img`
	margin: auto;
	cursor: pointer;

	:active {
		opacity: 0.6;
	}
`

export const TableContainer = styled.div`
	background: ${({ theme }) => theme.COLORS.WHITE};
	overflow-x: auto;
`
