import { CircularProgress } from '@mui/material'
import React from 'react'
import { ButtonMui } from './styles'
import { ButtonProps } from './types'

const Button = ({
	label,
	disabled = false,
	onClick,
	loading = false,
	variant = 'contained',
	color = undefined,
	type
}: ButtonProps) => {
	return (
		<ButtonMui
			color={color}
			variant={variant}
			disabled={disabled}
			onClick={onClick}
			type={type}
			label={label}
		>
			{loading ? <CircularProgress size={25} color='inherit' /> : label}
		</ButtonMui>
	)
}

export { Button }
