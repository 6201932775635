import * as S from './styles'

//componentes
import { AuthHeader, Button } from '../../components'
import { ControlledInputText } from '../../components/InputText/controlled'

//icons
import arrowBack from '../../assets/icons/arrow-left.svg'

//types
import { Column, Container, Row } from '../../styles'
import { IPageProps } from './types'

function ResetPasswordView({
	control,
	errors,
	handleSignIn,
	handleReturn
}: IPageProps) {
	return (
		<>
			<AuthHeader title='Recuperar Senha' />
			<Container>
				<S.Wrapper>
					<Row>
						<Column sm='12' lg='7'>
							<S.PageInfo>
								<S.GoBackContainer>
									<S.ArrowBack src={arrowBack} />
									<S.GoBackText onClick={handleReturn}>Voltar</S.GoBackText>
								</S.GoBackContainer>
								<S.Title>Recuperar Senha</S.Title>
								<S.Paragraph>
									Informe o e-mail cadastrado para receber um link de
									recuperação de senha.
								</S.Paragraph>
							</S.PageInfo>
						</Column>
						<Column sm='12' lg='5'>
							<S.Card>
								<S.FormTitle>Digite seu email</S.FormTitle>

								<S.Form>
									<ControlledInputText
										name={'email'}
										control={control}
										floatingLabel='E-mail'
										placeholder='E-mail válido'
										errorMessage={errors.email?.message}
									/>

									<span>
										<Button
											//disabled={!isDirty || !isValid || loading}
											onClick={handleSignIn}
											label={'Enviar'}
										/>
									</span>
								</S.Form>
							</S.Card>
						</Column>
					</Row>
				</S.Wrapper>
			</Container>
		</>
	)
}

export { ResetPasswordView }
