import * as S from './styles'

//types
import { IPageProps } from './types'

//icons
// import help from '../../assets/icons/help.svg'
import HarvLogo from '../../assets/icons/harv-logo.svg'

//components
// import { Typography } from '../../components/Typography'
import { About } from '../../components/About'
import { ERoutes } from '../../routes/routes'
import { useLocation, useNavigate } from 'react-router-dom'

function ExternalAboutView(props: IPageProps) {
	const navigate = useNavigate()
	const { search } = useLocation()
	const isApp = search === '?app=true'
	return (
		<S.Container>
			{!isApp && (
				<>
					<S.HeaderAbout>
						<img
							src={HarvLogo}
							alt='harv-logo'
							onClick={() => navigate('/login')}
						/>
						<S.About to='/about/ '>Conheça a Harv</S.About>
					</S.HeaderAbout>
					<S.Divisor />
				</>
			)}
			<S.AboutContainer isApp={isApp}>
				<About route={ERoutes.EXTERNAL_ABOUT} />
			</S.AboutContainer>
		</S.Container>
	)
}
export { ExternalAboutView }
