import {
	QueryFunctionContext,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult
} from '@tanstack/react-query'

import {
	ICreateDeliveryAreaRequest,
	ICreateDeliveryAreaResponse,
	IDeleteDeliveryAreaRequest,
	IDeleteDeliveryAreaResponse,
	IDeliveryAreaResponse,
	IDeliveryAreasResponse,
	IUpdateDeliveryAreaRequest,
	IUpdateDeliveryAreaResponse
} from '../types/deliveryAreas'
import { QUERY_KEYS } from '../utils/reactQuery'
import axiosInstance from './axiosInstance'

export function useDeliveryAreas(
	storeID: string
): UseQueryResult<IDeliveryAreasResponse> {
	return useQuery([QUERY_KEYS.DELIVERY_AREAS, storeID], getDeliveryAreas, {
		retry: false,
		enabled: !!storeID
	})
}

async function getDeliveryAreas(context: QueryFunctionContext) {
	const [, storeID] = context.queryKey

	const response = await axiosInstance.get<IDeliveryAreasResponse>(
		`/delivery-areas/store/${storeID}`
	)

	return response.data
}

export function useDeliveryArea(
	id: string
): UseQueryResult<IDeliveryAreaResponse> {
	return useQuery([QUERY_KEYS.DELIVERY_AREA, id], getDeliveryArea, {
		enabled: !!id,
		retry: false
	})
}

export async function getDeliveryArea(context: QueryFunctionContext) {
	const [, id] = context.queryKey

	const response = await axiosInstance.get<IDeliveryAreaResponse>(
		`/delivery-areas/${id}`
	)

	return response.data
}

export function useCreateDeliveryArea(): UseMutationResult<
	ICreateDeliveryAreaResponse,
	unknown,
	ICreateDeliveryAreaRequest
> {
	return useMutation([QUERY_KEYS.DELIVERY_AREA], createDeliveryArea)
}

async function createDeliveryArea(payload: ICreateDeliveryAreaRequest) {
	const response = await axiosInstance.post<ICreateDeliveryAreaResponse>(
		'/delivery-areas',
		payload
	)

	return response.data
}

export function useUpdateDeliveryArea(): UseMutationResult<
	IUpdateDeliveryAreaResponse,
	unknown,
	IUpdateDeliveryAreaRequest
> {
	return useMutation([QUERY_KEYS.UPDATE_DELIVERY_AREA], updateDeliveryArea)
}

async function updateDeliveryArea({
	id,
	...payload
}: IUpdateDeliveryAreaRequest) {
	const response = await axiosInstance.patch<IUpdateDeliveryAreaResponse>(
		`/delivery-areas/${id}`,
		payload
	)

	return response.data
}

export function useDeleteDeliveryArea(): UseMutationResult<
	IDeleteDeliveryAreaResponse,
	unknown,
	IDeleteDeliveryAreaRequest
> {
	return useMutation([QUERY_KEYS.DELETE_DELIVERY_AREA], deleteDeliveryArea)
}

async function deleteDeliveryArea({ id }: IDeleteDeliveryAreaRequest) {
	const response = await axiosInstance.delete<IDeleteDeliveryAreaResponse>(
		`/delivery-areas/${id}`
	)

	return response.data
}
