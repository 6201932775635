import * as S from './styles'

//components
import { Button } from '../../components'
import { ControlledInputText } from '../../components/InputText/controlled'
import { ControlledSelect } from '../../components/Select/controlled'
import { Container } from '../../styles'

//types
import { IEditBankAccountForm } from './types'

//utils
import {
	accountNumberMask,
	agencyMask,
	documentMask
} from '../../utils/inputMasks'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
	useBankAccount,
	useUpdateBankAccount
} from '../../services/bankAccount.service'
import { formatBankCode, getBanks, IBank } from '../../utils/banks'
import { EAccountType } from '../CreateSeller/types'
import { editBankAccountSchema } from './schema'
import { formatChangeBankAccountPayload } from '../../utils/formatPayload'
import { ERoutes } from '../../routes/routes'
import { Toaster } from '../../utils/toaster'

function BankAccount() {
	const [banks, setBanks] = useState<IBank[]>([])

	const { data } = useBankAccount()

	const {
		control,
		handleSubmit,
		formState: { errors, isDirty },
		reset
	} = useForm<IEditBankAccountForm>({
		resolver: yupResolver(editBankAccountSchema),
		mode: 'onChange',
		delayError: 1000 * 1, //1 second,
		defaultValues: {}
	})

	function fetchFormFields() {
		getBanks().then((response) => {
			const filteredBanks = response.filter((bank) => {
				return bank.code !== null
			})

			const organizedBanks = filteredBanks.sort((a, b) => {
				return a.code < b.code ? -1 : 1
			})
			setBanks(organizedBanks)
		})
	}

	useEffect(fetchFormFields, [])

	const bankSelectItems = useMemo(
		() =>
			banks.map((bank) => ({
				value: formatBankCode(bank.code) + ' - ' + bank.name
			})),
		[banks]
	)

	async function fetchFields() {
		const bankCode = data?.bankCode as string

		const bank = bankSelectItems.find((item) => {
			return item.value.includes(bankCode.toString())
		})?.value

		reset({
			bank: bank,
			account_digit: data?.accountCheckDigit,
			account_number: data?.account,
			account_document: data?.holderDocument,
			account_name: data?.holderName,
			agency: data?.agency,
			account_type:
				data?.type === 'checking' ? EAccountType.CHECKING : EAccountType.SAVINGS
		})
	}

	useEffect(() => {
		fetchFields()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, reset, banks])

	const { mutateAsync: updateAsync, isLoading: isLoadingUpdate } =
		useUpdateBankAccount()

	const navigate = useNavigate()

	const submitForm = handleSubmit(async (data: IEditBankAccountForm) => {
		const payload = formatChangeBankAccountPayload(data)

		await updateAsync(payload)

		Toaster({
			type: 'success',
			title: 'Informações atualizadas com sucesso!'
		})

		reset()

		navigate(ERoutes.PRODUCTS)
	})

	return (
		<Container>
			<S.Container>
				<S.Content>
					<S.Form>
						<S.FormTitle>Dados Bancários</S.FormTitle>

						<S.WarningDescription>
							Atenção: É necessário que o titular da conta tenha o mesmo
							CPF/CNPJ cadastrado na sua loja.
						</S.WarningDescription>

						<S.FormRow>
							<ControlledSelect
								control={control}
								name={'bank'}
								floatingLabel={'Banco'}
								placeholder={'Selecione o Banco'}
								errorMessage={errors.bank?.message}
								items={bankSelectItems}
							/>

							<ControlledInputText
								control={control}
								name={'agency'}
								floatingLabel={'Agência'}
								placeholder={'0000'}
								flex={0.4}
								errorMessage={errors.agency?.message}
								maxLength={6}
								maskFunction={agencyMask}
							/>
						</S.FormRow>

						<S.FormRow>
							<ControlledInputText
								control={control}
								name={'account_number'}
								floatingLabel={'Número da Conta + Dígito Verificador'}
								placeholder={'000000'}
								flex={0.3}
								errorMessage={errors.account_number?.message}
								maxLength={12}
								maskFunction={accountNumberMask}
							/>
							<ControlledInputText
								style={{ maxWidth: 50, marginRight: 'auto' }}
								control={control}
								name={'account_digit'}
								placeholder={'0'}
								errorMessage={errors.account_digit?.message}
								maxLength={1}
							/>

							<ControlledInputText
								control={control}
								name={'account_name'}
								floatingLabel={'Nome do Titular da Conta'}
								placeholder={'Nome Completo'}
								errorMessage={errors.account_name?.message}
								flex={0.5}
							/>
						</S.FormRow>

						<S.FormRow>
							<ControlledInputText
								control={control}
								name={'account_document'}
								floatingLabel={'CPF ou CNPJ'}
								placeholder={'Somente Números'}
								errorMessage={errors.account_document?.message}
								maskFunction={documentMask}
								flex={0.5}
								maxLength={18}
								disabled={false}
							/>

							<ControlledSelect
								control={control}
								name={'account_type'}
								floatingLabel={'Tipo de conta'}
								placeholder={'Tipo de conta'}
								flex={0.5}
								errorMessage={errors.account_type?.message}
								items={[
									{ value: EAccountType.CHECKING },
									{ value: EAccountType.SAVINGS }
								]}
							/>
						</S.FormRow>

						<S.ButtonContainer>
							<Button
								label={'Salvar Informações'}
								onClick={submitForm}
								loading={isLoadingUpdate}
								disabled={!isDirty || Object.entries(errors).length > 0}
								type='submit'
							/>
						</S.ButtonContainer>
					</S.Form>
				</S.Content>
			</S.Container>
		</Container>
	)
}
export { BankAccount }
