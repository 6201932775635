import { Typography } from '@mui/material'
import { Modal } from '../../../../components/Modal'
import { DeleteModalProps } from './types'

function DeleteModal(props: DeleteModalProps) {
	return (
		<Modal
			id='delete-delivery-area-modal'
			title='Excluir Área'
			actionsMode='DELETE'
			{...props}
		>
			<Typography>Deseja confirmar esta ação?</Typography>
		</Modal>
	)
}

export { DeleteModal }
