import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import { IUser } from '../../../types'
import { IActionsFromCreators, IUserActionCreators } from '../../../types/action'
import { UserTypesEnum } from '../../../types/user'

/**
 * Action types & creators
 */
export const { Types, Creators }: IActionsFromCreators<IUserActionCreators> =
	createActions({
		setUser: ['user'],
		resetUser: []
	})

/**
 * Handlers
 */

export const INITIAL_STATE: IUser = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	personalRegistration: '',
	isApproved: false,
	status: '',
	type: UserTypesEnum.SELLER,
	verified: false,
	createdAt: '',
	updatedAt: '',
	store: { _id: '', name: '', photoURL: '', status: '' },
	fullName: '',
	id: ''
}

const set = (state = INITIAL_STATE, action: any) => action.user
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<IUser, Action<IUserActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_USER]: set,
		[Types.RESET_USER]: reset
	}
)
