import * as S from './styles'

//componentes
import { Button, AuthHeader } from '../../components'
import { ControlledInputText } from '../../components/InputText/controlled'
import { Column, Container, Row } from '../../styles'

//icons
import arrowBack from '../../assets/icons/arrow-left.svg'

//types
import { IPageProps } from './types'
import { Spinner } from '../../components/Spinner'

function ChangePasswordView({
	control,
	errors,
	loading,
	validateLoading,
	handleChangePassword,
	handleReturn
}: IPageProps) {
	return (
		<>
			<AuthHeader title='Cadastrar Senha' />
			<Container>
				<S.Wrapper>
					<Row>
						<Column md='12' lg='7'>
							<S.PageInfo>
								<S.GoBackContainer>
									<S.ArrowBack src={arrowBack} />
									<S.GoBackText onClick={handleReturn}>
										Voltar ao Login
									</S.GoBackText>
								</S.GoBackContainer>
								<S.Title>Cadastrar Nova Senha</S.Title>
								<S.Paragraph>
									Digite ao lado sua nova senha de acesso ao portal.
								</S.Paragraph>
							</S.PageInfo>
						</Column>
						<Column md='12' lg='5'>
							<S.Card>
								{validateLoading ? (
									<S.LoadingWrapper>
										<Spinner size='small' />
									</S.LoadingWrapper>
								) : (
									<>
										<S.FormTitle>Crie sua nova senha</S.FormTitle>

										<S.Form>
											<ControlledInputText
												control={control}
												name={'password'}
												floatingLabel={'Digite uma senha'}
												placeholder={'Senha'}
												type={'password'}
												errorMessage={errors.password?.message}
											/>

											<ControlledInputText
												control={control}
												name={'confirm_password'}
												floatingLabel={'Repetir sua Senha'}
												placeholder={'Digite sua senha novamente'}
												type={'password'}
												errorMessage={errors.confirm_password?.message}
											/>

											<S.PasswordText>
												Sua senha deve conter pelo menos 8 caracteres, 1 letra
												maiúscula, 1 letra minúscula, 1 caractere especial e 1 número
											</S.PasswordText>

											<span>
												<Button
													//disabled={!isDirty || !isValid || loading}
													onClick={handleChangePassword}
													label={'Cadastrar'}
												/>
											</span>
										</S.Form>
									</>
								)}
							</S.Card>
						</Column>
					</Row>
				</S.Wrapper>
			</Container>
		</>
	)
}

export { ChangePasswordView }
