import { ERegisterType } from '../views/CreateSeller/types'
import { IChangeSellerStatusActions } from '../views/AdminSellerDetails/types'
import { IUser, UserTypesEnum } from './user'

export interface ICreateSellerRequest {
	user: {
		firstName: string
		lastName: string
		email: string
		phone: string
		personalRegistration: string
		password: string
	}
	store: {
		companyRegistration: string
		companyName: string
		name: string
		address: {
			postalCode: string
			city: string
			state: string
			street: string
			neighborhood: string
			number: string
		}
	}
	bankAccount: {
		bankCode: number
		bankName: string
		holderName: string
		agency: string
		account: string
		// pix?: {
		// 	type?: string
		// 	key?: string
		// }
	}
}

export type ISellersResponse = ISeller[]

export interface ICreateSellerResponse {
	sucesso: true
}

export interface IActivateSellerRequest {
	activateToken: string
}

export interface IActivateSellerResponse {
	email: string
	firstName: string
	fullName: string
	id: string
	_id: string
}

export interface IPatchSellerRequest {
	payload: FormData
	token: String
}

export interface IChangeSellerStatusRequest {
	seller_id: string
	action: IChangeSellerStatusActions
	message?: string
}

export interface IChangeSellerStatusResponse {
	message?: string
	statusCode: number
}

export interface IResendResponse {
	message?: string
	statusCode: number
}

export type ISellerStatus =
	| 'ACTIVE'
	| 'PENDING'
	| 'BLOCKED'
	| 'DELETED'
	| 'DECLINED'

export interface ISeller {
	_id: string
	isApproved: boolean
	firstName: string
	lastName: string
	email: string
	phone: string
	personalRegistration: string
	status: ISellerStatus
	type: UserTypesEnum
	verified: boolean
	createdAt: string
	updatedAt: string
	store: IStore
	statusGateway: EGatewayStatus
	fullName: string
	id: string
}

export interface IOpeningHour {
	startDay: string
	endDay: string
	startTime: string
	endTime: string
}

export interface IStore {
	_id: string
	companyRegistration: string
	companyName: string
	commercialPhone: string
	name: string
	status: string
	bio: string
	address?: IAddress
	bankAccount: IBankAccount
	user: string
	openingHours: IOpeningHour[]
	createdAt: string
	updatedAt: string
	photoURL: string
	type: EStoreType | ERegisterType
	__v: number
	isOpen: boolean
}

export enum EStoreType {
	company = 'company',
	individual = 'individual'
}

export enum EGatewayStatus {
	REGISTRATION = 'REGISTRATION', // PENDENTE
	AFFILIATION = 'AFFILIATION', // NAO SEI
	ACTIVE = 'ACTIVE', // ATIVO
	REFUSED = 'REFUSED', // RECUSADO
	BLOCKED = 'BLOCKED', // BLOQUEADO
	INACTIVE = 'INACTIVE' // INATIVO
}

export interface IAddress {
	postalCode: string
	city: string
	state: string
	street: string
	neighborhood: string
	number: string
	location: ILocation
	complement: string
}

export interface ILocation {
	type: string
	coordinates: [number, number]
}

export interface IBankAccount {
	_id: string
	bankCode: number
	bankName: string
	holderName: string
	agency: string
	account: string
	accountCheckDigit: string
	type: 'checking' | 'savings'
	createdAt: string
	updatedAt: string
	v: number
	store: string
}

export interface IPix {
	key?: string
	type?: string
}

export interface ISellerListItem {
	fullName: string
	status: ISellerStatus
	city: string
	state: string
	profileName: string
	isApproved: boolean
	id: string
}

export interface ISellerDetailsRequest {
	seller_id?: string
}

export interface IResendRequest {
	seller_id?: string
}
export type ISellerDetailsResponse = ISeller

export enum statusGateway {
	REGISTRATION = 'REGISTRATION', // ACEITAÇÃO DO ADMIN
	AFFILIATION = 'AFFILIATION', // PENDENTE ACEITACÃO DO ADMIN
	ACTIVE = 'ACTIVE', // DELETADO LOGICAMENTE
	REFUSED = 'REFUSED', // RECUSADO PELO ADMIN
	BLOCKED = 'BLOCKED', // BLOQUEADO
	INACTIVE = 'INACTIVE' // BLOQUEADO
}

export interface ISellerDetails {}

export interface IShippingAddress {
	city: string
	state: string
	street: string
	neighborhood: string
	number: string
	postalCode: string
	complement?: string
}

export interface IUpdateSellerResponse {
	user: IUser
	store: IStore
}

export interface IActivateSellerByOtpRequest {
	email: string
	otp: string
}

export interface IOpenAndCloseRequest {
	storeID: string
}

export interface Switch {
	value: boolean
	label: string
}
