import styled from 'styled-components'
import bg from '../../assets/background-pattern.png'
import { Link } from 'react-router-dom'
import { mediaQuery } from '../../styles/function'

export const Container = styled.div`
	width: 100vw;
	min-height: 100vh;
	display: flex;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE} url(${bg}) no-repeat
		right / contain;
	flex-direction: column;

	${mediaQuery('lg')(`
		background-image: unset;
		gap: 72px;
		padding: 56px 0 0;
    `)}
`

export const content = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	max-width: 1160px;
	margin: auto;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	padding: 0 16px;

	${mediaQuery('lg')(`
		flex-direction: column;
		align-items: center;
		gap:48px;
    `)}
`

export const About = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 541px;

	> img {
		width: 149.25px;
		height: 72px;
		margin-bottom: 24px;
	}

	> h1 {
		margin-bottom: 32px;
		font-style: normal;
		font-weight: 400;
		font-size: 32px;
		line-height: 40px;
		color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	}

	> p {
		margin-bottom: 48px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	}

	${mediaQuery('lg')(`
		max-width: 430px;
    `)}
`

export const Form = styled.form`
	width: 100%;
	max-width: 430px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	padding: 72px 37px 48px;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		width: 100%;
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
		margin-bottom: 40px;
	}
`

export const Row = styled.div`
	width: 100%;
	margin-bottom: 24px;
`

export const RecoverPassword = styled(Link)`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	display: flex;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	width: fit-content;
	margin: 0 0 40px auto;
`

export const Footer = styled.footer`
	min-height: 136px;
	width: 100vw;
	border-top: 1px solid #e0e0e0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
	padding: 35px 16px;

	a {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
		text-align: start;

		${mediaQuery('xs')(`
			width: 100%;
   		`)}
	}

	${mediaQuery('lg')(`
		display:grid;
		grid-template-columns: repeat(2, 1fr);
		justify-items: center;
    `)}
`

export const Social = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	margin-left: 26px;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};

	a {
		img {
			width: 24px;
			height: 24px;
		}
	}

	${mediaQuery('lg')(`
		justify-content: center;
		grid-column: 1 / -1;
		margin: 0;
    `)}
`
