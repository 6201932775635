import styled from 'styled-components'
import { mediaQuery } from '../../styles/function'

export const AboutContainer = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	grid-column: 2;

	${mediaQuery('sm')(`
			width: fit-content;
			margin-top: 40px;
			margin-left: 24px;
			margin-right: 24px;
    `)}
`

export const AboutHeader = styled.div`
	height: 92px;
	padding: 0 24px;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #fafafa;
`

export const Title = styled.h2`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const AboutContentContainer = styled.div`
	padding: 32px 24px;
`

export const AboutContent = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};

	strong {
		color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	}

	div {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	li {
		margin-bottom: 24px;
	}

	ul {
		margin-left: 16px;

		li {
			margin: 0;
		}
	}
`
