import * as S from './styles'

//components
import { SearchInput } from '../../components/SearchInput'

//types
import { IPageProps } from './types'
import { AdminOrdersList } from '../../components/AdminOrdersList'

function AdminOrdersView({
	searchQuery,
	setSearchQuery,
	orders,
	handleChangePage,
	filter,
	setFilter
}: IPageProps) {
	return (
		<S.Container>
			<S.Head>
				<S.SearchContainer>
					<S.Title>Vendas</S.Title>

					<SearchInput
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</S.SearchContainer>
			</S.Head>

			<S.Body>
				<AdminOrdersList
					handleChangePage={handleChangePage}
					filter={filter}
					setFilter={setFilter}
					orders={orders}
					searchQuery={searchQuery}
				/>
			</S.Body>
		</S.Container>
	)
}

export { AdminOrdersView }
