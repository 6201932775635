import * as S from './styles'

//components
import { ControlledInputText } from '../../components/InputText/controlled'
import { ControlledInputMedia } from '../../components/InputMedia/controlled'
import { ControlledSelect } from '../../components/Select/controlled'
import { Info } from '../../components/Info'
import { Button } from '../../components'
import { Switch } from '../../components/Switch'

//icons
import backIcon from '../../assets/icons/arrow-left.svg'

//types
import { IPageProps } from './types'
import { RadioCard } from '../../components/RadioCard'
import { ControlledTextArea } from '../../components/TextArea/controlled'
import { EUnitType, convertToBytes } from '../../utils/data'

function ServiceView({
	control,
	sendToAprove,
	setSendToAprove,
	registrationType,
	handleSwitchRegister,
	categories,
	isLoading,
	submitForm,
	errors,
	isEdit,
	ExcludeModal,
	BackModal,
	isProduct
}: IPageProps) {
	const {
		Modal: Exclude,
		modalProps: excludeProps,
		toggle: toggleExclude
	} = ExcludeModal

	const { Modal: Back, modalProps: backProps, toggle: toggleBack } = BackModal

	return (
		<S.Container>
			<Exclude {...excludeProps} />
			<Back {...backProps} />

			<S.Head>
				<S.BackContainer onClick={toggleBack}>
					<S.BackIcon src={backIcon} />
					<S.BackText>Voltar</S.BackText>
				</S.BackContainer>
				<S.PageTitle>Detalhes do Serviço ou Anúncio</S.PageTitle>
			</S.Head>

			<S.Content>
				{!isEdit && (
					<>
						<S.SectionTitle>
							Primeiro, selecione o tipo de Cadastro
						</S.SectionTitle>
						<S.RadioContainer>
							<RadioCard
								value={'product'}
								selected={registrationType}
								label={'Cadastro de Produto'}
								onClick={() => handleSwitchRegister('product')}
							/>

							<RadioCard
								value={'service'}
								selected={registrationType}
								label={'Cadastro de Serviço ou Anúncio'}
								onClick={() => handleSwitchRegister('service')}
								info={{
									title: 'Cadastro de Serviço ou Anúncio',
									text: 'Se você quer cadastrar um Serviço ou apenas fazer um Anúncio, clique aqui. Nesta opção não é possível vender.'
								}}
							/>
						</S.RadioContainer>
						<hr />
					</>
				)}

				<S.SectionTitle>Upload de mídia</S.SectionTitle>
				<S.MediaSubTitle>
					O vídeo será publicado no Feed como destaque e por isso precisa estar
					no formato vertical. Resolução máxima de{' '}
					<strong> 20mb por vídeo</strong> e no máximo
					<strong> 50 segundos de duração.</strong> <br /> <br /> As imagens
					(fotos) serão exibidas em “Detalhes do Produto”. Resolução máxima de{' '}
					<strong>750kb por imagem. </strong>
				</S.MediaSubTitle>

				<S.MediaContainer>
					<S.MediaGroup>
						<S.MediaLabel>Vídeo do Feed</S.MediaLabel>

						<S.MediaRow>
							<ControlledInputMedia
								fileType='video'
								showLabel={true}
								errorMessage={errors.video?.message}
								name='video'
								control={control}
								max={convertToBytes(20, EUnitType.MB)}
							/>
						</S.MediaRow>
					</S.MediaGroup>
					<S.MediaGroup>
						<S.MediaLabel>Detalhes do Serviço</S.MediaLabel>
						<S.MediaRow>
							<ControlledInputMedia
								control={control}
								name='image_01'
								fileType='image'
								errorMessage={errors.image_01?.message}
								showLabel={true}
								max={convertToBytes(750, EUnitType.KB)}
							/>

							<ControlledInputMedia
								control={control}
								name='image_02'
								fileType='image'
								showLabel={true}
								max={convertToBytes(750, EUnitType.KB)}
							/>

							<ControlledInputMedia
								control={control}
								name='image_03'
								fileType='image'
								showLabel={true}
								max={convertToBytes(750, EUnitType.KB)}
							/>
						</S.MediaRow>
					</S.MediaGroup>
				</S.MediaContainer>

				<S.SectionTitle>Informações</S.SectionTitle>

				<S.FormRow>
					<ControlledInputText
						control={control}
						name={'name'}
						floatingLabel={'Nome do Serviço ou Anúncio'}
						placeholder='Nome do Serviço ou Anúncio'
						errorMessage={errors.name?.message}
						maxLength={60}
						showMaxLength={true}
					/>
				</S.FormRow>
				<S.FormRow>
					<ControlledSelect
						control={control}
						name={'category'}
						flex={0.5}
						floatingLabel={'Categoria do Serviço ou Anúncio'}
						placeholder='Categoria do Serviço ou Anúncio'
						items={categories}
						errorMessage={errors.category?.message}
					/>
					<S.ToolTipContainer style={{ left: '335px' }}>
						<Info
							title={'Categoria'}
							text={
								'O vídeo do Feed e as imagens inseridas devem pertencer a mesma categoria, caso contrário o cadastro de Serviço ou Anúncio não será aprovado.'
							}
							cardStyle={{ right: '-7px', top: '30px' }}
						/>
					</S.ToolTipContainer>
				</S.FormRow>
				<S.FormRow>
					<ControlledTextArea
						control={control}
						name={'description'}
						floatingLabel={'Descrição do Serviço'}
						placeholder='Descrição do Serviço'
						errorMessage={errors.description?.message}
						maxLength={1024}
						showMaxLength={true}
					/>
				</S.FormRow>
				<hr />

				<S.AproveContainer>
					<S.AproveText>
						Ao finalizar, enviar o serviço ou anúncio para aprovação?
					</S.AproveText>

					<Switch isActive={sendToAprove} setIsActive={setSendToAprove} />
				</S.AproveContainer>

				<S.ButtonContainer>
					{isEdit && (
						<S.ExcludeButton onClick={toggleExclude}>
							{isProduct ? 'Excluir Produto' : 'Excluir Serviço'}
						</S.ExcludeButton>
					)}

					<Button
						label={
							isEdit ? 'Salvar Alterações' : 'Cadastrar Serviço ou Anúncio'
						}
						onClick={submitForm}
						//disabled={isFormLocked || isLoading}
						loading={isLoading}
					/>
				</S.ButtonContainer>
			</S.Content>
		</S.Container>
	)
}

export { ServiceView }
