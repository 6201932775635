import * as S from './styles'

//components
import { RadioCard } from '../../components/RadioCard'
import { ControlledInputText } from '../../components/InputText/controlled'
import { ControlledInputMedia } from '../../components/InputMedia/controlled'
import { ControlledInputDate } from '../../components/InputDate/controlled'
import { ControlledRadioText } from '../../components/RadioText/controlled'
import { ControlledSelect } from '../../components/Select/controlled'
import { ControlledTextArea } from '../../components/TextArea/controlled'
import { Button } from '../../components'
import { Switch } from '../../components/Switch'

//icons
import backIcon from '../../assets/icons/arrow-left.svg'
import warningBlack from '../../assets/icons/warning-black.svg'

//types
import { IPageProps } from './types'
import { Info } from '../../components/Info'
import { priceMask, weightMask } from '../../utils/inputMasks'

import {
	ProductFormDays,
	ProductFormHours,
	ProductFormWeekDays
} from '../../mocks/form'
import { convertToBytes, EUnitType } from '../../utils/data'
import { MassUnitProduct } from '../../types/products'

function ProductView({
	control,
	watch,
	isFreeShipping,
	isPickupStore,
	setIsPickupStore,
	setIsFreeShipping,
	sendToAprove,
	setSendToAprove,
	registrationType,
	handleSwitchRegister,
	errors,
	submitForm,
	categories,
	isLoading,
	BackModal,
	isEdit,
	ExcludeModal,
	finalDate,
	inicialDate,
	handleCleanCountdown
}: IPageProps) {
	const { shipping_type, price, start_Date, start_Hour, massUnit } = watch()

	const isFreeDelivery = shipping_type === 'RELATIVE'

	const {
		Modal: Exclude,
		modalProps: excludeProps,
		toggle: toggleExclude
	} = ExcludeModal

	const { Modal: Back, modalProps: backProps, toggle: toggleBack } = BackModal

	return (
		<S.Container>
			<Exclude {...excludeProps} />
			<Back {...backProps} />

			<S.Head>
				<S.BackContainer onClick={toggleBack}>
					<S.BackIcon src={backIcon} />
					<S.BackText>Voltar</S.BackText>
				</S.BackContainer>
				<S.PageTitle>
					{isEdit
						? 'Detalhes do Produto'
						: 'Cadastro de Novo Produto ou Serviço'}
				</S.PageTitle>
			</S.Head>

			<S.Content>
				{!isEdit && (
					<>
						<S.SectionTitle>
							Primeiro, selecione o tipo de Cadastro
						</S.SectionTitle>

						<S.RadioContainer>
							<RadioCard
								value={'product'}
								selected={registrationType}
								label={'Cadastro de Produto'}
								onClick={() => handleSwitchRegister('product')}
								info={{
									title: 'Cadastro de Produto',
									text: 'Se você quer vender um produto, clique aqui.'
								}}
							/>

							<RadioCard
								value={'service'}
								selected={registrationType}
								label={'Cadastro de Serviço ou Anúncio'}
								onClick={() => handleSwitchRegister('service')}
								info={{
									title: 'Cadastro de Serviço ou Anúncio',
									text: 'Se você quer cadastrar um Serviço ou apenas fazer um Anúncio, clique aqui. Nesta opção não é possível vender.'
								}}
							/>
						</S.RadioContainer>
						<hr />
					</>
				)}

				<S.SectionTitle>Upload de mídia</S.SectionTitle>
				<S.MediaSubTitle>
					O vídeo será publicado no Feed como destaque e por isso precisa estar
					no formato vertical. Resolução máxima de{' '}
					<strong> 20mb por vídeo</strong> e no máximo de
					<strong> 50 segundos de duração.</strong> <br /> <br /> As imagens
					(fotos) serão exibidas em “Detalhes do Produto”. Resolução máxima de{' '}
					<strong>750kb por imagem. </strong>
				</S.MediaSubTitle>

				<S.MediaContainer>
					<S.MediaGroup>
						<S.MediaLabel>Vídeo do Feed</S.MediaLabel>

						<S.MediaRow>
							<ControlledInputMedia
								fileType='video'
								showLabel={true}
								errorMessage={errors.video?.message}
								name='video'
								control={control}
								max={convertToBytes(20, EUnitType.MB)}
							/>
						</S.MediaRow>
					</S.MediaGroup>

					<S.MediaGroup>
						<S.MediaLabel>Detalhes do Produto</S.MediaLabel>

						<S.MediaRow>
							<ControlledInputMedia
								control={control}
								name='image_01'
								fileType='image'
								errorMessage={errors.image_01?.message}
								showLabel={true}
								max={convertToBytes(750, EUnitType.KB)}
							/>

							<ControlledInputMedia
								control={control}
								name='image_02'
								fileType='image'
								showLabel={true}
								max={convertToBytes(750, EUnitType.KB)}
							/>

							<ControlledInputMedia
								control={control}
								name='image_03'
								fileType='image'
								showLabel={true}
								max={convertToBytes(750, EUnitType.KB)}
							/>
						</S.MediaRow>
					</S.MediaGroup>
				</S.MediaContainer>
				<S.SectionTitle>Informações</S.SectionTitle>

				<S.FormRow>
					<ControlledInputText
						control={control}
						name={'name'}
						floatingLabel={'Nome do Produto'}
						placeholder='Nome do Produto'
						errorMessage={errors.name?.message}
						maxLength={60}
						showMaxLength={true}
					/>
				</S.FormRow>

				<S.FormRow>
					<S.ToolTipContainer>
						<Info
							title={'Categoria'}
							text={
								'O vídeo do Feed e as imagens (fotos) inseridas devem pertencer a mesma categoria, caso contrário o CADASTRO DO PRODUTO não será aprovado.'
							}
							cardStyle={{ left: '40px' }}
						/>
					</S.ToolTipContainer>

					<ControlledSelect
						control={control}
						name={'category'}
						flex={0.5}
						floatingLabel={'Categoria do Produto'}
						placeholder='Categoria do Produto'
						items={categories}
						errorMessage={errors.category?.message}
					/>
				</S.FormRow>

				<S.FormRow>
					<ControlledInputText
						control={control}
						name={'quantity'}
						flex={0.3}
						floatingLabel={'Quantidade'}
						placeholder='0'
						type={'number'}
						min={0}
						errorMessage={errors.quantity?.message}
					/>
					<S.WeightInputWrapper>
						<ControlledInputText
							control={control}
							name={'weight'}
							flex={0.35}
							floatingLabel={'Peso do Produto'}
							placeholder='0,000'
							maskFunction={weightMask}
							errorMessage={errors.weight?.message}
						/>
						{massUnit ? (
							<S.WeightInputBadge>
								<span>
									{massUnit === MassUnitProduct.GRAMAS ? 'gramas' : 'ml'}
								</span>
							</S.WeightInputBadge>
						) : null}
					</S.WeightInputWrapper>
					<S.MassUnitOptionsWrapper hasError={!!errors.massUnit?.message}>
						<ControlledRadioText
							control={control}
							name='massUnit'
							value={MassUnitProduct.MILILITROS}
							label='ml'
							selected={massUnit}
						/>
						<ControlledRadioText
							control={control}
							name='massUnit'
							value={MassUnitProduct.GRAMAS}
							label='gramas'
							selected={massUnit}
						/>
						{!!errors.massUnit?.message && (
							<S.MassUnitErrorMessage>
								{errors.massUnit.message}
							</S.MassUnitErrorMessage>
						)}
					</S.MassUnitOptionsWrapper>
				</S.FormRow>

				<S.FormRow>
					<ControlledTextArea
						control={control}
						name={'description'}
						floatingLabel={'Crie uma Descrição para o Produto'}
						placeholder='Descrição do Produto'
						showMaxLength={true}
						maxLength={1024}
						errorMessage={errors.description?.message}
					/>
				</S.FormRow>

				<hr />

				<S.SectionTitle>Defina os Valores deste Produto</S.SectionTitle>

				<S.FormRow>
					<ControlledInputText
						control={control}
						name={'price'}
						flex={0.5}
						floatingLabel={'Preço Normal'}
						placeholder='Informe o Valor (R$)'
						info={{
							title: 'Preço Normal',
							text: 'Este valor é o preço do seu produto sem desconto.'
						}}
						maskFunction={priceMask}
						errorMessage={errors.price?.message}
					/>
					<S.FormColl flex={0.5}>
						<ControlledInputText
							control={control}
							name={'discount'}
							floatingLabel={'Preço com Desconto'}
							placeholder='Informe o Valor (R$)'
							info={{
								title: 'Preço com Desconto',
								text: 'Este valor é o preço do seu produto com desconto. Lembre-se que nessa modalidade o seu produto estará em oferta e isso pode potencializar suas vendas.'
							}}
							maskFunction={priceMask}
							errorMessage={errors.discount?.message}
							disabled={!price}
						/>
						{!price && (
							<S.WarningContainer>
								<S.Warning src={warningBlack} alt='' />
								<S.WarningText>Primeiro, defina o Preço Normal</S.WarningText>
							</S.WarningContainer>
						)}
					</S.FormColl>
				</S.FormRow>

				<hr />

				<S.SectionTitle>
					Informe o prazo de exibição deste anúncio (opcional){' '}
					<Info
						title='Prazo de Exibição'
						text='Estabeleça um prazo máximo de até 94 horas caso deseje limitar o prazo
						de exibição do anúncio. Após esse prazo, o anúncio será desativado.'
						textSecond='Para ativá-lo novamente, você deverá renovar o prazo de exibição nesse
						painel. Porém, se optar por não limitar o prazo de exibição, basta
						deixar os espaços em branco.'
					/>
				</S.SectionTitle>

				<S.CountdownContainer>
					<S.FormRow>
						<ControlledInputDate
							control={control}
							name={'start_Date'}
							errorMessage={errors.start_Date?.message}
							flex={0.25}
							floatingLabel={'Inicia em:'}
							placeholder='Data'
							type={'date'}
							min={inicialDate}
						/>

						<ControlledSelect
							control={control}
							name={'start_Hour'}
							flex={0.25}
							placeholder='Horário'
							items={ProductFormHours}
						/>

						<ControlledInputDate
							control={control}
							name={'end_Date'}
							flex={0.25}
							floatingLabel={'Termina em:'}
							errorMessage={errors.end_Date?.message}
							placeholder='Informe o Valor (R$)'
							type={'date'}
							disabled={!start_Date || !start_Hour}
							min={start_Date}
							max={start_Date && finalDate}
						/>

						<ControlledSelect
							control={control}
							name={'end_Hour'}
							flex={0.25}
							placeholder='Horário'
							items={ProductFormHours}
							errorMessage={errors.end_Hour?.message}
							disabled={!start_Date || !start_Hour}
						/>
					</S.FormRow>

					<Button
						label='Limpar'
						onClick={handleCleanCountdown}
						disabled={!start_Date}
					/>
				</S.CountdownContainer>

				<hr />

				<S.SectionTitle>Tipo de Entrega</S.SectionTitle>
				<S.ContainerShippingPickup>
					<S.ShippingContainer>
						<S.Shipping>Produto com Frete Grátis?</S.Shipping>

						<Switch isActive={isFreeShipping} setIsActive={setIsFreeShipping} />
					</S.ShippingContainer>
					<S.PickupStoreContainer>
						<S.PickUpStore>Pode ser retirado em seu endereço?</S.PickUpStore>
						<Switch isActive={isPickupStore} setIsActive={setIsPickupStore} />
					</S.PickupStoreContainer>
					<S.WarningContainer>
						<S.Warning src={warningBlack} />
						<S.InfoPickUpStore>
							Esteja ciente que o produto precisa estar a pronta entrega.
						</S.InfoPickUpStore>
					</S.WarningContainer>
				</S.ContainerShippingPickup>
				<S.ShippingItem>
					<S.ShippingItemHead>
						<ControlledRadioText
							control={control}
							name='shipping_type'
							value='RELATIVE'
							label='Entrega Livre'
							selected={shipping_type}
							info={{
								title: 'Entrega Livre',
								text: 'Informe em quanto tempo será efetuada a entrega.'
							}}
						/>
					</S.ShippingItemHead>

					<S.ShippingItemBody>
						<S.FormRow>
							<ControlledSelect
								control={control}
								name={'free_delivery_date'}
								floatingLabel='Previsão de Entrega'
								flex={0.475}
								placeholder='Selecione o Período'
								items={ProductFormDays}
								disabled={!isFreeDelivery}
								errorMessage={errors.free_delivery_date?.message}
							/>

							{/* <ControlledSelect
								control={control}
								name={'free_delivery_start'}
								floatingLabel='Horário Início'
								flex={0.25}
								placeholder='Horário'
								items={ProductFormHours}
								disabled={!isFreeDelivery}
								errorMessage={errors.free_delivery_start?.message}
							/>

							<ControlledSelect
								control={control}
								name={'free_delivery_end'}
								floatingLabel='Horário Fim'
								flex={0.25}
								placeholder='Horário'
								items={ProductFormHours}
								disabled={!isFreeDelivery}
								errorMessage={errors.free_delivery_end?.message}
							/> */}
						</S.FormRow>
					</S.ShippingItemBody>
				</S.ShippingItem>

				<S.ShippingItem>
					<S.ShippingItemHead>
						<ControlledRadioText
							control={control}
							name='shipping_type'
							value='FIXED'
							label='Entrega Programada'
							selected={shipping_type}
							info={{
								title: 'Entrega Programada',
								text: 'Programe o dia e o horário na semana em que você fará as entregas. Exemplo: toda QUINTA-FEIRA das 14:30h às 17:30h.'
							}}
						/>
					</S.ShippingItemHead>

					<S.ShippingItemBody>
						<S.FormRow>
							<ControlledSelect
								control={control}
								name={'scheduled_delivery_date'}
								floatingLabel='Data da entrega'
								flex={0.5}
								placeholder='Selecione o Período'
								items={ProductFormWeekDays}
								disabled={isFreeDelivery}
								errorMessage={errors.scheduled_delivery_date?.message}
							/>

							<ControlledSelect
								control={control}
								name={'scheduled_delivery_start'}
								floatingLabel='Horário Início'
								flex={0.25}
								placeholder='Horário'
								items={ProductFormHours}
								disabled={isFreeDelivery}
								errorMessage={errors.scheduled_delivery_start?.message}
							/>

							<ControlledSelect
								control={control}
								name={'scheduled_delivery_end'}
								floatingLabel='Horário Fim'
								flex={0.25}
								placeholder='Horário'
								items={ProductFormHours}
								disabled={isFreeDelivery}
								errorMessage={errors.scheduled_delivery_end?.message}
							/>
						</S.FormRow>
					</S.ShippingItemBody>
				</S.ShippingItem>

				<hr />

				<S.AproveContainer>
					<S.AproveText>
						Ao finalizar, enviar o produto para aprovação?
					</S.AproveText>

					<Switch isActive={sendToAprove} setIsActive={setSendToAprove} />
				</S.AproveContainer>

				<S.ButtonContainer>
					{isEdit && (
						<S.ExcludeButton onClick={toggleExclude}>
							Excluir Produto
						</S.ExcludeButton>
					)}

					<Button
						label={isEdit ? 'Salvar Alterações' : 'Cadastrar Produto'}
						onClick={submitForm}
						//disabled={isFormLocked || isLoading}
						loading={isLoading}
					/>
				</S.ButtonContainer>
			</S.Content>
		</S.Container>
	)
}

export { ProductView }
