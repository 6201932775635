import CloseIcon from '@mui/icons-material/Close'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { Button } from '../Button'
import { BaseDialogStyled, KeepText } from './styles'
import { ModalProps, ModalTitleProps } from './types'

const BaseDialogTitle = (props: ModalTitleProps) => {
	const { children, onClose, ...other } = props

	return (
		<DialogTitle
			sx={{
				mt: 3,
				ml: 1,
				mr: 1,
				mb: 3,
				p: 2,
				display: 'flex',
				justifyContent: 'space-between'
			}}
			{...other}
			fontSize={26}
		>
			{children}
			{onClose ? (
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{
						color: (theme) => theme.palette.grey[900]
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	)
}

function Modal({
	id,
	handleClose,
	isLoading,
	open,
	title,
	dividers = false,
	children,
	actionsMode,
	confirmLabel = 'Cadastrar',
	deleteLabel = 'Excluir',
	disabledActions,
	handleConfirm
}: ModalProps) {
	return (
		<BaseDialogStyled
			onClose={handleClose}
			aria-labelledby={id}
			open={open}
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 700 } }}
			maxWidth='xs'
		>
			<BaseDialogTitle id={id} onClose={handleClose}>
				{title}
			</BaseDialogTitle>
			<DialogContent dividers={dividers} sx={{ m: 1 }}>
				{children}
			</DialogContent>
			<DialogActions
				sx={{ alignItems: 'center', justifyContent: 'center', m: 3 }}
			>
				{actionsMode === 'CREATE' ? (
					<Button
						label={confirmLabel}
						onClick={handleConfirm}
						disabled={isLoading || disabledActions}
						loading={isLoading}
					/>
				) : (
					<>
						<KeepText onClick={handleClose}>Manter</KeepText>

						<Button
							color='inherit'
							label={deleteLabel}
							onClick={handleConfirm}
							disabled={isLoading || disabledActions}
							loading={isLoading}
						/>
					</>
				)}
			</DialogActions>
		</BaseDialogStyled>
	)
}

export { Modal }
