import styled from 'styled-components'

export const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: ${({ theme }) => theme.COLORS.OFF_WHITE};
	box-sizing: border-box;
	padding: 32px 24px;
`

export const Head = styled.div`
	width: 100%;
	min-height: 46px;
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
	gap:16px;

	@media screen and (max-width: 500px) {
		flex-direction: column;
	}
`

export const PageTitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-right: 32px;
`

export const Body = styled.div`
	width: 100%;
`

export const SearchContainer = styled.div`
	width: 100%;
	max-width: 320px;
	display: flex;
	align-items: center;
`
