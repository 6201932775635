import styled from 'styled-components'
import { mediaQuery } from '../../styles/function'

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	padding: 48px 24px;

	hr {
		border-top: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
		margin-bottom: 40px;
		width: 100%;
	}
`

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ContentHead = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 875px;
`

export const GoBackContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	width: min-content;
	cursor: pointer;
	margin-bottom: 16px;

	:active {
		opacity: 0.8;
	}
`

export const ArrowBack = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`

export const GoBackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const HeadTitle = styled.h1`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0 0 32px;
`

export const ProductDetails = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 650px;
`

export const SellerContainer = styled.div`
	width: 100%;
	height: 72px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 0 16px;
	margin-bottom: 40px;
`

export const SellerImage = styled.img`
	width: 38px;
	height: 38px;
	border-radius: 10px;
	object-fit: cover;
	margin-right: 12px;
`

export const SellerTitle = styled.span`
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const SellerName = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const SellerInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`

export const StatusTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const StatusContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	justify-content: space-between;
	gap: 24px;
`

export const MediaContent = styled.div`
	display: flex;
	flex-direction: column;
`

export const SectionTitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 22px;
`

export const MediaContainer = styled.div`
	display: flex;
	gap: 40px;
	margin-bottom: 40px;

	${mediaQuery('sm')(`
		justify-content:center;
	`)}
`

export const MediaGroup = styled.div`
	display: flex;
	flex-direction: column;
`

export const MediaLabel = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 22px;
`

export const MediaRow = styled.div`
	display: flex;
	gap: 8px;

	${mediaQuery('sm')(`
		flex-direction: column;	
	`)}
`

export const FormRow = styled.div`
	display: flex;
	width: 100%;
	gap: 16px;
	margin-bottom: 24px;
	position: relative;

	${mediaQuery('sm')(`

		flex-direction: column;

		> div {
			flex: 1;
		}
	`)}
`

export const ValueContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
`

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
`

export const DescriptionText = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const ShippingContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
`

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 32px;
	cursor: pointer;
	user-select: none;
`

export const Decline = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
