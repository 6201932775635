import * as S from './styles'
import { ISwitchProps } from './types'

function Switch({ isActive, setIsActive, onChange }: ISwitchProps) {
	function handleOnClick() {
		setIsActive && setIsActive((prev) => !prev)
		onChange && onChange(isActive)
	}

	return (
		<S.Container isActive={isActive} onClick={handleOnClick}>
			<S.Selector isActive={isActive} />
		</S.Container>
	)
}

export { Switch }
