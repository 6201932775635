import { cnpj, cpf } from 'cpf-cnpj-validator'
import * as yup from 'yup'
import { dayOfWeekLetter } from '../../utils/formatOpeningHour'
import { EDayOfWeek } from '../../utils/shipping'

import { EFormError } from '../../utils/texts'
import { ERegisterType } from './types'
import { passwordRegex, phoneRegex } from '../../utils/regex'

const createSellerSchema = yup.object({
	document: yup
		.string()
		.when('registerType', {
			is: 'Pessoa Jurídica',
			then: yup.string().required(EFormError.REQUIRED),
			otherwise: yup.string().notRequired()
		})
		.typeError(EFormError.INVALID_VALUE)
		.test('document test', EFormError.INVALID_DOCUMENT, (value) => {
			if (!value) return true
			if (cnpj.isValid(value || '')) return true
			return false
		})
		.when('registerType', {
			is: ERegisterType.COMPANY,
			then: yup.string().required(EFormError.REQUIRED)
		}),
	corporate_name: yup
		.string()
		.optional()
		.typeError(EFormError.INVALID_VALUE)
		.when('registerType', {
			is: ERegisterType.COMPANY,
			then: yup.string().required(EFormError.REQUIRED)
		}),
	store_name: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	cep: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.test('cep test', EFormError.INVALID_CEP, (value) => {
			if (value?.includes('-') && value.length === 9) return true
			return false
		}),
	address: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	number: yup
		.number()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	uf: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	district: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	complement: yup.string().typeError(EFormError.INVALID_VALUE),
	city: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	bank: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	agency: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.min(4, EFormError.INVALID_VALUE),
	account_number: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.min(4, EFormError.INVALID_VALUE)
		.max(12, EFormError.INVALID_VALUE),
	account_digit: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required('Digito obrigatório'),
	account_name: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	account_type: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	account_document: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.test('document test', EFormError.INVALID_DOCUMENT, (value) => {
			if (cnpj.isValid(value || '') || cpf.isValid(value || '')) return true
			return false
		}),
	first_name: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	last_name: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	cpf: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.test('document test', EFormError.INVALID_DOCUMENT, (value) => {
			if (cpf.isValid(value || '')) return true
			return false
		}),
	email: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.email(EFormError.EMAIL)
		.required(EFormError.REQUIRED),
	confirm_email: yup
	.string()
	.typeError(EFormError.INVALID_VALUE)
	.email(EFormError.EMAIL)
		.required(EFormError.REQUIRED)
		.oneOf([yup.ref('email')], EFormError.EMAIL_MATCH),
	phone: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.matches(phoneRegex, EFormError.INVALID_PHONE),
	commercial_phone: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	store_bio: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	password: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.matches(passwordRegex, EFormError.INVALID_PASSWORD)
		.min(8, EFormError.SHORT_PASSWORD),
	confirm_password: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.min(8, EFormError.SHORT_PASSWORD)
		.oneOf([yup.ref('password')], EFormError.PASSWORD_MATCH),
	registerType: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),

	// photo: yup.mixed().required()
	// photo: yup
	// 	.mixed()
	// 	.typeError(EFormError.INVALID_VALUE)
	// 	.required(EFormError.REQUIRED),
	openingHours: yup
		.array()
		.min(1, EFormError.REQUIRED)
		.max(3)
		.of(
			yup.object().shape({
				startDay: yup
					.string()
					.typeError(EFormError.INVALID_VALUE)
					.required(EFormError.REQUIRED)
					.test('', EFormError.INVALID_VALUE, (_value, ctx) => {
						const { startDay, endDay } = ctx.parent
						const start = dayOfWeekLetter(startDay)
						const end = dayOfWeekLetter(endDay)
						const week = Object.fromEntries(Object.entries(EDayOfWeek))
						if (!startDay || !endDay) return true
						if (week[start] > week[end]) return false
						return true
					}),
				endDay: yup
					.string()
					.typeError(EFormError.INVALID_VALUE)
					.required(EFormError.REQUIRED)
					.test('', EFormError.INVALID_VALUE, (_value, ctx) => {
						const { startDay, endDay } = ctx.parent
						const start = dayOfWeekLetter(startDay)
						const end = dayOfWeekLetter(endDay)
						const week = Object.fromEntries(Object.entries(EDayOfWeek))
						if (!startDay || !endDay) return true
						if (week[start] > week[end]) return false
						return true
					}),

				startTime: yup
					.string()
					.typeError(EFormError.INVALID_VALUE)
					.required(EFormError.REQUIRED)
					.test('', EFormError.INVALID_VALUE, (value, ctx) => {
						const { endTime, startDay, endDay, startTime } = ctx.parent
						if (!endTime || !value) return true
						if (startDay === endDay && startTime > endTime) return false
						return true
					}),
				endTime: yup
					.string()
					.typeError(EFormError.INVALID_VALUE)
					.test('', EFormError.INVALID_VALUE, (value, ctx) => {
						const { startTime, startDay, endDay, endTime } = ctx.parent
						if (!startTime || !value) return true
						if (startDay === endDay && startTime > endTime) return false
						return true
					})
			})
		)
})

export { createSellerSchema }
