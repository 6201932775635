import * as S from './styles'

//types
import { IRadioCardProps } from './types'

import { Info } from '../Info'

function RadioCard({
	value,
	name,
	selected,
	label,
	id,
	info,
	...rest
}: IRadioCardProps) {
	const checked = value === selected

	return (
		<S.Container checked={checked} htmlFor={id}>
			<S.RadioContainer>
				<S.Radio
					type={'radio'}
					name={name}
					value={value}
					checked={checked}
					id={id}
					readOnly
					{...rest}
				/>
			</S.RadioContainer>

			<S.CardLabel>{label}</S.CardLabel>

			{info &&
				<Info
					title={info?.title || ''}
					text={info?.text || ''}
					cardStyle={{ left: '-190px' }}
				/>
			}
		</S.Container>
	)
}

export { RadioCard }
