import * as S from './styles'

//icons
import OptionsSvg from '../../assets/icons/options.svg'
//types
import { ERoutes } from '../../routes/routes'
import { INotificationsList } from './types'

//components
import { ListPagination } from '../ListPagination'

//hooks
import { useNavigate } from 'react-router-dom'
import {
	INotificationSendResponse,
	PushNotificationStatus
} from '../../types/notifications'
import React from 'react'
import { ColumnHead } from './ColumnHead'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

function NotificationsList({
	notifications,
	handleChangePage,
	setFilter,
	filter
}: INotificationsList) {
	return (
		<S.Container>
			<S.TableContainer>
				<S.Table>
					<thead>
						<S.TableHeader>
							<S.HeaderPadding />
							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Título'
								value='title'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Motivo'
								value='reason'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Envio'
								value='sentAt'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Status'
								value='status'
							/>

							<S.TableHeaderItem>
								<S.ItemsContainer></S.ItemsContainer>
							</S.TableHeaderItem>
							<S.HeaderPadding />
						</S.TableHeader>
					</thead>

					<tbody>
						{notifications?.docs?.map((props) => (
							<ListItem {...props} key={props._id} />
						))}
					</tbody>
				</S.Table>
			</S.TableContainer>

			<ListPagination
				showingItems={notifications?.docs.length}
				itemsLength={notifications?.totalDocs}
				itemsPerPage={notifications?.limit}
				onChange={handleChangePage}
			/>
		</S.Container>
	)
}

const ListItem = React.memo((notification: INotificationSendResponse) => {
	const navigate = useNavigate()
	return (
		<S.TableRow>
			<S.BodyPadding />
			<S.TableItem>{notification.title}</S.TableItem>

			<S.TableItem>{notification.reason}</S.TableItem>
			{notification.sentAt ? (
				<S.TableItem>
					{format(new Date(notification.sentAt), 'dd/MM/yyyy HH:mm')}
				</S.TableItem>
			) : (
				<S.TableItem>-</S.TableItem>
			)}

			<S.TableItem>
				<S.TableStatusContainer>
					{notification.status === PushNotificationStatus.SENT ? (
						<S.TableSentStatus>
							<S.TableSentText>Enviado</S.TableSentText>
						</S.TableSentStatus>
					) : notification.status === PushNotificationStatus.DRAFT ? (
						<S.TableDraftStatus>
							<S.TableDraftText>Rascunho</S.TableDraftText>
						</S.TableDraftStatus>
					) : (
						<S.TableDraftStatus>
							<S.TableDraftText>Processando</S.TableDraftText>
						</S.TableDraftStatus>
					)}
				</S.TableStatusContainer>
			</S.TableItem>

			<S.TableItem>
				<S.ItemsContainer>
					<S.EditButton
						style={{
							border: 'none',
							backgroundColor: 'tranparent',
							background: 'none',
							marginLeft: 'auto',
							marginRight: 24
						}}
						onClick={() => {
							navigate(ERoutes.CREATE_NOTIFICATION, {
								state: { notification: notification }
							})
						}}
					>
						<S.Icons src={OptionsSvg} alt='' />
					</S.EditButton>
				</S.ItemsContainer>

				{/* <S.ItemsContainer>
					<Link
						to={ERoutes.ADMIN_SELLER_DETAILS.replace(':id', notification._id)}
						style={{ marginLeft: 'auto' }}
					>
						<S.Icons src={OptionsSvg} alt='' />
					</Link>
				</S.ItemsContainer> */}
			</S.TableItem>

			<S.BodyPadding />
		</S.TableRow>
	)
})

export { NotificationsList }
