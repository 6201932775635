import { createElement, useEffect, useState } from 'react'
import { AdminListNotifications } from './view'

//types
import { IPageProps } from './types'

//services
import { useListNotifications } from '../../services/notifications.service'
import { INotificationSendResponse } from '../../types/notifications'
import { IPaginationResponse } from '../../types/pagination'
import { IFilter } from '../../components/NotificationsList/types'

function AdminNotifications() {
	const [searchQuery, setSearchQuery] = useState('')
	const [pageIndex, setPageIndex] = useState(1)
	const [filter, setFilter] = useState<IFilter>({
		sort: {
			by: '',
			sort: ''
		},
		order: 1
	})
	const { data, isLoading } = useListNotifications({
		maxItemsInPage: 10,
		pageIndex: pageIndex,
		searchString: searchQuery,
		order: filter.order,
		sort: filter.sort.sort
	})

	const [notifications, setNotifications] = useState<
		IPaginationResponse<INotificationSendResponse> | undefined
	>(undefined)

	useEffect(() => {
		if (!data) return
		setNotifications(data)
	}, [data])

	function handleChangePage(page: number) {
		setPageIndex(page)
	}

	function setSearchString(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchQuery(e.target.value)
	}

	const pageProps: IPageProps = {
		notifications,
		isLoading,
		setSearchString,
		searchQuery,
		handleChangePage,
		setFilter,
		filter
	}

	return createElement(AdminListNotifications, pageProps)
}

export { AdminNotifications }
