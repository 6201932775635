import * as yup from 'yup'

import { EFormError } from '../../../../utils/texts'

const createDeliveryAreaSchema = yup.object({
	price: yup
		.string()
		.test(
			'price',
			EFormError.INVALID_VALUE,
			(price) =>
				Number(price?.replace(/[^0-9, ',']/g, '').replace(',', '.')) > 0
		)
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	area: yup
		.number()
		.positive(EFormError.INVALID_VALUE)
		.min(0.1, EFormError.INVALID_VALUE)
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),
	freeShipping: yup
		.boolean()
		.typeError(EFormError.INVALID_VALUE)
		.optional()
		.default(false)
})

export { createDeliveryAreaSchema }
