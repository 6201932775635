import * as S from './styles'

//icons
import arrowBack from '../../assets/icons/arrow-left.svg'

//utils
import { toMoney } from '../../utils/currency'
import { weightMask } from '../../utils/inputMasks'
import { TimeToDelivery } from '../../mocks/form'

//components
import { Button, InputText } from '../../components'
import { DeclineProductModal } from '../../components/DeclineProductModal'
import { Status } from '../../components/Status'
import InputMedia from '../../components/InputMedia'

//types
import { IPageProps } from './types'
import { TextArea } from '../../components/TextArea'
import { EDayOfWeek, getFixedShippingInfo } from '../../utils/shipping'

function AdminProductDetailsView({
	goBackListProducts,
	product,
	handleOpenAndCloseModal,
	isModalOpen,
	changeProductStatus,
	isLoading
}: IPageProps) {
	const deadLine = () => {
		if (!product?.deadline) return ''

		const startDate = new Date(product?.deadline?.startDate || '')
		const endDate = new Date(product?.deadline?.endDate || '')

		const deadLine = `${startDate.toLocaleDateString()} as ${
			startDate.getHours() < 10
				? '0' + startDate.getHours()
				: startDate.getHours()
		}:${
			startDate.getMinutes() < 10
				? '0' + startDate.getMinutes()
				: startDate.getMinutes()
		} 
	até 
	${endDate.toLocaleDateString()} as ${
			endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours()
		}:${
			endDate.getMinutes() < 10
				? '0' + endDate.getMinutes()
				: endDate.getMinutes()
		}`

		return deadLine
	}

	function deliveryString() {
		if (!product?.delivery) return ''

		if (product.delivery.type === 'RELATIVE')
			return Object.entries(TimeToDelivery).find(
				(item) => item[0] === product?.delivery.timeToDelivery
			)?.[1]

		return getFixedShippingInfo({
			endTime: product.delivery.endTime,
			startTime: product.delivery.startTime,
			dayOfWeek: product.delivery.weekDay as EDayOfWeek
		})
	}

	return (
		<S.Container>
			<DeclineProductModal
				changeProductStatus={changeProductStatus}
				handleOpenAndCloseModal={handleOpenAndCloseModal}
				isModalOpen={isModalOpen}
				isLoading={isLoading}
				type={product && product?.type}
			/>

			<S.Content>
				<S.ContentHead>
					<S.GoBackContainer onClick={goBackListProducts}>
						<S.ArrowBack src={arrowBack} />
						<S.GoBackText>Voltar</S.GoBackText>
					</S.GoBackContainer>

					<S.HeadTitle>Detalhes do Produto</S.HeadTitle>
				</S.ContentHead>

				<S.ProductDetails>
					<S.SellerContainer>
						<S.SellerImage src={product?.store.photoURL} />
						<S.SellerInfoContainer>
							<S.SellerTitle>Seller</S.SellerTitle>
							<S.SellerName>{product?.store.name}</S.SellerName>
						</S.SellerInfoContainer>
					</S.SellerContainer>

					<S.StatusContainer>
						<S.StatusTitle>Situação do Produto</S.StatusTitle>
						<Status status={product?.status} />
					</S.StatusContainer>

					<hr />

					<S.MediaContent>
						<S.SectionTitle>Imagens e Vídeo</S.SectionTitle>

						<S.MediaContainer>
							<S.MediaGroup>
								<S.MediaLabel>Vídeo do Feed</S.MediaLabel>

								<S.MediaRow>
									<InputMedia
										preview
										value={product?.videoURL}
										fileType='video'
										showLabel={true}
										disabled
									/>
								</S.MediaRow>
							</S.MediaGroup>

							<S.MediaGroup>
								<S.MediaLabel>Detalhes do Produto</S.MediaLabel>

								<S.MediaRow>
									{product?.imagesURL[0] && (
										<InputMedia
											preview
											value={product?.imagesURL[0]}
											fileType='image'
											showLabel={true}
											disabled
										/>
									)}

									{product?.imagesURL[1] && (
										<InputMedia
											preview
											value={product?.imagesURL[1]}
											fileType='image'
											showLabel={true}
											disabled
										/>
									)}

									{product?.imagesURL[2] && (
										<InputMedia
											preview
											value={product?.imagesURL[2]}
											fileType='image'
											showLabel={true}
											disabled
										/>
									)}
								</S.MediaRow>
							</S.MediaGroup>
						</S.MediaContainer>
					</S.MediaContent>

					<S.SectionTitle>Informações</S.SectionTitle>

					<S.FormRow>
						<InputText
							value={product?.name}
							disabled
							floatingLabel={'Nome do Produto'}
							placeholder='Nome do Produto'
							flex={0.5}
						/>
					</S.FormRow>

					<S.FormRow>
						<InputText
							value={product?.category.label}
							disabled
							flex={0.55}
							floatingLabel={'Categoria do Produto'}
							placeholder='Categoria do Produto'
						/>
						<InputText
							value={weightMask(product?.weight?.toString() || '0')}
							disabled
							flex={0.25}
							floatingLabel={
								product?.massUnit === 'MILILITROS' ? 'Peso (ml)' : 'Peso (g)'
							}
							placeholder='0,000'
						/>
						<InputText
							value={product?.quantity}
							disabled
							flex={0.25}
							floatingLabel={'Quantidade'}
							placeholder='0'
							type={'number'}
							min={0}
						/>
					</S.FormRow>
					<S.FormRow>
						<TextArea
							value={product?.description}
							disabled
							floatingLabel={'Descrição do Produto'}
							placeholder='Descrição do Produto'
						/>
					</S.FormRow>

					<hr />

					<S.ValueContainer>
						<S.SectionTitle>Valores</S.SectionTitle>

						<S.Row>
							<S.DescriptionText>Preço Normal</S.DescriptionText>

							<S.DescriptionText>
								{toMoney(product?.price || 0)}
							</S.DescriptionText>
						</S.Row>

						<S.Row>
							<S.DescriptionText>Preço com Desconto</S.DescriptionText>

							<S.DescriptionText>
								{toMoney(product?.priceDiscount || 0)}
							</S.DescriptionText>
						</S.Row>
						{deadLine() && (
							<S.Row>
								<S.DescriptionText>Data da Oferta</S.DescriptionText>

								<S.DescriptionText>{deadLine()}</S.DescriptionText>
							</S.Row>
						)}
					</S.ValueContainer>

					<hr />

					<S.ShippingContainer>
						<S.SectionTitle>Tipo de Entrega</S.SectionTitle>

						<S.Row>
							<S.DescriptionText>
								{product?.delivery.type === 'RELATIVE'
									? 'Entrega Livre'
									: 'Entrega Programada'}
							</S.DescriptionText>

							<S.DescriptionText>{deliveryString()}</S.DescriptionText>
						</S.Row>

						<S.Row>
							<S.DescriptionText>Produto com Frete Grátis?</S.DescriptionText>

							<S.DescriptionText>
								{product?.delivery.freeShipping ? 'Sim' : 'Não'}
							</S.DescriptionText>
						</S.Row>
					</S.ShippingContainer>

					<hr />

					<S.Row>
						<S.SectionTitle>Aprovar Produto?</S.SectionTitle>

						<S.ButtonsContainer>
							<S.Decline onClick={handleOpenAndCloseModal}>Recusar</S.Decline>
							<Button
								label='Aprovar'
								onClick={() =>
									changeProductStatus('approve', undefined, product?.type)
								}
								loading={isLoading}
							/>
						</S.ButtonsContainer>
					</S.Row>
				</S.ProductDetails>
			</S.Content>
		</S.Container>
	)
}

export { AdminProductDetailsView }
