import * as S from './styles'

//icons
import calendar from '../../assets/icons/calendar.svg'

//types
import { InputDateProps } from './types'

function InputDate({
	label,
	placeholder = ' ',
	flex = 1,
	type = 'date',
	floatingLabel,
	errorMessage,
	value,
	...rest
}: InputDateProps) {
	return (
		<S.Container flex={flex}>
			<S.FloatingLabel>{floatingLabel}</S.FloatingLabel>

			<S.InputContainer hasError={!!errorMessage}>
				<S.Input
					type={type}
					placeholder={placeholder}
					value={value}
					{...rest}
				/>
				<img src={calendar} alt='' />
			</S.InputContainer>

			{errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
		</S.Container>
	)
}

export { InputDate }
