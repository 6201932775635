import * as S from './styles'

//components
import { Button, AuthHeader } from '../../components'
import { ControlledInputText } from '../../components/InputText/controlled'
import { ControlledSelect } from '../../components/Select/controlled'
import { Container } from '../../styles'
import { ControlledInputMedia } from '../../components/InputMedia/controlled'
import { ControlledTextArea } from '../../components/TextArea/controlled'
import addIcon from '../../assets/icons/add-button.svg'
import noAddIcon from '../../assets/icons/no-add.svg'

//types
import { IPageProps, ERegisterType, EAccountType } from './types'

//utils
import {
	accountNumberMask,
	agencyMask,
	cepMask,
	cnpjMask,
	documentMask,
	numberMask,
	phoneMask
} from '../../utils/inputMasks'

//icons
import arrowBack from '../../assets/icons/arrow-left.svg'
import file from '../../assets/icons/icon-fileinfo.svg'
import money from '../../assets/icons/icon-money.svg'
import user from '../../assets/icons/icon-user.svg'
import sign from '../../assets/icons/icon-signup.svg'
import { ProductFormHoursFormated, ProductFormWeekDays } from '../../mocks/form'

//mocks
const tutorialItems = [
	{
		icon: file,
		title: 'Informe os dados do seu Perfil'
	},
	{
		icon: money,
		title: 'Informe os dados bancários'
	},
	{
		icon: user,
		title: 'Preencha os seus dados pessoais'
	},
	{
		icon: sign,
		title: 'Conclua sua solicitação e aguarde a aprovação de seu cadastro'
	}
]

function CreateSellerView({
	control,
	errors,
	states,
	submitForm,
	cities,
	searchCEP,
	bankSelectItems,
	watch,
	goBackToLogin,
	fields,
	addOpeningHour,
	removeOpeningHour,
	isSellerActiveByGateway,
	inputMediaRef
}: IPageProps) {
	const { registerType } = watch()

	const isCPF = registerType === ERegisterType.INDIVIDUAL

	return (
		<>
			<AuthHeader title='Cadastro de Perfil' />
			<Container>
				<S.Container>
					<S.Content>
						<S.ContentHead>
							<S.GoBackContainer onClick={goBackToLogin}>
								<S.ArrowBack src={arrowBack} />
								<S.GoBackText>Voltar</S.GoBackText>
							</S.GoBackContainer>

							<S.HeadTitle>
								Crie sua conta gratuitamente e comece a anunciar seus produtos
								ou serviços!
							</S.HeadTitle>

							<S.HeadSubtitle>
								Em alguns instantes você estará cadastrado na plataforma de
								vendas da Harv. Veja só como é simples:
							</S.HeadSubtitle>

							<S.TutorialContainer>
								{tutorialItems.map(({ icon, title }) => (
									<S.TutorialItem key={title}>
										<S.TutorialImg src={icon} />
										<S.TutorialTitle>{title}</S.TutorialTitle>
									</S.TutorialItem>
								))}
							</S.TutorialContainer>
						</S.ContentHead>

						<S.Form ref={inputMediaRef}>
							<S.FormTitle>Informações sobre o perfil</S.FormTitle>

							<S.PhotoContainer>
								<S.PhotoTitle>Foto da Loja</S.PhotoTitle>
								<S.PhotoSubTitle>
									Insira a imagem ou logo do seu Perfil para que seja visível
									aos usuários.
								</S.PhotoSubTitle>

								<ControlledInputMedia
									control={control}
									name={'photo'}
									errorMessage={errors.photo?.message?.toString()}
									fileType='image'
								/>
							</S.PhotoContainer>

							<S.Divisor />

							<S.FormRow>
								<ControlledSelect
									control={control}
									name={'registerType'}
									errorMessage={errors.registerType?.message}
									floatingLabel={'Cadastrar como'}
									placeholder={'Tipo de Cadastro'}
									flex={0.5}
									items={[
										{ value: ERegisterType.COMPANY },
										{ value: ERegisterType.INDIVIDUAL }
									]}
									disabled={isSellerActiveByGateway}
								/>
								<ControlledInputText
									control={control}
									name={'commercial_phone'}
									floatingLabel={'Telefone Comercial'}
									placeholder={'Número de telefone da sua loja'}
									errorMessage={errors.phone?.message}
									maxLength={15}
									flex={0.5}
									maskFunction={phoneMask}
								/>
							</S.FormRow>

							{!isCPF && (
								<S.FormRow>
									<ControlledInputText
										control={control}
										name={'document'}
										floatingLabel={'Digite seu CNPJ'}
										placeholder={'Digite seu CNPJ'}
										errorMessage={errors.document?.message}
										maskFunction={cnpjMask}
										maxLength={18}
										disabled={!registerType || isSellerActiveByGateway}
										flex={0.5}
									/>

									<ControlledInputText
										control={control}
										name={'corporate_name'}
										floatingLabel={'Informe a Razão Social'}
										placeholder={'Informe a Razão Social'}
										errorMessage={errors.corporate_name?.message}
										disabled={!registerType || isSellerActiveByGateway}
										flex={0.5}
										maxLength={30}
									/>
								</S.FormRow>
							)}
							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'email'}
									floatingLabel={'E-mail'}
									placeholder={'Digite um e-mail válido'}
									errorMessage={errors.email?.message}
									disabled={isSellerActiveByGateway}
									flex={0.5}
								/>
								<ControlledInputText
									control={control}
									name={'confirm_email'}
									floatingLabel={'Digite seu E-mail novamente'}
									placeholder={'Digite um e-mail válido'}
									errorMessage={errors.confirm_email?.message}
									autoComplete='new-email'
									disabled={isSellerActiveByGateway}
									flex={0.5}
								/>
							</S.FormRow>
							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'store_name'}
									floatingLabel={'Nome do Perfil'}
									placeholder={'Ex. Panificadora Pão Puro'}
									errorMessage={errors.store_name?.message}
									disabled={isSellerActiveByGateway}
								/>
							</S.FormRow>
							<S.FormRow>
								<ControlledTextArea
									control={control}
									name={'store_bio'}
									floatingLabel={'Bio do seu Perfil'}
									placeholder={
										'Descreva sobre o que o seu Perfil oferece, horário de funcionamento, etc.'
									}
									errorMessage={errors.store_bio?.message}
									showMaxLength={true}
									maxLength={140}
								/>
							</S.FormRow>
							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'cep'}
									floatingLabel={'CEP'}
									placeholder={'CEP'}
									flex={0.4}
									errorMessage={errors.cep?.message}
									maskFunction={cepMask}
									maxLength={9}
									onBlur={searchCEP}
								/>

								<ControlledInputText
									control={control}
									name={'address'}
									floatingLabel={'Endereço'}
									placeholder={'Nome da Rua'}
									errorMessage={errors.address?.message}
								/>
							</S.FormRow>

							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'number'}
									floatingLabel={'Número'}
									placeholder={'Número'}
									flex={0.35}
									errorMessage={errors.number?.message}
									maskFunction={numberMask}
								/>

								<ControlledInputText
									control={control}
									name={'district'}
									floatingLabel={'Bairro'}
									placeholder={'Nome do Bairro'}
									errorMessage={errors.district?.message}
								/>

								<ControlledInputText
									control={control}
									name={'complement'}
									floatingLabel={'Complemento ( Opcional )'}
									placeholder={'Bloco ou Apartamento'}
									flex={0.45}
									errorMessage={errors.complement?.message}
								/>
							</S.FormRow>

							<S.FormRow>
								<ControlledSelect
									control={control}
									name={'uf'}
									floatingLabel={'Estado'}
									placeholder={'Estado'}
									flex={0.19}
									items={states}
									errorMessage={errors.uf?.message}
								/>

								<ControlledSelect
									control={control}
									name={'city'}
									floatingLabel={'Cidade'}
									placeholder={'Cidade'}
									flex={0.64}
									items={cities}
									errorMessage={errors.city?.message}
									value={watch('city')}
									disabled={!watch('uf')}
								/>
							</S.FormRow>

							<S.Divisor />
							<S.Form>
								<S.OpeningHourTitle>
									Horário de Funcionamento
								</S.OpeningHourTitle>
								<S.OpeningHourSubtitle>
									Insira data e horário funcionamento da sua loja. Essa
									informação ficará visível em seu Perfil caso opte por
									“retirada em seu endereço”
								</S.OpeningHourSubtitle>
								{fields.map((openingHour, i) => (
									<S.FormRow key={openingHour.id}>
										<ControlledSelect
											control={control}
											name={`openingHours[${i}].startDay`}
											floatingLabel={'Inicia em:'}
											placeholder={'Data'}
											errorMessage={
												errors?.openingHours?.[i]?.startDay?.message
											}
											items={ProductFormWeekDays}
										/>
										<ControlledSelect
											control={control}
											name={`openingHours[${i}].startTime`}
											placeholder={'Horário'}
											errorMessage={
												errors?.openingHours?.[i]?.startTime?.message
											}
											items={ProductFormHoursFormated}
										/>

										<ControlledSelect
											control={control}
											name={`openingHours[${i}].endDay`}
											floatingLabel={'Termina em:'}
											placeholder={'Data'}
											errorMessage={errors?.openingHours?.[i]?.endDay?.message}
											items={ProductFormWeekDays}
										/>
										<ControlledSelect
											control={control}
											name={`openingHours[${i}].endTime`}
											placeholder={'Horário'}
											errorMessage={errors?.openingHours?.[i]?.endDay?.message}
											items={ProductFormHoursFormated}
										/>
										<S.AddHourButtonContainer>
											{i === 0 ? (
												<S.AddHour
													onClick={() => addOpeningHour(fields)}
													type='button'
												>
													<img
														src={addIcon}
														alt='Icone para adicionar mais um horário de funcionamento'
													/>
												</S.AddHour>
											) : (
												<S.RemoveHour
													onClick={() => removeOpeningHour(i)}
													type='button'
												>
													<img
														src={noAddIcon}
														alt='Icone de limite de horário de funcionamento atingido'
													/>
												</S.RemoveHour>
											)}
										</S.AddHourButtonContainer>
									</S.FormRow>
								))}
							</S.Form>
							<S.Divisor />

							<S.PaymentTitle>Dados Bancários</S.PaymentTitle>
							<S.PaymentSubtitle>
								Usaremos estes dados para realizar o repasse de suas vendas
								diretamente na sua Conta Bancária.
							</S.PaymentSubtitle>

							<S.FormRow>
								<ControlledSelect
									control={control}
									name={'bank'}
									floatingLabel={'Banco'}
									placeholder={'Selecione o Banco'}
									errorMessage={errors.bank?.message}
									items={bankSelectItems}
								/>

								<ControlledInputText
									control={control}
									name={'agency'}
									floatingLabel={'Agência'}
									placeholder={'0000'}
									flex={0.4}
									errorMessage={errors.agency?.message}
									maxLength={6}
									maskFunction={agencyMask}
								/>
							</S.FormRow>

							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'account_number'}
									floatingLabel={'Número da Conta + Dígito Verificador'}
									placeholder={'000000'}
									flex={0.3}
									errorMessage={errors.account_number?.message}
									maxLength={12}
									maskFunction={accountNumberMask}
								/>
								<ControlledInputText
									style={{ maxWidth: 50, marginRight: 'auto' }}
									control={control}
									name={'account_digit'}
									placeholder={'0'}
									errorMessage={errors.account_digit?.message}
									maxLength={1}
								/>

								<ControlledInputText
									control={control}
									name={'account_name'}
									floatingLabel={'Nome do Titular da Conta'}
									placeholder={'Nome Completo'}
									errorMessage={errors.account_name?.message}
									flex={0.5}
									maxLength={30}
								/>
							</S.FormRow>

							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'account_document'}
									floatingLabel={'CPF ou CNPJ'}
									placeholder={'Somente Números'}
									errorMessage={errors.account_document?.message}
									maskFunction={documentMask}
									flex={0.5}
									maxLength={18}
									disabled={!registerType || isSellerActiveByGateway}
								/>

								<ControlledSelect
									control={control}
									name={'account_type'}
									floatingLabel={'Tipo de conta'}
									placeholder={'Tipo de conta'}
									flex={0.5}
									errorMessage={errors.account_type?.message}
									items={[
										{ value: EAccountType.CHECKING },
										{ value: EAccountType.SAVINGS }
									]}
								/>
							</S.FormRow>

							<S.Divisor />

							<S.FormRow>
								<S.PaymentTitle>Dados Pessoais</S.PaymentTitle>
							</S.FormRow>

							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'first_name'}
									floatingLabel={'Nome'}
									placeholder={'Nome'}
									errorMessage={errors.first_name?.message}
								/>
								<ControlledInputText
									control={control}
									name={'last_name'}
									floatingLabel={'Sobrenome'}
									placeholder={'Sobrenome'}
									errorMessage={errors.last_name?.message}
								/>
							</S.FormRow>

							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'cpf'}
									floatingLabel={'CPF'}
									placeholder={'Somente Números'}
									errorMessage={errors.cpf?.message}
									maskFunction={documentMask}
									maxLength={14}
								/>
							</S.FormRow>

							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'phone'}
									floatingLabel={'Telefone para Contato'}
									placeholder={'Número do Celular'}
									errorMessage={errors.phone?.message}
									maskFunction={phoneMask}
									maxLength={15}
									flex={0.5}
								/>
							</S.FormRow>

							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'password'}
									floatingLabel={'Cadastrar Senha'}
									placeholder={'Cadastre uma Senha'}
									type={'password'}
									autoComplete='new-password'
									errorMessage={errors.password?.message}
								/>

								<ControlledInputText
									control={control}
									name={'confirm_password'}
									floatingLabel={'Repetir sua Senha'}
									placeholder={'Repetir Senha'}
									type={'password'}
									autoComplete='new-password'
									errorMessage={errors.confirm_password?.message}
								/>
							</S.FormRow>

							<S.PasswordText>
								Sua senha deve conter pelo menos 8 caracteres, 1 letra
								maiúscula, 1 letra minúscula, 1 caractere especial e 1 número
							</S.PasswordText>

							<S.ButtonContainer>
								<Button label={'Cadastrar Conta'} onClick={submitForm} />
							</S.ButtonContainer>
						</S.Form>
					</S.Content>
				</S.Container>
			</Container>
		</>
	)
}

export { CreateSellerView }
