import { useSelector } from 'react-redux'

import { pick } from 'ramda'
import { IStore } from '../types/store'
import { SelectorEqualityFn } from '../types/common'
export function useTypedSelector<K extends keyof IStore>(
	props: Array<K>,
	equalityFn: SelectorEqualityFn<K> = undefined
) {
	// Type Properly
	const getState: any = pick(props)

	return useSelector<IStore, Pick<IStore, K>>(getState, equalityFn)
}
