import {
	IAdminProductsResponse,
	IChangeProductStatusRequest,
	IChangeProductStatusResponse,
	IPatchProductRequest,
	IPatchProductResponse,
	IRegisterProductRequest,
	IRegisterProductResponse,
	IToggleProductResponse
} from './../types/products'
import {
	QueryFunctionContext,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult
} from '@tanstack/react-query'

import {
	IProductData,
	IProductsResponse,
	ICategoryResponse
} from '../types/products'
import axiosInstance from './axiosInstance'

import { QUERY_KEYS } from '../utils/reactQuery'
import { AxiosResponse } from 'axios'
import { EQueryKeys } from '../context/QueryProvider'
import { IFetchRequest, IPaginationResponse } from '../types/pagination'

export function useProducts(
	store: string
): UseQueryResult<IProductsResponse[]> {
	return useQuery([QUERY_KEYS.PRODUCTS], () => products(store))
}

async function products(store: string) {
	const response = await axiosInstance.get<IProductsResponse[]>(
		`products/store/` + store
	)

	return response.data
}
// export function useAdminProducts(): UseQueryResult<IAdminProductsResponse[]> {
// 	return useQuery([QUERY_KEYS.PRODUCTS], adminProducts)
// }

// async function adminProducts() {
// 	const response = await axiosInstance.get<IAdminProductsResponse[]>(
// 		`/products/admin/list`
// 	)

// 	return response.data
// }

export function useCategory(): UseQueryResult<ICategoryResponse[]> {
	return useQuery([QUERY_KEYS.CATEGORY], category)
}

async function category() {
	const response = await axiosInstance.get<ICategoryResponse[]>('/categories')

	return response.data
}

export function useRegisterProduct(): UseMutationResult<
	IRegisterProductResponse,
	any,
	IRegisterProductRequest
> {
	return useMutation([QUERY_KEYS.REGISTER_PRODUCT], registerProduct)
}

async function registerProduct({ formData }: IRegisterProductRequest) {
	const response = await axiosInstance.post('/products', formData)

	return response.data
}

export function usePatchProduct(): UseMutationResult<
	IPatchProductResponse,
	any,
	IPatchProductRequest
> {
	return useMutation([QUERY_KEYS.PATCH_PRODUCT], patchProducts)
}

async function patchProducts({ formData, id }: IPatchProductRequest) {
	const response = await axiosInstance.patch<IPatchProductResponse>(
		'/products/' + id,
		formData
	)

	return response.data
}

export function useDeleteProduct(): UseMutationResult<
	AxiosResponse<any>,
	any,
	IPatchProductRequest
> {
	return useMutation([QUERY_KEYS.DELETE_PRODUCT], deleteProducts)
}

async function deleteProducts({ id }: IPatchProductRequest) {
	const response = await axiosInstance.delete('/products/' + id)

	return response
}

export function useToggleProduct(): UseMutationResult<
	IToggleProductResponse,
	any,
	IPatchProductRequest
> {
	return useMutation([QUERY_KEYS.TOGGLE_PRODUCT], toggleProduct)
}

async function toggleProduct({ id }: IPatchProductRequest) {
	const response = await axiosInstance.patch('/products/toggle-activate/' + id)

	return response.data
}

export function useProductDetails(id?: string): UseQueryResult<IProductData> {
	return useQuery([EQueryKeys.PRODUCTS, id], () => findProduct(id), {
		enabled: !!id
	})
}

async function findProduct(id?: string) {
	const response = await axiosInstance.get<IProductData>('/products/' + id)
	return response.data
}

export function useChangeProductStatus(
	id?: string
): UseMutationResult<
	IChangeProductStatusResponse,
	any,
	IChangeProductStatusRequest
> {
	return useMutation([EQueryKeys.PRODUCTS, id + 'change'], changeProductStatus)
}

async function changeProductStatus({
	id,
	message,
	action
}: IChangeProductStatusRequest) {
	const response = await axiosInstance.patch<IChangeProductStatusResponse>(
		`/products/${action}/${id}`,
		message && { message }
	)
	return response.data
}

async function listProducts(
	context: QueryFunctionContext<[unknown, IFetchRequest]>
): Promise<IPaginationResponse<IAdminProductsResponse>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort } = params
	const response = await axiosInstance.post('/products/admin/list', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage
	})

	return response.data
}

export function useListProduct({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort
}: IFetchRequest): UseQueryResult<IPaginationResponse<IAdminProductsResponse>> {
	const result = useQuery(
		[
			QUERY_KEYS.PRODUCTS_LIST,
			{ maxItemsInPage, pageIndex, searchString, order, sort }
		],
		listProducts
	)
	return result
}
