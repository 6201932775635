import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

// types
import { ICreateSellerForm } from '../../views/CreateSeller/types'

export type ICreateSellerFormValue = {
    token?: string
} & ICreateSellerForm | null
export interface ICreateSellerFormActions {
    addCreateSellerForm: (createSellerForm: ICreateSellerFormValue) => void
    removeCreateSellerForm: () => void
}

interface ICreateSellerFormStore extends ICreateSellerFormActions {
    createSellerForm: ICreateSellerFormValue
}

export const useCreateSellerFormStore = create(
    persist<ICreateSellerFormStore>(
        (set) => ({
            createSellerForm: null,
            addCreateSellerForm: (createSellerForm) => set({ createSellerForm }),
            removeCreateSellerForm: () => set({ createSellerForm: null }),
        }),
        {
            name: 'harv:create-seller-form',
            storage: createJSONStorage(() => sessionStorage)
        }
    )
)
