import { toast, ToastOptions } from 'react-toastify'
import { ToasterComponent } from '../components/Toaster'
import { IToaster } from '../components/Toaster/types'

const toastOptions: ToastOptions = {
	hideProgressBar: true,
	closeButton: false
}

export function Toaster(props: IToaster) {
	toast(<ToasterComponent {...props} />, toastOptions)
}
