import * as S from './styles'

//icons
import { INotificationSellersResponse } from '../../types/notifications'

//components
//types
interface IStoreCardProps {
	store: INotificationSellersResponse | undefined
}

function StoreCard({ store }: IStoreCardProps) {
	return (
		<>
			{store ? (
				<S.Container>
					<S.ImageWrapper>
						<img src={store.photoURL} alt={store.name} />
					</S.ImageWrapper>

					<S.InfosWrapper>
						<S.CardTitle>Seller</S.CardTitle>
						<S.CardSbutitle>{store.name}</S.CardSbutitle>
					</S.InfosWrapper>
				</S.Container>
			) : null}
		</>
	)
}

export { StoreCard }
