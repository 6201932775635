import { createElement } from "react"
import { SellerAboutView } from "./view"

//types
import {IPageProps} from './types'

function SellerAbout() {

    const pageProps:IPageProps = {}

	return createElement(SellerAboutView, pageProps)
}

export { SellerAbout }
