import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InputText } from '../../components/InputText'
import { AuthHeader, Button } from '../../components'
import { Container } from '../../styles'
import * as S from './styles'
import {
	useActivateSellerBytOtp,
	useRequestOtpToActivate
} from '../../services/seller.service'
import { Toaster } from '../../utils/toaster'
import { ERoutes } from '../../routes/routes'

export function SellerRegistrationConfirmation() {
	const { state } = useLocation()
	const sellerEmail: string = state.sellerEmail

	const [code, setCode] = useState('')

	const navigate = useNavigate()

	const { mutateAsync: requestOtpToActivateMutateAsync } =
		useRequestOtpToActivate()
	const { mutateAsync: activateSellerBytOtpMutateAsync } =
		useActivateSellerBytOtp()

	const handleChangeCode = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const sanitizedValue = e.target.value.replace(/[^0-9]/g, '')
		setCode(sanitizedValue.slice(0, 6))
	}, [])

	async function sendCode() {
		await requestOtpToActivateMutateAsync(sellerEmail)
	}

	async function handleResendCode() {
		await sendCode()
	}

	async function handleActivateSeller() {
		const response = await activateSellerBytOtpMutateAsync({
			email: sellerEmail,
			otp: code
		})

		if (!response) return
		Toaster({
			type: 'success',
			title: 'Cadastro validado com sucesso!'
		})

		navigate(ERoutes.SUCCESS_REGISTRATION, {
			state: { bypass: true },
			replace: true
		})
	}

	useEffect(() => {
		sendCode()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const isValid = !!code.length

	return (
		<>
			<AuthHeader title='Cadastro de Perfil' />
			<Container>
				<S.Container>
					<S.Content>
						<S.ContentHead>
							<S.HeadTitle>
								Digite o código que enviamos em seu E-mail.
							</S.HeadTitle>

							<S.HeadSubtitle>
								Para validar seu cadastro, digite o código que enviamos para o
								e-mail
							</S.HeadSubtitle>
							<S.SellerEmail>{sellerEmail}</S.SellerEmail>
						</S.ContentHead>
						<InputText
							floatingLabel='Digite o Código'
							placeholder='Digite o Código'
							style={{ width: 430, maxHeight: 85 }}
							maxLength={6}
							value={code}
							onChange={handleChangeCode}
						/>
						<S.ResendCodeLabel>
							Não recebeu?{' '}
							<S.ResendCodeLink onClick={handleResendCode}>
								Reenviar Código.
							</S.ResendCodeLink>
						</S.ResendCodeLabel>
						<Button
							label='Finalizar Cadastro'
							onClick={handleActivateSeller}
							disabled={!isValid}
						/>
					</S.Content>
				</S.Container>
			</Container>
		</>
	)
}
