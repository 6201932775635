import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { IListPagination, IPaginationItems } from './types'

//icons
import arrowLeft from '../../assets/icons/chevron-left.svg'
import arrowRight from '../../assets/icons/chevron-right.svg'

function ListPagination({
	itemsLength = 0,
	itemsPerPage = 10,
	onChange,
	showingItems = 0
}: IListPagination) {
	const [paginationItems, setPaginationItems] = useState<IPaginationItems[]>()

	function handleChangePage(e: any) {
		const clickIndex = e.target.innerText - 1

		const newPaginationItems = paginationItems?.map((item, index) => ({
			...item,
			active: index === clickIndex
		}))

		onChange &&
			newPaginationItems &&
			onChange(newPaginationItems[clickIndex].number)

		setPaginationItems(newPaginationItems)
	}

	function handleMovePagination(direction: '+' | '-') {
		const activeNumber = paginationItems?.find(
			(item) => item.active === true
		)?.number

		if (!activeNumber) return

		if (direction === '+' && activeNumber < paginationItems.length) {
			const newPagine = paginationItems.map((item) => ({
				...item,
				active: item.number === activeNumber + 1 ? true : false
			}))

			setPaginationItems(newPagine)
		}

		if (direction === '-' && activeNumber > 1) {
			const newPagine = paginationItems.map((item) => ({
				...item,
				active: item.number === activeNumber - 1 ? true : false
			}))

			setPaginationItems(newPagine)
		}
	}

	function setupPagination() {
		const pageLength =
			itemsLength % itemsPerPage > 0
				? Math.ceil(itemsLength / itemsPerPage)
				: itemsLength / itemsPerPage

		const items: IPaginationItems[] = Array.from(
			{ length: pageLength },
			(_, i) => ({
				number: i + 1,
				active: i === 0
			})
		)

		setPaginationItems(items)
	}

	useEffect(setupPagination, [itemsLength, itemsPerPage])

	useEffect(() => {
		const activeItem = paginationItems?.find((item) => item.active)?.number

		onChange && activeItem && onChange(activeItem)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationItems])

	if (itemsLength === 0) {
		return <S.Container />
	}

	const numberActive: any = paginationItems?.find((item) => {
		return item.active === true
	})

	return (
		<S.Container>
			<S.Count>
				Mostrando {showingItems} de {itemsLength}
			</S.Count>
			<S.Arrow onClick={() => handleMovePagination('-')} src={arrowLeft} />

			{paginationItems?.map(({ number, active }) =>
				paginationItems.length > 6 ? (
					<React.Fragment key={'list item' + number}>
						{number <= 1 && (
							<>
								<S.ListOption active={active} onClick={handleChangePage}>
									{number}
								</S.ListOption>
								{numberActive.number <= 3 || <span>...</span>}
							</>
						)}
						{active && (
							<>
								{number > 2 && (
									<S.ListOption onClick={handleChangePage}>
										{number - 1}
									</S.ListOption>
								)}
								<S.ListOption
									active={number > 1 && active}
									onClick={handleChangePage}
								>
									{number > 1 ? number : 2}
								</S.ListOption>
								{number > 1 &&
									number < paginationItems.length - 1 &&
									(number === paginationItems.length ? (
										<></>
									) : (
										<S.ListOption onClick={handleChangePage}>
											{number + 1}
										</S.ListOption>
									))}
							</>
						)}
						{!active && number === paginationItems.length && (
							<>
								{numberActive.number < paginationItems.length - 1 && (
									<span>...</span>
								)}
								<S.ListOption active={active} onClick={handleChangePage}>
									{number}
								</S.ListOption>
							</>
						)}
					</React.Fragment>
				) : (
					<React.Fragment key={'list item' + number}>
						<S.ListOption active={active} onClick={handleChangePage}>
							{number}
						</S.ListOption>
					</React.Fragment>
				)
			)}
			<S.Arrow onClick={() => handleMovePagination('+')} src={arrowRight} />
		</S.Container>
	)
}

export { ListPagination }
