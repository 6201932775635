import styled from 'styled-components'
import { mediaQuery } from '../../styles/function'

export const Title = styled.h1`
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	font-size: 32px;
	font-weight: 400;
	line-height: 40px;
	margin-bottom: 30px;
`
export const Card = styled.div`
	padding: 60px 40px;
	background: #ffffff;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	width: 100%;
	display: flex;
	flex-direction: column;
`

export const Form = styled.div`
	width: 100%;
	align-items: center;

	> div {
		margin-bottom: 30px;
	}

	> span {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

export const PageInfo = styled.div`
	height: 100%;
	display: flex;
	width: 100%;
	justify-content: center;
	flex-direction: column;
`

export const Wrapper = styled.div`
	margin-top: 150px;
	width: 100%;
	height: 100%;

	${mediaQuery('md')(`
			margin-top: 70px;
	`)}
`

export const Paragraph = styled.h4`
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	max-width: 540px;
`

export const LoadingWrapper = styled.div`
	padding: 80px;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const GoBackContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
	margin-bottom: 16px;

	:active {
		opacity: 0.8;
	}
`
export const GoBackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const ArrowBack = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`

export const FormTitle = styled.h2`
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	font-size: 32;
	font-weight: 400;
	line-height: 40px;
	margin-bottom: 40px;
`

export const Label = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0;
`

export const PasswordText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 16px;
`
