import { useMemo, useState } from 'react'
import * as S from './styles'

//icons
import options from '../../assets/icons/options.svg'
import trashIcon from '../../assets/icons/trash-icon.svg'

//types
import { IDeliveryAreaList, IFilter } from './types'

//components
import { Grid, Typography } from '@mui/material'
import { toMoney } from '../../utils/currency'
import { ListPagination } from '../ListPagination'
import { ColumnHead } from './components/column-head'

function DeliveryAreasTable({
	isLoading = false,
	areas,
	itemsPerPage = 10,
	handleOpenEditModal,
	handleOpenDeleteModal
}: IDeliveryAreaList) {
	const [filter, setFilter] = useState<IFilter>({
		sort: {
			by: 'price',
			order: 'desc'
		},
		page: 1
	})

	const renderList = useMemo(() => {
		const {
			sort: { by, order }
		} = filter
		let newList = areas
		let filteredLength = 0

		//sort list
		newList?.sort((a, b) => {
			if (order === 'asc')
				return a[by]?.toString().toLowerCase() < b[by]?.toString().toLowerCase()
					? 1
					: -1

			return a[by]?.toString().toLowerCase() > b[by]?.toString().toLowerCase()
				? 1
				: -1
		})

		if (!newList?.length) return

		const searchPages =
			filteredLength % itemsPerPage > 0 ? filteredLength + 1 : filteredLength

		return { list: newList, pages: searchPages }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter.page, filter.sort, areas])

	function handleChangePage(page: number) {
		setFilter({ ...filter, page })
	}

	return (
		<S.Container>
			<S.TableContainer>
				<S.Table>
					<thead>
						<S.TableHeader>
							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Alcance'
								value='area'
								width={30}
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Taxa'
								value='price'
								width={60}
							/>

							<S.TableHeaderItem />
						</S.TableHeader>
					</thead>
					{!isLoading && renderList?.list && (
						<tbody>
							{renderList?.list?.map(({ _id, area, freeShipping, price }) => (
								<S.TableRow key={_id}>
									<S.TableItem>{`${area}km`}</S.TableItem>

									<S.TableItem>
										{freeShipping ? 'Frete Grátis' : toMoney(price)}
									</S.TableItem>

									<S.TableItem>
										<S.ItemsContainer>
											<S.Icons
												src={trashIcon}
												alt=''
												onClick={() => handleOpenDeleteModal(_id)}
											/>
											<S.Icons
												src={options}
												alt=''
												onClick={() => handleOpenEditModal(_id)}
											/>
										</S.ItemsContainer>
									</S.TableItem>
								</S.TableRow>
							))}
						</tbody>
					)}
				</S.Table>

				{!isLoading && !renderList?.list && (
					<Grid
						container
						sx={{
							width: '100%',
							justifyContent: 'center',
							mt: 2,
							mb: 3,
							p: 4
						}}
					>
						<Typography>Não existem áreas de entregas cadastradas.</Typography>
					</Grid>
				)}
			</S.TableContainer>

			<ListPagination
				showingItems={renderList?.list.length}
				itemsLength={renderList?.pages}
				itemsPerPage={itemsPerPage}
				onChange={handleChangePage}
			/>
		</S.Container>
	)
}

export { DeliveryAreasTable }
