import { Dispatch, SetStateAction } from 'react'
import { IAdminOrdersResponse } from '../../types/orders'
import { IPaginationResponse } from '../../types/pagination'
import { IFilter } from '../../views/AdminOrders/types'

export interface ISellerList {
	searchQuery?: string
	itemsPerPage?: number
	orders?: IPaginationResponse<IAdminOrdersResponse> | undefined
	handleChangePage(page: number): void
	filter: IFilter
	setFilter: Dispatch<SetStateAction<IFilter>>
}

export type IListItem = IAdminOrdersResponse

export type ISortOrderValues = 'asc' | 'desc'
export type ISortByValues = keyof IListItem

export enum EAdminOrderStatus {
	PENDING = 'Pendente',
	PAID = 'Pago',
	PRODUCTION = 'Em produção',
	ON_ROUTE = 'A caminho',
	DELIVERED = 'Entregue',
	CANCELED = 'Cancelado',
	FAILED = 'Falha',
	READY_WITHDRAWAL = 'Pronto para retirada'
}

export enum EAdminOrder {
	Pendente = 'PENDING',
	Pago = 'PAID',
	'Em produção' = 'PRODUCTION',
	'A caminho' = 'ON_ROUTE',
	Entregue = 'DELIVERED',
	Cancelado = 'CANCELED',
	Falha = 'FAILED',
	'Pronto para retirada' = 'READY_WITHDRAWAL'
}

export interface IFilterProps {
	setFilter: (filters: NotAppliedFilter) => void
	isOpen: boolean
}

export type NotAppliedFilter = Pick<IFilter, 'date' | 'status'>
