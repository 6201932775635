import styled from 'styled-components'
import { mediaQuery } from '../../styles/function'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 32px;
	margin-bottom: 120px;
`

export const Head = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;

	${mediaQuery('sm')(`
	flex-direction: column;
	align-items: start;
	
	`)}
`

export const SearchContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 450px;

	${mediaQuery('sm')(`
	flex-direction: column;
	margin-bottom: 16px;
	align-items: start;

	`)}
`

export const Title = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-right: 24px;
`
export const Body = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;
`

export const SpinnerContainer = styled.div`
	margin: auto;
`
