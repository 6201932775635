import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as S from './styles'

//icons
import notification from '../../../../assets/icons/notification.svg'

//components
import { Typography } from '../../../Typography'
import { CircularProgress } from '@mui/material'

//hooks
import {
	ENotificationType,
	useOnSnapshotNotifications
} from '../../../../hooks/useOnSnapshotNotifications'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside'

//services
import { useReadNotifications } from '../../../../services/user.service'

//types
import { UserTypesEnum } from '../../../../types/user'
import { useSound } from '../../../../hooks/useSound'
import { SoundContext } from '../../../../context/SoundProvider'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from '../../../../routes/routes'
import { hashNotification } from './types'

//mocks
const navigation = [
	{
		name: 'Novas',
		types: [
			ENotificationType.APPROVATION_PRODUCT,
			ENotificationType.DECLINED_PRODUCT,
			ENotificationType.NEW_BUY,
			ENotificationType.NEW_SELLER,
			ENotificationType.NEW_PRODUCT,
			ENotificationType.UPDATED_PRODUCT,
			ENotificationType.UPDATED_SELLER
		],
		userType: [UserTypesEnum.SELLER, UserTypesEnum.ADMIN]
	},
	{
		name: 'Produtos',
		types: [
			ENotificationType.APPROVATION_PRODUCT,
			ENotificationType.DECLINED_PRODUCT
		],
		userType: [UserTypesEnum.SELLER]
	},
	{
		name: 'Vendas',
		types: [ENotificationType.NEW_BUY],
		userType: [UserTypesEnum.SELLER]
	},
	{
		name: 'Produtos',
		types: [ENotificationType.NEW_PRODUCT],
		userType: [UserTypesEnum.ADMIN]
	},
	{
		name: 'Sellers',
		types: [ENotificationType.NEW_SELLER],
		userType: [UserTypesEnum.ADMIN]
	}
]

function Notifications() {
	const {
		user: { type }
	} = useTypedSelector(['user'])

	const { play, stop } = useSound()

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [activeIndex, setActiveIndex] = useState(0)
	const navigate = useNavigate()

	const modalRef = useRef<HTMLDivElement>(null)

	const { notifications, reFetchNotications } = useOnSnapshotNotifications()

	const { mutateAsync: readNotifications, isLoading } = useReadNotifications()

	const soundContext = useContext(SoundContext)

	function toggleModalState() {
		setIsModalOpen((prev) => !prev)

		if (soundContext?.playing) {
			stop()
		}
	}

	function handleSetActiveIndex(index: number) {
		setActiveIndex(index)
	}

	function badgeName(
		typeNotification: ENotificationType,
		hash: hashNotification
	) {
		switch (typeNotification) {
			case ENotificationType.APPROVATION_PRODUCT:
				if (hash.type && hash.type === 'PRODUCT') {
					return 'Produto Aprovado'
				}
				return 'Anúncio/Serviço Aprovado'
			case ENotificationType.DECLINED_PRODUCT:
				if (hash.type && hash.type === 'PRODUCT') {
					return 'Produto Reprovado'
				}
				return 'Anúncio/Serviço Reprovado'
			case ENotificationType.NEW_BUY:
				return 'Nova Venda'
			case ENotificationType.NEW_PRODUCT:
				if (hash.option?.isService) {
					return 'Novo Serviço'
				}
				return 'Novo Produto'
			case ENotificationType.NEW_SELLER:
				return 'Novo Seller'
			case ENotificationType.UPDATED_SELLER:
				return 'Edição Seller'
			case ENotificationType.UPDATED_PRODUCT:
				return 'Edição Produto'

			default:
				return 'TYPE_DOES_NOT_EXIST'
		}
	}

	function getNotificationType(time: string) {
		const date = new Date(time)

		return `${date.toLocaleDateString()} - ${date
			.toLocaleTimeString()
			.substring(0, 5)}`
	}

	function countNewNotifications(types: ENotificationType[]) {
		const filter = notifications?.filter(
			(item) => types.includes(item.type) && !item.read
		)

		return filter?.length ?? 0
	}

	const notificationsList = useMemo(() => {
		const tab = navigation[activeIndex]

		const list = notifications?.filter(({ type }) => tab.types.includes(type))

		return list
	}, [activeIndex, notifications])

	const haveNewNotifications = notifications?.some((item) => !item.read)

	useEffect(() => {
		const hasUnreadNewBuyNotification = notifications?.some(
			(notification) =>
				notification.type === ENotificationType.NEW_BUY && !notification.read
		)

		if (hasUnreadNewBuyNotification) {
			play()
		} else {
			if (soundContext?.playing) stop()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications])

	async function handleSetAllAsRead() {
		if (isLoading) return

		await readNotifications()

		await reFetchNotications()
	}

	async function handleRedirect(
		typeNotification: ENotificationType,
		hash: hashNotification
	) {
		await handleSetAllAsRead()
		switch (typeNotification) {
			case ENotificationType.NEW_SELLER:
			case ENotificationType.UPDATED_SELLER:
				navigate(ERoutes.ADMIN_SELLER_DETAILS.replace(':id', hash.idHash))
				break

			case ENotificationType.UPDATED_PRODUCT:
			case ENotificationType.NEW_PRODUCT:
				navigate(ERoutes.ADMIN_PRODUCT_DETAILS.replace(':id', hash.idHash))
				break

			case ENotificationType.NEW_BUY:
				navigate(ERoutes.SELLER_ORDER_DETAILS.replace(':id', hash.idHash))
				break

			case ENotificationType.DECLINED_PRODUCT:
			case ENotificationType.APPROVATION_PRODUCT:
				navigate(ERoutes.EDIT_PRODUCT.replace(':id', hash.idHash))
				break

			default:
				navigate(ERoutes.PRODUCTS)
		}
	}

	useOnClickOutside(modalRef, () => {
		isModalOpen && setIsModalOpen(false)
	})

	return (
		<S.Container ref={modalRef}>
			<S.NotificationIconWrapper
				onClick={toggleModalState}
				isModalOpen={isModalOpen}
			>
				<img src={notification} alt='notifications' />
				{haveNewNotifications && <S.NotificationAsset />}
			</S.NotificationIconWrapper>

			<S.Modal isModalOpen={isModalOpen}>
				<S.ModalHeader>
					<S.HeaderRow>
						<Typography as='h3'>Notificações</Typography>

						<S.ReadButton onClick={handleSetAllAsRead}>
							{isLoading && <CircularProgress size={14} color='inherit' />}
							Marcar como Lidas
						</S.ReadButton>
					</S.HeaderRow>

					<S.HeaderNavigation>
						{React.Children.toArray(
							navigation.map(({ name, types, userType }, index) => {
								if (!userType.includes(type)) {
									return null
								}

								return (
									<S.NavItem onClick={() => handleSetActiveIndex(index)}>
										<S.NavName active={activeIndex === index}>{name}</S.NavName>

										<S.Counter active={activeIndex === index}>
											{countNewNotifications(types)}
										</S.Counter>
									</S.NavItem>
								)
							})
						)}
					</S.HeaderNavigation>
				</S.ModalHeader>

				<S.NotificationsContainer className='custom-scrollbar'>
					{React.Children.toArray(
						notificationsList
							?.sort((a, b) => {
								if (a.createdAt > b.createdAt) {
									return -1
								}
								return 1
							})
							.map(
								({
									description,
									createdAt,
									type: typeNotification,
									read,
									photo,
									hash
								}) => {
									return (
										<S.Notification
											read={read}
											onClick={() => handleRedirect(typeNotification, hash)}
										>
											<S.PhotoContainer>
												<img src={photo} alt='' />
											</S.PhotoContainer>
											<S.BodyContainer>
												<S.BadgeContainer>
													<S.Badge type={typeNotification}>
														{hash && badgeName(typeNotification, hash)}
													</S.Badge>
													{!!hash &&
														hash.option &&
														typeof hash.option.info === 'string' &&
														type === 'ADMIN' && (
															<S.BadgeStore>{hash.option.info}</S.BadgeStore>
														)}
												</S.BadgeContainer>

												<S.NotificationDescription
													dangerouslySetInnerHTML={{ __html: description }}
												/>

												<S.Time>{getNotificationType(createdAt)}</S.Time>
											</S.BodyContainer>
										</S.Notification>
									)
								}
							)
					)}
				</S.NotificationsContainer>
			</S.Modal>
		</S.Container>
	)
}

export { Notifications }
