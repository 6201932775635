import { createElement, useCallback, useState } from 'react'
import { CreateDeliveryAreasView } from './view'

import { DeliveryAreaPayload, IPageProps } from './types'

//services
import {
	useDeleteDeliveryArea,
	useCreateDeliveryArea,
	useUpdateDeliveryArea,
	useDeliveryAreas
} from '../../services/deliveryAreas.service'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Toaster } from '../../utils/toaster'

function ListDeliveryAreas() {
	const [openCreateModal, setOpenCreateModal] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [deliveryAreaId, setDeliveryAreaId] = useState<string | null>(null)

	const { user } = useTypedSelector(['user'])

	const {
		data: deliveryAreas,
		isLoading,
		refetch
	} = useDeliveryAreas(user.store._id)

	const { mutateAsync: deleteDeliveryArea, isLoading: isLoadingDelete } =
		useDeleteDeliveryArea()
	const { mutateAsync: createDeliveryArea, isLoading: isLoadingCreate } =
		useCreateDeliveryArea()
	const { mutateAsync: updateDeliveryArea, isLoading: isLoadingUpdate } =
		useUpdateDeliveryArea()

	const handleOpenCreateModal = useCallback((id?: string) => {
		if (id) {
			setDeliveryAreaId(id)
		} else {
			setDeliveryAreaId(null)
		}

		setOpenCreateModal((prev) => !prev)
	}, [])

	const handleOpenDeleteModal = useCallback((id?: string) => {
		if (id) {
			setDeliveryAreaId(id)
		} else {
			setDeliveryAreaId(null)
		}

		setOpenDeleteModal((prev) => !prev)
	}, [])

	const handleConfirmCreateModal = useCallback(
		async (payload: DeliveryAreaPayload) => {
			let sucessResponse
			const payloadWithStore = {
				...payload,
				store: user.store._id
			}

			if (deliveryAreaId) {
				const response = await updateDeliveryArea({
					id: deliveryAreaId as string,
					...payloadWithStore
				})

				sucessResponse = !!response
			} else {
				const response = await createDeliveryArea(payloadWithStore)
				sucessResponse = !!response
			}

			handleOpenCreateModal()

			Toaster({
				type: 'success',
				title: `${
					deliveryAreaId
						? 'Alcance editado com sucesso.'
						: 'Novo Alcance cadastrado.'
				}`
			})

			refetch()

			return sucessResponse
		},
		[
			handleOpenCreateModal,
			updateDeliveryArea,
			createDeliveryArea,
			user.store,
			refetch,
			deliveryAreaId
		]
	)

	const handleConfirmDeleteModal = useCallback(async () => {
		await deleteDeliveryArea({ id: deliveryAreaId as string })

		handleOpenDeleteModal()

		Toaster({
			type: 'success',
			title: 'Área de Entrega deletada com sucesso.'
		})

		refetch()
	}, [deleteDeliveryArea, handleOpenDeleteModal, deliveryAreaId, refetch])

	const pageProps: IPageProps = {
		deliveryAreas,
		isLoading,
		isLoadingDelete,
		isLoadingCreate,
		isLoadingUpdate,
		deliveryAreaId,
		openCreateModal,
		handleOpenCreateModal,
		handleConfirmCreateModal,
		openDeleteModal,
		handleOpenDeleteModal,
		handleConfirmDeleteModal
	} as IPageProps

	return createElement(CreateDeliveryAreasView, pageProps)
}

export { ListDeliveryAreas }
