import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Modal } from '../../../../components/Modal'
import { ControlledInputText } from '../../../../components/InputText/controlled'
import { useDeliveryArea } from '../../../../services/deliveryAreas.service'
import { moneyMask, unmaskMoney } from '../../../../utils/currency'
import { createDeliveryAreaSchema } from './schema'
import * as S from './styles'
import { CreateModalProps, ICreateDeliveryAreaForm } from './types'
import { useTheme } from 'styled-components'

function CreateModal({
	id,
	isEdit = false,
	handleConfirm,
	...props
}: CreateModalProps) {
	const { data, isFetching: isLoading } = useDeliveryArea(id as string)

	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
		reset
	} = useForm<ICreateDeliveryAreaForm>({
		resolver: yupResolver(createDeliveryAreaSchema),
		mode: 'onChange',
		delayError: 1000 * 1, //1 second,
		defaultValues: {
			area: null,
			price: null,
			freeShipping: false
		}
	})

	const theme = useTheme()

	const freeShippingValue = watch('freeShipping')

	useEffect(() => {
		if (id && data) {
			setValue('price', data?.price.toFixed(2))
			setValue('area', data?.area)
			setValue('freeShipping', data?.freeShipping)
		}
	}, [data, id, setValue])

	const handleSubmitCreateDeliveryArea = handleSubmit(
		async (payload: ICreateDeliveryAreaForm) => {
			const { price, area, freeShipping } = payload

			const unmaskedPrice = unmaskMoney(price!)

			const response = await handleConfirm({
				area,
				freeShipping: unmaskedPrice === 0 ? true : freeShipping,
				price: freeShipping ? 0 : unmaskedPrice
			})

			if (response) reset()
		}
	)

	return (
		<Modal
			id='create-delivery-area-modal'
			title='Raio de Entrega'
			actionsMode='CREATE'
			{...props}
			//disabledActions={!isDirty || !isValid}
			handleConfirm={handleSubmitCreateDeliveryArea}
			handleClose={() => {
				props.handleClose()
				reset()
			}}
			{...(isEdit && { confirmLabel: 'Salvar' })}
		>
			{isEdit && isLoading ? (
				<Grid container sx={{ justifyContent: 'center', mt: 2, mb: 2 }}>
					<CircularProgress color='secondary' />
				</Grid>
			) : (
				<S.FormWrapper onSubmit={handleSubmitCreateDeliveryArea}>
					<S.FormFields>
						<ControlledInputText
							control={control}
							name={'area'}
							type={'number'}
							floatingLabel={'Área (Km)'}
							placeholder={'0'}
							min={0}
							maskFunction={(value) => Number(value).toFixed(0)}
							errorMessage={errors.area?.message}
							adornment={
								<Typography color={theme.COLORS.PRIMARY_COLOR} fontSize={13}>
									KM
								</Typography>
							}
						/>

						<ControlledInputText
							control={control}
							name={'price'}
							floatingLabel={'Preço (R$)'}
							min={0}
							placeholder={'0'}
							disabled={freeShippingValue}
							adornment={
								<Typography color={theme.COLORS.PRIMARY_COLOR} fontSize={13}>
									R$
								</Typography>
							}
							maskFunction={moneyMask}
							errorMessage={errors.price?.message}
						/>
					</S.FormFields>

					{/* <S.FormFields>
						<Typography>Oferecer Frete Grátis?</Typography>
						<Controller
							name={'freeShipping'}
							control={control}
							render={({ field: { onChange, value, name, ...rest } }) => (
								<Switch
									onChange={(value) => {
										if (value) {
											setValue('price', 0)
											clearErrors('price')
										}

										onChange(value)
									}}
									checked={value}
									name={name}
									defaultValue={'false'}
									onColor={theme.COLORS.PRIMARY_COLOR}
									offColor='#E0E0E0'
									uncheckedIcon={false}
									checkedIcon={false}
									height={25}
									width={50}
									{...rest}
								/>
							)}
						/>
					</S.FormFields> */}
				</S.FormWrapper>
			)}
		</Modal>
	)
}

export { CreateModal }
