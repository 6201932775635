import { useMemo } from 'react'
import { useTypedSelector } from './useTypedSelector'

export function useIsAdmin() {
	const { user } = useTypedSelector(['user'])

	const isAdmin = useMemo(() => user?.type === 'ADMIN', [user])

	return { isAdmin }
}
