import styled, { css, DefaultTheme } from 'styled-components'
import { getFontType, spacer } from '../../styles/function'
import { SpacerProps } from '../../styles/types'
import { ColorsType, TagType } from './types'

type TextProps = {
	type: TagType
	color: ColorsType
}

const textModifiers = {
	getFontType: getFontType,
	getSpacings: spacer,
	colors: {
		grafitti: (theme: DefaultTheme) => css`
			color: ${theme.COLORS.DARK_GRAFFITI};
		`,
		primary: (theme: DefaultTheme) => css`
			color: ${theme.COLORS.PRIMARY_COLOR};
		`,
		gray: (theme: DefaultTheme) => css`
			color: ${theme.COLORS.DARK_GRAY};
		`
	}
}

export const Text = styled.div<TextProps>`
	${({ theme, type, color, ...rest }) => css`
		${textModifiers.getFontType(type)}

		${textModifiers.colors[color!](theme)}

		${textModifiers.getSpacings(rest as SpacerProps)}
	`}
`
