import { Control } from 'react-hook-form'
import {
	INotificationCitysResponse,
	INotificationProductsResponse,
	INotificationSellersResponse,
	INotificationSendRequest,
	INotificationSendResponse
} from '../../types/notifications'
import { SetStateAction } from 'react'

export enum ETypeNotification {
	PRODUCT = 'PROMOTE_PRODUCT',
	SELLER = 'PROMOTE_STORE',
	GENERAL = 'GENERAL_MESSAGE'
}

export interface IPageProps {
	goBack(): void
	typeNotification: string
	handleTypeNotification: (type: string) => void
	control: Control<any>
	productsSelect:
		| {
				value: string
		  }[]
		| undefined
	sellersSelect:
		| {
				value: string
		  }[]
		| undefined

	citysSelect:
		| {
				value: string
		  }[]
		| undefined
	selectedSeller: string | undefined
	modalOpen: boolean
	handleOpenAndCloseModal: () => void
	selectedProductInfos: INotificationProductsResponse | undefined
	selectedSellersInfos: INotificationSellersResponse | undefined
	modalData: IModalData
	handleSendNotification: (payload: INotificationSendRequest) => Promise<void>
	handleSaveDraftNotification: any
	selectedCityLabels: string[]
	handleChange: (event: any) => void
	citysList: INotificationCitysResponse[] | undefined
	notificationEditData: INotificationSendResponse | undefined
	isReadOnly: boolean
	isSending: boolean
	canSubmit: boolean
	formIsValid: boolean
}

export interface ICreateSellerForm {
	reason: string
	product?: string
	seller?: string
	title: string
	city: string
	description: string
}

export interface IModalData {
	reason: string
	title: string
	cities: INotificationCitysResponse[]
	description: string
}
