import { useMemo } from 'react'
import { useTypedSelector } from './useTypedSelector'

export function useIsLogged() {
	const { user, token } = useTypedSelector(['user', 'token'])

	const isLogged = useMemo(
		() => !!user.id && !!token.accessToken,
		[token, user]
	)

	return { isLogged }
}
