import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
* {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
	font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background: ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
}

html {
  /* Prevent scroll bar push page*/
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%)); 
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

//disable autofill background
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

.custom-scrollbar{	
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.COLORS.MEDIUM_GRAY} #ffffff;

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
	 width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.COLORS.MEDIUM_GRAY};
    border-radius: 10px;
    border: 6px solid #ffffff;
  }

}

a {
  text-decoration:none
}

`

export { GlobalStyle }
