import {
	QueryFunctionContext,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
	IAdminOrdersResponse,
	IChangeOrderStatusRequest,
	IOrderResponse,
	IReceivableResponse,
	ISellerOrdersResponse
} from '../types/orders'

import { IError } from '../utils/handleError'
import { QUERY_KEYS } from '../utils/reactQuery'
import axiosInstance from './axiosInstance'
import { IFetchOrdersSellerRequest } from '../views/Orders/types'
import { IPaginationResponse } from '../types/pagination'
import { IFetchRequest } from '../views/AdminOrders/types'

// // hook
// export function useSellerOrders(): UseQueryResult<
// 	ISellerOrdersResponse[],
// 	AxiosError<IError>
// > {
// 	return useQuery([QUERY_KEYS.SELLER_ORDERS], sellerOrders)
// }

// async function sellerOrders() {
// 	const response = await axiosInstance.get(`/orders/seller`)

// 	return response.data
// }

// hook
export function useChangeOrderStatus(): UseMutationResult<
	any,
	AxiosError<IError>,
	IChangeOrderStatusRequest
> {
	return useMutation([QUERY_KEYS.ORDER_STATUS], changeOrderStatus)
}

async function changeOrderStatus(payload: IChangeOrderStatusRequest) {
	const response = await axiosInstance.patch(
		`/orders/status/` + payload.id,
		payload
	)

	return response.data
}

// hook
export function useAdminOrders(): UseQueryResult<
	IAdminOrdersResponse[],
	AxiosError<IError>
> {
	return useQuery([QUERY_KEYS.SELLER_ORDERS], adminOrders)
}

async function adminOrders() {
	const response = await axiosInstance.get(`/orders/all`)

	return response.data
}

//hook
export function useReceivable(): UseQueryResult<
	IReceivableResponse,
	AxiosError<IError>
> {
	return useQuery([QUERY_KEYS.RECEIVABLE_ORDERS], receivable)
}

async function receivable() {
	const response = await axiosInstance.get(`/stores/payables/balance`)
	return response.data
}

// hook
export function useOrder(
	id?: string
): UseQueryResult<IOrderResponse, AxiosError<IError>> {
	return useQuery([QUERY_KEYS.ORDER, id], () => findOrder(id))
}

async function findOrder(id?: string) {
	const response = await axiosInstance.get(`/orders/details/${id}`)
	if (response) return response.data
}

interface ISellerPaginationResponse {
	paginationData: IPaginationResponse<ISellerOrdersResponse>
	totalOrdersData: {
		PENDING: number
		PRODUCTION: number
		ON_ROUTE: number
		DELIVERED: number
	}
}
//hook
export function useSellerOrders({
	pageIndex,
	searchString,
	order,
	sort,
	maxItemsInPage,
	date,
	status,
	storeID
}: IFetchOrdersSellerRequest): UseQueryResult<ISellerPaginationResponse> {
	const result = useQuery(
		[
			QUERY_KEYS.RECEIVABLE_ORDERS,
			{
				maxItemsInPage,
				pageIndex,
				searchString,
				order,
				sort,
				date,
				status,
				storeID
			}
		],
		sellerOrders
	)

	return result
}

async function sellerOrders(
	context: QueryFunctionContext<[unknown, IFetchOrdersSellerRequest]>
): Promise<ISellerPaginationResponse> {
	const [_, params] = context.queryKey
	const {
		maxItemsInPage,
		pageIndex,
		searchString,
		order,
		sort,
		date,
		status,
		storeID
	} = params

	const response = await axiosInstance.post('/orders/seller', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
		date,
		status,
		storeID
	})

	return response.data
}

async function listOrders(
	context: QueryFunctionContext<[unknown, IFetchRequest]>
): Promise<IPaginationResponse<IAdminOrdersResponse>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort, date, status } =
		params
	const response = await axiosInstance.post('/orders/all', {
		pageIndex,
		searchString,
		order,
		sort,
		maxItemsInPage,
		date,
		status
	})

	return response.data
}

export function useListOrders({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort,
	date,
	status
}: IFetchRequest): UseQueryResult<IPaginationResponse<IAdminOrdersResponse>> {
	const result = useQuery(
		[
			QUERY_KEYS.ORDERS_LIST,
			{ maxItemsInPage, pageIndex, searchString, order, sort, date, status }
		],
		listOrders
	)
	return result
}
