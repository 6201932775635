import { createElement, useCallback, useState, useEffect } from 'react'
import { add, format } from 'date-fns'
import { Navigate, useNavigate, useParams, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CircularProgress } from '@mui/material'

// Types
import type { IPageProps, IProductForm, IRegisterTypes } from './types'
import type { ISelectItems } from '../../components/Select/types'
import { EProductType } from '../../types/products'

// Components
import { ProductView } from './product'
import { ServiceView } from './service'
import { LoadingContainer } from './styles'

// Utils
import { Toaster } from '../../utils/toaster'
import {
	formatPatchProductPayload,
	formatRegisterProductPayload
} from '../../utils/formatPayload'
import { weightMask } from '../../utils/inputMasks'

// Hooks
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useModal } from '../../hooks/useModal'

// Routes
import { ERoutes } from '../../routes/routes'

// Schemas
import { productSchema, serviceSchema } from './schema'

// Services
import {
	useCategory,
	useDeleteProduct,
	useProductDetails,
	usePatchProduct,
	useRegisterProduct
} from '../../services/products.service'

// Mocks
import { TimeToDelivery } from '../../mocks/form'

function Product() {
	const [isFreeShipping, setIsFreeShipping] = useState(false)
	const [isPickupStore, setIsPickupStore] = useState(false)
	const [sendToAprove, setSendToAprove] = useState(true)
	const [finalDate, setFinalDate] = useState('')
	const [inicialDate, setInicialDate] = useState('')

	const navigate = useNavigate()
	const { id, type } = useParams()
	const { pathname } = useLocation()

	const { data: CategoriesData } = useCategory()

	const { data: product, isLoading: isLoadingProduct } = useProductDetails(id)
	const registrationType = type || product?.type || ''

	const { mutateAsync: registerAsync, isLoading: registerLoading } =
		useRegisterProduct()
	const { mutateAsync: patchAsync, isLoading: patchLoading } = usePatchProduct()
	const { mutateAsync: deleteAsync, isLoading: deleteLoading } =
		useDeleteProduct()

	const {
		user: { store }
	} = useTypedSelector(['user'])

	const ExcludeModal = useModal({
		text: 'Ao excluir este Produto, ele não estará mais disponível em sua loja. Deseja confirmar esta ação?',
		title: 'Excluir Produto',
		onClickActionButton: handleDeleteProduct,
		closeButtonLabel: 'Manter',
		actionButtonLabel: 'Excluir'
	})

	const handleGoBackToProducts = useCallback(() => {
		navigate('/seller/products')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const BackModal = useModal({
		text: 'Ao sair desta página, você perderá todas as informações preenchidas. Deseja confirmar?',
		title: 'Sair do Cadastro',
		onClickActionButton: handleGoBackToProducts,
		actionButtonLabel: 'Descartar',
		closeButtonLabel: 'Manter'
	})

	const handleFinalDate = (start_Date: string): void => {
		const start = format(add(new Date(start_Date), { days: 4 }), 'yyyy-MM-dd')

		setFinalDate(start)
	}

	const handleInicialDate = (): void => {
		const start = format(new Date(), 'yyyy-MM-dd')

		setInicialDate(start)
	}

	const isProduct = registrationType.toUpperCase() === EProductType.PRODUCT
	const isEdit = pathname.includes('edit')

	const {
		control,
		formState: { errors },
		watch,
		handleSubmit,
		reset,
		setValue,
		clearErrors,
		resetField,
		setError
	} = useForm<IProductForm>({
		resolver: yupResolver(isProduct ? productSchema : serviceSchema),
		defaultValues: isProduct
			? {
					shipping_type: 'RELATIVE'
			  }
			: {},
		mode: 'onChange'
	})

	function handleSwitchRegister(to: IRegisterTypes) {
		navigate(ERoutes.CREATE_PRODUCT.replace(':type', '') + to)
	}

	async function handleDeleteProduct() {
		await deleteAsync({ id: product?._id })

		Toaster({ type: 'success', text: 'Produto Deletado !' })

		handleGoBackToProducts()
	}

	const submitForm = handleSubmit(async (data) => {
		const imagesList: string[] = [
			data.image_01?.toString(),
			data.image_02?.toString(),
			data.image_03?.toString()
		]

		let removedImagesURL: any

		for (let i = 0; i < imagesList.length; i++) {
			const item = product?.imagesURL[i]

			if (!imagesList.includes(item || ' ')) {
				removedImagesURL += ' ' + item
			}
		}

		if (removedImagesURL) {
			removedImagesURL = removedImagesURL.split(' ')
			removedImagesURL.shift()
		}

		if (isProduct && !(data.image_01 || data.image_02 || data.image_03)) {
			Toaster({
				type: 'error',
				title: 'É necessário imagens para criar um produto.'
			})

			return
		}

		const type = registrationType.toUpperCase()

		const category =
			CategoriesData?.find((item) => item.label === data.category)?._id || ''

		if (isEdit) {
			const { formData } = formatPatchProductPayload(
				{ ...data, category },
				removedImagesURL,
				store._id,
				isFreeShipping,
				isPickupStore,
				isProduct,
				sendToAprove
			)

			await patchAsync({ formData, id: product?._id })

			Toaster({
				type: 'success',
				title: `${isProduct ? 'Produto' : 'Anúncio/Serviço'} Editado`,
				text: 'Nossa equipe irá avaliar seu Produto ou Serviço e você será notificado sobre seu status.'
			})

			handleGoBackToProducts()

			return
		}
		const { formData } = formatRegisterProductPayload(
			{ ...data, category },
			sendToAprove,
			isFreeShipping,
			isPickupStore,
			type,
			store._id,
			isProduct
		)

		await registerAsync({ formData })

		Toaster({
			type: 'success',
			title: `${isProduct ? 'Produto' : 'Anúncio/Serviço'} Cadastrado!`,
			text: 'Nossa equipe irá avaliar seu Produto ou Serviço e você será notificado sobre seu status.'
		})

		handleGoBackToProducts()
	})

	const categories: ISelectItems[] | undefined = CategoriesData?.map(
		(item) => ({
			value: item.label
		})
	)

	const { start_Date, end_Date, start_Hour, end_Hour, discount } = watch()
	useEffect(() => {
		if (!start_Date) {
			setValue('start_Hour', '')
			setValue('end_Date', '')
			setValue('end_Hour', '')
		}
		start_Date && handleFinalDate(start_Date)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start_Date])

	function resetFormValidations() {
		reset()
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(resetFormValidations, [product])

	useEffect(() => {
		if ((start_Date || end_Date || start_Hour || end_Hour) && +discount === 0) {
			setError('discount', {
				type: 'custom',
				message: 'Campo Obrigatório'
			})
		} else {
			clearErrors('discount')
		}
	}, [
		start_Date,
		end_Date,
		start_Hour,
		end_Hour,
		discount,
		clearErrors,
		setError
	])

	useEffect(() => {
		handleInicialDate()
	}, [start_Date])

	function setupProduct() {
		if (isEdit && product && CategoriesData) {
			const {
				category,
				deadline,
				delivery,
				description,
				name,
				imagesURL,
				price,
				priceDiscount,
				quantity,
				videoURL,
				weight,
				massUnit,
				readyToApprove
			} = product

			const dayOfWeek = (day: string) => {
				switch (day) {
					case '0':
						return 'Domingo'
					case '1':
						return 'Segunda'
					case '2':
						return 'Terça'
					case '3':
						return 'Quarta'
					case '4':
						return 'Quinta'
					case '5':
						return 'Sexta'
					case '6':
						return 'Sábado'

					default:
						return ''
				}
			}

			const deliveryDay = Object.entries(TimeToDelivery).find(
				(item) => item[0] === delivery.timeToDelivery
			)

			const startDate = deadline ? new Date(deadline.startDate) : null
			const endDate = deadline ? new Date(deadline.endDate) : null
			const startHour = startDate?.toLocaleTimeString()
			const endHour = endDate?.toLocaleTimeString()

			videoURL && setValue('video', videoURL)

			imagesURL[0] && setValue('image_01', imagesURL[0])
			imagesURL[1] && setValue('image_02', imagesURL[1])
			imagesURL[2] && setValue('image_03', imagesURL[2])

			delivery && setIsFreeShipping(delivery.freeShipping)
			delivery && setIsPickupStore(delivery.pickupInStore)
			setSendToAprove(readyToApprove)

			name && setValue('name', name)
			weight && setValue('weight', weightMask(weight.toString()))
			massUnit && setValue('massUnit', massUnit)
			quantity && setValue('quantity', quantity.toString())
			description && setValue('description', description)
			price && setValue('price', 'R$ ' + price.toString())
			priceDiscount && setValue('discount', 'R$ ' + priceDiscount.toString())
			startHour &&
				setValue('start_Hour', startHour.substring(0, startHour.length - 3))
			endHour && setValue('end_Hour', endHour.substring(0, endHour.length - 3))
			delivery && setValue('shipping_type', delivery.type)

			if (isProduct && delivery.type === 'RELATIVE') {
				delivery &&
					deliveryDay &&
					setValue('free_delivery_date', deliveryDay[1])
				delivery &&
					delivery.startTime &&
					setValue('free_delivery_start', delivery.startTime)
				delivery &&
					delivery.endTime &&
					setValue('free_delivery_end', delivery.endTime)
			}

			if (isProduct && delivery.type === 'SCHEDULED') {
				delivery &&
					deliveryDay &&
					setValue('scheduled_delivery_date', deliveryDay[1])
				delivery &&
					delivery.startTime &&
					setValue('scheduled_delivery_start', delivery.startTime)
				delivery &&
					delivery.endTime &&
					setValue('scheduled_delivery_end', delivery.endTime)
			}
			if (isProduct && delivery.type === 'FIXED') {
				delivery &&
					delivery.weekDay &&
					setValue(
						'scheduled_delivery_date',
						dayOfWeek(delivery.weekDay.toString())
					)
				delivery && setValue('scheduled_delivery_start', delivery.startTime)
				delivery && setValue('scheduled_delivery_end', delivery.endTime)
			}

			category && setValue('category', category.label, { shouldValidate: true })

			startDate &&
				setValue(
					'start_Date',
					startDate.toLocaleDateString().split('/').reverse().join('-')
				)
			endDate &&
				setValue(
					'end_Date',
					endDate.toLocaleDateString().split('/').reverse().join('-')
				)
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setupProduct, [product])

	function handleCleanCountdown() {
		resetField('end_Date')
		resetField('end_Hour')
		resetField('start_Date')
		resetField('start_Hour')
	}

	const pageProps: IPageProps = {
		control,
		watch,
		isFreeShipping,
		setIsFreeShipping,
		sendToAprove,
		setSendToAprove,
		registrationType,
		handleSwitchRegister,
		errors,
		submitForm,
		categories,
		isLoading: patchLoading || registerLoading || deleteLoading,
		isEdit,
		handleDeleteProduct,
		ExcludeModal,
		BackModal,
		isProduct,
		finalDate,
		inicialDate,
		isPickupStore,
		setIsPickupStore,
		handleCleanCountdown
	}

	if (isEdit && isLoadingProduct)
		return (
			<LoadingContainer>
				<CircularProgress size={32} />
			</LoadingContainer>
		)

	if (registrationType.toUpperCase() === EProductType.PRODUCT) {
		return createElement(ProductView, pageProps)
	}

	if (registrationType.toUpperCase() === EProductType.SERVICE) {
		return createElement(ServiceView, pageProps)
	}

	return <Navigate to={'/seller/products'} />
}

export { Product }
