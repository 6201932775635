import { createElement } from 'react'
import { ExternalAboutView } from './view'

//types
import { IPageProps } from './types'

function ExternalAbout() {
	const pageProps: IPageProps = {}

	return createElement(ExternalAboutView, pageProps)
}

export { ExternalAbout }
