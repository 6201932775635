//redux
import { IUser } from "../types"
import { ITokenActionCreators, IUserActionCreators } from "../types/action"
import { IToken } from "../types/token"
import { store as reduxStore } from "./redux/"
import { Creators as TokenActions } from './redux/ducks/token'
import { Creators as UserActions } from './redux/ducks/user'

//zustand
import { ICreateSellerFormActions, ICreateSellerFormValue } from "./zustand/useCreateSellerFormStore"
import { store as zustandStore } from "./zustand"

export interface IStore<Value, Actions> {
	value: Value
	actions: Actions
}

export const store = () => {
    const userStore: IStore<IUser, IUserActionCreators> = {
        value: reduxStore.getState().user,
        actions: {
            setUser: (user: IUser) => reduxStore.dispatch(UserActions.setUser(user)),
            resetUser: () => reduxStore.dispatch(UserActions.resetUser())
        }
    }
    const tokenStore: IStore<IToken, ITokenActionCreators> = {
        value: reduxStore.getState().token,
        actions: {
            setToken: (token: IToken) => reduxStore.dispatch(TokenActions.setToken(token)),
            resetToken: () => reduxStore.dispatch(TokenActions.resetToken())
        }
    }
    const createSellerFormStore: IStore<
        ICreateSellerFormValue,
        ICreateSellerFormActions
    > = {
        value: zustandStore.createSellerFormStore.getState().createSellerForm,
        actions: {
            addCreateSellerForm: zustandStore.createSellerFormStore.getState().addCreateSellerForm,
            removeCreateSellerForm: zustandStore.createSellerFormStore.getState().removeCreateSellerForm
        }
    }

    return {
        user: userStore,
        token: tokenStore,
        createSellerForm: createSellerFormStore
    }
}