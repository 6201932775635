import styled, { css } from 'styled-components'

export const Container = styled.label<{
	showLabel?: boolean
	hasError?: boolean
}>`
	width: 102px;
	height: ${({ showLabel }) => (showLabel ? 180 : 102)}px;
	background: ${({ theme }) => theme.COLORS.BLUEISH_WHITE};
	border: 1px dashed ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	border-radius: 4px;
	aspect-ratio: 1/1;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex-direction: column;
	padding: 0px 15px;
	box-sizing: border-box;

	input {
		display: none;
	}

	${({ showLabel }) =>
		showLabel &&
		css`
			img {
				margin-bottom: 40px;
			}
		`}

	${({ hasError, theme }) =>
		hasError &&
		css`
			border: 1px dashed ${theme.COLORS.RED_ERROR} !important;
		`}

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	}

	${({ hasError }) => {
		return (
			hasError &&
			css`
				border: 1px dashed ${({ theme }) => theme.COLORS.RED_ERROR}!important;
			`
		)
	}}
`
export const PreviewContainer = styled.div<{
	showLabel?: boolean
	preview: boolean
}>`
	width: 102px;
	height: ${({ showLabel }) => (showLabel ? 180 : 102)}px;
	border-radius: 4px;
	display: flex;
	background: ${({ theme }) => theme.COLORS.BLUEISH_WHITE};
	aspect-ratio: 1/1;
	position: relative;
	border-radius: 4px;

	${({ preview }) =>
		preview &&
		css`
			cursor: pointer;
		`}
`

export const PreviewImage = styled.img`
	width: 100%;
	border-radius: 4px;
	object-fit: cover;
`

export const PreviewVideo = styled.video`
	width: 100%;
	border-radius: 4px;
	object-fit: cover;
`

export const PreviewShadow = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 4px;

	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.64) 100%
	);
	position: absolute;
`

export const ClearContainer = styled.div`
	background: #ffffff;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.24);
	border-radius: 50%;
	width: 24px;
	height: 24px;
	position: absolute;
	top: -8px;
	right: -8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 3;

	:active {
		opacity: 0.6;
	}
`

export const FileSize = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.WHITE};
	position: absolute;
	z-index: 4;
	bottom: 12px;
	left: 50%;
	transform: translate(-50%, -50%);
`

export const ErrorMessage = styled.span`
	position: absolute;
	bottom: -20px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.RED_ERROR};
`
export const LoadingPlaceHolder = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;

	span {
		font-size: 11px;
	}
`
