import styled, { css } from 'styled-components'

export const Container = styled.div`
	position: relative;
`

export const Selector = styled.div<{ isListOpen: boolean }>`
	width: 28px;
	height: 28px;
	background-color: ${({ theme, isListOpen }) =>
		isListOpen ? theme.COLORS.PRIMARY_COLOR : theme.COLORS.GRAYISH_WHITE};
	border-radius: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	transition: all 0.1s;

	img {
		width: 16px;
		height: 16px;
	}

	${({ isListOpen }) =>
		isListOpen &&
		css`
			transform: rotateZ(180deg);
		`}
`

export const List = styled.div<{ isListOpen: boolean }>`
	width: 262px;
	padding: 24px 16px;
	background: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-radius: 4px;
	position: absolute;
	z-index: 5;
	transform: translateY(5px);
	transition: all 0.2s;
	pointer-events: none;
	opacity: 0;
	display: flex;
	flex-direction: column;

	${({ isListOpen }) =>
		isListOpen &&
		css`
			opacity: 1;
			pointer-events: all;
		`}
`

export const ListTitle = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	margin-bottom: 30px;
`

export const ItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 26px;
`

export const ListItem = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #a3a3a3;
	padding: 0 16px;
	cursor: pointer;
	user-select: none;
`
