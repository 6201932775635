import { Dispatch, SetStateAction } from 'react'
import { ISellerOrdersResponse } from '../../types/orders'
import { IPaginationResponse } from '../../types/pagination'

export interface IPageProps {
	orders: IPaginationResponse<ISellerOrdersResponse> | undefined
	refetch: () => void
	isLoading: boolean
	isFetchedAfterMount: boolean
	searchQuery: string
	setSearchQuery: Dispatch<SetStateAction<string>>
	receivable: string
	setReceivable: Dispatch<SetStateAction<string>>
	accountBalance: string
	setAccountBalance: Dispatch<SetStateAction<string>>
	handleChangePage: (page: number) => void
	setFilter: Dispatch<SetStateAction<IFilter>>
	filter: IFilter
	setDateFilter: Dispatch<SetStateAction<Date | undefined>>
	dateFilter: Date | undefined
	setStatus: Dispatch<SetStateAction<OrderStatus | undefined>>
	status: OrderStatus | undefined
	totalItemsData: ITotalItemsData | undefined
}

export enum OrderStatus {
	PENDING = 'PENDING', // PENDENTE (PAGAMENTO)
	PRODUCTION = 'PRODUCTION', // EM PRODUÇAO
	ON_ROUTE = 'ON_ROUTE', // EM ROTA (A CAMINHO)
	DELIVERED = 'DELIVERED' // ENTREGUE
}

export interface IFilter {
	sort: {
		by: string
		sort: string
	}
	order: number
}

export interface IFetchOrdersSellerRequest {
	maxItemsInPage: number
	pageIndex: number
	order: number
	searchString: string
	sort: string
	date: Date | undefined
	status?: OrderStatus | undefined
	storeID: string
}

export interface ITotalItemsData {
	PENDING: number
	PRODUCTION: number
	ON_ROUTE: number
	DELIVERED: number
}
