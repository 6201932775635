import {
	QueryFunctionContext,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult
} from '@tanstack/react-query'

import { AxiosError } from 'axios'

import {
	IActivateSellerRequest,
	IActivateSellerResponse,
	ISellersResponse,
	ISellerDetailsRequest,
	ISellerDetailsResponse,
	ICreateSellerResponse,
	IChangeSellerStatusRequest,
	IChangeSellerStatusResponse,
	IPatchSellerRequest,
	IResendResponse,
	IResendRequest,
	IUpdateSellerResponse,
	IActivateSellerByOtpRequest,
	IOpenAndCloseRequest,
	Switch
} from '../types/seller'

import { IError } from '../utils/handleError'
import { QUERY_KEYS } from '../utils/reactQuery'

import axiosInstance from './axiosInstance'

// hook
export function useCreateSeller(): UseMutationResult<
	ICreateSellerResponse,
	any,
	FormData
> {
	return useMutation([QUERY_KEYS.CREATE_SELLER], createSeller)
}

async function createSeller(payload: FormData) {
	const response = await axiosInstance.post<ICreateSellerResponse>(
		'/users/seller',
		payload
	)

	return response.data
}

// hook
export function useEditSeller(): UseMutationResult<
	IUpdateSellerResponse,
	any,
	FormData
> {
	return useMutation([QUERY_KEYS.UPDATE_SELLER], editSeller)
}

async function editSeller(payload: FormData) {
	const response = await axiosInstance.patch<IUpdateSellerResponse>(
		'/users/seller',
		payload
	)

	return response.data
}

// hook
export function useUpdateSeller(): UseMutationResult<
	ICreateSellerResponse,
	any,
	IPatchSellerRequest
> {
	return useMutation([QUERY_KEYS.CREATE_SELLER], updateSeller)
}

async function updateSeller({ payload, token }: IPatchSellerRequest) {
	const response = await axiosInstance.patch<ICreateSellerResponse>(
		'/users/seller/' + token,
		payload
	)

	return response.data
}

// hook
export function useSellers(): UseQueryResult<ISellersResponse> {
	return useQuery([QUERY_KEYS.SELLERS], sellers)
}

async function sellers() {
	const response = await axiosInstance.get<ISellersResponse>('/users/seller')

	return response.data
}

// hook
export function useActivateSeller({
	activateToken
}: IActivateSellerRequest): UseQueryResult<
	IActivateSellerResponse,
	AxiosError<IError>
> {
	return useQuery([QUERY_KEYS.ACTIVATE_SELLER, activateToken], activateSeller, {
		enabled: !!activateToken,
		retry: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false
	})
}

async function activateSeller(context: QueryFunctionContext) {
	try {
		const [, activateToken] = context.queryKey

		const response = await axiosInstance.patch(
			`/users/seller/activate/${activateToken}`
		)

		return response.data
	} catch (err) {
		throw err
	}
}

// hook
export function useSellerDetails({
	seller_id
}: ISellerDetailsRequest): UseQueryResult<
	ISellerDetailsResponse,
	AxiosError<IError>
> {
	return useQuery(
		[QUERY_KEYS.SELLER_DETAILS, seller_id],
		() => sellerDetails(seller_id),
		{
			enabled: !!seller_id,
			retry: false,
			refetchOnWindowFocus: false
		}
	)
}

async function sellerDetails(seller_id?: string) {
	try {
		const response = await axiosInstance.get(`/users/seller/${seller_id}`)

		return response.data
	} catch (err) {
		throw err
	}
}

// hook
export function useChangeSellerStatus(): UseMutationResult<
	IChangeSellerStatusResponse,
	AxiosError<IError>,
	IChangeSellerStatusRequest
> {
	return useMutation([QUERY_KEYS.CHANGE_SELLER_STATUS], changeSellerStatus)
}

async function changeSellerStatus({
	action,
	seller_id,
	message
}: IChangeSellerStatusRequest) {
	try {
		let route = ''
		switch (action) {
			case 'aprove':
				route = 'approve-seller'
				break
			case 'decline':
				route = 'decline-seller'
				break
			case 'inactive':
				route = 'inactive-seller'
				break
			default:
				throw new Error('Status inválido')
		}

		const response = await axiosInstance.patch(
			`/users/${route}/${seller_id}`,
			(action === 'decline' || 'inactive') && { message }
		)

		return response.data
	} catch (err) {
		throw err
	}
}

// hook
export function useResendGateway(): UseMutationResult<
	IResendResponse,
	AxiosError<IError>,
	IResendRequest
> {
	return useMutation([QUERY_KEYS.RESEND_GATEWAY], resendGateway)
}

async function resendGateway({ seller_id }: IResendRequest) {
	try {
		const response = await axiosInstance.post(`/users/resend/${seller_id}`)

		return response.data
	} catch (err) {
		throw err
	}
}

// hook
export function useRequestOtpToActivate(): UseMutationResult<
	void,
	AxiosError<IError>,
	string
> {
	return useMutation([QUERY_KEYS.ACTIVATE_SELLER_BY_OTP], requestOtpToActivate)
}

async function requestOtpToActivate(email: string) {
	try {
		await axiosInstance.post('/users/request-otp-to-active', { email })
	} catch (err) {
		throw err
	}
}

// hook
export function useActivateSellerBytOtp(): UseMutationResult<
	any,
	AxiosError<IError>,
	IActivateSellerByOtpRequest
> {
	return useMutation([QUERY_KEYS.ACTIVATE_SELLER_BY_OTP], activateSellerBytOtp)
}

async function activateSellerBytOtp(data: IActivateSellerByOtpRequest) {
	try {
		const response = await axiosInstance.patch(
			`/users/seller/activate/${data.otp}`,
			data
		)
		return response.data
	} catch (err) {
		throw err
	}
}

async function switchOpenAndCloseStore(
	payload: IOpenAndCloseRequest
): Promise<Switch> {
	const { storeID } = payload
	const response = await axiosInstance.get(`/stores/open/${storeID}`)

	return response.data
}

export function useMutationSwitchOpenAndCloseStore(): UseMutationResult<
	Switch,
	AxiosError<IError>,
	IOpenAndCloseRequest
> {
	return useMutation(switchOpenAndCloseStore)
}
