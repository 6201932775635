import { createElement, useCallback, useState } from 'react'
import { AdminProductDetailsView } from './view'

//hooks
import { useNavigate, useParams, Navigate } from 'react-router-dom'

//service
import {
	useChangeProductStatus,
	useProductDetails
} from '../../services/products.service'

//utils
import { Toaster } from '../../utils/toaster'

//types
import { IChangeProductStatusAction } from '../../types/products'
import { ERoutes } from '../../routes/routes'
import { IPageProps } from './types'

function AdminProductDetails() {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const navigator = useNavigate()

	const { id } = useParams()

	const { data, isLoading: isLoadingProduct } = useProductDetails(id)

	const { isLoading, mutateAsync } = useChangeProductStatus()

	const goBackListProducts = useCallback(() => {
		navigator(ERoutes.ADMIN_PRODUCTS_LIST)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function handleOpenAndCloseModal() {
		setIsModalOpen((prev) => !prev)
	}

	async function changeProductStatus(
		action: IChangeProductStatusAction,
		message: string,
		type: string
	) {
		if (!data?._id) return

		await mutateAsync({ message, id: data?._id, action })

		Toaster({
			type: 'success',
			text: ` ${type === 'PRODUCT' ? 'Produto' : 'Serviço/Anúncio'} ${
				action === 'approve' ? 'aprovado' : 'recusado'
			} com sucesso!`
		})

		goBackListProducts()
	}

	if (!isLoadingProduct && !data)
		return <Navigate to={ERoutes.ADMIN_PRODUCTS_LIST} />

	const pageProps: IPageProps = {
		goBackListProducts,
		product: data,
		handleOpenAndCloseModal,
		isModalOpen,
		changeProductStatus,
		isLoading
	}

	return createElement(AdminProductDetailsView, pageProps)
}

export { AdminProductDetails }
