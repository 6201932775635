import * as S from './styles'

//components

//types
import { ETypeNotification, IPageProps } from './types'

//utils

//icons
import arrowBack from '../../assets/icons/arrow-left.svg'
import { Radio } from '@mui/material'
import { Container } from '../../styles'
import { ControlledInputText } from '../../components/InputText/controlled'
import { ControlledSelect } from '../../components/Select/controlled'
import { Button } from '../../components'
import { ControlledTextArea } from '../../components/TextArea/controlled'
import { NotificationModal } from '../../components/NotificationModal'
import { StoreCard } from '../../components/StoreCard'
import { ProductCard } from '../../components/ProductCard'
import { MenuItem } from '@material-ui/core'
import { PushNotificationStatus } from '../../types/notifications'

//mocks

function CreateNotificationView({
	goBack,
	typeNotification,
	handleTypeNotification,
	control,
	productsSelect,
	sellersSelect,
	selectedSeller,
	citysSelect,
	modalOpen,
	handleOpenAndCloseModal,
	selectedProductInfos,
	selectedSellersInfos,
	modalData,
	handleSendNotification,
	handleSaveDraftNotification,
	selectedCityLabels,
	handleChange,
	notificationEditData,
	citysList,
	isReadOnly,
	isSending,
	canSubmit,
	formIsValid
}: IPageProps) {
	function RadioCard({
		value,
		label
	}: {
		value: ETypeNotification
		label: string
	}) {
		return (
			<S.RadioCard
				checked={typeNotification === value}
				onClick={() => handleTypeNotification(value)}
			>
				<Radio checked={typeNotification === value} />
				<S.RadioText>{label}</S.RadioText>
			</S.RadioCard>
		)
	}

	function NotificationStatus() {
		return notificationEditData ? (
			<S.FormSubtitleWrapper>
				<S.FormSubtitle>Configuração da Notificação</S.FormSubtitle>
				{notificationEditData.status === PushNotificationStatus.DRAFT ? (
					<S.TypeDraftContainer>
						<S.TypeSDraftTitle>Rascunho</S.TypeSDraftTitle>
					</S.TypeDraftContainer>
				) : (
					<S.TypeSentContainer>
						<S.TypeSentTitle>Enviado</S.TypeSentTitle>
					</S.TypeSentContainer>
				)}
			</S.FormSubtitleWrapper>
		) : (
			<S.FormSubtitle>Configuração da Notificação</S.FormSubtitle>
		)
	}

	function ActionButtons() {
		return (
			<S.SendWrapper>
				<S.SendSubtitle>Enviar notificação?</S.SendSubtitle>
				<S.ButtonsWrapper>
					<S.SketchButton type='button' onClick={handleSaveDraftNotification}>
						<S.SketchButtonText>Salvar rascunho</S.SketchButtonText>
					</S.SketchButton>

					<Button
						disabled={formIsValid}
						onClick={handleOpenAndCloseModal}
						label='Enviar'
					/>
				</S.ButtonsWrapper>
			</S.SendWrapper>
		)
	}

	return (
		<>
			<Container>
				<S.Container>
					<NotificationModal
						product={selectedProductInfos}
						seller={selectedSellersInfos}
						data={modalData}
						type={typeNotification}
						handleOpenAndCloseModal={handleOpenAndCloseModal}
						isModalOpen={modalOpen}
						handleSendNotification={handleSendNotification}
						isSending={isSending}
					/>
					<S.Content>
						<S.ContentHead>
							<S.GoBackContainer onClick={goBack}>
								<S.ArrowBack src={arrowBack} />
								<S.GoBackText>Voltar</S.GoBackText>
							</S.GoBackContainer>{' '}
						</S.ContentHead>
						<S.TitleWrapper>
							<S.Title>
								{isReadOnly
									? 'Detalhes da notificação'
									: notificationEditData
									? 'Editar notificação'
									: 'Criar notificação'}
							</S.Title>
						</S.TitleWrapper>
						<S.SubtitleWrapper>
							<S.Subtitle>
								{isReadOnly
									? 'Tipo de notificação:'
									: notificationEditData
									? 'O que deseja editar:'
									: 'Que tipo de notificação deseja criar:'}
							</S.Subtitle>
						</S.SubtitleWrapper>

						<S.NotificationTypeCardsWrapper>
							<RadioCard
								value={ETypeNotification.PRODUCT}
								label='Promover um Produto'
							/>
							<RadioCard
								value={ETypeNotification.SELLER}
								label='Promover um Seller'
							/>
							<RadioCard
								value={ETypeNotification.GENERAL}
								label='Mensagem Geral'
							/>
						</S.NotificationTypeCardsWrapper>

						{typeNotification.length !== 0 ? <S.Divider /> : null}

						{typeNotification === ETypeNotification.PRODUCT ? (
							<>
								<S.Form>
									<NotificationStatus />

									<S.InputWrapper>
										<S.InputContainer>
											<ControlledInputText
												disabled={isReadOnly}
												control={control}
												name={'reason'}
												floatingLabel={'Motivo'}
												placeholder={'Escreva aqui o Motivo'}
												flex={1}
											/>
										</S.InputContainer>
										<S.EmptyInput />
									</S.InputWrapper>

									<S.InputWrapper>
										<S.InputContainer>
											<ControlledSelect
												disabled={isReadOnly}
												control={control}
												name={'seller'}
												floatingLabel={'Seller'}
												placeholder={'Selecionar'}
												flex={1}
												items={sellersSelect}
											/>
										</S.InputContainer>
										<S.InputContainer>
											<ControlledSelect
												control={control}
												name={'product'}
												floatingLabel={'Produto'}
												placeholder={'Selecionar '}
												flex={1}
												items={productsSelect}
												disabled={!selectedSeller === true || isReadOnly}
											/>
										</S.InputContainer>
									</S.InputWrapper>
									{selectedSellersInfos || selectedProductInfos ? (
										<S.CardsWrapper>
											{selectedSellersInfos ? (
												<StoreCard store={selectedSellersInfos} />
											) : null}

											{selectedProductInfos ? (
												<ProductCard product={selectedProductInfos} />
											) : null}
										</S.CardsWrapper>
									) : null}

									<S.InputWrapper>
										<S.InputContainer>
											<S.MultiSelectWrapper>
												<S.MultiSelectTitle>Cidade</S.MultiSelectTitle>
												<S.MultiSelect
													isSelected={selectedCityLabels.length > 0}
													disabled={isReadOnly}
													defaultValue={'none'}
													value={selectedCityLabels}
													onChange={handleChange}
													multiple={true}
												>
													<MenuItem disabled value='none'>
														Selecionar
													</MenuItem>
													<MenuItem value='all-cities'>
														Selecionar todas
													</MenuItem>
													{citysList?.map((item, index) => {
														return (
															<MenuItem key={index} value={item.label}>
																{item.label}
															</MenuItem>
														)
													})}
												</S.MultiSelect>
											</S.MultiSelectWrapper>
											{selectedCityLabels.length === 0 ? (
												<S.SelectPlaceHolder>Selecionar</S.SelectPlaceHolder>
											) : null}
										</S.InputContainer>
										<S.InputContainer>
											<ControlledInputText
												disabled={isReadOnly}
												control={control}
												name={'title'}
												floatingLabel={'Título da notificação'}
												placeholder={'Escreva aqui o título'}
												flex={1}
											/>
										</S.InputContainer>
									</S.InputWrapper>

									<S.InputWrapper>
										<ControlledTextArea
											disabled={isReadOnly}
											control={control}
											name={'description'}
											floatingLabel={'Descrição'}
											placeholder={'Escreva aqui a descrição'}
											maxLength={400}
											showMaxLength
											flex={1}
										/>
									</S.InputWrapper>

									<S.DividerBottom />

									{canSubmit ? <ActionButtons /> : null}
								</S.Form>
							</>
						) : null}

						{typeNotification === ETypeNotification.SELLER ? (
							<>
								<S.Form>
									<NotificationStatus />

									<S.InputWrapper>
										<S.InputContainer>
											<ControlledInputText
												disabled={isReadOnly}
												control={control}
												name={'reason'}
												floatingLabel={'Motivo'}
												placeholder={'Escreva aqui o Motivo'}
												flex={1}
											/>
										</S.InputContainer>
										<S.EmptyInput />
									</S.InputWrapper>

									<S.InputWrapper>
										<S.InputContainer>
											<ControlledSelect
												disabled={isReadOnly}
												control={control}
												name={'seller'}
												floatingLabel={'Seller'}
												placeholder={'Selecionar'}
												flex={1}
												items={sellersSelect}
											/>
										</S.InputContainer>
									</S.InputWrapper>

									{selectedSellersInfos ? (
										<S.CardsWrapper>
											{selectedSellersInfos ? (
												<StoreCard store={selectedSellersInfos} />
											) : null}
										</S.CardsWrapper>
									) : null}

									<S.InputWrapper>
										<S.InputContainer>
											<S.MultiSelectWrapper>
												<S.MultiSelectTitle>Cidade</S.MultiSelectTitle>
												<S.MultiSelect
													disabled={isReadOnly}
													defaultValue={'none'}
													value={selectedCityLabels}
													onChange={handleChange}
													multiple={true}
													placeholder='Selecionar'
												>
													<MenuItem value='none'>Selecionar</MenuItem>
													<MenuItem value='all-cities'>
														Selecionar todas
													</MenuItem>
													{citysList?.map((item, index) => {
														return (
															<MenuItem key={index} value={item.label}>
																{item.label}
															</MenuItem>
														)
													})}
												</S.MultiSelect>
											</S.MultiSelectWrapper>
											{selectedCityLabels.length === 0 ? (
												<S.SelectPlaceHolder>Selecionar</S.SelectPlaceHolder>
											) : null}
										</S.InputContainer>
										<S.InputContainer>
											<ControlledInputText
												disabled={isReadOnly}
												control={control}
												name={'title'}
												floatingLabel={'Título da notificação'}
												placeholder={'Escreva aqui o título'}
												flex={1}
											/>
										</S.InputContainer>
									</S.InputWrapper>

									<S.InputWrapper>
										<ControlledTextArea
											disabled={isReadOnly}
											control={control}
											name={'description'}
											floatingLabel={'Descrição'}
											placeholder={'Escreva aqui a descrição'}
											flex={1}
											maxLength={400}
											showMaxLength
										/>
									</S.InputWrapper>

									<S.DividerBottom />

									{canSubmit ? <ActionButtons /> : null}
								</S.Form>
							</>
						) : null}

						{typeNotification === ETypeNotification.GENERAL ? (
							<>
								<S.Form>
									<NotificationStatus />
									<S.InputWrapper>
										<S.InputContainer>
											<S.MultiSelectWrapper>
												<S.MultiSelectTitle>Cidade</S.MultiSelectTitle>
												<S.MultiSelect
													disabled={isReadOnly}
													defaultValue={'none'}
													value={selectedCityLabels}
													onChange={handleChange}
													multiple={true}
												>
													<MenuItem disabled value='none'>
														Selecionar
													</MenuItem>
													<MenuItem value='all-cities'>
														Selecionar todas
													</MenuItem>
													{citysSelect?.map((item, index) => {
														return (
															<MenuItem key={index} value={item.value}>
																{item.value}
															</MenuItem>
														)
													})}
												</S.MultiSelect>
											</S.MultiSelectWrapper>
											{selectedCityLabels.length === 0 ? (
												<S.SelectPlaceHolder>Selecionar</S.SelectPlaceHolder>
											) : null}
										</S.InputContainer>
										<S.InputContainer>
											<ControlledInputText
												disabled={isReadOnly}
												control={control}
												name={'title'}
												floatingLabel={'Título da notificação'}
												placeholder={'Escreva aqui o título'}
												flex={1}
											/>
										</S.InputContainer>
										<S.InputContainer>
											<ControlledInputText
												disabled={isReadOnly}
												control={control}
												name={'reason'}
												floatingLabel={'Motivo'}
												placeholder={'Escreva aqui o Motivo'}
												flex={1}
											/>
										</S.InputContainer>
									</S.InputWrapper>

									<S.InputWrapper>
										<ControlledTextArea
											disabled={isReadOnly}
											control={control}
											name={'description'}
											floatingLabel={'Descrição'}
											placeholder={'Escreva aqui a descrição'}
											flex={1}
											maxLength={400}
											showMaxLength
										/>
									</S.InputWrapper>

									<S.DividerBottom />

									{canSubmit ? <ActionButtons /> : null}
								</S.Form>
							</>
						) : null}
					</S.Content>
				</S.Container>
			</Container>
		</>
	)
}

export { CreateNotificationView }
