import styled from 'styled-components'

import { getWidthString } from './function'
import { IGridProps } from './types'
import { grid } from './variables'

export const Container = styled.div.attrs({
	className: 'container'
})`
	margin: 0 auto;
	padding-right: ${grid.gutter}px;
	padding-left: ${grid.gutter}px;
	justify-content: center;
	height: 100%;
	width: 100%;

	@media screen and (min-width: ${grid.lg}px) {
		max-width: ${grid.lg}px;
	}
	@media screen and (max-width: ${grid.lg}px) {
		max-width: ${grid.md}px;
	}
	@media screen and (max-width: ${grid.md}px) {
		max-width: ${grid.sm}px;
	}
	@media screen and (max-width: ${grid.sm}px) {
		max-width: ${grid.xs}px;
	}
	@media screen and (max-width: ${grid.xs}px) {
		max-width: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`

export const FullContainer = styled.div.attrs({
	className: 'container'
})`
	margin: 0 auto;
	padding-right: ${grid.gutter}px;
	padding-left: ${grid.gutter}px;
	justify-content: center;
	height: 100%;
	width: 100%;
	max-width: ${grid.md * 2}px;

	@media screen and (max-width: ${grid.xs}px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`

export const Row = styled.div.attrs({
	className: 'row'
})`
	display: flex;
	display: -ms-flexbox;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: ${-grid.gutter / 2}px;
	margin-left: ${-grid.gutter / 2}px;
`

export const Column = styled.div.attrs({
	className: 'column'
})<IGridProps>`
	position: relative;
	width: 100%;
	min-height: 1px;
	padding: ${grid.gutter / 2}px;

	@media screen and (min-width: ${grid.lg}px) {
		${({ lg }) => lg && getWidthString(lg)};
	}
	@media screen and (max-width: ${grid.lg}px) {
		${({ lg }) => lg && getWidthString(lg)};
	}
	@media screen and (max-width: ${grid.md}px) {
		${({ md }) => md && getWidthString(md)};
	}
	@media screen and (max-width: ${grid.sm}px) {
		${({ sm }) => sm && getWidthString(sm)};
	}
	@media screen and (max-width: ${grid.xs}px) {
		${({ xs }) => (xs ? getWidthString(xs) : 'max-width: 100%')};
	}
`

export const Offset = styled.div.attrs({
	className: 'offset'
})<IGridProps>`
	position: relative;
	width: 100%;
	min-height: 1px;
	padding: ${grid.gutter / 2}px;

	@media screen and (min-width: ${grid.lg}px) {
		${({ lg }) => (lg ? getWidthString(lg) : 'dispa')};
	}
	@media screen and (max-width: ${grid.lg}px) {
		${({ lg }) => (lg ? getWidthString(lg) : 'display: none;')};
	}
	@media screen and (max-width: ${grid.md}px) {
		${({ md }) => (md ? getWidthString(md) : 'display: none;')};
	}
	@media screen and (max-width: ${grid.sm}px) {
		${({ sm }) => (sm ? getWidthString(sm) : 'display: none;')};
	}
	@media screen and (max-width: ${grid.xs}px) {
		${({ xs }) => (xs ? getWidthString(xs) : 'display: none;')};
	}
`
