import styled, { css } from 'styled-components'

export const Sort = styled.img<{ spin: boolean }>`
	transition: all 0.5s;
	width: 21px;
	height: 21px;
	margin-left: 8px;
	cursor: pointer;

	${({ spin }) =>
		spin &&
		css`
			transform: rotateZ(180deg);
		`}
`

export const TableHeaderItem = styled.th`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	border-color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	min-width: 150px;
`

export const ItemsContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
`
