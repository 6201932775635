import styled from 'styled-components'

export const Wrapper = styled.div`
	height: 112px;
	background-color: ${({ theme }) => theme.COLORS.OFF_WHITE};
	border-bottom: solid 1px #ebebeb;
	padding: 0 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const Image = styled.img`
	max-width: 103px;
	cursor: pointer;
`

export const Title = styled.h4`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: right;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`
