import axios from 'axios'

export interface IBank {
	ispb: string
	name: string
	code: number
	fullName: string
}

async function getBanks() {
	const response = await axios.get<IBank[]>(
		'https://brasilapi.com.br/api/banks/v1'
	)

	return response.data
}

const formatBankCode = (code: number) => {
	switch (code.toString().length) {
		case 1:
			return '00' + code
		case 2:
			return '0' + code

		default:
			return code
	}
}

export { getBanks, formatBankCode }
