import { ComponentClass, FunctionComponent } from 'react'

export enum UserTypesEnum {
	ADMIN = 'ADMIN',
	SELLER = 'SELLER',
	CUSTOMER = 'CUSTOMER'
}

export interface IRoute {
	element: string | FunctionComponent<{}> | ComponentClass<{}>
	title?: string
	path: string
	secure?: boolean
	fullPage?: boolean
	layout?: boolean
	menu?: boolean
	accessType?: UserTypesEnum[]
	children?: IRoute[]
}
