import React, { useRef, useState } from 'react'
import * as S from './styles'

//types
import { EOrderStatusLabels, IStatusSelector } from './types'

//icons
import arrowBlue from '../../assets/icons/chevron-down-blue.svg'
import arrowWhite from '../../assets/icons/chevron-down-white.svg'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'

import { CancelOrderModal } from '../CancelOrderModal'
import { useChangeOrderStatus } from '../../services/orders.service'
import { EOrderStatus } from '../../types/orders'
import { availableStatus } from '../../utils/status'

function StatusSelector({
	onChange,
	orderId,
	status,
	refetch
}: IStatusSelector) {
	const [isListOpen, setIsListOpen] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const ref = useRef<HTMLDivElement>(null)

	const { mutateAsync: changeStatusAsync, isLoading } = useChangeOrderStatus()

	useOnClickOutside(ref, () => {
		setIsListOpen(false)
	})

	async function onSelect(status: EOrderStatus) {
		setIsListOpen(false)

		if (isLoading) return

		if (status === EOrderStatus.CANCELED) {
			setIsModalOpen(true)

			return
		}

		await changeStatusAsync({ id: orderId, status })

		onChange && onChange(status)
		refetch && refetch()
	}

	function handleOpenAndCloseModal() {
		setIsModalOpen((prev) => !prev)
	}

	if (status === EOrderStatus.DELIVERED || status === EOrderStatus.CANCELED)
		return null

	return (
		<S.Container ref={ref}>
			<S.Selector
				isListOpen={isListOpen}
				onClick={() => setIsListOpen(!isListOpen)}
			>
				<img src={isListOpen ? arrowWhite : arrowBlue} alt='' />
			</S.Selector>

			<S.List isListOpen={isListOpen}>
				<S.ListTitle>Informe o Status do Pedido</S.ListTitle>

				<S.ItemsContainer>
					{React.Children.toArray(
						availableStatus(status).map((item) => (
							<S.ListItem onClick={() => onSelect(item)}>
								{EOrderStatusLabels[item]}
							</S.ListItem>
						))
					)}
				</S.ItemsContainer>
			</S.List>

			<CancelOrderModal
				orderId={orderId}
				isModalOpen={isModalOpen}
				handleOpenAndCloseModal={handleOpenAndCloseModal}
				onSuccess={() => onChange && onChange(EOrderStatus.CANCELED)}
			/>
		</S.Container>
	)
}

export { StatusSelector }
