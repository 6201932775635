import { createElement, useMemo, useState } from 'react'
import { AdminSellerDetailsView } from './view'

//hooks
import { useNavigate, useParams } from 'react-router-dom'

//services
import {
	useChangeSellerStatus,
	useResendGateway,
	useSellerDetails
} from '../../services/seller.service'

//types
import { IChangeSellerStatusActions, IPageProps } from './types'
import { EGatewayStatus } from '../../types/seller'

//utils
import { Toaster } from '../../utils/toaster'
import { formatBR } from '../../utils/formatOpeningHour'

function AdminSellerDetails() {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const navigate = useNavigate()
	const params = useParams()
	const { mutateAsync: resendGateway, isLoading: resendLoading } =
		useResendGateway()
	const { mutateAsync, isLoading } = useChangeSellerStatus()
	const { data, refetch } = useSellerDetails({ seller_id: params.id })

	function goBackListSeller() {
		navigate(-1)
	}

	if (!params.id) {
		goBackListSeller()
	}

	const disabledByStatusGateway = useMemo(() => {
		const statusGateway = data?.statusGateway

		return statusGateway === EGatewayStatus.REGISTRATION
	}, [data])

	const openingHours = useMemo(() => {
		if (data?.store.openingHours) {
			const openingHoursFormatted = formatBR(data?.store.openingHours)

			return openingHoursFormatted
		}

		return []
	}, [data])

	async function changeSellerStatus(
		action: IChangeSellerStatusActions,
		message?: string
	) {
		if (!data?.id) return

		await mutateAsync({ action, seller_id: data.id, message })

		Toaster({
			type: 'success',
			title: 'Status atualizado'
		})

		goBackListSeller()
	}

	function handleOpenAndCloseModal() {
		setIsModalOpen((prev) => !prev)
	}

	async function resendForGateway() {
		await resendGateway({ seller_id: params.id })
		Toaster({
			type: 'success',
			title: 'Dados reenviados com sucesso'
		})
		refetch()
	}

	const pageProps: IPageProps = {
		sellerDetails: data,
		openingHours,
		goBackListSeller,
		changeSellerStatus,
		disabledByStatusGateway,
		isLoading,
		handleOpenAndCloseModal,
		isModalOpen,
		resendForGateway,
		resendLoading
	}

	return createElement(AdminSellerDetailsView, pageProps)
}

export { AdminSellerDetails }
