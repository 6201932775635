import { EOrderStatus } from '../types/orders'

const EOrderStatusLabels: Record<EOrderStatus, string> = {
	[EOrderStatus.CANCELED]: 'Cancelado',
	[EOrderStatus.FAILED]: 'Falhou',
	[EOrderStatus.PAID]: 'Pago',
	[EOrderStatus.ON_ROUTE]: 'A Caminho',
	[EOrderStatus.PRODUCTION]: 'Em Produção',
	[EOrderStatus.DELIVERED]: 'Entregue',
	[EOrderStatus.PENDING]: 'Pendente'
}

export const getStatusLabel = (status?: EOrderStatus): string => {
	switch (status) {
		case EOrderStatus.CANCELED:
			return EOrderStatusLabels[status]
		case EOrderStatus.FAILED:
			return EOrderStatusLabels[status]
		case EOrderStatus.PAID:
			return EOrderStatusLabels[status]
		case EOrderStatus.ON_ROUTE:
			return EOrderStatusLabels[status]
		case EOrderStatus.PRODUCTION:
			return EOrderStatusLabels[status]
		case EOrderStatus.DELIVERED:
			return EOrderStatusLabels[status]
		default:
			return status || ''
	}
}

export const getStatusFromLabel = (label: string): EOrderStatus => {
	const status = Object.keys(EOrderStatusLabels).find(
		(key) => EOrderStatusLabels[key as EOrderStatus] === label
	)

	return status as EOrderStatus
}

export const availableStatus = (status: EOrderStatus) => {
	const availableStatuses: EOrderStatus[] = []

	switch (status) {
		case EOrderStatus.PAID:
			availableStatuses.push(EOrderStatus.PRODUCTION, EOrderStatus.CANCELED)
			break

		case EOrderStatus.PRODUCTION:
			availableStatuses.push(EOrderStatus.ON_ROUTE, EOrderStatus.CANCELED)
			break

		case EOrderStatus.ON_ROUTE:
			availableStatuses.push(EOrderStatus.DELIVERED, EOrderStatus.CANCELED)
			break

		default:
			Object.values(EOrderStatus).forEach((item) => {
				if (item !== EOrderStatus.CANCELED) {
					availableStatuses.push(item)
				}
			})
			break
	}

	return availableStatuses
}

export const statusFormat = (statusType: EOrderStatus[]) =>
	statusType?.map((stt) => ({ value: getStatusLabel(stt) }))

export const statusType = (status: EOrderStatus) => {
	const result = Object.values(EOrderStatus)?.filter((item) => {
		if (status === EOrderStatus.PAID) {
			return item === EOrderStatus.PRODUCTION || item === EOrderStatus.CANCELED
		}

		if (status === EOrderStatus.PRODUCTION) {
			return item === EOrderStatus.ON_ROUTE || item === EOrderStatus.CANCELED
		}

		if (status === EOrderStatus.ON_ROUTE) {
			return item === EOrderStatus.DELIVERED || item === EOrderStatus.CANCELED
		}

		return item
	})
	return statusFormat(result)
}
