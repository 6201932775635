import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`
export const DetailsContainer = styled.div``

export const Header = styled.div`
	margin-top: 37px;
	margin-left: 135px;
`

export const Order = styled.div`
	width: 652px;
`

export const GoBackContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	width: min-content;
	cursor: pointer;
	margin-bottom: 16px;

	:active {
		opacity: 0.8;
	}
`

export const ArrowBack = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`

export const GoBackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const Title = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-right: 24px;
`
export const ContentOrder = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 42px;
	margin-bottom: 40px;
`

export const OrderInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

export const Description = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const StatusSelect = styled.div`
	position: relative;
	bottom: 40px;
`

export const OrderNumber = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	margin-top: 15px;
`

export const CanceledMessageContainer = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${({theme}) => theme.COLORS.LIGHT_GRAY};
	padding: 16px 0;
	margin: 40px 0;
`

export const OrderDetailContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

export const OrderDetail = styled.div`
	background-color: ${({ theme }) => theme.COLORS.WHITE};
	display: flex;
	flex-direction: column;
	width: 652px;
	margin-top: 16px;
`

export const HeaderItem = styled.div`
	width: 100%;
	display: flex;
	margin: 24px 0 16px 0;
	width: 100%;
	padding: 0 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 21px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	justify-content: space-between;
	padding: 0px 63px 0px 68px;
	align-items: center;
`

export const BodyItem = styled.div`
	white-space: nowrap;
	width: 100%;
	display: flex;
	height: 70px;
	width: 100%;
	padding: 0 24px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-bottom: 2px solid ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
	justify-content: space-around;
	align-items: center;
`

export const ItemProduct = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	flex: 50%;
`

export const ItemQuant = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	flex: 35%;
`

export const ItemValue = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	flex: 15%;
`

export const ContainerOrder = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

export const TableItemProduct = styled.div`
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	img {
		width: 36px;
		height: 36px;
		object-fit: cover;
		border-radius: 10px;
		margin-right: 16px;
	}
	flex: 50%;

	p {
		white-space: break-spaces;
		margin-right: 24px;
	}
`

export const TableItemQuant = styled.div`
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	justify-content: flex-start;
	flex: 30%;
`

export const TableItemValue = styled.div`
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	justify-content: flex-start;
	flex: 20%;
`

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
`

export const InfoContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
`

export const TitleContainer = styled.p`
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap;
`

export const DeliveryInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

export const Info = styled.p`
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: end;
`

export const DeliveryContainer = styled.div`
	width: 100%;
	margin-top: 40px;
	margin-bottom: 40px;
`

export const Delivery = styled.div`
	display: flex;
`

export const DeliveryType = styled.p`
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const DeliveryDate = styled.p`
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const Divisor = styled.div`
	background-color: ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	height: 1px;
	width: 100%;
	margin: 16px 0;
`

export const StatusContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 16px;
	width: 206px;
	height: 65px;
`

export const ButtonContainer = styled.div`
	margin-bottom: 128px;
	width: 100%;
	display: flex;
	justify-content: center;
`
