export const grid = {
	cols: 12,
	gutter: 16, // Default 32
	lg: 1200,
	md: 992,
	sm: 768,
	xs: 576
}

export const font = {
	baseFontSize: 16
}
