import { createElement } from 'react'
import { AdminSellersView } from './view'

//types
import { IPageProps } from './types'

//services
import { useSellers } from '../../services/seller.service'

function AdminSellers() {
	const { data: sellers, isLoading } = useSellers()

	const pageProps: IPageProps = {
		sellers,
		isLoading
	}

	return createElement(AdminSellersView, pageProps)
}

export { AdminSellers }
