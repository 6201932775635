import { createElement } from 'react'

//hooks
import { useNavigate } from 'react-router-dom'

//types
import { ERoutes } from '../../routes/routes'
import { IPageProps } from './types'

//utils
import { EditSelllerView } from './view'

function EditSeller() {
	const navigate = useNavigate()

	function goBackToProduct() {
		navigate(ERoutes.PRODUCTS)
	}
	const pageProps: IPageProps = {
		goBackToProduct
	}

	return createElement(EditSelllerView, pageProps)
}

export { EditSeller }
