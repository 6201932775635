import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px 16px;
	width: 100%;
	height: 46px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	border-radius: 26px;
	box-sizing: border-box;

	:has(input:not([disabled focus])) {
		:hover {
			background: ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
			border: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
		}
	}

	:has(input:focus) {
		border: 2px solid ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	}
`

export const Input = styled.input`
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
	background: transparent;
`

export const Search = styled.img`
	width: 18px;
	height: 18px;
`
