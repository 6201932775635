import styled from 'styled-components'

export const Wrapper = styled.div`
	padding: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const Logo = styled.div`
	margin-top: 130px;
`

export const Title = styled.h1`
	margin: 10px 0;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	font-size: 32;
	font-weight: 400;
	text-align: center;
`

export const Description = styled.h4`
	margin: 20px 0;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	font-size: 16px;
	font-weight: 400;
	text-align: center;
`
export const Paragraph = styled.p`
	margin: 30px 0;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	font-size: 14px;
	font-weight: 400px;
	max-width: 650px;
	text-align: center;
`
