import {
	QueryFunctionContext,
	UseMutationResult,
	UseQueryResult,
	useMutation,
	useQuery
} from '@tanstack/react-query'
import { QUERY_KEYS } from '../utils/reactQuery'
import axiosInstance from './axiosInstance'
import {
	INotificationCitysResponse,
	INotificationProductsResponse,
	INotificationSaveDraftRequest,
	INotificationSellersResponse,
	INotificationSendRequest,
	INotificationSendResponse
} from '../types/notifications'
import { IFetchRequest, IPaginationResponse } from '../types/pagination'

export function useProductsList(
	storeID?: string
): UseQueryResult<INotificationProductsResponse[]> {
	return useQuery(
		[QUERY_KEYS.NOTIFICATIONS_PRODUCTS],
		() => productsList(storeID),
		{ enabled: !!storeID }
	)
}

async function productsList(storeID?: string) {
	const response = await axiosInstance.get<INotificationProductsResponse[]>(
		`/products/list/products-for-select/` + storeID
	)

	return response.data
}

export function useSellersList(): UseQueryResult<
	INotificationSellersResponse[]
> {
	return useQuery([QUERY_KEYS.NOTIFICATIONS_SELLERS], () => sellersList())
}

async function sellersList() {
	const response = await axiosInstance.get<INotificationSellersResponse[]>(
		`/stores/list/stores-for-select/`
	)

	return response.data
}

export function useCitysList(): UseQueryResult<INotificationCitysResponse[]> {
	return useQuery([QUERY_KEYS.NOTIFICATIONS_CITYS], () => citysList())
}

async function citysList() {
	const response = await axiosInstance.get<INotificationCitysResponse[]>(
		`/users/list/cities-for-select/`
	)

	return response.data
}

export function useSendNotification(): UseMutationResult<
	INotificationSendResponse,
	any,
	INotificationSendRequest
> {
	return useMutation([QUERY_KEYS.NOTIFICATIONS_SEND], sendNotification, {
		retry: false
	})
}

async function sendNotification(payload: INotificationSendRequest) {
	const response = await axiosInstance.post<INotificationSendResponse>(
		'/push-notifications/send-notification',
		payload
	)

	return response.data
}

async function listNotifications(
	context: QueryFunctionContext<[unknown, IFetchRequest]>
): Promise<IPaginationResponse<INotificationSendResponse>> {
	const [_, params] = context.queryKey
	const { maxItemsInPage, pageIndex, searchString, order, sort } = params
	const response = await axiosInstance.post(
		'/push-notifications/list/get-page',
		{
			pageIndex,
			searchString,
			order,
			sort,
			maxItemsInPage
		}
	)

	return response.data
}

export function useListNotifications({
	maxItemsInPage,
	pageIndex,
	searchString,
	order,
	sort
}: IFetchRequest): UseQueryResult<
	IPaginationResponse<INotificationSendResponse>
> {
	const result = useQuery(
		[
			QUERY_KEYS.NOTIFICATIONS_LIST,
			{ maxItemsInPage, pageIndex, searchString, order, sort }
		],
		listNotifications
	)
	return result
}

export function useSaveDraftNotification(): UseMutationResult<
	any,
	any,
	INotificationSaveDraftRequest
> {
	return useMutation(
		[QUERY_KEYS.NOTIFICATIONS_SAVE_DRAFT],
		saveDaftNotification,
		{
			retry: false
		}
	)
}

async function saveDaftNotification(payload: INotificationSaveDraftRequest) {
	const response = await axiosInstance.post<any>(
		'/push-notifications/save-draft',
		payload
	)

	return response.data
}
