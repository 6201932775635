import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	position: relative;
`

export const Table = styled.table`
	width: 100%;
	border-spacing: 8px;
	border-collapse: collapse;
`

export const TableRow = styled.tr`
	height: 70px;
	width: 100%;
	padding: 0 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-bottom: 2px solid ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
`

export const TableHeader = styled.tr`
	height: 70px;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	padding: 0 24px;
`

export const HeaderPadding = styled.th`
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	width: 16px;
`

export const BodyPadding = styled.td`
	width: 16px;
`

export const TableHeaderItem = styled.th`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const ItemsContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: flex-start;
`

export const TableItem = styled.td`
	justify-content: center;
	align-items: center;
	height: 100%;
	min-width: 150px;
	padding: 0 8px;
`

export const Icons = styled.img`
	margin: auto;
	cursor: pointer;

	:active {
		opacity: 0.6;
	}
`

export const TableContainer = styled.div`
	background: ${({ theme }) => theme.COLORS.WHITE};
	overflow: auto;
`

export const ProductImage = styled.img`
	border-radius: 10px;
	width: 38px;
	height: 38px;
	object-fit: cover;
	margin-right: 12px;
`
export const ProductVideo = styled.video`
	border-radius: 10px;
	width: 38px;
	height: 38px;
	aspect-ratio: 1/1;
	object-fit: cover;
	margin-right: 12px;
`
