import styled, { css } from 'styled-components'

export const Container = styled.div<{ items: number; isOpen: boolean }>`
	background: ${({ theme }) => theme.COLORS.WHITE};
	border: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	border-radius: 4px;
	height: ${({ items, isOpen }) =>
		isOpen ? 75 + 32 + 70 * (items + 1) : 75}px;
	box-sizing: border-box;
	padding: 0px 24px;
	overflow: hidden;
	transition: all 0.2s;
	box-sizing: border-box;
	max-width: 100%;
`

export const CategoryName = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-right: 8px;
`

export const Head = styled.div`
	height: 75px;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
`

export const Counter = styled.div`
	min-width: 20px;
	height: 22px;
	background: ${({ theme }) => theme.COLORS.SOFT_BLUE};
	border-radius: 11px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Arrow = styled.img<{ isOpen: boolean }>`
	width: 20px;
	height: 20px;
	margin-left: auto;
	transition: all 0.2s;

	${({ isOpen }) =>
		isOpen &&
		css`
			transform: rotateZ(180deg);
		`}
`
export const Body = styled.div`
	height: 75px;
`

export const TableHeader = styled.tr`
	height: 70px;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	padding: 0 24px;
`

export const HeaderPadding = styled.th`
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	min-width: 16px;
`

export const TableHeaderItem = styled.th<{ width?: number }>`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};

	${({ width }) =>
		width &&
		css`
			width: ${width}%;
		`}
`

export const Table = styled.table`
	width: 100%;
	border-spacing: 8px;
	border-collapse: collapse;
	overflow-x: auto;

	//background: red;
`

export const TableRow = styled.tr`
	height: 70px;
	width: 100%;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-bottom: 2px solid ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
`

export const BodyPadding = styled.td`
	width: 16px;
	height: 50px;
`

export const ItemsContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: flex-start;
`

export const Name = styled.span`
	text-overflow: ellipsis;
	white-space: normal;
	overflow: hidden;
	width: 100%;
	margin-right: 8px;
`

export const TableItem = styled.td`
	justify-content: center;
	align-items: center;
	height: 100%;
	min-width: 150px;
`

export const ProductImage = styled.img`
	width: 38px;
	height: 38px;
	border-radius: 10px;
	margin-right: 12px;
	object-fit: cover;
`
export const ProductVideo = styled.video`
	width: 38px;
	height: 38px;
	border-radius: 10px;
	margin-right: 12px;
	object-fit: cover;
`

export const Icons = styled.img`
	margin-left: 15px;
	cursor: pointer;

	:active {
		opacity: 0.5;
	}
`

export const IconsPadding = styled.div`
	margin-left: auto;
`

export const TableContainer = styled.div`
	background: ${({ theme }) => theme.COLORS.WHITE};
	overflow: auto;
`

export const Chevron = styled.img<{ isOpen: boolean }>`
	margin-left: auto;
	transition: all 0.2s;
	${({ isOpen }) =>
		isOpen &&
		css`
			transform: rotateZ(180deg);
		`}
`

export const Sort = styled.img<{ spin: boolean }>`
	transition: all 0.5s;
	width: 21px;
	height: 21px;
	margin-left: 8px;
	cursor: pointer;

	${({ spin }) =>
		spin &&
		css`
			transform: rotateZ(180deg);
		`}
`
