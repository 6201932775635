import * as S from './styles'

//icons
import info from '../../assets/icons/info.svg'

import { IInfo } from './types'

function Info({ text, title, cardStyle, textSecond }: IInfo) {
	return (
		<S.Container>
			<img src={info} alt='' />

			<S.TextContainer style={cardStyle}>
				<S.Title>{title}</S.Title>

				{textSecond ? (
					<S.Text>
						{text}
						<br />
						<br />
						{textSecond}
					</S.Text>
				) : (
					<S.Text>{text}</S.Text>
				)}
			</S.TextContainer>
		</S.Container>
	)
}

export { Info }
