import * as yup from 'yup'

import { EFormError } from '../../utils/texts'

const emailSchema = yup.object({
	email: yup.string().email(EFormError.EMAIL).required(EFormError.REQUIRED),
	password: yup
		.string()
		.required(EFormError.REQUIRED)
		.min(8, EFormError.SHORT_PASSWORD)
})

export { emailSchema }
