import * as S from './styles'

//components
import { Autocomplete } from '@mui/material'

//types
import { ISelectProps } from './types'

const Select = ({
	flex = 1,
	type = 'text',
	floatingLabel,
	items,
	errorMessage,
	value,
	name,
	onChange,
	disabled,
	...rest
}: ISelectProps) => {
	return (
		<S.Container flex={flex}>
			<S.FloatingLabelContainer>
				<S.FloatingLabel>{floatingLabel}</S.FloatingLabel>
			</S.FloatingLabelContainer>

			<Autocomplete
				options={items?.map((item) => item.value) || ['']}
				sx={{ width: 300 }}
				onChange={(e, value: any) => onChange && onChange(value)}
				style={{ width: '100%' }}
				disabled={disabled}
				value={value || ''}
				isOptionEqualToValue={() => true}
				renderInput={(params) => (
					<S.SelectStyled
						value={value || ''}
						sx={{ input: { fontFamily: 'Poppins' } }}
						hasError={!!errorMessage}
						hasValue={!!value}
						name={name}
						{...rest}
						{...params}
					/>
				)}
			/>

			{errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
		</S.Container>
	)
}

export { Select }
