function humanFileSize(bytes: number, decimalPlaces: number = 2): string {
	const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	let index = 0

	while (bytes >= 1024 && index < units.length - 1) {
		bytes /= 1024
		index++
	}

	return bytes.toFixed(decimalPlaces) + ' ' + units[index]
}

enum EUnitType {
	B = 1,
	KB = 1024,
	MB = 1024 * 1024,
	GB = 1024 * 1024 * 1024
}

function convertToBytes(value: number, unit: EUnitType): number {
	const bytes = value * unit
	return bytes
}

export { humanFileSize, convertToBytes, EUnitType }
