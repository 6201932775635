import { createElement } from 'react'
import { SuccessRegistrationView } from './view'

//hooks
import { Navigate, useLocation } from 'react-router-dom'

//types
import { ERoutes } from '../../routes/routes'

function SuccessRegistration() {
	const { state } = useLocation()

	if (!state?.bypass) {
		return <Navigate to={ERoutes.LOGIN} />
	}

	return createElement(SuccessRegistrationView)
}

export { SuccessRegistration }
