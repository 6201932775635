import * as yup from 'yup'

import { EFormError } from '../../utils/texts'
import { passwordRegex } from '../../utils/regex'

const passwordSchema = yup.object({
	password: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.matches(passwordRegex, EFormError.INVALID_PASSWORD)
		.min(8, EFormError.SHORT_PASSWORD),
	confirm_password: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
		.matches(passwordRegex, EFormError.INVALID_PASSWORD)
		.min(8, EFormError.SHORT_PASSWORD)
		.oneOf([yup.ref('password')], EFormError.PASSWORD_MATCH)
})

export { passwordSchema }
