import styled, { css } from 'styled-components'

export const Container = styled.div<{ isModalOpen?: boolean }>`
	transition: all 0.2s;
	width: 100vw;
	height: 100vh;
	position: fixed;
	background: rgba(0, 0, 0, 0.56);
	top: 0;
	display: flex;
	z-index: ${({ isModalOpen }) => (isModalOpen ? 1000 : -1)};
	opacity: ${({ isModalOpen }) => (isModalOpen ? 1 : 0)};
`

export const Modal = styled.div`
	width: 100%;
	max-width: 430px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	margin: auto;
	padding: 40px 24px;
`

export const MoldaHead = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 34px;

	img {
		cursor: pointer;

		:active {
			opacity: 0.5;
		}
	}
`

export const ModalTitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0;
`

export const ModalSubtitle = styled.h2`
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const ModalText = styled.h2`
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const CardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 32px;
`

export const TextsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;
`

export const DescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 8px;
	margin-bottom: 42px;
`
export const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
`

export const CancelButton = styled.button`
	${({ theme }) => css`
		display: flex;
		padding: 12px 24px;
		border-radius: 24px;
		background-color: transparent;
		border: none;
		cursor: pointer;
		transition: all 0.3s;
		:hover {
			background-color: ${theme.COLORS.LIGHT_GRAY};
		}
	`}
`

export const CancelButtonText = styled.p`
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
