import styled, { css } from 'styled-components'
import bg from '../../assets/background-pattern.png'

//TODO - Pass theme

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	padding: 28px 0px;
	width: 100%;
	background: #ffffff;
	border-radius: 4px;
`

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ContentHead = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 1000px;
`

export const GoBackContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	width: min-content;
	cursor: pointer;
	margin-bottom: 16px;

	:active {
		opacity: 0.8;
	}
`

export const ArrowBack = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`

export const GoBackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const HeadTitle = styled.h1`
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0 0 32px;
`

export const HeadSubtitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin: 0 0 48px;
	max-width: 652px;
`

export const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 800px;
`

export const FormTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const WarningDescription = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 36px;
`

export const FormRow = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
	margin-bottom: 20px;

	@media screen and (max-width: 540px) {
		flex-direction: column;

		div {
			flex: 1;
		}
	}
`

export const Divisor = styled.hr`
	background: #e0e0e0;
	width: 100%;
	margin-bottom: 40px;
`

export const PaymentTitle = styled(FormTitle)`
	margin-bottom: 16px;
`

export const PaymentSubtitle = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 54px;
`

export const ButtonContainer = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;

	button {
		width: 160px;
	}
`

// export const PixTitle = styled(FormTitle)`
// 	margin-bottom: 54px;
// `

export const TutorialContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 76px;
	flex-wrap: wrap;
	gap: 57px;
`

export const TutorialItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	max-width: 200px;
	margin-bottom: 24px;
`

export const TutorialImg = styled.img`
	margin-bottom: 16px;
`

export const TutorialTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const PasswordText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 16px;
`

export const PhotoTitle = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 8px;
`

export const PhotoSubTitle = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 16px;
`

export const PhotoContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
`

export const Header = styled.div`
	width: 100%;
	height: 232px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
	padding: 0 50px;
	background-image: url(${bg});
	background-position: right;
	background-size: 300px;
	background-repeat: no-repeat;
	margin-bottom: 48px;
`

export const Row = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
`

export const InfoText = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`
export const EditContainer = styled.div`
	display: grid;
	grid-template-columns: minmax(auto, 200px) auto;
	gap: 16px;
`

export const ContainerEdit = styled.div`
	padding: 32px 0px;
	display: flex;
	flex-direction: column;
`
export const NavigationLabel = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	grid-column: 1;
`

export const NavigationContainer = styled.div`
	height: min-content;
	width: 100%;
	grid-column: 1;
	border-left: 1px solid #e0e0e0;
	text-decoration: none;
`
export const NavItem = styled.div<{ isActive?: boolean }>`
	height: 46px;
	padding: 0 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;

	:active {
		opacity: 0.8;
	}

	span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		display: flex;
		align-items: center;
		color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
		text-decoration: none;
	}

	${({ theme, isActive }) =>
		isActive &&
		css`
			border-left: 2px solid ${theme.COLORS.PRIMARY_COLOR};
		`}
`
export const AddHourButtonContainer = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
	height: 30px;
	width: 30px;
	margin-top: 40px;
	button {
		width: 30px;
		height: 30px;
		border: none;
	}
`

export const AddHour = styled.button`
	background-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	border-radius: 21px;
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	> img {
		width: 18px;
		height: 18px;
	}
`

export const RemoveHour = styled.button`
	background-color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-radius: 21px;
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	> img {
		width: 18px;
		height: 18px;
	}
`
export const OpeningHourTitle = styled(FormTitle)`
	margin-bottom: 16px;
`

export const OpeningHourSubtitle = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	margin-bottom: 54px;
`

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const Divider = styled.div`
	width: 100%;
	height: 2px;
	background-color: #fafafa;
	margin-top: 19px;
	margin-bottom: 32px;
`

export const OpenAndCloseStoreWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
`

export const OpenAndCloseStoreTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 18px;
`

export const OpenAndCloseStoreBottom = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
`

export const OpenAndCloseStoreTitle = styled.p`
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const StoreIsOpenValue = styled.p`
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const TooltipContainer = styled.div`
	${({ theme }) => css`
		max-width: 288px;
		position: absolute;
		padding: 16px 12px;
		background-color: ${theme.COLORS.DARK_GRAFFITI};
		border-radius: 4px;
		margin-top: 170px;
		z-index: 10;
	`}
`

export const TooltipTitle = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: 14px;
		color: ${theme.COLORS.WHITE};
		margin-bottom: 6px;
	`}
`
export const TooltipText = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 14px;
		color: ${theme.COLORS.WHITE};
	`}
`
