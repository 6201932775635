import styled, { css } from 'styled-components'

export const Container = styled.label`
	display: flex;
	align-items: center;

	:active {
		opacity: 0.6;
	}
`

export const RadioContainer = styled.div<{ checked?: boolean }>`
	width: 18px;
	height: 18px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border: 1px solid ${({ theme }) => theme.COLORS.MEDIUM_GRAY};
	border-radius: 9px;
	position: relative;
	margin-right: 8px;

	${({ checked }) =>
		checked &&
		css`
			background: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
			border: 1px solid #b1aaff;

			::after {
				content: '';
				position: absolute;
				width: 6px;
				height: 6px;
				background: ${({ theme }) => theme.COLORS.WHITE};
				border-radius: 9px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		`}
`

export const Radio = styled.input`
	display: none;
`

export const CardLabel = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
