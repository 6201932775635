import React from 'react'
import * as S from './styles'

//components
import { SearchInput } from '../../components/SearchInput'

//types
import { IPageProps } from './types'

//utils
import { NotificationsList } from '../../components/NotificationsList'
import { Button } from '../../components'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from '../../routes/routes'

function AdminListNotifications({
	isLoading,
	notifications,
	setSearchString,
	searchQuery,
	handleChangePage,
	setFilter,
	filter
}: IPageProps) {
	const navigate = useNavigate()
	return (
		<S.Container>
			<S.Head>
				<S.PageTitle>Notificações</S.PageTitle>
				<S.SearchContainer>
					<SearchInput
						onChange={(e) => setSearchString(e)}
						value={searchQuery}
					/>
				</S.SearchContainer>
				<Button
					onClick={() => {
						navigate(ERoutes.CREATE_NOTIFICATION)
					}}
					label='Criar notificação'
				/>
			</S.Head>
			<S.Body>
				<NotificationsList
					handleChangePage={handleChangePage}
					notifications={notifications}
					setFilter={setFilter}
					filter={filter}
				/>
			</S.Body>
		</S.Container>
	)
}

export { AdminListNotifications }
