import { Dispatch, SetStateAction, useCallback } from 'react'
import * as S from './styles'

//types
import { IFilter, ISortByValues } from '../types'

//icons
import sort from '../../../assets/icons/sort.svg'
import sortSelected from '../../../assets/icons/sort-selected.svg'

interface IColumnHead {
	name: string
	value: ISortByValues
	filter: IFilter
	setFilter: Dispatch<SetStateAction<IFilter>>
}

function ColumnHead({ name, value, filter, setFilter }: IColumnHead) {
	const { by } = filter.sort
	const { order } = filter

	const isSelected = value === by
	const willSpinIcon = isSelected && order === -1

	function handleToggleSort() {
		if (value === by) {
			setFilter({
				...filter,
				order: order === 1 ? -1 : 1
			})
			return
		}

		setFilter({
			...filter,
			sort: { by: value, sort: value }
		})
	}

	return (
		<S.TableHeaderItem>
			<S.ItemsContainer>
				{name}
				<S.Sort
					spin={willSpinIcon}
					src={isSelected ? sortSelected : sort}
					onClick={handleToggleSort}
				/>
			</S.ItemsContainer>
		</S.TableHeaderItem>
	)
}

export { ColumnHead }
