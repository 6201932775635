import styled, { css } from 'styled-components'

export const Container = styled.label<{ checked?: boolean }>`
	width: 100%;
	height: 120px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	padding: 30px 24px;
	box-sizing: border-box;
	cursor: pointer;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	user-select: none;

	:active {
		opacity: 0.6;
	}

	:hover {
		border: 1px solid #b1aaff;
	}

	${({ checked }) =>
		checked &&
		css`
			border: 1px solid #b1aaff;
		`}
`

export const RadioContainer = styled.div``

export const Radio = styled.input`
	width: 18px;
	height: 18px;
	position: relative;
	display: flex;
	margin-right: 16px;

	${({ theme, checked }) =>
		checked
			? css`
					::after {
						display: flex;
						content: '';
						width: 18px;
						height: 16px;
						background: ${theme.COLORS.PRIMARY_COLOR};
						border: 1px solid #b1aaff;
						border-radius: 9px;
					}

					::before {
						display: flex;
						content: '';
						width: 6px;
						height: 6px;
						background: ${theme.COLORS.WHITE};
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border-radius: 6px;
					}
			  `
			: css`
					::after {
						display: flex;
						content: '';
						width: 18px;
						height: 16px;
						background: ${theme.COLORS.WHITE};
						border: 1px solid #b1aaff;
						border-radius: 9px;
					}
			  `}
`

export const CardLabel = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
