import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'
import { useRef } from 'react'

interface ITranscode {
	videoFile: File | string
	videoRef: React.MutableRefObject<HTMLInputElement | null>
}

export function useVideo() {
	const ffmpegRef = useRef(new FFmpeg())
	const messageRef = useRef<HTMLParagraphElement | null>(null)

	const load = async () => {
		const baseURLUnpkg = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd'
		const ffmpeg = ffmpegRef.current
		ffmpeg.on('log', ({ message }) => {
			if (messageRef.current) messageRef.current.innerHTML = message
		})

		await ffmpeg.load({
			coreURL: await toBlobURL(
				`${baseURLUnpkg}/ffmpeg-core.js`,
				'text/javascript'
			),
			wasmURL: await toBlobURL(
				`${baseURLUnpkg}/ffmpeg-core.wasm`,
				'application/wasm'
			)
		})
	}

	const transcode = async ({ videoFile, videoRef }: ITranscode) => {
		const ffmpeg = ffmpegRef.current
		const file =
			videoFile instanceof File ? await fetchFile(videoFile) : videoFile

		await ffmpeg.writeFile('input.MOV', file)
		await ffmpeg.exec(['-i', 'input.MOV', 'output.mp4'])
		const fileData = await ffmpeg.readFile('output.mp4')
		const data = new Uint8Array(fileData as ArrayBuffer)

		const convertedFile = new File([data.buffer], 'output.mp4', {
			type: 'video/mp4'
		})
		const blob = new Blob([data.buffer], { type: 'video/mp4' })

		if (videoRef.current && videoRef.current instanceof HTMLInputElement) {
			videoRef.current.src = URL.createObjectURL(blob)
		}

		return convertedFile
	}

	return { load, transcode }
}
