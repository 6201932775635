import { createElement, useEffect, useState } from 'react'
import { AdminProductsView } from './view'

//types
import { IPageProps, EventProps } from './types'

//services
import {
	// useAdminProducts,
	useListProduct
} from '../../services/products.service'
import { IPaginationResponse } from '../../types/pagination'
import { IAdminProductsResponse } from '../../types/products'
import { IFilter } from '../../components/AdminProductsList/types'

function AdminProducts() {
	const [search, setSearch] = useState('')
	const [pageIndex, setPageIndex] = useState(1)
	const [products, setProducts] = useState<
		IPaginationResponse<IAdminProductsResponse> | undefined
	>(undefined)
	const [filter, setFilter] = useState<IFilter>({
		sort: {
			by: '',
			sort: ''
		},
		order: 1
	})

	const { data, isLoading } = useListProduct({
		maxItemsInPage: 10,
		pageIndex,
		searchString: search,
		order: filter.order,
		sort: filter.sort.sort
	})

	useEffect(() => {
		if (!data) return

		setProducts(data)
	}, [data])

	function handleChangeSearchString(event: EventProps) {
		const { value } = event.target
		setSearch(value)
	}

	function handleChangePage(page: number) {
		setPageIndex(page)
	}

	const pageProps: IPageProps = {
		products,
		isLoading,
		search,
		filter,
		setFilter,
		handleChangeSearchString,
		handleChangePage
	}

	return createElement(AdminProductsView, pageProps)
}

export { AdminProducts }
