import { useMemo, useState } from 'react'
import * as S from './styles'

//components
import { Button } from '../../Button'
import { InputDate } from '../../InputDate'
import { Select } from '../../Select'

//types
import { EAdminOrderStatus, IFilterProps, NotAppliedFilter } from '../types'
import { EOrderStatus } from '../../../types/orders'
import { IFilter, OrderStatus } from '../../../views/AdminOrders/types'

type FilterKey = keyof IFilter

function Filter({ setFilter, isOpen }: IFilterProps) {
	const [notAppliedFilter, setNotAppliedFilter] = useState<NotAppliedFilter>({
		status: '',
		date: ''
	})
	function handleChangeFilter(value: string | EOrderStatus, key: FilterKey) {
		setNotAppliedFilter({ ...notAppliedFilter, [key]: value })
	}

	function handleApplyFilter() {
		setFilter(notAppliedFilter)
	}

	function handleResetFilter() {
		const resetValues: NotAppliedFilter = { date: '', status: '' }
		setNotAppliedFilter(resetValues)
		setFilter(resetValues)
	}

	const statusItems = useMemo(() => {
		return Object.values(OrderStatus).map((item) => ({
			value: EAdminOrderStatus[item]
		}))
	}, [])

	return (
		<S.Container isOpen={isOpen}>
			<S.FiltersContainer>
				<InputDate
					flex={0.25}
					floatingLabel='Selecione o período'
					placeholder={'Período'}
					value={notAppliedFilter.date}
					onChange={(e) => handleChangeFilter(e.target.value, 'date')}
				/>

				<Select
					flex={0.25}
					floatingLabel='Selecione um Status'
					placeholder='Status'
					items={statusItems}
					value={notAppliedFilter.status}
					onChange={(e: any) => handleChangeFilter(e, 'status')}
				/>

				<Button label='Aplicar' onClick={handleApplyFilter} />
				<S.Reset onClick={handleResetFilter}>Limpar</S.Reset>
			</S.FiltersContainer>
		</S.Container>
	)
}

export { Filter }
