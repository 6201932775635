import styled, { css } from 'styled-components'
import { Button } from '@mui/material'

export const ButtonMui = styled(Button)<{ label?: string }>`
	width: max-content !important;
	height: 42px;
	text-transform: none !important;
	border-radius: 21px !important;
	box-shadow: none !important;
	padding: 12px 24px !important;
	font-size: 12px !important;
	font-family: 'Poppins' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 18px !important;
	background: ${({ theme }) => theme.COLORS.PRIMARY_COLOR} !important;

	&:disabled {
		background: #e0e0e0 !important;
		color: #b8b8b8 !important;
	}

	${({ color }) =>
		color === 'inherit' &&
		css`
			background: #7b7b7b !important;
		`}
	&:hover {
		background: ${({ theme }) => theme.COLORS.PRIMARY_COLOR_HOVER} !important;
	}

	${({ label }) =>
		label === 'Reenviar' &&
		css`
			padding: 6px 14px !important;
			height: 30px !important;
			margin-left: 8px !important;
		`}
`
