import { cpf, cnpj } from 'cpf-cnpj-validator'

const nonDigitsTest = /\D/g
const digitsTest = /[a-zA-Z&._-|,]/g
const valueTest = /[^,\d]/g

const accountNumberTest = /[a-zA-Z&._-|,]{0}[^x0-9]/g

export function documentMask(value: string) {
	const parsedDocument = value.replaceAll(nonDigitsTest, '')

	if (parsedDocument.length === 11) {
		return cpf.format(parsedDocument)
	}

	if (parsedDocument.length === 14) {
		return cnpj.format(parsedDocument)
	}

	return parsedDocument
}

export function cnpjMask(value: string) {
	const parsedDocument = value.replaceAll(nonDigitsTest, '')

	return cnpj.format(parsedDocument)
}

export function cepMask(value: string) {
	const parsedCep = value.replaceAll(nonDigitsTest, '')

	let cepWithMask = ''
	for (let index = 0; index < parsedCep.length; index++) {
		const digit = parsedCep[index]

		if (index === 5) {
			cepWithMask += '-' + digit
		} else {
			cepWithMask += digit
		}
	}

	return cepWithMask
}

export function agencyMask(value: string) {
	const parsedCep = value.replaceAll(nonDigitsTest, '')

	let cepWithMask = ''
	for (let index = 0; index < parsedCep.length; index++) {
		const digit = parsedCep[index]

		if (index === 4) {
			cepWithMask += '-' + digit
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			cepWithMask += digit
		}
	}

	return cepWithMask
}

export function accountNumberMask(value: string) {
	const parsedNumber = value.replaceAll(accountNumberTest, '')

	// let numberWithMask = ''

	// for (let index = 0; index < parsedNumber.length; index++) {
	// 	const digit = parsedNumber[index]

	// 	if (index === parsedNumber.length - 1 && index > 5) {
	// 		numberWithMask += '-' + digit
	// 	} else {
	// 		numberWithMask += digit
	// 	}
	// }

	return parsedNumber
}

export function phoneMask(value: string) {
	const parsedPhone = value.replaceAll(nonDigitsTest, '')

	let formattedNumber = parsedPhone

	formattedNumber = formattedNumber.replace(/^(\d{2})(\d)/g, '($1) $2')
	formattedNumber = formattedNumber.replace(/(\d)(\d{4})$/, '$1-$2')

	return formattedNumber
}

export function numberMask(value: string) {
	return value.replaceAll(digitsTest, '')
}

export function priceMask(value: string) {
	let parsedPrice = value.replaceAll(valueTest, '')

	if (parsedPrice.length > 0) {
		return 'R$ ' + parsedPrice
	}
	return parsedPrice
}

export function weightMask(value: string) {
	const parsedValue = value.replaceAll(valueTest, '')

	return parsedValue
}
