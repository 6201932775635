import { useState } from 'react'
import * as S from './styles'

//icons
import close from '../../assets/icons/close.svg'

//components
import { Button } from '../Button'
import { InputText } from '../InputText'

//types
import type { IChangeSellerStatusActions } from '../../views/AdminSellerDetails/types'

interface IDeclineSellerModalProps {
	changeSellerStatus(action: IChangeSellerStatusActions, message?: string): void
	isModalOpen: boolean
	handleOpenAndCloseModal: () => void
	isLoading?: boolean
}

function DeclineSellerModal({
	changeSellerStatus,
	handleOpenAndCloseModal,
	isModalOpen,
	isLoading
}: IDeclineSellerModalProps) {
	const [message, setMessage] = useState('')

	return (
		<S.Container isModalOpen={isModalOpen}>
			<S.Modal>
				<S.MoldaHead>
					<S.ModalTitle>Recusar Cadastro</S.ModalTitle>
					<img src={close} alt='' onClick={handleOpenAndCloseModal} />
				</S.MoldaHead>

				<S.ModalParagraph>
					Informe abaixo o motivo de recusa do cadastro para sinalizarmos ao
					vendedor.
				</S.ModalParagraph>

				<S.InputContainer>
					<InputText
						placeholder='Escreva o motivo'
						onChange={(e) => setMessage(e.target.value)}
					/>
				</S.InputContainer>

				<S.ButtonsContainer>
					<S.Keep onClick={handleOpenAndCloseModal}>Manter</S.Keep>
					<Button
						label='Enviar'
						onClick={() => changeSellerStatus('decline', message)}
						disabled={!message}
						loading={isLoading}
					/>
				</S.ButtonsContainer>
			</S.Modal>
		</S.Container>
	)
}

export { DeclineSellerModal }
