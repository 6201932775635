import styled, { css } from 'styled-components'
import { ENotificationType } from '../../../../hooks/useOnSnapshotNotifications'

interface INavigation {
	active: boolean
}

export const Container = styled.div``

export const NotificationIconWrapper = styled.div<{ isModalOpen: boolean }>`
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.COLORS.WHITE};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.2s;
	position: relative;
	user-select: none;

	&:hover {
		background-color: #f3f1f5;
	}

	img {
		max-width: 22px;
		max-height: 22px;
	}

	${({ isModalOpen }) =>
		isModalOpen &&
		css`
			background-color: #f3f1f5;
		`}
`

export const NotificationAsset = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.COLORS.RED_ERROR};
	border: 2px solid #f3f1f5;
	position: absolute;
	top: 10px;
	right: 10px;
`

export const Modal = styled.div<{ isModalOpen: boolean }>`
	width: 100%;
	max-width: 440px;
	height: 100%;
	height: 560px;
	background-color: ${({ theme }) => theme.COLORS.WHITE};
	border: 1px solid ${({ theme }) => theme.COLORS.OFF_WHITE};
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
	border-radius: 4px;
	position: fixed;
	right: 20px;
	transform: translateY(10px);
	opacity: 0;
	pointer-events: none;
	transition: all 0.1s;
	display: flex;
	flex-direction: column;
	margin-left: 8px;

	${({ isModalOpen }) =>
		isModalOpen &&
		css`
			opacity: 1;
			pointer-events: all;
		`}

	@media screen and (max-width:475px) {
		right: 0;
		max-width: unset;
		width: 100vw;
	}
`

export const ModalHeader = styled.div`
	height: 110px;
	width: 100%;
	padding: 32px 24px 0;
	display: flex;
	flex-direction: column;
`

export const HeaderRow = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
`

export const ReadButton = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: right;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	gap: 4px;

	:active {
		opacity: 0.8;
	}
`

export const HeaderNavigation = styled.div`
	width: 100%;
	display: flex;
	gap: 24px;
`

export const NavItem = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 6px;
	cursor: pointer;
	user-select: none;
	border-bottom-width: 10px;
`

export const NavName = styled.div<INavigation>`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	padding-bottom: 7px;

	${({ active }) =>
		active &&
		css`
			color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
			border-bottom: 2px solid ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
		`}
`

export const Counter = styled.div<INavigation>`
	padding: 2px 6px;
	background: ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	border-radius: 11px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};

	${({ active }) =>
		active &&
		css`
			color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
			background: ${({ theme }) => theme.COLORS.SOFT_BLUE};
		`}
`

export const NotificationsContainer = styled.div`
	overflow-y: auto;
`

export const Notification = styled.div<{ read: boolean }>`
	width: 100%;
	border-bottom: 1px solid #f5f5f5;
	padding: 24px;
	gap: 8px;
	display: flex;
	background-color: ${({ theme, read }) =>
		!read ? theme.COLORS.BLUEISH_WHITE : theme.COLORS.WHITE};
`

export const PhotoContainer = styled.div`
	width: 38px;

	img {
		width: 38px;
		height: 38px;
		border-radius: 10px;
		object-fit: cover;
	}
`

export const BodyContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export const BadgeContainer = styled.div`
	width: 100%;
	display: flex;
`

export const Badge = styled.div<{ type: ENotificationType }>`
	padding: 2px 6px;
	border-radius: 11px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	width: fit-content;

	${({ type }) => {
		switch (type) {
			case ENotificationType.DECLINED_PRODUCT:
				return css`
					background: ${({ theme }) => theme.COLORS.LIGHT_GRAY};
					color: ${({ theme }) => theme.COLORS.DARK_GRAY};
				`

			default:
				return css`
					background: ${({ theme }) => theme.COLORS.SOFT_BLUE};
					color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
				`
		}
	}}
`

export const BadgeStore = styled.div`
	padding: 2px 6px;
	border-radius: 11px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	width: fit-content;
	background: ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0 8px;
`

export const NotificationDescription = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const Time = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.MEDIUM_GRAY};
`
