import { Controller } from 'react-hook-form'
import { Select } from '.'

import { IControlledSelect } from './types'

function ControlledSelect({
	control,
	name,
	disabled,
	...rest
}: IControlledSelect) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, onBlur, value, name, ref } }) => (
				<Select
					disabled={disabled}
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					name={name}
					inputRef={ref}
					{...rest}
				/>
			)}
		/>
	)
}

export { ControlledSelect }
