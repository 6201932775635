import React from 'react'
import * as S from './styles'

//types
import { IPageProps } from './types'
import { EOrderStatus } from '../../types/orders'

//icons
import arrowBack from '../../assets/icons/arrow-left.svg'

//utils
import { getStatusLabel } from '../../utils/status'
import { deliveryDate, formatDateTime } from '../../utils/date'
import { parseItemPrice, toMoney } from '../../utils/currency'

//components
import { Button } from '../../components'
import { CancelOrderModal } from '../../components/CancelOrderModal'
import { Select } from '../../components/Select'

export function OrderDetailsView({
	order,
	status,
	isLoading,
	isModalOpen,
	isEditable,
	statusOptions,
	handleGoBack,
	handleChangeStatus,
	handleOpenAndCloseModal,
	onStatusChange
}: IPageProps) {
	return (
		<S.DetailsContainer>
			<S.Header>
				<S.GoBackContainer onClick={handleGoBack}>
					<S.ArrowBack src={arrowBack} />
					<S.GoBackText>Voltar</S.GoBackText>
				</S.GoBackContainer>
				<S.Title>Detalhes do Pedido</S.Title>
			</S.Header>

			<S.Container>
				<S.Order>
					<S.ContentOrder>
						<S.OrderInfoContainer>
							<S.Description>Número do Pedido</S.Description>

							<S.OrderNumber>{order?.code}</S.OrderNumber>
						</S.OrderInfoContainer>

						<S.OrderInfoContainer>
							<S.StatusContainer>
								<S.Description>Sinalize o Status</S.Description>

								<S.StatusSelect>
									<Select
										value={getStatusLabel(status)}
										flex={1}
										items={statusOptions}
										disabled={
											!!(
												status === EOrderStatus.CANCELED ||
												status === EOrderStatus.DELIVERED
											) || !isEditable
										}
										onChange={onStatusChange}
									/>
								</S.StatusSelect>
							</S.StatusContainer>
						</S.OrderInfoContainer>
					</S.ContentOrder>

					{order?.status === EOrderStatus.CANCELED && (
						<S.CanceledMessageContainer>
							<S.TitleContainer>Motivo do Cancelamento</S.TitleContainer>
							<S.Info>{order?.message}</S.Info>
						</S.CanceledMessageContainer>
					)}

					<S.OrderDetailContainer>
						<S.Description>Detalhes do Pedido</S.Description>
						<S.OrderDetail>
							<S.ContainerOrder>
								<S.HeaderItem>
									<S.ItemProduct>Produto</S.ItemProduct>
									<S.ItemQuant>Quantidade</S.ItemQuant>
									<S.ItemValue>Valor</S.ItemValue>
								</S.HeaderItem>
								{React.Children.toArray(
									order?.items.map(({ photo, name, quantity, amount }) => (
										<S.BodyItem>
											<S.TableItemProduct>
												<img src={photo} alt={name} />
												<p>{name}</p>
											</S.TableItemProduct>
											<S.TableItemQuant>{quantity}</S.TableItemQuant>
											<S.TableItemValue>
												{parseItemPrice(amount / 100)}
											</S.TableItemValue>
										</S.BodyItem>
									))
								)}
							</S.ContainerOrder>
							<S.InfoContainer>
								{!order?.delivery.freeShipping &&
									order?.delivery.type !== 'WITHDRAW' && (
										<S.InfoContent>
											<S.TitleContainer>Valor Entrega</S.TitleContainer>
											<S.Info>
												{toMoney(order?.delivery.priceShipping || 0)}
											</S.Info>
										</S.InfoContent>
									)}
								<S.InfoContent>
									<S.TitleContainer>Valor Total</S.TitleContainer>
									<S.Info>{parseItemPrice((order?.amount || 0) / 100)}</S.Info>
								</S.InfoContent>
							</S.InfoContainer>
						</S.OrderDetail>

						<S.DeliveryContainer>
							<S.InfoContainer>
								<S.InfoContent>
									<S.TitleContainer>Pedido realizado em</S.TitleContainer>

									<S.Info>{formatDateTime(order?.createdAt)}</S.Info>
								</S.InfoContent>

								<S.Divisor />
							</S.InfoContainer>

							<S.InfoContainer>
								<S.InfoContent>
									<S.TitleContainer>Tipo de Entrega</S.TitleContainer>
									<S.Info>
										{order?.delivery.type === 'WITHDRAW' ? (
											<S.DeliveryType>
												{deliveryDate(order?.delivery)}
											</S.DeliveryType>
										) : (
											<S.Delivery>
												<S.DeliveryType>Delivery</S.DeliveryType>
												<S.DeliveryDate>{` - ${
													order?.delivery && deliveryDate(order?.delivery)
												}`}</S.DeliveryDate>
											</S.Delivery>
										)}
									</S.Info>
								</S.InfoContent>

								<S.Divisor />
							</S.InfoContainer>

							<S.InfoContainer>
								<S.InfoContent>
									<S.TitleContainer>Dados do Cliente</S.TitleContainer>
									<S.DeliveryInfo>
										<S.Info>{order?.customer.fullName}</S.Info>
										{order?.delivery.type !== 'WITHDRAW' && (
											<S.Info>{`${order?.shippingAddress.street}, ${
												order?.shippingAddress.number
											}, ${order?.shippingAddress.neighborhood} ${
												order?.shippingAddress.city
											}, ${
												order?.shippingAddress?.complement !== undefined
													? order?.shippingAddress?.complement
													: ''
											}, ${order?.shippingAddress.state} - ${
												order?.shippingAddress.postalCode
											} `}</S.Info>
										)}
									</S.DeliveryInfo>
								</S.InfoContent>
							</S.InfoContainer>

							<S.Divisor />
							<S.InfoContainer>
								<S.InfoContent>
									<S.TitleContainer>Contato</S.TitleContainer>

									<S.DeliveryInfo>
										<S.Info>{order?.customer.phone}</S.Info>
									</S.DeliveryInfo>
								</S.InfoContent>
							</S.InfoContainer>
						</S.DeliveryContainer>

						{isEditable && (
							<S.ButtonContainer>
								<Button
									label='Aplicar Atualização'
									onClick={handleChangeStatus}
									loading={isLoading}
									//disabled={!isDirty}
								/>
							</S.ButtonContainer>
						)}
					</S.OrderDetailContainer>
				</S.Order>
			</S.Container>

			{isEditable && (
				<CancelOrderModal
					orderId={order?._id}
					isModalOpen={isModalOpen}
					handleOpenAndCloseModal={handleOpenAndCloseModal}
					onSuccess={handleGoBack}
				/>
			)}
		</S.DetailsContainer>
	)
}
