import { createTheme } from '@mui/material/styles'

export const materialTheme = createTheme({
	typography: {
		fontFamily: [
			'Poppins',
			'-apple-system',
			'BlinkMacSystemFont',
			'Segoe UI',
			'Roboto',
			'Oxygen',
			'Ubuntu',
			'Cantarell',
			'Fira Sans',
			'Droid Sans',
			'Helvetica Neue',
			'sans-serif'
		].join(',')
	},
	components: {
		MuiButton: {
			variants: [
				{
					props: { color: 'inherit' },
					style: {
						backgroundColor: '#7B7B7B',
						color: '#fafafa',
						':hover': {
							backgroundColor: '#666666',
							color: '#fafafa'
						}
					}
				},
				{
					props: { variant: 'text' },
					style: {
						color: '#3D3D3D',
						backgroundColor: 'transparent'
					}
				}
			]
		}
	}
})
