import { Controller } from 'react-hook-form'
import InputMedia from '.'

import { IControlledInputMedia } from './types'

function ControlledInputMedia({
	control,
	name,
	...rest
}: IControlledInputMedia) {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, onBlur, value, name, ref } }) => (
				<InputMedia
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					name={name}
					{...rest}
				/>
			)}
		/>
	)
}

export { ControlledInputMedia }
