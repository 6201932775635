import styled, { css } from 'styled-components'

export const Wrapper = styled.header`
	${({ theme }) => css`
		background-color: ${theme.COLORS.WHITE};
		height: 88px;
		display: flex;
		z-index: 20;
		position: fixed;
		top: 0;
		width: 100%;

		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.14);
	`}
`

export const Separator = styled.div`
	${({ theme }) => css`
		width: 1px;
		height: 100%;
		background: ${theme.COLORS.LIGHT_GRAY};
	`}
`
export const Container = styled.div`
	flex: 1;
	display: flex;
	padding: 20px 32px;
	justify-content: space-between;
	align-items: center;

	img {
		width: 83px;
		height: 40px;
		cursor: pointer;
	}
`
export const Menu = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;

	button {
		border: none;
		background: transparent;
		cursor: pointer;
	}

	img {
		width: 24px;
		height: 24px;
	}
`

export const NotificationButton = styled.button`
	border: none;
	background: none;
	cursor: pointer;
	height: 50px;
	width: 50px;
	margin-left: 20px;

	img {
		width: 22px;
		height: 22px;
	}
`

export const EditButton = styled.button`
	border: none;
	background: transparent;
	cursor: pointer;
	margin-left: 16px;

	> img {
		width: 20px;
		height: 20px;
	}
`

export const ActionsContent = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	cursor: pointer;
	user-select: none;
`

export const AccountInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	max-height: 50px;
	height: 100%;
	max-width: 300px;
	width: 100%;
	border-radius: 5px;
	font-size: 12px;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	border: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};

	:active {
		opacity: 0.8;
	}
`

export const Info = styled.div`
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;

	span {
		margin: 0;
		color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	}

	p {
		width: 100px;
		margin: 0;
		font-weight: 500;
		color: #4a4552;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (max-width: 700px) {
		display: none;
	}
`
