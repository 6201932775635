import styled from 'styled-components'
import { grid } from '../../styles/variables'

export const PageWrapper = styled.div`
	display: flex;
`

export const Container = styled.div.attrs({
	className: 'container'
})`
	margin: 0 auto;
	padding-right: ${grid.gutter}px;
	padding-left: ${grid.gutter}px;
	justify-content: center;
	height: 100%;
	width: 100%;
	padding-top: 88px;
	box-sizing: border-box;

	@media screen and (min-width: ${grid.lg}px) {
		max-width: ${grid.lg}px;
	}
	@media screen and (max-width: ${grid.lg}px) {
		max-width: ${grid.md}px;
	}
	@media screen and (max-width: ${grid.md}px) {
		max-width: ${grid.sm}px;
	}
	@media screen and (max-width: ${grid.sm}px) {
		max-width: ${grid.xs}px;
	}
	@media screen and (max-width: ${grid.xs}px) {
		max-width: 100%;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`
