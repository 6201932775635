//types
import type { IRegisterProductRequest } from '../types/products'
import type { ILoginRequest } from '../types/user'
import type {
	IResetPasswordRequest,
	IChangePasswordRequest
} from '../types/user'
import type { ILoginForm } from '../views/Login/types'
import type { IResetPasswordForm } from '../views/ResetPassword/types'
import {
	EAccountType,
	// EPixType,
	ERegisterType,
	ICreateSellerForm
} from '../views/CreateSeller/types'

import type { IChangePasswordForm } from '../views/ChangePassword/types'

//utils
import { IProductForm } from '../views/Product/types'
import { TimeToDelivery } from '../mocks/form'
import {
	IEditBankAccountForm,
	IEditSellerForm
} from '../views/EditSeller/types'
import { IUpdateBankAccountRequest } from '../types/bankAccount'
import formatOpeningHour from './formatOpeningHour'

const dayOfWeek = (day: string) => {
	switch (day) {
		case 'Domingo':
			return '0'
		case 'Segunda':
			return '1'
		case 'Terça':
			return '2'
		case 'Quarta':
			return '3'
		case 'Quinta':
			return '4'
		case 'Sexta':
			return '5'
		case 'Sábado':
			return '6'

		default:
			return ''
	}
}

const parsePrice = (price: string) => {
	return price ? price.replaceAll('R$ ', '').replaceAll(',', '.') : '0'
}

const getBankAccountType = (type: EAccountType) => {
	const entries = Object.entries(EAccountType)

	const pair = entries.find((item) => item[1] === type)

	if (pair) return pair[0].toLowerCase()

	return ''
}

const getSellerType = (type: ERegisterType) => {
	const entries = Object.entries(ERegisterType)

	const pair = entries.find((item) => item[1] === type)

	if (pair) return pair[0].toLowerCase()

	return ''
}

export function formatEditSellerPayload(data: IEditSellerForm): FormData {
	console.log({ data })

	const payload = new FormData()
	payload.append('user[email]', data.email)
	payload.append('user[firstName]', data.first_name)
	payload.append('user[lastName]', data.last_name)
	payload.append('user[personalRegistration]', data.cpf)
	payload.append('user[phone]', data.phone)
	payload.append('store[name]', data.store_name)
	payload.append('store[address][city]', data.city)
	payload.append('store[address][neighborhood]', data.district)
	payload.append('store[address][number]', data.number.toString())
	payload.append('store[address][postalCode]', data.cep)
	payload.append('store[address][state]', data.uf)
	payload.append('store[address][street]', data.address)
	payload.append('store[address][complement]', data.complement)
	payload.append('store[companyName]', data.corporate_name)
	payload.append('store[commercialPhone]', data.commercial_phone)
	payload.append('store[type]', getSellerType(data.registerType))
	data.document && payload.append('store[companyRegistration]', data.document)
	payload.append('photo', data.photo[0])
	payload.append('store[bio]', data.store_bio)
	data?.openingHours?.forEach((e, i) => {
		const openingHours = formatOpeningHour({
			startDay: e.startDay,
			endDay: e.endDay,
			startTime: e.startTime,
			endTime: e.endTime
		})
		payload.append(`store[openingHours][${i}][startDay]`, openingHours.startDay)
		payload.append(`store[openingHours][${i}][endDay]`, openingHours.endDay)
		payload.append(
			`store[openingHours][${i}][startTime]`,
			openingHours.startTime
		)
		payload.append(`store[openingHours][${i}][endTime]`, openingHours.endTime)
	})

	return payload
}

export function formatChangeBankAccountPayload(
	data: IEditBankAccountForm
): IUpdateBankAccountRequest {
	const bankCode = data.bank.split(' - ')[0]
	const bankName = data.bank.split(' - ')[1]
	const type = getBankAccountType(data.account_type)

	return {
		account: data.account_number,
		accountCheckDigit: data.account_digit,
		agency: data.agency,
		bankCode: bankCode,
		bankName: bankName,
		holderDocument: data.account_document,
		holderName: data.account_name,
		type: type
	}
}

export function formatCreateSellerPayload(data: ICreateSellerForm): FormData {
	const bankCode = data.bank.split(' - ')[0]
	const bankName = data.bank.split(' - ')[1]

	// const pix_type = () => {
	// 	switch (data.pix_type) {
	// 		case 'CPF':
	// 			return EPixType.DOCUMENT
	// 		case 'CNPJ':
	// 			return EPixType.DOCUMENT
	// 		case 'E-mail':
	// 			return EPixType.EMAIL
	// 		case 'Telefone':
	// 			return EPixType.PHONE
	// 		case 'Chave aleatória':
	// 			return EPixType.RANDOM_KEY

	// 		default:
	// 			return undefined
	// 	}
	// }

	const payload = new FormData()
	payload.append('user[email]', data.email)
	payload.append('user[firstName]', data.first_name)
	payload.append('user[lastName]', data.last_name)
	payload.append('user[password]', data.password)
	payload.append('user[personalRegistration]', data.cpf)
	payload.append('user[phone]', data.phone)
	payload.append('user[termsOfUse]', (!!data.termsOfUse).toString())
	payload.append('store[name]', data.store_name)
	payload.append('store[address][city]', data.city)
	payload.append('store[address][neighborhood]', data.district)
	payload.append('store[address][number]', data.number.toString())
	payload.append('store[address][postalCode]', data.cep)
	payload.append('store[address][state]', data.uf)
	payload.append('store[address][street]', data.address)
	data.corporate_name &&
		payload.append('store[companyName]', data.corporate_name)
	payload.append('store[commercialPhone]', data.commercial_phone)
	payload.append('store[type]', getSellerType(data.registerType))
	data.document && payload.append('store[companyRegistration]', data.document)
	payload.append('photo', data.photo[0])
	payload.append('store[bio]', data.store_bio)
	payload.append('bankAccount[account]', data.account_number)
	payload.append('bankAccount[accountCheckDigit]', data.account_digit)
	payload.append('bankAccount[agency]', data.agency)
	payload.append('bankAccount[bankCode]', bankCode)
	payload.append('bankAccount[bankName]', bankName)
	payload.append('bankAccount[holderName]', data.account_name)
	data.document && payload.append('bankAccount[holderDocument]', data.document)
	!data.document && payload.append('bankAccount[holderDocument]', data.cpf)
	payload.append('bankAccount[type]', getBankAccountType(data.account_type))
	data.openingHours.forEach((e, i) => {
		const openingHours = formatOpeningHour({
			startDay: e.startDay,
			endDay: e.endDay,
			startTime: e.startTime,
			endTime: e.endTime
		})

		payload.append(`store[openingHours][${i}][startDay]`, openingHours.startDay)
		payload.append(`store[openingHours][${i}][endDay]`, openingHours.endDay)
		payload.append(
			`store[openingHours][${i}][startTime]`,
			openingHours.startTime
		)
		payload.append(`store[openingHours][${i}][endTime]`, openingHours.endTime)
	})

	return payload
}

export function formatLoginPayload(data: ILoginForm): ILoginRequest {
	return {
		email: data.email,
		password: data.password
	}
}

export function formatResetPasswordPayload(
	data: IResetPasswordForm
): IResetPasswordRequest {
	return {
		email: data.email
	}
}

export function formatChangePasswordPayload(
	data: IChangePasswordForm,
	resetToken: string
): IChangePasswordRequest {
	return {
		resetToken: resetToken,
		password: data.password
	}
}

export function formatRegisterProductPayload(
	{
		category,
		description,
		discount,
		free_delivery_date,
		free_delivery_end,
		free_delivery_start,
		name,
		price,
		quantity,
		scheduled_delivery_date,
		scheduled_delivery_end,
		scheduled_delivery_start,
		shipping_type,
		weight,
		massUnit,
		image_01,
		image_02,
		image_03,
		video,
		end_Date,
		end_Hour,
		start_Date,
		start_Hour
	}: IProductForm,
	sendToApprove: boolean,
	freeShipping: boolean,
	isPickupStore: boolean,
	type: string,
	store: string,
	isProduct: boolean
): IRegisterProductRequest {
	const formData = new FormData()

	const deliveryDay = Object.entries(TimeToDelivery).find(
		(item) => item[1] === free_delivery_date
	)

	formData.append('sendToApprove', sendToApprove.toString())
	name && formData.append('name', name)
	category && formData.append('category', category)
	weight && formData.append('weight', weight.replace(',', ''))
	massUnit && formData.append('massUnit', massUnit)
	type && formData.append('type', type)
	quantity && formData.append('quantity', quantity)
	price && formData.append('price', parsePrice(price))
	description && formData.append('description', description)
	store && formData.append('store', store)
	formData.append('priceDiscount', parsePrice(discount))
	video && formData.append('video', video[0])
	image_01 && formData.append('images', image_01[0])
	image_02 && formData.append('images', image_02[0])
	image_03 && formData.append('images', image_03[0])
	isProduct &&
		formData.append('delivery[freeShipping]', freeShipping.toString())
	formData.append('delivery[pickupInStore]', isPickupStore.toString())
	shipping_type && formData.append('delivery[type]', shipping_type)

	start_Date &&
		start_Hour &&
		formData.append(
			'deadline[startDate]',
			new Date(`${start_Date}T${start_Hour}`).toISOString()
		)
	end_Date &&
		end_Hour &&
		formData.append(
			'deadline[endDate]',
			new Date(`${end_Date}T${end_Hour}`).toISOString()
		)

	if (isProduct) {
		if (shipping_type === 'RELATIVE') {
			deliveryDay &&
				deliveryDay[0] &&
				formData.append('delivery[timeToDelivery]', deliveryDay[0])
			// formData.append('delivery[startTime]', free_delivery_start)
			// formData.append('delivery[endTime]', free_delivery_end)
		}

		if (shipping_type === 'FIXED') {
			formData.append('delivery[startTime]', scheduled_delivery_start)
			formData.append('delivery[endTime]', scheduled_delivery_end)
			formData.append(
				'delivery[weekDay]',
				dayOfWeek(scheduled_delivery_date).toString()
			)
		}
	}

	return { formData }
}

export function formatPatchProductPayload(
	{
		category,
		description,
		discount,
		free_delivery_date,
		end_Hour,
		end_Date,
		free_delivery_start,
		free_delivery_end,
		name,
		weight,
		massUnit,
		quantity,
		price,
		shipping_type,
		scheduled_delivery_end,
		scheduled_delivery_date,
		scheduled_delivery_start,
		start_Date,
		start_Hour,
		image_01,
		image_03,
		image_02,
		video
	}: IProductForm,
	removedImagesURL: string[],
	store: string,
	freeShipping: boolean,
	isPickupStore: boolean,
	isProduct: boolean,
	sendToApprove: boolean
) {
	const formData = new FormData()

	const deliveryDay = Object.entries(TimeToDelivery).find(
		(item) => item[1] === free_delivery_date
	)

	const parsedImagensUrl = removedImagesURL?.filter(
		(item) => item !== 'undefined'
	)

	parsedImagensUrl?.forEach((image) => {
		formData.append(`removedImagesURL`, image)
	})

	formData.append('sendToApprove', sendToApprove.toString())

	name && formData.append('name', name)
	category && formData.append('category', category)
	store && formData.append('store', store)
	weight && formData.append('weight', weight.replace(',', ''))
	massUnit && formData.append('massUnit', massUnit)
	quantity && formData.append('quantity', quantity)
	price && formData.append('price', parsePrice(price))
	description && formData.append('description', description)

	isProduct &&
		formData.append('delivery[freeShipping]', freeShipping.toString())
	formData.append('delivery[pickupInStore]', isPickupStore.toString())
	shipping_type && formData.append('delivery[type]', shipping_type)

	start_Date && start_Hour
		? formData.append(
				'deadline[startDate]',
				new Date(`${start_Date}T${start_Hour}`).toISOString()
		  )
		: formData.append('deadline[startDate]', '')
	end_Date && end_Hour
		? formData.append(
				'deadline[endDate]',
				new Date(`${end_Date}T${end_Hour}`).toISOString()
		  )
		: formData.append('deadline[endDate]', '')

	if (isProduct && shipping_type === 'RELATIVE') {
		deliveryDay &&
			deliveryDay[0] &&
			formData.append('delivery[timeToDelivery]', deliveryDay[0])
		// formData.append('delivery[startTime]', free_delivery_start)
		// formData.append('delivery[endTime]', free_delivery_end)
	}

	if (isProduct && shipping_type === 'FIXED') {
		formData.append('delivery[startTime]', scheduled_delivery_start)
		formData.append('delivery[endTime]', scheduled_delivery_end)
		formData.append(
			'delivery[weekDay]',
			dayOfWeek(scheduled_delivery_date).toString()
		)
	}

	formData.append('priceDiscount', parsePrice(discount))

	video && typeof video[0] !== 'string' && formData.append('video', video[0])
	image_01 &&
		typeof image_01[0] !== 'string' &&
		formData.append('images', image_01[0])
	image_02 &&
		typeof image_02[0] !== 'string' &&
		formData.append('images', image_02[0])
	image_03 &&
		typeof image_03[0] !== 'string' &&
		formData.append('images', image_03[0])

	return { formData }
}
