import React, { FocusEvent } from 'react'
import {
	Control,
	FieldArrayWithId,
	FieldErrorsImpl,
	UseFormWatch
} from 'react-hook-form'
import { ISelectItems } from '../../components/Select/types'
import { IOpeningHour } from '../../types/seller'

// export enum EPixType {
// 	PHONE = 'PHONE',
// 	DOCUMENT = 'DOCUMENT',
// 	EMAIL = 'EMAIL',
// 	RANDOM_KEY = 'RANDOM_KEY'
// }

export interface IPageProps {
	control: Control<any>
	states: ISelectItems[]
	cities: ISelectItems[]
	errors: FieldErrorsImpl<ICreateSellerForm>
	fields: FieldArrayWithId<ICreateSellerForm, 'openingHours', 'id'>[]
	watch: UseFormWatch<ICreateSellerForm>
	bankSelectItems: ISelectItems[]
	isSellerActiveByGateway: boolean
	inputMediaRef: React.RefObject<HTMLFormElement>
	submitForm: () => void
	searchCEP: (event: FocusEvent<HTMLInputElement>) => void
	goBackToLogin(): void
	addOpeningHour(
		fields: FieldArrayWithId<ICreateSellerForm, 'openingHours', 'id'>[]
	): void
	removeOpeningHour(index: number): void
}

export enum ERegisterType {
	INDIVIDUAL = 'Pessoa Física',
	COMPANY = 'Pessoa Jurídica'
}

export enum EAccountType {
	CHECKING = 'Conta corrente',
	SAVINGS = 'Conta poupança'
}

export interface ICreateSellerForm {
	document: string
	corporate_name: string
	store_name: string
	store_categories: string
	cep: string
	address: string
	number: number
	district: string
	complement: string
	uf: string
	city: string
	bank: string
	agency: string
	account_number: string
	account_digit: string
	account_name: string
	account_document: string
	account_type: EAccountType
	cpf: string
	email: string
	confirm_email: string
	phone: string
	password: string
	confirm_password: string
	// pix_type: string
	// pix_key: string
	store_bio: string
	first_name: string
	last_name: string
	photo: any
	registerType: ERegisterType
	commercial_phone: string
	startDay: string
	endDay: string
	startTime: string
	endTime: string
	openingHours: IOpeningHour[]
	termsOfUse?: boolean
}
