import * as React from 'react'
import notificationSound from '../assets/audio/audio-order.mp3'
import useSound from 'use-sound'

export type ISoundContext = {
	playing: boolean
	play: () => void
	stop: () => void
}

export type ISoundProvider = {
	children: React.ReactNode
}

export const SoundContext = React.createContext<ISoundContext | null>(null)

export const SoundProvider = ({ children }: ISoundProvider) => {
	const audioContext = new AudioContext()
	const [playing, setPlaying] = React.useState(false)
	const [playSound, { stop: stopSound }] = useSound(notificationSound, {
		soundEnabled: true,
		interrupt: true,
		onend: () => {
			setPlaying(false)
		}
	})

	const play = React.useCallback(() => {
		audioContext.resume().then(() => {
			console.log('Playback resumed successfully')
		})

		playSound()
		setPlaying(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playSound])

	const stop = React.useCallback(() => {
		stopSound()
		setPlaying(false)
	}, [stopSound])

	return (
		<SoundContext.Provider value={{ playing: playing, play, stop }}>
			{children}
		</SoundContext.Provider>
	)
}
