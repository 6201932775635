import { styled as st } from '@mui/material/styles'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'

export const BaseDialogStyled = st(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2)
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1)
	}
}))

export const KeepText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	/* identical to box height, or 150% */
	margin-right: 40px;
	text-align: center;
	color: #3d3d3d;
	cursor: pointer;
`

export const DeleteButton = styled.button`
	width: 87px;
	height: 42px;
	background: #7b7b7b;
	border-radius: 21px;
`
