import { createElement, useEffect, useState } from 'react'
import { CreateNotificationView } from './view'
//hooks
import { useLocation, useNavigate } from 'react-router-dom'

//types
import { ERoutes } from '../../routes/routes'
import { ETypeNotification, ICreateSellerForm, IPageProps } from './types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createNotificationSchema } from './schema'
import {
	useCitysList,
	useProductsList,
	useSaveDraftNotification,
	useSellersList,
	useSendNotification
} from '../../services/notifications.service'
import {
	INotificationCitysResponse,
	INotificationSendRequest,
	INotificationSendResponse,
	PushNotificationStatus
} from '../../types/notifications'
import { Toaster } from '../../utils/toaster'

//utils

function CreateNotification() {
	const { state } = useLocation() || {}
	const notificationEditData: INotificationSendResponse | undefined =
		state?.notification
	const isReadOnly =
		!!notificationEditData &&
		notificationEditData.status !== PushNotificationStatus.DRAFT

	const [typeNotification, setTypeNotification] = useState('')
	const [modalOpen, setModalOpen] = useState(false)

	const [selectedCities, setSelectedCities] = useState<
		INotificationCitysResponse[]
	>([])

	const {
		control,
		watch,
		setValue
		// trigger
	} = useForm<ICreateSellerForm>({
		resolver: yupResolver(createNotificationSchema),
		mode: 'onChange',
		delayError: 1000 * 1, //1 second,
		defaultValues: {}
	})

	const navigate = useNavigate()

	const { data: sellersList = [] } = useSellersList()
	const sellersSelect = sellersList.map((sellers) => ({
		value: sellers.name
	}))

	const selectedSeller = watch('seller')
	const selectedSellersInfos = sellersList.find((seller) => {
		return seller.name === selectedSeller
	})

	const { data: productsList = [], refetch: refetchProductsList } =
		useProductsList(selectedSellersInfos?._id)
	const productsSelect = productsList.map((product) => ({
		value: product.name
	}))
	const selectedProduct = watch('product')

	const selectedProductInfos = productsList.find((product) => {
		return product.name === selectedProduct
	})

	const { data: cityList = [] } = useCitysList()
	const citysSelect = cityList.map((citys) => ({
		value: citys.label,
		label: citys.label
	}))

	const { mutateAsync: sendNotification, isLoading: isSending } =
		useSendNotification()
	const { mutateAsync: saveDraftNotification } = useSaveDraftNotification()

	const handleChange = (event: any) => {
		if (event.target.value.includes('all-cities')) {
			if (cityList) {
				if (event.target.value.length > cityList.length) {
					setSelectedCities([])
				} else {
					setSelectedCities(cityList)
				}
			}
		} else {
			const values: string[] = event.target.value
			const selectedCities = cityList.filter((city) => {
				return values.some((valueLabel) => valueLabel === city.label)
			})
			setSelectedCities(selectedCities)
		}
	}

	function goBack() {
		navigate(ERoutes.LIST_NOTIFICATIONS)
	}
	function handleTypeNotification(type: string) {
		if (isReadOnly) return
		setTypeNotification(type)
		setModalOpen(false)
	}

	function handleOpenAndCloseModal() {
		setModalOpen(!modalOpen)
	}

	async function handleSendNotification(payload: INotificationSendRequest) {
		const response = await sendNotification({
			...payload,
			cities: selectedCities.map(({ value }) => value),
			_id: notificationEditData?._id
		})
		if (response) {
			handleOpenAndCloseModal()
			Toaster({
				type: 'success',
				title: 'Notificação enviada',
				text: 'Notificação enviada com sucesso!'
			})
			navigate(ERoutes.LIST_NOTIFICATIONS)
		} else {
			handleOpenAndCloseModal()
			Toaster({
				type: 'error',
				title: 'Error',
				text: 'Erro ao enviar notificação'
			})
		}
		handleOpenAndCloseModal()
	}

	async function handleSaveDraftNotification() {
		const payload: INotificationSendRequest = {
			type: typeNotification,
			title: watch('title'),
			reason: watch('reason'),
			body: watch('description'),
			cities: selectedCities.map(({ value }) => value),
			...(notificationEditData && { _id: notificationEditData._id }),
			...(typeNotification === ETypeNotification.PRODUCT && {
				product: selectedProductInfos?._id,
				store: selectedSellersInfos?._id
			}),
			...(typeNotification === ETypeNotification.SELLER && {
				store: selectedSellersInfos?._id
			})
		}

		const response = await saveDraftNotification(payload)

		if (response) {
			Toaster({
				type: 'success',
				title: 'Rascunho Salvo',
				text: 'Rascunho salvo com sucesso!'
			})
			navigate(ERoutes.LIST_NOTIFICATIONS)
		} else {
			Toaster({
				type: 'error',
				title: 'Error',
				text: 'Erro ao salvar rascunho'
			})
		}
	}

	useEffect(() => {
		if (selectedSeller !== undefined) {
			refetchProductsList()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSeller])

	useEffect(() => {
		if (!notificationEditData) return
		setValue('reason', notificationEditData?.reason || '')
		setValue('description', notificationEditData?.body || '')
		setValue('title', notificationEditData?.title || '')
		setTypeNotification(notificationEditData?.type || '')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationEditData])

	useEffect(() => {
		if (!cityList || !notificationEditData) return

		const selectedCitiesNames = cityList.filter((city) => {
			return notificationEditData?.cities.find((cityItem) => {
				return city.label === `${cityItem.city} - ${cityItem.uf}`
			})
		})

		setSelectedCities(selectedCitiesNames)
	}, [cityList, notificationEditData])

	useEffect(() => {
		if (!notificationEditData || selectedSeller) return

		const sellersSelectName = sellersList.find(
			(sellers) => sellers._id === notificationEditData.store
		)

		sellersSelectName && setValue('seller', sellersSelectName.name)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sellersList])

	useEffect(() => {
		if (!notificationEditData || selectedProduct) return

		const productSelectName = productsList.find(
			(product) => product._id === notificationEditData.product
		)
		productSelectName && setValue('product', productSelectName.name)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productsList])

	useEffect(() => {
		if (!selectedProduct) return

		const productIsFromAnotherStore = productsList.find(
			(product) => product.name === selectedProduct
		)

		if (!productIsFromAnotherStore) setValue('product', undefined)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSeller])

	useEffect(() => {
		if (!selectedSeller || !selectedProduct) return

		setValue('product', undefined)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSeller])

	const modalData = {
		title: watch('title'),
		reason: watch('reason'),
		cities: selectedCities,
		description: watch('description')
	}

	const selectedCityLabels = selectedCities.map((city) => city.label)

	const canSubmit =
		!notificationEditData ||
		notificationEditData.status === PushNotificationStatus.DRAFT

	const formIsValid =
		typeNotification === ETypeNotification.PRODUCT
			? !modalData.reason ||
			  !modalData.title ||
			  !modalData.description ||
			  !modalData.cities ||
			  !selectedProductInfos ||
			  !selectedSellersInfos ||
			  isReadOnly
			: typeNotification === ETypeNotification.SELLER
			? !modalData.reason ||
			  !modalData.title ||
			  !modalData.description ||
			  !modalData.cities ||
			  !selectedSellersInfos ||
			  isReadOnly
			: !modalData.reason ||
			  !modalData.title ||
			  !modalData.description ||
			  !modalData.cities ||
			  isReadOnly

	const pageProps: IPageProps = {
		goBack,
		typeNotification,
		handleTypeNotification,
		control,
		productsSelect,
		sellersSelect,
		selectedSeller,
		citysSelect,
		modalOpen,
		handleOpenAndCloseModal,
		selectedProductInfos,
		selectedSellersInfos,
		modalData,
		handleSendNotification,
		handleSaveDraftNotification,
		selectedCityLabels,
		handleChange,
		citysList: cityList,
		notificationEditData,
		isReadOnly,
		isSending,
		canSubmit,
		formIsValid
	}

	return createElement(CreateNotificationView, pageProps)
}

export { CreateNotification }
