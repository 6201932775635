import styled, { css } from 'styled-components'
import { TextField } from '@mui/material'
export const Container = styled.div<{ flex: number }>`
	flex: ${({ flex }) => flex};
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 85px;
`

export const FloatingLabel = styled.label`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

export const ErrorMessage = styled.span`
	margin-top: 12px;
	margin-bottom: 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.RED_ERROR};
`

export const SelectStyled = styled(TextField)<{
	hasError?: boolean
	hasValue?: boolean
	disabled?: boolean
	name?: string
}>`
	width: 100%;
	height: 50px;
	margin-top: auto;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 26px;

	* {
		-webkit-text-fill-color: unset !important;
	}

	${({ theme, disabled, hasValue }) =>
		disabled &&
		hasValue &&
		css`
			background-color: ${theme.COLORS.LIGHT_WHITE}!important;
			color: ${theme.COLORS.MEDIUM_GRAY}!important;
			border: 1px solid ${theme.COLORS.LIGHT_WHITE}!important;
		`};

	input {
		height: 18px;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;

		${({ theme, disabled, hasValue }) =>
			disabled &&
			hasValue &&
			css`
				background-color: ${theme.COLORS.LIGHT_WHITE}!important;
				color: ${theme.COLORS.MEDIUM_GRAY}!important;
				border: 1px solid ${theme.COLORS.LIGHT_WHITE}!important;
			`};
	}
	${({ theme, disabled, hasValue }) =>
		!disabled &&
		hasValue &&
		css`
			fieldset {
				border: 1px solid ${theme.COLORS.PRIMARY_COLOR}!important;
			}
		`};

	${({ theme, disabled, hasValue }) =>
		disabled &&
		!hasValue &&
		css`
			background-color: ${theme.COLORS.LIGHT_WHITE}!important;
			color: ${theme.COLORS.MEDIUM_GRAY}!important;
			border: 1px solid ${theme.COLORS.LIGHT_WHITE}!important;
		`};

	fieldset {
		height: 53px;
		border-radius: 26px;
	}

	.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
		border: 1px solid ${({ theme }) => theme.COLORS.PRIMARY_COLOR}!important;
	}

	${({ hasError }) => {
		return (
			hasError &&
			css`
				border: 1px solid ${({ theme }) => theme.COLORS.RED_ERROR}!important;
			`
		)
	}}

	${({ name }) => {
		return (
			name === 'statusOrder' &&
			css`
				fieldset {
					border: 1px solid
						${({ theme }) => theme.COLORS.PRIMARY_COLOR}!important;
					width: 100%;
				}
			`
		)
	}}
`

export const ItemsContainer = styled.div`
	width: 100%;
	position: absolute;
	background-color: ${({ theme }) => theme.COLORS.WHITE};
	z-index: 5;
	padding: 8px;
	top: 85px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	max-height: 500px;
	overflow-y: auto;
`

export const Item = styled.span`
	cursor: pointer;
	font-size: 18px;

	:hover {
		opacity: 0.3;
	}
`

export const FloatingLabelContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	height: 22px;
`
