import { useMemo } from 'react'
import * as S from './styles'

//icons
import success from '../../assets/icons/toast-success.svg'
import warning from '../../assets/icons/toast-warning.svg'
import error from '../../assets/icons/toast-error.svg'

import { IToaster } from './types'

function ToasterComponent({ title, text, type }: IToaster) {
	const Icon = useMemo(() => {
		switch (type) {
			case 'error':
				return error
			case 'success':
				return success
			case 'warning':
				return warning

			default:
				return ''
		}
	}, [type])
	const defaultTitle = useMemo(() => {
		switch (type) {
			case 'error':
				return 'Erro !'
			case 'success':
				return 'Sucesso !'
			case 'warning':
				return 'Aviso !'

			default:
				return ''
		}
	}, [type])

	return (
		<S.Container type={type}>
			<img src={Icon} alt='' />
			<span>{title || defaultTitle}</span>
			<p>{text}</p>
		</S.Container>
	)
}

export { ToasterComponent }
