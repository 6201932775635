import styled from 'styled-components'

export const FormWrapper = styled.form`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
`

export const FormFields = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	gap: 20px;

	:first-child {
		margin-bottom: 50px;
	}
`
