import {
	QueryFunctionContext,
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult
} from '@tanstack/react-query'
import {
	IGetBankAccountResponse,
	IUpdateBankAccountRequest
} from '../types/bankAccount'
import { QUERY_KEYS } from '../utils/reactQuery'
import axiosInstance from './axiosInstance'

export function useBankAccount(): UseQueryResult<IGetBankAccountResponse> {
	return useQuery([QUERY_KEYS.BANK_ACCOUNT], getDeliveryAreas, {
		retry: false
	})
}

async function getDeliveryAreas(context: QueryFunctionContext) {
	const response = await axiosInstance.get<IGetBankAccountResponse>(
		`/bank-accounts/default`
	)

	return response.data
}

export function useUpdateBankAccount(): UseMutationResult<
	IGetBankAccountResponse,
	any,
	IUpdateBankAccountRequest
> {
	return useMutation([QUERY_KEYS.UPDATE_BANK_ACCOUNT], updateBankAccount)
}

async function updateBankAccount(payload: IUpdateBankAccountRequest) {
	const response = await axiosInstance.patch<IGetBankAccountResponse>(
		'/bank-accounts/change/default',
		payload
	)

	return response.data
}
