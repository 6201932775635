import React from 'react'
import * as S from './styles'

//components
import { SearchInput } from '../../components/SearchInput'

//types
import { IPageProps } from './types'

//utils
import { AdminProductsList } from '../../components/AdminProductsList'

function AdminProductsView({
	products,
	search,
	handleChangeSearchString,
	handleChangePage,
	filter,
	setFilter
}: IPageProps) {
	return (
		<S.Container>
			<S.Head>
				<S.PageTitle>Produtos Cadastrados</S.PageTitle>
				<S.SearchContainer>
					<SearchInput
						onChange={(e) => handleChangeSearchString(e)}
						value={search}
					/>
				</S.SearchContainer>
			</S.Head>
			<S.Body>
				<AdminProductsList
					products={products}
					handleChangePage={handleChangePage}
					filter={filter}
					setFilter={setFilter}
				/>
			</S.Body>
		</S.Container>
	)
}

export { AdminProductsView }
