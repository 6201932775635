import styled, { css } from 'styled-components'
export const Container = styled.div<{ flex: number }>`
	flex: ${({ flex }) => flex};
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 85px;
`

export const FloatingLabel = styled.label`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

export const ErrorMessage = styled.span`
	margin-top: 12px;
	margin-bottom: 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.RED_ERROR};
`

export const InputContainer = styled.div<{ hasError?: boolean }>`
	width: 100%;
	height: 50px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	border-radius: 26px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 8px 16px;

	:has(input:focus) {
		border-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	}

	/* :has(input:not(:placeholder-shown)) {
		border-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	} */

	${({ theme, hasError }) =>
		hasError &&
		css`
			border-color: ${theme.COLORS.RED_ERROR} !important;
		`}

	img {
		margin-left: auto;
	}
`

export const Input = styled.input`
	min-width: 90%;
	height: 100%;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	outline: none;
	border: none;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};

	::placeholder {
		color: ${({ theme }) => theme.COLORS.MEDIUM_GRAY};
	}

	::-webkit-inner-spin-button,
	::-webkit-calendar-picker-indicator {
		width: 25px;
		height: 25px;
		opacity: 0;
		position: absolute;
		right: 12px;
		z-index: 20;
	}
`
