import {
	useMutation,
	UseMutationResult,
	useQuery,
	UseQueryResult
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
	IChangePasswordRequest,
	IChangePasswordResponse,
	ILoginRequest,
	ILoginResponse,
	IResetPasswordRequest,
	IResetPasswordResponse,
	IValidateTokenRequest,
	IValidateTokenResponse,
	ITokenRequest,
	ITokenResponse
} from '../types/user'
import { IError } from '../utils/handleError'
import { QUERY_KEYS } from '../utils/reactQuery'
import axiosInstance from './axiosInstance'

export function useLogin(): UseMutationResult<
	ILoginResponse,
	any,
	ILoginRequest
> {
	return useMutation([QUERY_KEYS.LOGIN], login, { retry: false })
}

async function login(payload: ILoginRequest) {
	const response = await axiosInstance.post<ILoginResponse>(
		'/auth/panel/login',
		payload
	)

	return response.data
}

export function useValidateResetToken({
	resetToken
}: IValidateTokenRequest): UseQueryResult<
	IValidateTokenResponse,
	AxiosError<IError>
> {
	return useQuery(
		[QUERY_KEYS.VALIDATE_TOKEN],
		() => validateToken(resetToken),
		{
			enabled: !!resetToken,
			retry: false,
			refetchOnWindowFocus: false
		}
	)
}

async function validateToken(resetToken: string) {
	try {
		const response = await axiosInstance.get<IValidateTokenRequest>(
			`/users/validate-reset-token/${resetToken}`
		)

		return response.data
	} catch (err) {
		throw err
	}
}

export function useResetPassword(): UseMutationResult<
	IResetPasswordResponse,
	any,
	IResetPasswordRequest
> {
	return useMutation([QUERY_KEYS.RESET_PASSWORD], resetPassword)
}

async function resetPassword(payload: IResetPasswordRequest) {
	const response = await axiosInstance.post<IResetPasswordResponse>(
		'/users/change-password',
		payload
	)

	return response.data
}

export function useChangePassword(): UseMutationResult<
	IChangePasswordResponse,
	any,
	IChangePasswordRequest
> {
	return useMutation([QUERY_KEYS.CHANGE_PASSWORD], changePassword)
}

async function changePassword(payload: IChangePasswordRequest) {
	const response = await axiosInstance.patch<IChangePasswordResponse>(
		'/users/change-password',
		payload
	)

	return response.data
}

export function useDeclineToken({
	token
}: ITokenRequest): UseQueryResult<ITokenResponse> {
	return useQuery([QUERY_KEYS.LOGIN], () => declineToken({ token }), {
		enabled: !!token
	})
}

async function declineToken({ token }: ITokenRequest) {
	const response = await axiosInstance.get<ITokenResponse>(
		'/users/seller/declined/' + token
	)

	return response.data
}

export function useReadNotifications() {
	return useMutation([QUERY_KEYS.READ_NOTIFICATION], readNotifications)
}

async function readNotifications() {
	await axiosInstance.patch('/notifications/mark-read')
}
