export enum EFormError {
	INVALID_DOCUMENT = 'Documento inválido',
	INVALID_CEP = 'Cep inválido',
	INVALID_VALUE = 'Valor inválido',
	INVALID_PHONE = 'Telefone inválido',
	SHORT_PASSWORD = 'Senha muito curta',
	REQUIRED = 'Campo obrigatório',
	PASSWORD_MATCH = 'Senhas não coincidem',
	EMAIL = 'Email inválido',
	INVALID_HOUR = 'Hora inválida',
	INVALID_DATE = 'Data inválida',
	INVALID_PASSWORD = 'Senha inválida',
	EMAIL_MATCH = 'Emails não coincidem'
}

export enum TooltipTexts {
	CATEGORY = 'O vídeo e as fotos devem ser responder à categoria selecionada, caso contrário seu anúncio não será aprovado.',
	FIXED_DELIVERY = 'Informe em quantos dias após a compra a entrega será feita e dentro de qual horário',
	RELATIVE_DELIVERY = 'Programe o dia e o horário da semana em que você fará as entregas. Exemplo: Toda Quinta-Feira das 14:40 às 17:30'
}
