import { Dispatch, SetStateAction } from 'react'
import { IPaginationResponse } from '../../types/pagination'
import { IAdminOrdersResponse } from '../../types/orders'

export interface IPageProps {
	searchQuery: string
	setSearchQuery: Dispatch<SetStateAction<string>>
	orders?: IPaginationResponse<IAdminOrdersResponse> | undefined
	handleChangePage(page: number): void
	filter: IFilter
	setFilter: Dispatch<SetStateAction<IFilter>>
}
export type IListItem = IAdminOrdersResponse
export type ISortOrderValues = 'asc' | 'desc'
export type ISortByValues = keyof IListItem

export interface IFilter {
	sort: {
		by: ISortByValues
		order: ISortOrderValues
	}
	status?: string
	date?: string
	page: number
}

export enum OrderStatus {
	PENDING = 'PENDING', // PENDENTE (PAGAMENTO)
	PAID = 'PAID', // PAGO
	PRODUCTION = 'PRODUCTION', // EM PRODUÇAO
	ON_ROUTE = 'ON_ROUTE', // EM ROTA (A CAMINHO)
	DELIVERED = 'DELIVERED', // ENTREGUE
	READY_WITHDRAWAL = 'READY_WITHDRAWAL', // PRONTO PARA RETIRADA
	CANCELED = 'CANCELED', // CANCELADO
	FAILED = 'FAILED' // FALHA NO PAGAMENTO
}

export type PaginatedRequest<T> = T & {
	maxItemsInPage: number
	searchString?: string
	pageIndex: number
	sort?: string
	order?: number
	date?: Date
	status?: string
}

export type IFetchRequest = PaginatedRequest<{}>
