import * as S from './styles'

//components
import { Button } from '../../Button'
import { InputDate } from '../../InputDate'

//types
import { IFilterProps } from '../types'
import { ChangeEvent, useState } from 'react'

type EventType = ChangeEvent<HTMLInputElement>

function Filter({ setDateFilter, onClick, isOpen }: IFilterProps) {
	const [date, setDate] = useState<string | undefined>('')

	function handleInputDate(event: EventType) {
		const { value } = event.target
		setDate(value)
	}

	const handleSetDate = () => setDateFilter(new Date(`${date}T12:00Z`))

	return (
		<S.Container isOpen={isOpen}>
			<S.FiltersContainer>
				<InputDate
					flex={0.25}
					floatingLabel='Data da Compra'
					placeholder={'Data da Compra'}
					value={date}
					onChange={(e) => handleInputDate(e)}
				/>

				<Button label='Aplicar' onClick={handleSetDate} />
				<S.Reset onClick={() => setDate(undefined)}>Limpar</S.Reset>
			</S.FiltersContainer>
		</S.Container>
	)
}

export { Filter }
