import { collection, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from '../services/firebase'
import { useTypedSelector } from './useTypedSelector'
import { hashNotification } from '../components/Layout/components/Notification/types'

interface IFirestoreNotifications {
	createdAt: string
	description: string
	id: string
	read: false
	type: ENotificationType
	updatedAt: string
	user: string
	photo: string
	_id: string
	hash: hashNotification
}

export enum ENotificationType {
	DECLINED_PRODUCT = 'DECLINED_PRODUCT', // PRODUTO RECUSADO (SELLER)
	APPROVATION_PRODUCT = 'APPROVATION_PRODUCT', // PRODUTO APROVADO (SELLER)
	NEW_BUY = 'NEW_BUY', // NOVA VENDA (SELLER)
	NEW_SELLER = 'NEW_SELLER', // NOVO SELLER (ADMIN)
	NEW_PRODUCT = 'NEW_PRODUCT', // NOVO PRODUTO (ADMIN)
	UPDATED_PRODUCT = 'UPDATED_PRODUCT',
	UPDATED_SELLER = 'UPDATED_SELLER'
}

export function useOnSnapshotNotifications() {
	const [notifications, setNotifications] =
		useState<IFirestoreNotifications[]>()

	const {
		user: { id }
	} = useTypedSelector(['user'])

	const fetchPost = async () => {
		onSnapshot(collection(db, `users/${id}/notifications`), (snapshot) => {
			const data = snapshot.docs.map((doc) => ({
				...doc.data(),
				id: doc.id
			}))

			setNotifications(data as any)
		})
	}

	const reFetchNotications = async () => await fetchPost()

	useEffect(() => {
		fetchPost()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { notifications, reFetchNotications }
}
