import React, { useState } from 'react'
import * as S from './styles'

//components
import { SearchInput } from '../../components/SearchInput'
import { SellersList } from '../../components/SellersList'

//types
import { IPageProps } from './types'

//utils
import { parseSellerList } from '../../utils/sellerList'

function AdminSellersView({ isLoading, sellers }: IPageProps) {
	const [searchQuery, setSearchQuery] = useState('')

	const formattedSellers = parseSellerList(sellers)

	return (
		<S.Container>
			<S.Head>
				<S.PageTitle>Sellers</S.PageTitle>
				<S.SearchContainer>
					<SearchInput
						onChange={(e) => setSearchQuery(e.target.value)}
						value={searchQuery}
					/>
				</S.SearchContainer>
			</S.Head>
			<S.Body>
				<SellersList sellers={formattedSellers} searchQuery={searchQuery} />
			</S.Body>
		</S.Container>
	)
}

export { AdminSellersView }
