import { createElement, useEffect } from 'react'
import { EmailActivationView } from './view'

//types
import { IPageProps } from './types'

//hooks
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ERoutes } from '../../routes/routes'
import { useActivateSeller } from '../../services/seller.service'

function EmailActivation() {
	const { activateToken } = useParams()
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const isApp = pathname.includes('/app')

	const { isFetching: loading, error } = useActivateSeller({
		activateToken: activateToken as string
	})

	function handleNavigate() {
		navigate(ERoutes.LOGIN)
	}

	useEffect(() => {
		if (!error) return

		handleNavigate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, navigate])

	const pageProps: IPageProps = {
		handleNavigate,
		loading,
		isApp
	}

	return createElement(EmailActivationView, pageProps)
}

export { EmailActivation }
