import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import * as S from './styles'

//icons
import frame from '../../assets/icons/frame.svg'
import video from '../../assets/icons/video.svg'
import close from '../../assets/icons/x-red.svg'

//types
import { IInputMedia } from './types'

//utils
import { humanFileSize } from '../../utils/data'
import { Toaster } from '../../utils/toaster'
import { useVideo } from '../../hooks/useVideo'
import { Spinner } from '../Spinner'

async function getDuration(file: any): Promise<number> {
	const url = URL.createObjectURL(file)

	return new Promise((resolve) => {
		const audio = document.createElement('audio')
		audio.muted = true
		const source = document.createElement('source')
		source.src = url //--> blob URL
		audio.preload = 'metadata'
		audio.appendChild(source)
		audio.onloadedmetadata = function () {
			resolve(audio.duration)

			audio.remove()
			source.remove()
		}
	})
}

function checkSize(size?: number, max?: number) {
	if (size && max) {
		if (size < max) return true

		Toaster({
			type: 'warning',
			title: `Arquivo muito grande (${humanFileSize(size)})`,
			text: '20mb por vídeo e  750kb por imagem.'
		})

		return false
	}
	return true
}

function InputMedia({
	value,
	onChange,
	fileType,
	showLabel,
	accept,
	errorMessage,
	max,
	disabled,
	preview = false,
	...rest
}: IInputMedia) {
	const [selectedFile, setSelectedFile] = useState<File | null>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const videoRef = useRef<HTMLInputElement | null>(null)
	const { load, transcode } = useVideo()

	const dropRef = useRef<HTMLLabelElement | null>(null)

	const isImage = fileType === 'image'

	const fileURL = selectedFile ? URL.createObjectURL(selectedFile) : ''

	const defaultFiles = isImage
		? 'image/jpg, image/jpeg, image/png'
		: 'video/mp4, video/quicktime'

	const typeInvalidFileToastError = () => {
		Toaster({
			type: 'warning',
			title: 'Arquivo invalido',
			text: 'Apenas arquivos ' + defaultFiles.replaceAll('image/', '.')
		})
	}

	async function handleChangeFiles(e: React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files ? e.target.files[0] : null

		if (!isImage) {
			const duration = await getDuration(files)

			if (duration > 50) {
				Toaster({
					type: 'warning',
					title: 'Video muito longo.',
					text: 'O vídeo precisa ter no máximo 50 segundos.'
				})

				return
			}
		}

		if (!checkSize(files?.size, max as number)) return

		if (!defaultFiles.includes(files?.type || '') && isImage) {
			typeInvalidFileToastError()
			return
		}

		if (files?.type === 'video/quicktime') {
			setIsLoading(true)
			await load()
			const convertedFileToMP4 = await transcode({
				videoFile: files ? files : '',
				videoRef
			})

			setSelectedFile(convertedFileToMP4)
			setIsLoading(false)

			const fileList = {
				0: convertedFileToMP4,
				length: 1
			}
			onChange && onChange(fileList as any)

			return
		}

		setSelectedFile(files)
		onChange && onChange(e.target.files as any)
	}

	function clearFile() {
		setSelectedFile(null)
		onChange && onChange(undefined as any)
	}

	const handleDrag = (e: DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDrop = (e: DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
		const file = e.dataTransfer?.files[0]

		if (checkSize(file?.size, max as number)) {
			if (defaultFiles.includes(file?.type || '')) {
				file && setSelectedFile(file)
				onChange && onChange(e.dataTransfer?.files as any)
				return
			}
		}

		typeInvalidFileToastError()
	}

	function setupDragAndDrop() {
		const drop = dropRef.current
		drop?.addEventListener('dragover', handleDrag)
		drop?.addEventListener('drop', handleDrop)

		return () => {
			drop?.removeEventListener('dragover', handleDrag)
			drop?.removeEventListener('drop', handleDrop)
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setupDragAndDrop, [])

	const handleShowPreview = useCallback(() => {
		value && window.open(value.toString(), '_blank')
	}, [value])

	if (fileURL || value)
		return (
			<S.PreviewContainer
				showLabel={showLabel}
				preview={preview}
				onClick={preview ? handleShowPreview : undefined}
			>
				{isImage ? (
					<S.PreviewImage src={fileURL || value?.toString()} />
				) : (
					<S.PreviewVideo
						src={fileURL || value?.toString()}
						autoPlay
						loop
						muted
					/>
				)}

				{!disabled && (
					<S.ClearContainer onClick={clearFile}>
						<img src={close} alt='' />
					</S.ClearContainer>
				)}
				{selectedFile && (
					<S.FileSize>{humanFileSize(selectedFile.size, 2)}</S.FileSize>
				)}
				<S.PreviewShadow />
			</S.PreviewContainer>
		)

	return (
		<S.Container showLabel={showLabel} hasError={!!errorMessage} ref={dropRef}>
			<input
				type='file'
				accept={accept ? accept : defaultFiles}
				//value={value}
				{...rest}
				onChange={handleChangeFiles}
				disabled={disabled}
				ref={videoRef}
			/>

			{isLoading && !isImage ? (
				<S.LoadingPlaceHolder>
					<Spinner size='xsmall' />
					<span>Convertendo para MP4...</span>
				</S.LoadingPlaceHolder>
			) : (
				<>
					<img src={isImage ? frame : video} alt='' />

					{showLabel && (
						<span>{isImage ? 'Upload de Imagem' : 'Upload de Vídeo'}</span>
					)}
				</>
			)}
		</S.Container>
	)
}

export default memo(InputMedia)
