import styled, { css } from 'styled-components'

export const Container = styled.div`
	width: 100%;
	height: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	user-select: none;

	* {
		transition: all 0.1s;
		user-select: none;
	}
`

export const ListOption = styled.div<{ active?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 22px;
	height: 22px;
	border-radius: 4px;
	cursor: pointer;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-align: center;

	background: ${({ theme }) => theme.COLORS.WHITE};
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};

	:active {
		opacity: 0.6;
	}

	:hover {
		height: 24px;
		border: 1px solid #C4C1E6;
	}

	${({ active }) =>
		active &&
		css`
			background: ${({ theme }) => theme.COLORS.SOFT_BLUE};
			border: 1px solid #C4C1E6;
		`}
`

export const Arrow = styled.img`
	width: 8px;
	margin: 0 17px;
	cursor: pointer;

	:hover {
		width: 11px;
	}
`

export const Count = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	position: absolute;
	left: 0;
	bottom: 0;
`
