import styled from 'styled-components'

export const Container = styled.div<{ isActive: boolean }>`
	width: 40px;
	height: 22px;
	background: ${({ theme, isActive }) =>
		isActive ? theme.COLORS.PRIMARY_COLOR : theme.COLORS.LIGHT_GRAY};
	border-radius: 15px;
	padding: 2px;
	cursor: pointer;
	display: flex;
	position: relative;

	:active {
		opacity: 0.6;
	}
`

export const Selector = styled.div<{ isActive: boolean }>`
	width: 18px;
	height: 18px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 50%;
	transition: all 0.2s;
	position: absolute;

	left: ${({ isActive }) => (isActive ? '50%' : '10%')};
	top: 50%;
	transform: translateY(-50%);
`
