import { useCallback, useState } from 'react'
import { Modal } from '../components/ModalHook'
import { IUseModalProps, IUseModalReturn } from '../components/ModalHook/types'

function useModal({
	onClickActionButton,
	title,
	text,
	actionButtonLabel,
	closeButtonLabel
}: IUseModalProps): IUseModalReturn {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const toggle = useCallback(() => {
		setIsModalOpen(!isModalOpen)
	}, [isModalOpen])

	return {
		Modal,
		modalProps: {
			isModalOpen,
			onClickButton: onClickActionButton,
			title,
			text,
			actionButtonLabel,
			closeButtonLabel,
			toggleModal: toggle
		},
		toggle,
		isModalOpen
	}
}

export { useModal }
