//components
import { ToastContainer } from 'react-toastify'
import Routes from './routes'

//providers
import { Provider } from 'react-redux'

import { ThemeProvider } from 'styled-components'
import { PersistGate } from 'redux-persist/integration/react'

import { ThemeProvider as MuiThemeProvider } from '@mui/material'

//styles
import { GlobalStyle } from './styles/GlobalStyle'
import { defaultTheme } from './styles/defaultTheme'
import 'react-toastify/dist/ReactToastify.css'
import '../src/styles/toast.css'
import { persistor, store } from './store/redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { materialTheme } from './styles/materialTheme'
import { useEffect } from 'react'
import { SoundProvider } from './context/SoundProvider'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 2,
			refetchOnWindowFocus: true,
			refetchOnMount: true,
			refetchOnReconnect: true,
			refetchIntervalInBackground: false
		}
	}
})

function App() {
	useEffect(() => {
		window.history.pushState(
			'',
			document.title,
			`${window.location.origin}/${window.location.hash}`
		)
	}, [])

	return (
		<SoundProvider>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ThemeProvider theme={defaultTheme}>
						<MuiThemeProvider theme={materialTheme}>
							<QueryClientProvider client={queryClient}>
								<GlobalStyle />
								<Routes />
								<ToastContainer />
							</QueryClientProvider>
						</MuiThemeProvider>
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</SoundProvider>
	)
}

export default App
