export const QUERY_KEYS = {
	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
	PRODUCTS: 'PRODUCTS',
	PRODUCTS_LIST: 'PRODUCTS_LIST',
	RESET_PASSWORD: 'RESET_PASSWORD',
	VALIDATE_TOKEN: 'VALIDATE_TOKEN',
	CREATE_SELLER: 'CREATE_SELLER',
	UPDATE_SELLER: 'UPDATE_SELLER',
	SELLERS: 'SELLERS',
	LOGIN: 'LOGIN',
	ACTIVATE_SELLER: 'ACTIVATE_SELLER',
	DELIVERY_AREAS: 'DELIVERY_AREAS',
	DELIVERY_AREA: 'DELIVERY_AREA',
	DELETE_DELIVERY_AREA: 'DELETE_DELIVERY_AREA',
	UPDATE_DELIVERY_AREA: 'UPDATE_DELIVERY_AREA',
	DELETE_PRODUCT: 'DELETE_PRODUCT',
	TOGGLE_ACTIVATE: 'TOGGLE_ACTIVATE',
	CHANGE_SELLER_STATUS: 'CHANGE_SELLER_STATUS',
	SELLER_DETAILS: 'SELLER_DETAILS',
	CATEGORY: 'CATEGORY',
	REGISTER_PRODUCT: 'REGISTER_PRODUCT',
	PATCH_PRODUCT: 'PATCH_PRODUCT',
	EXCLUDE_PRODUCT: 'EXCLUDE_PRODUCT',
	TOGGLE_PRODUCT: 'TOGGLE_PRODUCT',
	READ_NOTIFICATION: 'READ_NOTIFICATION',
	SELLER_ORDERS: 'SELLER_ORDERS',
	ORDER_STATUS: 'ORDER_STATUS',
	BANK_ACCOUNT: 'BANK_ACCOUNT',
	UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT',
	RECEIVABLE_ORDERS: 'RECEIVABLE_ORDERS',
	RESEND_GATEWAY: 'RESEND_GATEWAY',
	ORDER: 'ORDER',
	ORDERS_LIST: 'ORDERS_LIST',
	REQUEST_OTP_TO_ACTIVATE: 'REQUEST_OTP_TO_ACTIVATE',
	ACTIVATE_SELLER_BY_OTP: 'ACTIVATE_SELLER_BY_OTP',
	NOTIFICATIONS_PRODUCTS: 'NOTIFICATIONS_PRODUCTS',
	NOTIFICATIONS_SELLERS: 'NOTIFICATIONS_SELLERS',
	NOTIFICATIONS_CITYS: 'NOTIFICATIONS_CITYS',
	NOTIFICATIONS_SEND: 'NOTIFICATIONS_SEND',
	NOTIFICATIONS_LIST: 'NOTIFICATIONS_LIST',
	NOTIFICATIONS_SAVE_DRAFT: 'NOTIFICATIONS_SAVE_DRAFT'
}
