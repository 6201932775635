import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toaster } from '../../utils/toaster'
import { ERoutes } from '../../routes/routes'
import { Helmet } from 'react-helmet'

export enum OSEnum {
	ANDROID = 'Android',
	IOS = 'iOS'
}

export function Share() {
	const navigate = useNavigate()
	const [os, setOS] = useState('')

	function getOS() {
		var userAgent = window.navigator.userAgent,
			platform =
				(window.navigator as any).userAgentData?.platform ||
				window.navigator.platform,
			macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['iPhone', 'iPad', 'iPod'],
			os = ''

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = 'Mac OS'
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = 'iOS'
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = 'Windows'
		} else if (/Android/.test(userAgent)) {
			os = 'Android'
		} else if (/Linux/.test(platform)) {
			os = 'Linux'
		}

		setOS(os)
	}

	function redirect() {
		if (os === '') return

		if (os === OSEnum.ANDROID) {
			window.location.replace(
				'https://play.google.com/store/apps/details?id=com.harv'
			)

			return
		}

		if (os === OSEnum.IOS) {
			window.location.replace(
				'https://apps.apple.com/br/app/harv-brasil/id6444048061'
			)

			return
		}

		Toaster({
			type: 'warning',
			title: 'Atenção!!',
			text: 'Para acessar esta oferta, baixe primeiramente o aplicativo nas lojas Android ou Apple'
		})
		navigate(ERoutes.LOGIN)

		return
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getOS, [])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(redirect, [os])

	return (
		<div>
			<Helmet>
				<title>My Page Title</title>
				<meta name='description' content='This is a description of my page' />
				<meta name='keywords' content='react, meta tags, seo' />
				<meta name='author' content='Your Name' />
				<meta property='og:title' content='My Page Title' />
				<meta
					property='og:description'
					content='This is a description of my page'
				/>
				<meta property='og:image' content='https://example.com/image.jpg' />
				<meta property='og:url' content='https://example.com/my-page' />
				<meta name='twitter:title' content='My Page Title' />
				<meta
					name='twitter:description'
					content='This is a description of my page'
				/>
				<meta name='twitter:image' content='https://example.com/image.jpg' />
				<meta name='twitter:card' content='summary_large_image' />
			</Helmet>
		</div>
	)
}
