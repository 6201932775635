export const TimeToDeliveryLabels = {
	TODAY_30M_50M: 'Em Menos de 1 hora',
	TODAY_51M_1H30M: 'Em Menos de 2 horas',
	UNTIL_1D: 'Até 1 dia útil',
	UNTIL_2D: 'Até 2 dias úteis',
	UNTIL_7D: 'Até 7 dias úteis ',
	UNTIL_15D: 'Até 15 dias úteis'
}

export const TimeToDeliveryDays = {
	UNTIL_1D: 1,
	UNTIL_2D: 2,
	UNTIL_7D: 6,
	UNTIL_15D: 8
}

export enum EDayOfWeek {
	SUNDAY = 0,
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6
}

export enum ETimeToDelivery {
	TODAY_30M_50M = 'TODAY_30M_50M',
	TODAY_51M_1H30M = 'TODAY_51M_1H30M',
	UNTIL_1D = 'UNTIL_1D',
	UNTIL_2D = 'UNTIL_2D',
	UNTIL_7D = 'UNTIL_7D',
	UNTIL_15D = 'UNTIL_15D'
}

function dateToString(date: Date) {
	return `${
		date.getDate().toString().length === 1
			? '0' + date.getDate()
			: date.getDate()
	}/${
		date.getMonth().toString().length === 1
			? '0' + (date.getMonth() + 1)
			: date.getMonth() + 1
	}`
}

export function getFixedShippingInfo({
	dayOfWeek,
	startTime,
	endTime,
}: {
	dayOfWeek: EDayOfWeek
	startTime: string
	endTime: string
}) {
	const targetDate = new Date()

	const currentDay = targetDate.getDay()
	const targetDay = +dayOfWeek
	const daysToAdd =
		targetDay > currentDay
			? targetDay - currentDay
			: 7 - (currentDay - targetDay)

	targetDate.setDate(targetDate.getDate() + daysToAdd)

	const weekdays = [
		'domingo',
		'segunda',
		'terça',
		'quarta',
		'quinta',
		'sexta',
		'sábado',
	]
	const dayName = weekdays[targetDate.getDay()]

	const isWeekend = [0, 6].includes(targetDate.getDay())
	const timeRange = `das ${startTime}h às ${endTime}h.`

	if (isWeekend) {
		return `Todo ${dayName}, ${timeRange}`
	}

	return `Toda ${dayName}-feira, ${timeRange}`
}

export function getRelativeShippingInfo(timeToDelivery: ETimeToDelivery) {
	if (
		timeToDelivery === ETimeToDelivery.TODAY_30M_50M ||
		timeToDelivery === ETimeToDelivery.TODAY_51M_1H30M
	) {
		return `${TimeToDeliveryLabels[timeToDelivery]}`
	} else {
		const targetDate = new Date()

		const daysToIncrement = TimeToDeliveryDays[timeToDelivery]

		targetDate.setDate(targetDate.getDate() + daysToIncrement)

		return `${dateToString(targetDate)}. \n${
			TimeToDeliveryLabels[timeToDelivery]
		}`
	}
}
