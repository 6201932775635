import React from 'react'
import { useCallback } from 'react'
import { HashRouter, Route, Routes as RoutesDom } from 'react-router-dom'
import { Layout } from '../components/Layout'
import { useIsAdmin } from '../hooks/useIsAdmin'
import { useIsLogged } from '../hooks/useIsLogged'
import { useTypedSelector } from '../hooks/useTypedSelector'

import { Login } from '../views/Login'
import { Redirect } from './redirect'
import { ERoutes, routes } from './routes'
import { UserTypesEnum } from './types'

const Routes = () => {
	const { user } = useTypedSelector(['user'])
	const { isLogged } = useIsLogged()
	const { isAdmin } = useIsAdmin()

	const handlePermission = useCallback(
		(accessType?: UserTypesEnum[], secure?: boolean) => {
			if (!accessType) return true

			if (secure && !isLogged) return false

			if (accessType.length > 0 && accessType.includes(user.type)) return true

			return false
		},
		[user.type, isLogged]
	)

	return (
		<HashRouter>
			<RoutesDom>
				{React.Children.toArray(
					routes.map(
						({ accessType, element, secure, layout, path, children }) => {
							const Screen = element

							const ScreenWithLayout = () => {
								return (
									<Layout>
										<Screen />
									</Layout>
								)
							}

							if (handlePermission(accessType, secure)) {
								return (
									<Route
										key={path}
										path={path}
										element={layout ? <ScreenWithLayout /> : <Screen />}
										children={React.Children.toArray(
											children
												? children.map((e) => {
														const Children = e.element
														return (
															<Route element={<Children />} path={e.path} />
														)
												  })
												: null
										)}
									/>
								)
							}

							return null
						}
					)
				)}

				{/*Login Route */}
				<Route path={ERoutes.LOGIN} element={<Login />} />

				{/* 404 */}
				<Route
					path='*'
					element={<Redirect isAdmin={isAdmin} isLogged={isLogged} />}
				/>
			</RoutesDom>
		</HashRouter>
	)
}

export default Routes
