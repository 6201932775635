import { Controller } from 'react-hook-form'
import { InputText } from '.'

import { IControlledInputText } from './types'

function ControlledInputText({
	control,
	name,
	maskFunction,
	errorMessage,
	disabled,
	...rest
}: IControlledInputText) {
	return (
		<Controller
			name={name}
			control={control}
			render={({
				field: { onChange, onBlur, value, name },
				formState: { errors }
			}) => {
				return (
					<InputText
						disabled={disabled}
						onChange={(e) => {
							maskFunction
								? onChange(maskFunction(e.target.value))
								: onChange(e.target.value)
						}}
						onBlur={onBlur}
						value={value || ''}
						name={name}
						{...rest}
						errorMessage={errors[name]?.message?.toString() || errorMessage}
					/>
				)
			}}
		/>
	)
}

export { ControlledInputText }
