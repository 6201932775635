import * as S from './styles'

//components
import { Button } from '../../components'
import { ControlledInputMedia } from '../../components/InputMedia/controlled'
import { ControlledInputText } from '../../components/InputText/controlled'
import { ControlledSelect } from '../../components/Select/controlled'
import { ControlledTextArea } from '../../components/TextArea/controlled'
import { Container } from '../../styles'

//types
import { IEditSellerForm } from './types'

//utils
import {
	cepMask,
	cnpjMask,
	documentMask,
	numberMask,
	phoneMask
} from '../../utils/inputMasks'

import { yupResolver } from '@hookform/resolvers/yup'
import { FocusEvent, useEffect, useState } from 'react'
import { FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ISelectItems } from '../../components/Select/types'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ERoutes } from '../../routes/routes'
import {
	useEditSeller,
	useMutationSwitchOpenAndCloseStore,
	useSellerDetails
} from '../../services/seller.service'
import { EGatewayStatus } from '../../types/seller'
import { formatEditSellerPayload } from '../../utils/formatPayload'
import { getCep, getCities, getState } from '../../utils/handleAddress'
import { Toaster } from '../../utils/toaster'
import { ERegisterType, ICreateSellerForm } from '../CreateSeller/types'
import { editSellerSchema } from './schema'
import { ProductFormHoursFormated, ProductFormWeekDays } from '../../mocks/form'
import addIcon from '../../assets/icons/add-button.svg'
import noAddIcon from '../../assets/icons/no-add.svg'
import { formatBR } from '../../utils/formatOpeningHour'
import { useStores } from '../../hooks/useStore'
import HelpSvg from '../../assets/icons/help.svg'
import { Switch } from '../../components/Switch'

function Profile() {
	const [states, setStates] = useState<ISelectItems[]>([])
	const [cities, setCities] = useState<ISelectItems[]>([])
	const [isRegister, setIsRegister] = useState(false)
	const { userStore } = useStores()
	const [tooltipOpen, setTooltipOpen] = useState(false)

	const { user } = useTypedSelector(['user'])
	const { data, refetch } = useSellerDetails({ seller_id: user.id })
	const [storeStatus, setStoreStatus] = useState(true)
	const { mutateAsync: switchStoreStatus } =
		useMutationSwitchOpenAndCloseStore()
	const {
		control,
		handleSubmit,
		formState: { errors, isDirty },
		watch,
		setValue,
		reset,
		resetField
	} = useForm<IEditSellerForm>({
		resolver: yupResolver(editSellerSchema),
		mode: 'onChange',
		delayError: 1000 * 1 //1 second,
		//
	})
	// const renderCount = useRef(0)

	useEffect(() => {
		reset({
			registerType:
				data?.store?.type &&
				Object.fromEntries(Object.entries(ERegisterType))[
					(data?.store?.type).toUpperCase()
				],
			cpf: data?.personalRegistration,
			store_name: data?.store.name,
			uf: data?.store?.address?.state,
			email: data?.email,
			first_name: data?.firstName,
			last_name: data?.lastName,
			phone: data?.phone,
			corporate_name: data?.store.companyName,
			document: data?.store.companyRegistration,
			photo: data?.store.photoURL,
			store_bio: data?.store.bio,
			commercial_phone: data?.store.commercialPhone,
			address: data?.store?.address?.street,
			cep: data?.store.address?.postalCode,
			number: Number(data?.store?.address?.number),
			city: data?.store.address?.city,
			district: data?.store.address?.neighborhood,
			complement: data?.store.address?.complement,
			openingHours:
				data?.store?.openingHours && formatBR(data?.store?.openingHours)
		})
	}, [data, reset])

	const { registerType } = watch()
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'openingHours',
		rules: {
			minLength: 1,
			maxLength: 3
		}
	})
	const { mutateAsync: updateAsync, isLoading: isLoadingUpdate } =
		useEditSeller()

	const navigate = useNavigate()

	function validateGateway() {
		const status =
			data?.statusGateway === EGatewayStatus.REGISTRATION ||
			data?.statusGateway === EGatewayStatus.ACTIVE
		setIsRegister(status)
	}

	useEffect(validateGateway, [data?.statusGateway])

	async function searchCEP(e: FocusEvent<HTMLInputElement>) {
		if (e.target.value.length !== 9) return

		const { bairro, localidade, logradouro, uf } = await getCep(e.target.value)

		setValue('address', logradouro)
		setValue('city', localidade)
		setValue('uf', uf)
		setValue('district', bairro)
	}

	function fetchFormFields() {
		getState().then((response) => {
			const parsedStates = response
				.sort((a, b) => {
					return a.sigla.toString().toLowerCase() >
						b.sigla.toString().toLowerCase()
						? 1
						: -1
				})
				.map((state) => ({
					value: state.sigla
				}))

			setStates(parsedStates)
		})
	}
	useEffect(fetchFormFields, [])

	const [state] = watch(['uf'])

	function searchCities() {
		if (!state) {
			resetField('city')
		}

		if (state) {
			getCities(state).then((response) => {
				const parsedCities = response.map((state) => ({
					value: state.nome
				}))

				setCities(parsedCities)
			})
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(searchCities, [state])

	function addOpeningHour(
		fields: FieldArrayWithId<ICreateSellerForm, 'openingHours', 'id'>[]
	) {
		if (fields.length < 3)
			append({
				startDay: '',
				endDay: '',
				startTime: '',
				endTime: ''
			})
	}
	function removeOpeningHour(index: number) {
		remove(index)
	}

	async function hadleSwitchOpenAndCloseStore(storeID: string) {
		const switchResponse = await switchStoreStatus({
			storeID
		})

		if (!switchResponse) return

		refetch()
	}

	const submitForm = handleSubmit(async (data: IEditSellerForm) => {
		if (!data?.photo) {
			return Toaster({
				type: 'error',
				title: 'Erro no formulário',
				text: 'A foto do perfil é um campo obrigatório.'
			})
		}

		const payload = formatEditSellerPayload(data)

		const { store, user } = await updateAsync(payload)

		userStore.actions.setUser({
			...user,
			photoURL: store.photoURL,
			store: store
		})

		Toaster({
			type: 'success',
			title: 'Informações atualizadas com sucesso!'
		})

		reset()

		navigate(ERoutes.PRODUCTS)
	})

	const isCPF = registerType === ERegisterType.INDIVIDUAL

	useEffect(() => {
		if (data) {
			setStoreStatus(data.store.isOpen)
		}
	}, [data])

	return (
		<Container>
			<S.Container>
				<S.Content>
					<S.Form>
						<S.HeaderContainer>
							<S.FormTitle> Informações do perfil</S.FormTitle>
							<S.OpenAndCloseStoreWrapper>
								<S.OpenAndCloseStoreTop>
									<S.OpenAndCloseStoreTitle>
										Status da Loja no dia de hoje
									</S.OpenAndCloseStoreTitle>
									<img
										src={HelpSvg}
										width={20}
										height={20}
										alt='info-icon'
										onMouseEnter={() => {
											setTooltipOpen(true)
										}}
										onMouseLeave={() => {
											setTooltipOpen(false)
										}}
									/>
									{tooltipOpen ? (
										<S.TooltipContainer>
											<S.TooltipTitle>Sobre</S.TooltipTitle>
											<S.TooltipText>
												Esta função é ideal para feriados ou período de férias
												pois permite que você feche temporariamente o
												funcionamento da sua Loja. Se estiver com status
												“fechada”, novos pedidos não serão efetuados. Não se
												esqueça de ativar o status “aberta” para receber novos
												pedidos.
											</S.TooltipText>
										</S.TooltipContainer>
									) : null}
								</S.OpenAndCloseStoreTop>
								<S.OpenAndCloseStoreBottom>
									<Switch
										isActive={storeStatus}
										onChange={() => {
											hadleSwitchOpenAndCloseStore(data?.store._id || '')
										}}
									/>
									{storeStatus ? (
										<S.StoreIsOpenValue>Aberta</S.StoreIsOpenValue>
									) : (
										<S.StoreIsOpenValue>Fechada</S.StoreIsOpenValue>
									)}
								</S.OpenAndCloseStoreBottom>
							</S.OpenAndCloseStoreWrapper>
						</S.HeaderContainer>

						<S.Divider />

						<S.PhotoContainer>
							<S.PhotoTitle>Foto do Perfil</S.PhotoTitle>

							<ControlledInputMedia
								control={control}
								name={'photo'}
								errorMessage={errors.photo?.message?.toString()}
								fileType='image'
							/>
						</S.PhotoContainer>

						<S.Divisor />

						<S.FormRow>
							<ControlledSelect
								control={control}
								name={'registerType'}
								errorMessage={errors.registerType?.message}
								floatingLabel={'Cadastrar como'}
								placeholder={'Tipo de Cadastro'}
								flex={0.5}
								items={[
									{ value: ERegisterType.COMPANY },
									{ value: ERegisterType.INDIVIDUAL }
								]}
								disabled={isRegister}
							/>
							<ControlledInputText
								control={control}
								name={'commercial_phone'}
								floatingLabel={'Telefone Comercial'}
								placeholder={'Número de telefone da sua loja'}
								errorMessage={errors.phone?.message}
								maxLength={15}
								flex={0.5}
								maskFunction={phoneMask}
							/>
						</S.FormRow>

						{!isCPF && (
							<S.FormRow>
								<ControlledInputText
									control={control}
									name={'document'}
									floatingLabel={'Digite seu CNPJ'}
									placeholder={'Digite seu CNPJ'}
									errorMessage={errors.document?.message}
									maskFunction={cnpjMask}
									maxLength={18}
									disabled={isRegister}
									flex={0.5}
								/>

								<ControlledInputText
									control={control}
									name={'corporate_name'}
									floatingLabel={'Informe a Razão Social'}
									placeholder={'Informe a Razão Social'}
									errorMessage={errors.corporate_name?.message}
									disabled={isRegister}
									flex={0.5}
								/>
							</S.FormRow>
						)}

						<S.FormRow>
							<ControlledInputText
								control={control}
								name={'store_name'}
								floatingLabel={'Nome do Perfil'}
								placeholder={'Ex. Panificadora Pão Puro'}
								errorMessage={errors.store_name?.message}
							/>
						</S.FormRow>
						<S.FormRow>
							<ControlledTextArea
								control={control}
								name={'store_bio'}
								floatingLabel={'Bio do seu Perfil'}
								placeholder={
									'Descreva sobre o que o seu Perfil oferece, horário de funcionamento, etc.'
								}
								errorMessage={errors.store_bio?.message}
								showMaxLength={true}
								maxLength={140}
							/>
						</S.FormRow>
						<S.FormRow>
							<ControlledInputText
								control={control}
								name={'cep'}
								floatingLabel={'CEP'}
								placeholder={'CEP'}
								flex={0.4}
								errorMessage={errors.cep?.message}
								maskFunction={cepMask}
								maxLength={9}
								onBlur={searchCEP}
							/>

							<ControlledInputText
								control={control}
								name={'address'}
								floatingLabel={'Endereço'}
								placeholder={'Nome da Rua'}
								errorMessage={errors.address?.message}
							/>
						</S.FormRow>

						<S.FormRow>
							<ControlledInputText
								control={control}
								name={'number'}
								floatingLabel={'Número'}
								placeholder={'Número'}
								flex={0.35}
								errorMessage={errors.number?.message}
								maskFunction={numberMask}
							/>

							<ControlledInputText
								control={control}
								name={'district'}
								floatingLabel={'Bairro'}
								placeholder={'Nome do Bairro'}
								errorMessage={errors.district?.message}
							/>

							<ControlledInputText
								control={control}
								name={'complement'}
								floatingLabel={'Complemento ( Opcional )'}
								placeholder={'Bloco ou Apartamento'}
								flex={0.45}
								errorMessage={errors.complement?.message}
							/>
						</S.FormRow>

						<S.FormRow>
							<ControlledSelect
								control={control}
								name={'uf'}
								floatingLabel={'Estado'}
								placeholder={'Estado'}
								flex={0.19}
								items={states}
								errorMessage={errors.uf?.message}
							/>

							<ControlledSelect
								control={control}
								name={'city'}
								floatingLabel={'Cidade'}
								placeholder={'Cidade'}
								flex={0.64}
								items={cities}
								errorMessage={errors.city?.message}
								disabled={!watch('uf')}
								value={watch('city')}
							/>
						</S.FormRow>

						<S.Divisor />
						<S.Form>
							<S.OpeningHourTitle>Horário de Funcionamento</S.OpeningHourTitle>
							<S.OpeningHourSubtitle>
								Insira data e horário funcionamento da sua loja. Essa informação
								ficará visível em seu Perfil caso opte por “retirada em seu
								endereço”
							</S.OpeningHourSubtitle>
							{fields.map((openingHour, i) => (
								<S.FormRow key={openingHour.id}>
									<ControlledSelect
										control={control}
										name={`openingHours[${i}].startDay`}
										floatingLabel={'Inicia em:'}
										placeholder={'Data'}
										errorMessage={errors?.openingHours?.[i]?.startDay?.message}
										items={ProductFormWeekDays}
									/>
									<ControlledSelect
										control={control}
										name={`openingHours[${i}].startTime`}
										placeholder={'Horário'}
										errorMessage={errors?.openingHours?.[i]?.startTime?.message}
										items={ProductFormHoursFormated}
									/>

									<ControlledSelect
										control={control}
										name={`openingHours[${i}].endDay`}
										floatingLabel={'Termina em:'}
										placeholder={'Data'}
										errorMessage={errors?.openingHours?.[i]?.endDay?.message}
										items={ProductFormWeekDays}
									/>
									<ControlledSelect
										control={control}
										name={`openingHours[${i}].endTime`}
										placeholder={'Horário'}
										errorMessage={errors?.openingHours?.[i]?.endDay?.message}
										items={ProductFormHoursFormated}
									/>
									<S.AddHourButtonContainer>
										{i === 0 ? (
											<S.AddHour
												onClick={() => addOpeningHour(fields)}
												type='button'
											>
												<img
													src={addIcon}
													alt='Icone para adicionar mais um horário de funcionamento'
												/>
											</S.AddHour>
										) : (
											<S.RemoveHour
												onClick={() => removeOpeningHour(i)}
												type='button'
											>
												<img
													src={noAddIcon}
													alt='Icone de limite de horário de funcionamento atingido'
												/>
											</S.RemoveHour>
										)}
									</S.AddHourButtonContainer>
								</S.FormRow>
							))}
						</S.Form>
						<S.Divisor />

						<S.FormRow>
							<S.PaymentTitle>Dados Pessoais</S.PaymentTitle>
						</S.FormRow>

						<S.FormRow>
							<ControlledInputText
								control={control}
								name={'first_name'}
								floatingLabel={'Nome'}
								placeholder={'Nome'}
								errorMessage={errors.first_name?.message}
							/>
							<ControlledInputText
								control={control}
								name={'last_name'}
								floatingLabel={'Sobrenome'}
								placeholder={'Sobrenome'}
								errorMessage={errors.last_name?.message}
							/>
						</S.FormRow>

						<S.FormRow>
							<ControlledInputText
								control={control}
								name={'cpf'}
								floatingLabel={'CPF'}
								placeholder={'Somente Números'}
								errorMessage={errors.cpf?.message}
								maskFunction={documentMask}
								maxLength={14}
								disabled={isRegister}
							/>
							<ControlledInputText
								control={control}
								name={'email'}
								floatingLabel={'E-mail'}
								placeholder={'Digite um e-mail válido'}
								errorMessage={errors.email?.message}
								disabled={isRegister}
							/>
						</S.FormRow>

						<S.FormRow>
							<ControlledInputText
								control={control}
								name={'phone'}
								floatingLabel={'Telefone para Contato'}
								placeholder={'Número do Celular'}
								errorMessage={errors.phone?.message}
								maskFunction={phoneMask}
								maxLength={15}
								flex={0.5}
							/>
						</S.FormRow>

						<S.ButtonContainer>
							<Button
								label={'Salvar Informações'}
								onClick={submitForm}
								loading={isLoadingUpdate}
								disabled={!isDirty || Object.entries(errors).length > 0}
								type='submit'
							/>
						</S.ButtonContainer>
					</S.Form>
				</S.Content>
			</S.Container>
		</Container>
	)
}
export { Profile }
