import { IToasterType } from './types'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ type: IToasterType }>`
	width: 468px;
	height: auto;
	border-radius: 4px;
	padding: 19px 18px;
	display: grid;
	grid-template-columns: 40px 1fr;
	grid-template-rows: 40px 1fr;
	font-family: 'Poppins';
	@media screen and (max-width: 508px) {
		width: 100%;
	}
	span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		display: flex;
		align-items: center;
		grid-column: 2;
		grid-row: 1;
	}

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		flex: none;
		order: 1;
		flex-grow: 0;
		grid-column: 2;
		grid-row: 2;
		margin: 0;
	}

	img {
		grid-column: 1;
		grid-row: 1;
		margin: auto;
	}

	${({ type }) => {
		switch (type) {
			case 'success':
				return css`
					background: #ccf0c0;
					border: 1px solid #adcca3;
					span {
						color: #48663d;
					}
					p {
						color: #658f56;
					}
				`

			case 'warning':
				return css`
					background: #fff1b0;
					border: 1px solid #e0d49b;
					span {
						color: #947c13;
					}
					p {
						color: #b89a18;
					}
				`

			case 'error':
				return css`
					background: #ffd2ce;
					border: 1px solid #e0b9b5;
					span {
						color: #d31100;
					}
					p {
						color: #e05d51;
					}
				`
		}
	}}
`
