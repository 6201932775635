import * as S from './styles'

//types
import { TextAreaProps } from './types'
import { Info } from '../Info'

const TextArea = ({
	label,
	placeholder = ' ',
	flex = 1,
	adornment,
	floatingLabel,
	errorMessage,
	disabled = false,
	showMaxLength,
	maxLength,
	value,
	info,
	...rest
}: TextAreaProps) => {
	return (
		<S.Container flex={flex}>
			<S.FloatingLabelContainer>
				<S.FloatingLabel>{floatingLabel}</S.FloatingLabel>

				{info?.title && <Info title={info.title} text={info.text} />}
			</S.FloatingLabelContainer>

			<S.InputContainer hasError={!!errorMessage} disabled={disabled} hasValue={!!value}>
				<S.TextArea
					placeholder={placeholder}
					maxLength={maxLength}
					value={value}
					disabled={disabled}
					contentEditable
					suppressContentEditableWarning={true}
					{...rest}
				/>

				{adornment}
			</S.InputContainer>

			{errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}

			{maxLength && showMaxLength && (
				<S.MaxLength>
					{value?.toString().length || 0}/{maxLength}
				</S.MaxLength>
			)}
		</S.Container>
	)
}

export { TextArea }
