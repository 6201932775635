import React, { useCallback } from 'react'

import { IProps } from './types'
import * as S from './styles'

import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { logout } from '../../../../utils/session'
import { ERoutes } from '../../../../routes/routes'

import about from '../../../../assets/icons/about.svg'
import { EAboutType } from '../../../AboutContentBox/types'

export default function SideNav({
	isOpen,
	handleOpenSideNav,
	routes
}: IProps): JSX.Element {
	const navigate = useNavigate()
	const location = useLocation()

	const handleLogout = useCallback(() => {
		const { LOGIN } = ERoutes

		logout()
		navigate(LOGIN)
	}, [navigate])

	return (
		<S.Wrapper isOpen={isOpen}>
			<div className='background' onClick={handleOpenSideNav} />
			<S.Menu>
				<S.Spacer />
				<S.NavigationWrapper>
					<nav>
						<S.Content>
							{routes.map((route) => (
								<div key={route.path}>
									<S.RouteWrapper
										isActive={route.path === location.pathname}
										className='routes-wrapper'
									>
										<div>
											<NavLink className='nav-link' to={route.path}>
												<p>{route.title}</p>
											</NavLink>
										</div>
									</S.RouteWrapper>
								</div>
							))}
						</S.Content>

						{!location.pathname.includes('admin') && (
							<S.AboutContainer>
								<NavLink
									className='nav-link'
									to={ERoutes.SELLER_ABOUT.replace(':type', EAboutType.HOME)}
								>
									<img src={about} alt='' color='#a3a3a3' />
									<p>{'Ajuda'}</p>
								</NavLink>
							</S.AboutContainer>
						)}

						<div>
							<S.LoggoutSeparator />
							<button onClick={handleLogout} className='bottom-part'>
								Sair
							</button>
						</div>
					</nav>
				</S.NavigationWrapper>
			</S.Menu>
		</S.Wrapper>
	)
}
