import styled, { css } from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'
export const Container = styled.div<{ flex: number }>`
	flex: ${({ flex }) => flex};
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 85px;
	:has(textarea:focus) {
		span {
			color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
		}
	}
`

export const FloatingLabel = styled.label`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

export const ErrorMessage = styled.p`
	margin-top: 12px;
	margin-bottom: 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.RED_ERROR};
`

export const TextArea = styled(TextareaAutosize)`
	width: 100%;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	outline: none;
	border: none;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	background: transparent;
	resize: vertical;

	::placeholder {
		color: ${({ theme }) => theme.COLORS.MEDIUM_GRAY};
	}
`

export const InputContainer = styled.div<{
	hasError?: boolean
	disabled?: boolean
	hasValue?: boolean
}>`
	width: 100%;
	min-height: 50px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
	border-radius: 26px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 8px 16px;

	:has(textarea:not([disabled focus])) {
		:hover {
			background: ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
			border: 1px solid ${({ theme }) => theme.COLORS.LIGHT_GRAY};
		}
	}

	:has(textarea:focus) {
		border-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	}

	${({ theme, hasError }) =>
		hasError &&
		css`
			border-color: ${theme.COLORS.RED_ERROR} !important;
		`}

	${({ theme, disabled }) =>
		disabled &&
		css`
			background: #ebebeb;
			border: 1px solid #cccccc;

			${TextArea} {
				color: ${theme.COLORS.MEDIUM_GRAY} !important;
			} ;
		`};

	${({ hasValue }) =>
	hasValue &&
	css`
		border: solid 1px ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
	`}
`

export const Eye = styled.img`
	width: 20px;
	cursor: pointer;

	:active {
		opacity: 0.6;
	}
`

export const MaxLength = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	text-align: right;
	color: ${({ theme }) => theme.COLORS.MEDIUM_GRAY};
	position: absolute;
	bottom: -20px;
	right: 0;
`

export const FloatingLabelContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	height: 22px;
`
