import { Fragment } from 'react'

import * as S from './styles'
import { IPageProps } from './types'

//components
import { Button } from '../../components'
import { DeliveryAreasTable } from '../../components/DeliveryAreasTable'
import { CreateModal } from './components/CreateModal'
import { DeleteModal } from './components/DeleteModal'
import { Container } from '../../styles'

function CreateDeliveryAreasView({
	isLoading,
	isLoadingCreate,
	isLoadingDelete,
	isLoadingUpdate,
	deliveryAreas,
	openCreateModal,
	handleOpenCreateModal,
	handleConfirmCreateModal,
	deliveryAreaId,
	openDeleteModal,
	handleOpenDeleteModal,
	handleConfirmDeleteModal
}: IPageProps) {
	return (
		<Fragment>
			<DeleteModal
				open={openDeleteModal}
				isLoading={isLoadingDelete}
				handleClose={() => handleOpenDeleteModal()}
				handleConfirm={handleConfirmDeleteModal}
			/>
			<CreateModal
				id={deliveryAreaId}
				isEdit={!!deliveryAreaId}
				open={openCreateModal}
				isLoading={isLoadingCreate || isLoadingUpdate}
				handleClose={() => handleOpenCreateModal()}
				handleConfirm={handleConfirmCreateModal}
			/>
			<Container>
				<S.Wrapper>
					<S.Head>
						<S.PageTitle>Área de Entrega</S.PageTitle>

						<Button
							label='Cadastrar Distância'
							onClick={() => handleOpenCreateModal()}
						/>
					</S.Head>
					<S.Body>
						<DeliveryAreasTable
							areas={deliveryAreas}
							handleOpenEditModal={handleOpenCreateModal}
							handleOpenDeleteModal={handleOpenDeleteModal}
						/>
					</S.Body>
				</S.Wrapper>
			</Container>
		</Fragment>
	)
}

export { CreateDeliveryAreasView }
