import { IDelivery } from './products'
import { IShippingAddress, IStore } from './seller'
import { IUser } from './user'
export interface IOrder {
	quantity: number
	productID: string
	amount: number
	name: string
	photo: string
}
export enum EOrderStatus {
	PENDING = 'PENDING', // PENDENTE (PAGAMENTO)
	PAID = 'PAID', // PAGO
	PRODUCTION = 'PRODUCTION', // EM PRODUÇAO
	ON_ROUTE = 'ON_ROUTE', // EM ROTA (A CAMINHO)
	DELIVERED = 'DELIVERED', // ENTREGUE
	CANCELED = 'CANCELED', // CANCELADO
	FAILED = 'FAILED' // FALHA NO PAGAMENTO
}

export interface ISellerOrdersResponse {
	_id: string
	status: EOrderStatus
	paymentMethod: string
	shippingAddress: IShippingAddress
	delivery: IDelivery
	amount: number
	items: IOrder[]
	store: string
	customer: string
	updatedAt: string
	createdAt: string
	netPrice: number
	code: number
}

export interface IOrderResponse
	extends Omit<ISellerOrdersResponse, 'customer'> {
	customer: IUser
	orderID: string
	card?: any
	chargeID?: string
	message?: string
}

export interface IChangeOrderStatusRequest {
	id: string
	status: EOrderStatus
	message?: string
}
export interface IAdminOrdersResponse {
	_id: string
	status: EOrderStatus
	paymentMethod: string
	shippingAddress: IShippingAddress
	delivery: IDelivery
	amount: number
	items: IOrder[]
	store: IStore
	customer: string
	updatedAt: string
	createdAt: string
	netPrice: number
	code: number
}

export interface IReceivableResponse {
	currency: string
	available_amount: number
	waiting_funds_amount: number
	transferred_amount: number
	recipient: object
}

export interface IFetchRequest {
	maxItemsInPage: number
	pageIndex: number
	order: number
	searchString: string
	sort: string
	date: Date | undefined
}
