import styled from 'styled-components'
import bg from '../../assets/background-pattern.png'

export const Container = styled.div`
	padding: 32px 0px;
	display: flex;
	flex-direction: column;
`

export const Header = styled.div`
	width: 100%;
	height: 232px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: center;
	padding: 0 50px;
	background-image: url(${bg});
	background-position: right;
	background-size: 300px;
	background-repeat: no-repeat;
	margin-bottom: 48px;
`

export const Row = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
`

export const HelpText = styled.p`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const AboutContainer = styled.div`
	width: 100%;
	padding: 0 135px;
`
