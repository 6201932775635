export enum PushNotificationStatus {
	DRAFT = 'DRAFT',
	PROCESSING = 'PROCESSING',
	SENT = 'SENT'
}

export interface INotificationProductsResponse {
	_id: string
	imagesURL: string[]
	name: string
	type: string
}

export interface INotificationSellersResponse {
	_id: string
	photoURL: string
	name: string
}

export interface INotificationCitysResponse {
	label: string
	value: {
		city: string
		uf: string
	}
}

export interface INotificationSendResponse {
	reason: string
	title: string
	body: string
	cities: {
		city: string
		uf: string
	}[]
	recipients: {
		buyerId: string
		pushToken: string
		read: boolean
	}

	totalRecipientsViaFCM: number
	type: string
	status: PushNotificationStatus
	store?: string
	product?: string
	sentAt: string
	_id: string
	fcmGroupId: string
	createdAt: string
	updatedAt: string
	__v: number
}

export interface INotificationSaveDraftResponse {
	reason: string
	title: string
	body: string
	cities: string[]
	recipients: {
		buyerId: string
		pushToken: string
		read: boolean
	}

	totalRecipientsViaFCM: number
	type: string
	status: PushNotificationStatus
	store?: string
	product?: string
	sentAt: string
	_id: string
	fcmGroupId: string
	createdAt: string
	updatedAt: string
	__v: number
}

export interface INotificationSendRequest {
	_id?: string
	reason: string
	title: string
	body: string
	type: string
	store?: string
	product?: string
	cities: {
		city: string
		uf: string
	}[]
}

export interface INotificationSaveDraftRequest {
	_id?: string
	reason: string
	title: string
	body: string
	type: string
	store?: string
	product?: string
	cities: {
		city: string
		uf: string
	}[]
}
