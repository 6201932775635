import styled from 'styled-components'

export const Container = styled.div<{ isOpen: boolean }>`
	transition: all 0.5s;
	width: 100%;
	height: ${({ isOpen }) => (isOpen ? '160px' : '0px')};
	background: #f0f0f0;
	z-index: 2;
	box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.08);
	display: flex;
	overflow: hidden;
	flex-direction: column;
	box-sizing: border-box;
	justify-content: center;
	padding: 0 25px;
`

export const FiltersContainer = styled.div`
	width: 100%;
	display: flex;
	gap: 16px;
	align-items: center;

	> button {
		margin-top: 25px;
	}

	label {
		width: min-content;
	}
`

export const Reset = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	margin-top: 25px;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	cursor: pointer;
`
