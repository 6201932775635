import React, { useMemo } from 'react'
import * as S from './styles'

//components
import { Button } from '../../components'
import { SearchInput } from '../../components/SearchInput'
import CategoryAccordion from '../../components/CategoryAccordion'

//types
import { IPageProps } from './types'

function ListProductsView({
	moveToRegister,
	products,
	refetch,
	searchQuery,
	setSearchQuery,
	isLoading
}: IPageProps) {
	const renderItems = useMemo(() => {
		if (searchQuery && searchQuery.trim() && products && products.length) {
			const filter = products?.filter((item) => {
				const productName = item.products.map((prod) => prod.name.toLowerCase())

				return productName.join(' ').includes(searchQuery.toLowerCase())
			})

			return filter
		}

		return products
	}, [products, searchQuery])

	return (
		<S.Container>
			<S.Head>
				<S.SearchContainer>
					<S.Title>Produtos</S.Title>

					<SearchInput
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
				</S.SearchContainer>

				<Button label='Novo Produto' onClick={moveToRegister} />
			</S.Head>

			<S.Body>
				{renderItems?.map(({ category, products }) => (
					<CategoryAccordion
						key={category._id}
						category={category}
						products={products}
						refetch={refetch}
						searchQuery={searchQuery}
					/>
				))}
			</S.Body>
		</S.Container>
	)
}

export { ListProductsView }
