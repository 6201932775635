import styled, { css } from 'styled-components'

export const Container = styled.div`
	width: 100%;
	position: relative;
`

export const Table = styled.table`
	width: 100%;
	border-spacing: 8px;
	border-collapse: collapse;
	/* display: block;
	overflow-x: auto;
	white-space: nowrap;

	tbody,
	thead {
		display: table;
		width: 100%;
	} */
`

export const TableRow = styled.tr`
	justify-content: center;
	align-self: center;
	height: 70px;
	width: 100%;
	padding: 0 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-bottom: 2px solid ${({ theme }) => theme.COLORS.GRAYISH_WHITE};
`

export const TableHeader = styled.tr`
	height: 70px;
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	padding: 0 24px;
`

export const HeaderPadding = styled.th`
	background: ${({ theme }) => theme.COLORS.OFF_WHITE};
	width: 16px;
`

export const BodyPadding = styled.td`
	width: 16px;
`

export const TableHeaderItem = styled.th`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

export const ItemsContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: flex-start;
	margin: 0;
	a {
		display: flex;
		margin: 0px;
		margin-right: 16px;
		justify-content: flex-end;
	}
`

export const TableItem = styled.td`
	justify-content: center;
	align-items: center;
	height: 100%;
	min-width: 130px;
`

export const Icons = styled.img`
	margin: auto;
	cursor: pointer;

	:active {
		opacity: 0.6;
	}
`

export const TableContainer = styled.div`
	background: ${({ theme }) => theme.COLORS.WHITE};
	overflow: auto;
`

export const TableTitle = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
		color: ${theme.COLORS.DARK_GRAFFITI};
	`}
`

export const TableBody = styled.div`
	display: flex;
	width: 100%;
`

export const TableSentStatus = styled.div`
	${({ theme }) => css`
		padding: 2px 8px;
		background-color: ${theme.COLORS.GREEN_POSITIVE};
		border-radius: 11px;
	`}
`

export const TableSentText = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: ${theme.COLORS.WHITE};
	`}
`

export const TableDraftStatus = styled.div`
	${({ theme }) => css`
		padding: 2px 8px;
		background-color: ${theme.COLORS.LIGHT_GRAY};
		border-radius: 11px;
	`}
`

export const TableDraftText = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: ${theme.COLORS.DARK_GRAFFITI};
	`}
`

export const TableStatusContainer = styled.div`
	${({ theme }) => css`
		display: flex;
		padding: 35px 35px 35px 0px;
		justify-content: flex-start;
	`}
`

export const TableEditIcon = styled.div`
	display: flex;
	position: absolute;
`

export const EditButton = styled.div`
	display: flex;
`
