import { Text } from './styles'
import { ElementsType, TypographyProps } from './types'

const elements = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	p1: 'p',
	p2: 'p'
}

const Typography = ({
	as = 'h1',
	color = 'grafitti',
	children,
	...rest
}: TypographyProps) => {
	const htmlElement = elements[as!] as ElementsType

	return (
		<Text as={htmlElement} type={as} color={color} {...rest}>
			{children}
		</Text>
	)
}

export { Typography }
