import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactNode } from 'react'

const client = new QueryClient()

function QueryProvider({ children }: { children: ReactNode }) {
	return <QueryClientProvider client={client}>{children}</QueryClientProvider>
}

enum EQueryKeys {
	CREATE_SELLER = 'create-seller',
	LOGIN = 'login',
	RESET_PASSWORD = 'reset-password',
	PRODUCTS = 'products',
	REGISTER_PRODUCT = 'register-product'
}

export { QueryProvider, EQueryKeys }
