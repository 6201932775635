import React from 'react'
import { styled as MUIStyled } from '@mui/material/styles'
import {
	Checkbox as MUICheckbox,
	CheckboxProps as MUICheckboxProps
} from '@mui/material'
import { defaultTheme } from '../../styles/defaultTheme'

export const Checkbox = (props: MUICheckboxProps) => {
	return (
		<MUICheckbox
			sx={{
				'&:hover': { bgcolor: 'transparent' }
			}}
			disableRipple
			color='default'
			checkedIcon={<CheckedIcon />}
			icon={<Icon />}
			inputProps={{ 'aria-label': 'Checkbox demo' }}
			{...props}
		/>
	)
}

const Icon = MUIStyled('span')({
	borderRadius: 4,
	width: 18,
	height: 18,
	boxShadow:
		'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: defaultTheme.COLORS.WHITE,
	'.Mui-focusVisible &': {
		outline: `2px auto ${defaultTheme.COLORS.MEDIUM_GRAY}`,
		outlineOffset: 2
	},
	'input:hover ~ &': {
		backgroundColor: defaultTheme.COLORS.GRAYISH_WHITE
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		backgroundColor: defaultTheme.COLORS.LIGHT_GRAY
	}
})

const CheckedIcon = MUIStyled(Icon)({
	backgroundColor: defaultTheme.COLORS.PRIMARY_COLOR,
	'&:before': {
		display: 'block',
		width: 18,
		height: 18,
		backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
		content: '""'
	},
	'input:hover ~ &': {
		backgroundColor: defaultTheme.COLORS.PRIMARY_COLOR
	}
})
