import { Info } from '../Info'
import * as S from './styles'

//types
import { IRadioTextProps } from './types'

function RadioText({
	value,
	name,
	label,
	id,
	selected,
	info,
	...rest
}: IRadioTextProps) {
	const checked = value === selected

	return (
		<S.Container htmlFor={id}>
			<S.RadioContainer checked={checked}>
				<S.Radio
					type={'radio'}
					name={name}
					value={value}
					checked={checked}
					id={id}
					
					{...rest}
				/>
			</S.RadioContainer>
			<S.CardLabel>{label}</S.CardLabel>
			{info && <Info title={info.title} text={info.text} />}
		</S.Container>
	)
}

export { RadioText }
