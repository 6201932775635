import { createElement, useEffect, useState } from 'react'
import { AdminOrdersView } from './view'

//types
import { IFilter, IPageProps } from './types'
import { useListOrders } from '../../services/orders.service'
import { IPaginationResponse } from '../../types/pagination'
import { IAdminOrdersResponse } from '../../types/orders'
import { parseISO } from 'date-fns'
import {
	EAdminOrder,
	EAdminOrderStatus
} from '../../components/AdminOrdersList/types'

function AdminOrders() {
	const [searchQuery, setSearchQuery] = useState('')
	const [pageIndex, setPageIndex] = useState(1)
	const [filter, setFilter] = useState<IFilter>({
		sort: {
			by: 'createdAt',
			order: 'desc'
		},
		status: undefined,
		page: 1,
		date: ''
	})

	const { data } = useListOrders({
		maxItemsInPage: 10,
		pageIndex: pageIndex,
		searchString: searchQuery,
		order: filter.sort.order === 'asc' ? 1 : -1,
		sort: filter.sort.by,
		date: filter.date ? parseISO(filter.date) : undefined,
		status: filter.status
			? EAdminOrder[filter.status as EAdminOrderStatus]
			: undefined
	})

	const [orders, setOrders] = useState<
		IPaginationResponse<IAdminOrdersResponse> | undefined
	>(undefined)

	useEffect(() => {
		if (!data) return
		setOrders(data)
	}, [data])

	function handleChangePage(page: number) {
		setPageIndex(page)
	}

	const pageProps: IPageProps = {
		searchQuery,
		setSearchQuery,
		orders,
		handleChangePage,
		filter,
		setFilter
	}

	return createElement(AdminOrdersView, pageProps)
}

export { AdminOrders }
