import React from 'react'
import * as S from './styles'

//components
import { Button } from '../../components'
import { ControlledInputText } from '../../components/InputText/controlled'

//icons
import logo from '../../assets/icons/harv-logo.svg'
import whats from '../../assets/icons/whatsapp.svg'
import insta from '../../assets/icons/instagram.svg'

//types
import { IPageProps } from './types'
import { ERoutes } from '../../routes/routes'
import { navItems } from '../../components/About'
import { Link } from 'react-router-dom'

function LoginView({
	control,
	errors,
	loading,
	handleSignIn,
	goToCreateSeller
}: IPageProps) {
	return (
		<S.Container>
			<S.content>
				<S.About>
					<img src={logo} alt='logo' />

					<h1>Cadastre-se</h1>

					<p>
						Já pensou em anunciar seus produtos ou serviços em um social
						shopping on-line de vídeos, podendo compartilhar e vender para
						milhares de pessoas? Na Harv isso é possível! Cadastre-se agora e
						conecte-se com os compradores próximos de sua região.
					</p>

					<Button label='Criar conta' onClick={goToCreateSeller} />
				</S.About>

				<S.Form>
					<h2>Acessar conta</h2>

					<S.Row>
						<ControlledInputText
							name={'email'}
							control={control}
							floatingLabel='E-mail'
							placeholder='E-mail válido'
						/>
					</S.Row>

					<S.Row>
						<ControlledInputText
							control={control}
							name={'password'}
							floatingLabel={'Informe sua Senha'}
							placeholder={'Senha'}
							type={'password'}
						/>
					</S.Row>

					<S.RecoverPassword to={ERoutes.RESET_PASSWORD}>
						Recuperar Senha
					</S.RecoverPassword>

					<Button
						label='Entrar'
						type='submit'
						onClick={handleSignIn}
						loading={loading}
					/>
				</S.Form>
			</S.content>

			<S.Footer>
				{React.Children.toArray(
					navItems.map(({ label, name }) => (
						<Link to={ERoutes.EXTERNAL_ABOUT.replace(':type', name)}>
							{label}
						</Link>
					))
				)}

				<S.Social>
					<a
						href='https://api.whatsapp.com/send?phone=5519999729216&text=Ol%C3%A1!%20Vim%20atrav%C3%A9s%20da%20plataforma%20Harv%2C%20gostaria%20de%20tirar%20algumas%20d%C3%BAvidas%20'
						target={'_blank'}
						rel={'external noreferrer'}
					>
						<img src={whats} alt='whatsapp' />
					</a>
					<a
						href='https://www.instagram.com/harv_brasil/'
						target={'_blank'}
						rel={'external noreferrer'}
					>
						<img src={insta} alt='instagram' />
					</a>
				</S.Social>
			</S.Footer>
		</S.Container>
	)
}

export { LoginView }
