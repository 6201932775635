// Import the functions you need from the SDKs you need

import * as firebase from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyCwEidQ500YgyNHv0vZYA2UMAyve5afWXk',
	authDomain: 'harv-362316.firebaseapp.com',
	projectId: 'harv-362316',
	storageBucket: 'harv-362316.appspot.com',
	messagingSenderId: '891375024809',
	appId: '1:891375024809:web:37282f50cda9ce81dd87b8',
	measurementId: 'G-7FVD1CJCY0'
}

// Initialize Firebase

const app = firebase.initializeApp(firebaseConfig)
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app)
