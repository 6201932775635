//views
import { ResetPassword } from '../views/ResetPassword'
import { EmailActivation } from '../views/EmailActivation'
import { ChangePassword } from '../views/ChangePassword'
import { CreateSeller } from '../views/CreateSeller'
import { CreateSeller as AcceptTerms } from '../views/AcceptTerms'
import { SuccessRegistration } from '../views/SuccessRegistration'
import { AdminSellers } from '../views/AdminSellers'
import { Product } from '../views/Product'
import { IRoute, UserTypesEnum } from './types'
import { ListDeliveryAreas } from '../views/ListDeliveryAreas'
import { AdminSellerDetails } from '../views/AdminSellerDetails'
import { ListProducts } from '../views/ListProducts'
import { AdminProducts } from '../views/AdminProducts'
import { AdminProductDetails } from '../views/AdminProductDetails'
import { AdminOrders } from '../views/AdminOrders'
import { Orders } from '../views/Orders'
import { EditSeller } from '../views/EditSeller'
import { Profile } from '../views/EditSeller/profile'
import { RedirectToProfile } from './redirect'
import { SellerAbout } from '../views/SellerAbout'
import { ExternalAbout } from '../views/ExternalAbout'
import { BankAccount } from '../views/EditSeller/bankAccount'
import { OrderDetails } from '../views/OrderDetails'
import { Login } from '../views/Login'
import { SellerRegistrationConfirmation } from '../views/SellerRegistrationConfirmation'
import { Share } from '../views/Share'
import { CreateNotification } from '../views/CreateNotification'
import { AdminNotifications } from '../views/AdminListNotifications'

enum ERoutes {
	LOGIN = '/login',
	RESET_PASSWORD = '/seller/reset-password',
	SUCCESS_REGISTRATION = '/seller/success-registration',
	CREATE_SELLER = '/seller/create-seller',
	CREATE_SELLER_WITH_TOKEN = '/seller/create-seller/:token',
	CREATE_SELLER_ACCEPT_TERMS = '/seller/create-seller/accept-terms',
	CHANGE_PASSWORD = '/seller/change-password/:resetToken',
	EMAIL_ACTIVATION = '/seller/email-activation/:activateToken',
	APP_EMAIL_ACTIVATION = '/app/email-activation/:activateToken',
	PRODUCTS = '/seller/products',
	LIST_DELIVERY_AREAS = '/seller/list-delivery-areas',
	EDIT_SELLER = '/seller/changes/',
	SELLER_ABOUT = '/seller/about/:type',
	EXTERNAL_ABOUT = '/about/:type',
	ADMIN_SELLERS_LIST = '/admin/sellers',
	ADMIN_SELLER_DETAILS = '/admin/seller/:id',
	ADMIN_PRODUCTS_LIST = '/admin/products',
	ADMIN_PRODUCT_DETAILS = '/admin/product/:id',
	ADMIN_ORDERS_LIST = '/admin/orders',
	ADMIN_ORDER_DETAILS = '/admin/order/:id',
	CREATE_PRODUCT = '/seller/create/:type',
	EDIT_PRODUCT = '/seller/edit/:id',
	SELLER_ORDERS = '/seller/orders',
	SELLER_ORDER_DETAILS = '/seller/order/:id',
	SELLER_REGISTRATION_CONFIRMATION = '/seller/registrantion-confirmation-code',
	SHARE = '/share/:type/:id',
	LIST_NOTIFICATIONS = '/notification/list',
	CREATE_NOTIFICATION = '/notification/create',
	EDIT_NOTIFICATION = '/notification/edit/:id'
}

const routes: IRoute[] = [
	{
		path: ERoutes.LOGIN,
		element: Login,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.RESET_PASSWORD,
		element: ResetPassword,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.SUCCESS_REGISTRATION,
		element: SuccessRegistration,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.CREATE_SELLER,
		element: CreateSeller,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.CREATE_SELLER_WITH_TOKEN,
		element: CreateSeller,
		secure: false
	},
	{
		path: ERoutes.CREATE_SELLER_ACCEPT_TERMS,
		element: AcceptTerms,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.SELLER_REGISTRATION_CONFIRMATION,
		element: SellerRegistrationConfirmation,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.CHANGE_PASSWORD,
		element: ChangePassword,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.EMAIL_ACTIVATION,
		element: EmailActivation,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.APP_EMAIL_ACTIVATION,
		element: EmailActivation,
		secure: false,
		layout: false
	},

	{
		path: ERoutes.PRODUCTS,
		element: ListProducts,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.SELLER],
		menu: true,
		title: 'Produtos'
	},
	{
		path: ERoutes.LIST_DELIVERY_AREAS,
		element: ListDeliveryAreas,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.SELLER],
		menu: true,
		title: 'Área de Entrega'
	},

	{
		path: ERoutes.EDIT_SELLER,
		element: EditSeller,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.SELLER],
		title: 'Editar perfil',
		children: [
			{
				path: 'profile',
				element: Profile,
				secure: true
			},
			{
				path: 'bank-account',
				element: BankAccount,
				secure: true
			},
			{
				path: '',
				element: RedirectToProfile,
				secure: true
			}
		]
	},

	{
		path: ERoutes.SELLER_ABOUT,
		element: SellerAbout,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.SELLER]
	},
	{
		path: ERoutes.EXTERNAL_ABOUT,
		element: ExternalAbout,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.ADMIN_SELLERS_LIST,
		element: AdminSellers,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.ADMIN],
		menu: true,
		title: 'Sellers'
	},
	{
		path: ERoutes.ADMIN_PRODUCTS_LIST,
		element: AdminProducts,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.ADMIN],
		menu: true,
		title: 'Produtos'
	},
	{
		path: ERoutes.ADMIN_SELLER_DETAILS,
		element: AdminSellerDetails,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.ADMIN]
	},
	{
		path: ERoutes.ADMIN_PRODUCT_DETAILS,
		element: AdminProductDetails,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.ADMIN]
	},
	{
		path: ERoutes.ADMIN_ORDERS_LIST,
		element: AdminOrders,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.ADMIN],
		menu: true,
		title: 'Pedidos'
	},
	{
		path: ERoutes.ADMIN_ORDER_DETAILS,
		element: OrderDetails,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.ADMIN]
	},
	{
		path: ERoutes.CREATE_PRODUCT,
		element: Product,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.SELLER]
	},
	{
		path: ERoutes.EDIT_PRODUCT,
		element: Product,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.SELLER]
	},
	{
		path: ERoutes.SELLER_ORDERS,
		element: Orders,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.SELLER],
		menu: true,
		title: 'Pedidos'
	},
	{
		path: ERoutes.SELLER_ORDER_DETAILS,
		element: OrderDetails,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.SELLER]
	},
	{
		path: ERoutes.SHARE,
		element: Share,
		secure: false,
		layout: false
	},
	{
		path: ERoutes.LIST_NOTIFICATIONS,
		element: AdminNotifications,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.ADMIN],
		menu: true,
		title: 'Notificações'
	},
	{
		path: ERoutes.CREATE_NOTIFICATION,
		element: CreateNotification,
		secure: true,
		layout: true,
		accessType: [UserTypesEnum.ADMIN],
		menu: false,
		title: 'Criar Notificações'
	}
]

export { routes, ERoutes }
