import React, { useEffect, useMemo, useState } from 'react'
import * as S from './styles'

//icons
import filterOff from '../../assets/icons/filter.svg'
import filterOn from '../../assets/icons/filter-on.svg'
import options from '../../assets/icons/options.svg'

//types
import { IListItem, ISellerList } from './types'

//components
import { Status } from '../Status'
import { ColumnHead } from './ColumnHead'
import { ListPagination } from '../ListPagination'
import { Typography } from '../Typography'
import { StatusSelector } from '../StatusSelector'
import { Filter } from './Filter'
import { Button } from '../Button'

//hooks

//utils
import { parseItemPrice, toNumber } from '../../utils/currency'
import { useNavigate } from 'react-router-dom'
import { deliveryDate } from '../../utils/date'
import { ERoutes } from '../../routes/routes'
import { OrderStatus } from '../../views/Orders/types'

function SellerOrderList({
	orders,
	refetch,
	isLoading,
	isFetchedAfterMount,
	handleChangePage,
	filter,
	setFilter,
	dateFilter,
	setDateFilter,
	setStatus,
	status,
	totalItemsData
}: ISellerList) {
	const [filterControl, setFilterControl] = useState(0)
	const [isFilterOpen, setIsFilterOpen] = useState(false)

	const tabs = useMemo(() => {
		return [
			{
				name: 'Novos Pedidos',
				value: OrderStatus.PENDING,
				count: totalItemsData?.PENDING
			},
			{
				name: 'Em produção',
				value: OrderStatus.PRODUCTION,
				count: totalItemsData?.PRODUCTION
			},
			{
				name: 'A caminho',
				value: OrderStatus.ON_ROUTE,
				count: totalItemsData?.ON_ROUTE
			},
			{
				value: OrderStatus.DELIVERED,
				name: 'Finalizados',
				disableCount: true
			}
		]
	}, [orders])

	function handleChangeTab(index: number, value: OrderStatus) {
		setFilter({ ...filter })
		setStatus(value)
	}

	function handleOpenAndCloseFilter() {
		setIsFilterOpen(!isFilterOpen)
	}

	function refetchOnChangeTab() {
		if (!isLoading && isFetchedAfterMount) {
			refetch()
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(refetchOnChangeTab)

	return (
		<S.Container>
			<S.TabContainer>
				{React.Children.toArray(
					tabs.map(({ name, count, disableCount, value }, index) => (
						<S.TabItemContainer onClick={() => handleChangeTab(index, value)}>
							<S.TabItem active={status === value ? true : false}>
								{name}
							</S.TabItem>
							{!disableCount && <S.TabCounter>{count}</S.TabCounter>}
						</S.TabItemContainer>
					))
				)}
				<S.ButtonContainer>
					<Button
						label='Atualizar Pedidos'
						onClick={refetch}
						loading={isLoading}
					/>
				</S.ButtonContainer>
			</S.TabContainer>

			<S.TableContainer>
				<S.Table>
					<thead>
						<S.TableHeader>
							<S.HeaderPadding />
							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Pedido'
								value='items'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Data da Compra'
								value='createdAt'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Status'
								value='status'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Entrega'
								value='delivery'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='Valor Total'
								value='amount'
							/>

							<ColumnHead
								filter={filter}
								setFilter={setFilter}
								name='A Receber'
								value='netPrice'
							/>

							<S.TableHeaderItem>
								<S.ItemsContainer>
									<S.Icons
										src={isFilterOpen ? filterOn : filterOff}
										alt=''
										onClick={handleOpenAndCloseFilter}
									/>
								</S.ItemsContainer>
							</S.TableHeaderItem>

							<S.HeaderPadding />
						</S.TableHeader>
					</thead>

					<tr>
						<th colSpan={9}>
							<Filter
								isOpen={isFilterOpen}
								filter={filter}
								setFilter={setFilter}
								setDateFilter={setDateFilter}
								onClick={() => setFilterControl((prev) => ++prev)}
							/>
						</th>
					</tr>

					<tbody>
						{React.Children.toArray(
							orders?.docs.map((props) => <ListItem {...props} />)
						)}
					</tbody>
				</S.Table>
			</S.TableContainer>

			<ListPagination
				showingItems={orders?.docs.length}
				itemsLength={orders?.totalDocs}
				itemsPerPage={orders?.limit}
				onChange={handleChangePage}
			/>
		</S.Container>
	)
}

const ListItem = React.memo(
	({
		status: stt,
		items,
		amount,
		createdAt,
		delivery,
		netPrice,
		code,
		_id
	}: IListItem) => {
		const [status, setStatus] = useState(stt)
		const navigate = useNavigate()
		const product = items[0]

		const date = new Date(createdAt)
		const buyDate = `${date.toLocaleDateString()} - ${date
			.toLocaleTimeString()
			.substring(0, 5)}`

		useEffect(() => {
			setStatus(stt)
		}, [stt, code])

		return (
			<S.TableRow>
				<S.BodyPadding />
				<S.TableItem>
					<S.ItemsContainer>
						<S.PhotoContainer>
							<img src={items[0].photo} alt='' />
						</S.PhotoContainer>
						<S.ProductContent>
							<Typography as={'p2'} color={'grafitti'}>
								{code}
							</Typography>
							<Typography as={'p2'} color={'grafitti'}>
								{product.name}
							</Typography>
						</S.ProductContent>
					</S.ItemsContainer>
				</S.TableItem>

				<S.TableItem>{buyDate}</S.TableItem>

				<S.TableItem>
					<S.ItemsContainer>
						<Status status={status} />

						<StatusSelector
							status={status}
							orderId={_id}
							onChange={(newStatus) => {
								setStatus(newStatus)
							}}
						/>
					</S.ItemsContainer>
				</S.TableItem>

				<S.TableItem>{deliveryDate(delivery)}</S.TableItem>

				<S.TableItem>{parseItemPrice(toNumber(amount.toString()))}</S.TableItem>

				<S.TableItem>
					{netPrice ? parseItemPrice(toNumber(netPrice.toFixed())) : ''}
				</S.TableItem>
				<S.TableItem>
					<S.ItemsContainer>
						<S.Icons
							src={options}
							onClick={() =>
								navigate(ERoutes.SELLER_ORDER_DETAILS.replace(':id', _id))
							}
						/>
					</S.ItemsContainer>
				</S.TableItem>
				<S.BodyPadding />
			</S.TableRow>
		)
	}
)

export { SellerOrderList }
