import { IOpeningHour } from '../types/seller'

export const dayOfWeekLetter = (day: string) => {
	switch (day) {
		case 'Domingo':
			return 'SUNDAY'
		case 'Segunda':
			return 'MONDAY'
		case 'Terça':
			return 'TUESDAY'
		case 'Quarta':
			return 'WEDNESDAY'
		case 'Quinta':
			return 'THURSDAY'
		case 'Sexta':
			return 'FRIDAY'
		case 'Sábado':
			return 'SATURDAY'

		default:
			return ''
	}
}

export const formatBR = (openingHours: IOpeningHour[]) => {
	openingHours?.forEach((openingHour) => {
		if (
			!openingHour.startTime.includes('h') ||
			!openingHour.endTime.includes('h')
		) {
			openingHour.startTime = `${openingHour.startTime}h`
			openingHour.endTime = `${openingHour.endTime}h`
		}

		switch (openingHour.startDay) {
			case 'SUNDAY':
				openingHour.startDay = 'Domingo'
				break
			case 'MONDAY':
				openingHour.startDay = 'Segunda'
				break
			case 'TUESDAY':
				openingHour.startDay = 'Terça'
				break
			case 'WEDNESDAY':
				openingHour.startDay = 'Quarta'
				break
			case 'THURSDAY':
				openingHour.startDay = 'Quinta'
				break
			case 'FRIDAY':
				openingHour.startDay = 'Sexta'
				break
			case 'SATURDAY':
				openingHour.startDay = 'Sábado'
				break

			default:
				return openingHour
		}

		switch (openingHour.endDay) {
			case 'SUNDAY':
				openingHour.endDay = 'Domingo'
				break
			case 'MONDAY':
				openingHour.endDay = 'Segunda'
				break
			case 'TUESDAY':
				openingHour.endDay = 'Terça'
				break
			case 'WEDNESDAY':
				openingHour.endDay = 'Quarta'
				break
			case 'THURSDAY':
				openingHour.endDay = 'Quinta'
				break
			case 'FRIDAY':
				openingHour.endDay = 'Sexta'
				break
			case 'SATURDAY':
				openingHour.endDay = 'Sábado'
				break

			default:
				return openingHour
		}
	})
	return openingHours
}

export default function formatOpeningHour(openingHour: IOpeningHour) {
	const startDay = openingHour.startDay
	const endDay = openingHour.endDay
	return {
		startDay: dayOfWeekLetter(startDay),
		endDay: dayOfWeekLetter(endDay),
		startTime: openingHour.startTime.replace(/h/g, ''),
		endTime: openingHour.endTime.replace(/h/g, '')
	}
}
