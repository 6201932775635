import React, { useMemo } from 'react'
import * as S from './styles'

//types
import { EAboutType } from './types'

import { AboutTexts } from './texts'

function AboutContentBox({
	type,
	isApp = false,
	contentContainerStyle
}: {
	type?: EAboutType
	isApp?: boolean
	contentContainerStyle?: React.CSSProperties
}) {
	const texts = useMemo(() => {
		if (!type)
			return {
				title: '',
				text: ''
			}

		return AboutTexts[type]
	}, [type])

	return (
		<S.AboutContainer>
			<S.AboutHeader>
				<S.Title>{texts.title}</S.Title>
			</S.AboutHeader>

			<S.AboutContentContainer style={contentContainerStyle}>
				<S.AboutContent
					dangerouslySetInnerHTML={{
						__html: isApp ? AboutTexts.app.text : texts.text
					}}
				/>
			</S.AboutContentContainer>
		</S.AboutContainer>
	)
}

export { AboutContentBox }
