import { IDelivery } from '../types/products'
import {
	EDayOfWeek,
	ETimeToDelivery,
	getFixedShippingInfo,
	getRelativeShippingInfo
} from './shipping'

export const deliveryDate = (delivery: IDelivery) => {
	if (delivery?.type === 'WITHDRAW') {
		return 'Retirada no Local'
	}

	if (delivery?.type === 'FIXED') {
		return getFixedShippingInfo({
			dayOfWeek: delivery.weekDay as EDayOfWeek,
			endTime: delivery.endTime,
			startTime: delivery.startTime
		})
	}

	return getRelativeShippingInfo(delivery?.timeToDelivery as ETimeToDelivery)
}

export function formatDateTime(dateString?: string): string {
	if (!dateString) return ''

	const date = new Date(dateString)

	const day = date.getDate().toString().padStart(2, '0')
	const month = (date.getMonth() + 1).toString().padStart(2, '0')
	const year = date.getFullYear()
	const hours = date.getHours().toString().padStart(2, '0')
	const minutes = date.getMinutes().toString().padStart(2, '0')

	return `${day}/${month}/${year} - ${hours}:${minutes}`
}
