import * as S from './styles'

//types
import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Typography } from '../../components/Typography'

const navItems = [
	{ label: 'Informações Perfil', name: 'profile' },
	{ label: 'Dados Bancários', name: 'bank-account' }
]
function EditSelllerView() {
	const { pathname } = useLocation()

	return (
		<>
			<S.ContainerEdit>
				<S.Header>
					<S.Row>
						<Typography as='h2' color='grafitti'>
							Meu Perfil
						</Typography>
					</S.Row>

					<S.Row>
						<S.InfoText>Acesso completo à suas informações.</S.InfoText>
					</S.Row>
				</S.Header>
				<S.EditContainer>
					<S.NavigationLabel>Categorias</S.NavigationLabel>

					<S.NavigationContainer>
						{React.Children.toArray(
							navItems.map(({ label, name }) => {
								const isActive = pathname.includes(name)
								return (
									<Link to={name}>
										<S.NavItem isActive={isActive}>
											<span>{label}</span>
										</S.NavItem>
									</Link>
								)
							})
						)}
					</S.NavigationContainer>
					<S.Container>
						<Outlet />
					</S.Container>
				</S.EditContainer>
			</S.ContainerEdit>
		</>
	)
}

export { EditSelllerView }
