import { Navigate } from 'react-router-dom'
import { ERoutes } from './routes'
export interface RedirectProps {
	isAdmin: boolean
	isLogged: boolean
}

export function RedirectToProfile() {
	return <Navigate to='/seller/changes/profile' />
}

function Redirect({ isAdmin, isLogged }: RedirectProps) {
	if (!isAdmin && isLogged) {
		return <Navigate to={ERoutes.PRODUCTS} />
	}

	if (isAdmin && isLogged) {
		return <Navigate to={ERoutes.ADMIN_SELLERS_LIST} />
	}

	return <Navigate to={ERoutes.LOGIN} />
}

export { Redirect }
