import * as S from './styles'

//icons
import close from '../../assets/icons/close.svg'
import {
	ETypeNotification,
	IModalData
} from '../../views/CreateNotification/types'
import {
	INotificationProductsResponse,
	INotificationSellersResponse,
	INotificationSendRequest
} from '../../types/notifications'
import { ProductCard } from '../ProductCard'
import { StoreCard } from '../StoreCard'
import { Button } from '../Button'

//components
//types
interface INotificationsModalProps {
	isModalOpen: boolean
	handleOpenAndCloseModal: () => void
	type: ETypeNotification | string
	data: IModalData
	product?: INotificationProductsResponse | undefined
	seller?: INotificationSellersResponse | undefined
	handleSendNotification: (payload: INotificationSendRequest) => Promise<void>
	isSending: boolean
}

function NotificationModal({
	handleOpenAndCloseModal,
	isModalOpen,
	product,
	seller,
	data,
	handleSendNotification,
	type,
	isSending
}: INotificationsModalProps) {
	return (
		<S.Container isModalOpen={isModalOpen}>
			<S.Modal>
				<S.MoldaHead>
					<S.ModalTitle>Detalhes da Notificação</S.ModalTitle>
					<img src={close} alt='' onClick={handleOpenAndCloseModal} />
				</S.MoldaHead>

				{seller || product ? (
					<S.CardsWrapper>
						{(seller && type === ETypeNotification.PRODUCT) ||
						type === ETypeNotification.SELLER ? (
							<StoreCard store={seller} />
						) : null}
						{product && type === ETypeNotification.PRODUCT ? (
							<ProductCard product={product} />
						) : null}
					</S.CardsWrapper>
				) : null}

				<S.TextsWrapper>
					<S.ModalSubtitle>Título da notificação</S.ModalSubtitle>
					<S.ModalText>{data.title}</S.ModalText>
				</S.TextsWrapper>
				<S.TextsWrapper>
					<S.ModalSubtitle>Motivo</S.ModalSubtitle>
					<S.ModalText>{data.reason}</S.ModalText>
				</S.TextsWrapper>
				<S.TextsWrapper>
					<S.ModalSubtitle>Cidade</S.ModalSubtitle>
					<S.ModalText>
						{data.cities?.map((city) => {
							return city.label + ', '
						})}
					</S.ModalText>
				</S.TextsWrapper>
				<S.DescriptionWrapper>
					<S.ModalSubtitle>Descrição</S.ModalSubtitle>
					<S.ModalText>{data.description}</S.ModalText>
				</S.DescriptionWrapper>

				<S.ButtonsWrapper>
					<S.CancelButton>
						<S.CancelButtonText onClick={handleOpenAndCloseModal}>
							Cancelar
						</S.CancelButtonText>
					</S.CancelButton>
					<Button
						onClick={() => {
							if (type === 'PROMOTE_PRODUCT') {
								handleSendNotification({
									title: data.title,
									reason: data.reason,
									cities: data.cities?.map((city) => {
										return city.value
									}) || [{ city: '', uf: '' }],
									body: data.description,
									type: type,
									product: product?._id,
									store: seller?._id
								})
							} else if (type === 'PROMOTE_STORE') {
								handleSendNotification({
									title: data.title,
									reason: data.reason,
									cities: data.cities?.map((city) => {
										return city.value
									}) || [{ city: '', uf: '' }],
									body: data.description,
									type: type,
									store: seller?._id
								})
							} else {
								handleSendNotification({
									title: data.title,
									reason: data.reason,
									cities: data.cities?.map((city) => {
										return city.value
									}) || [{ city: '', uf: '' }],
									body: data.description,
									type: type
								})
							}
						}}
						disabled={isSending}
						label='Confirmar'
					/>
				</S.ButtonsWrapper>
			</S.Modal>
		</S.Container>
	)
}

export { NotificationModal }
