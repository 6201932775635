import styled from 'styled-components'

export const Container = styled.div<{ isModalOpen?: boolean }>`
	transition: all 0.2s;
	width: 100vw;
	height: 100vh;
	position: fixed;
	background: rgba(0, 0, 0, 0.56);
	top: 0;
	left: 0;
	display: flex;
	z-index: ${({ isModalOpen }) => (isModalOpen ? 1000 : -1)};
	opacity: ${({ isModalOpen }) => (isModalOpen ? 1 : 0)};
`

export const Modal = styled.div`
	width: 100%;
	max-width: 430px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	margin: auto;
	padding: 40px 24px;
`

export const MoldaHead = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 34px;

	img {
		cursor: pointer;

		:active {
			opacity: 0.5;
		}
	}
`

export const ModalTitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin: 0;
`

export const ModalParagraph = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const InputContainer = styled.div`
	width: 100%;
	margin-bottom: 40px;
`

export const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 40px;

	button {
		justify-self: center;
	}
`

export const Keep = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	cursor: pointer;
	user-select: none;
`
