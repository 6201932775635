import styled, { css } from 'styled-components'
import { transition } from '../../../../styles/function'
import { RouteWrapperProps } from './types'

const navWidth = 230

export const Wrapper = styled.div<{ isOpen: boolean }>`
	${({ theme, isOpen }) => css`
		.background {
			position: fixed;
			z-index: 10;

			${transition()};
		}

		.menu-wrapper {
			.spacer {
			}

			.nav-wrapper {
				display: flex;
				flex-direction: column;
				flex: 1;
				width: ${navWidth}px;
				transform: translateX(-${navWidth}px);
				overflow-y: auto;
				${transition()};

				nav {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					background: ${theme.COLORS.DARK_GRAFFITI};

					.upper-part {
						padding: 40px 10px;
					}

					.bottom-part {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 25px;
						width: 100%;
						background: transparent;
						border: none;
						cursor: pointer;
						color: ${theme.COLORS.LIGHT_GRAY};
						font-size: 14px;
						font-family: 'Poppins';
					}

					.nav-bottom {
						li {
							display: flex;
							align-items: center;
							border: none;
							background: none;
							font-size: 14px;
							${transition()};

							&:hover {
								opacity: 0.7;
							}
						}
					}
				}
			}
		}

		${isOpen &&
		css`
			.background {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}

			${Menu} {
				visibility: visible;
			}

			${NavigationWrapper} {
				transform: translateX(0px);
			}
		`}
	`}
`

export const RouteWrapper = styled.div<RouteWrapperProps>`
	${(props) => css`
		height: 50px;
		display: flex;
		align-items: center;
		border-radius: 4px;
		background: ${props.isActive
			? props.theme.COLORS.DARK_GRAY_MENU
			: 'transparent'};
		cursor: pointer;

		div {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		:hover {
			background: #383838;
		}

		.icon-indicator {
			height: 50px;
			width: 3px;
			background: ${props.isActive
				? props.theme.COLORS.PRIMARY_COLOR
				: 'transparent'};
			border-radius: 0px 2px 2px 0px;
		}

		.nav-link {
			color: ${props.isActive
				? props.theme.COLORS.WHITE
				: props.theme.COLORS.MEDIUM_GRAY};
			font-size: 14px;
			text-decoration: none;
			display: flex;
			align-items: center;
			margin-left: 8px;
			width: 100%;
			height: 100%;
		}
	`}
`

export const Spacer = styled.div`
	height: 88px;
`

export const Menu = styled.div<{ isOpen?: boolean }>`
	position: fixed;
	display: flex;
	flex-direction: column;
	z-index: 10;
	top: 0;
	bottom: 0;
	left: 0;
	visibility: hidden;

	${({ isOpen }) =>
		isOpen &&
		css`
			visibility: visible;

			${NavigationWrapper} {
				transform: translateX(0px);
			}
		`}
`

export const NavigationWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		flex: 1;
		width: ${navWidth}px;
		transform: translateX(-${navWidth}px);
		overflow-y: auto;
		${transition()};

		nav {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background: ${theme.COLORS.DARK_GRAFFITI};

			.bottom-part {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 25px;
				width: 100%;
				background: transparent;
				border: none;
				cursor: pointer;
				color: ${theme.COLORS.LIGHT_GRAY};
				font-size: 14px;
				font-family: 'Poppins';
			}

			.nav-bottom {
				li {
					display: flex;
					align-items: center;
					border: none;
					background: none;
					font-size: 14px;
					${transition()};

					&:hover {
						opacity: 0.7;
					}
				}
			}
		}
	`}
`

export const Content = styled.div`
	padding: 40px 10px;
`

export const LoggoutSeparator = styled.div`
	height: 1px;
	width: 100%;
	background: ${({ theme }) => theme.COLORS.DARK_GRAY};
`

export const AboutContainer = styled.div`
	height: 48px;
	display: flex;
	align-items: center;
	padding: 0 9px;
	margin: auto 8px 12px;
	border-radius: 4px;
	cursor: pointer;

	:hover {
		background: #383838;
	}

	a {
		text-decoration: none;
		flex-direction: row;
		display: flex;
		gap: 9px;
	}

	p {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: #a3a3a3;
		text-decoration: none;
	}
`
