import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 7px;
	position: relative;
    z-index: 9;

	img {
		:hover {
			+ div {
				opacity: 1;
				display: flex;
			}
		}
	}
`

export const TextContainer = styled.div`
	transition: all 0.2s;
	width: max-content;
	max-width: 288px;
	padding: 12px 16px;
	background: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	border-radius: 4px;
	position: absolute;
	display: none;
	flex-direction: column;
	opacity: 0;

	:hover {
		opacity: 1;
		display: flex;
	}
`

export const Title = styled.span`
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 14px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.WHITE};
`

export const Text = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
	color: ${({ theme }) => theme.COLORS.WHITE};
`
