import * as S from './styles'

//components
import { Button, AuthHeader } from '../../components'

//icons
import Logo from '../../assets/icon-harv.png'
// import EmailIcon from '../../assets/icons/email-icon.png'

//types
import { IPageProps } from './types'
import { Spinner } from '../../components/Spinner'

function EmailActivationView({ handleNavigate, loading, isApp }: IPageProps) {
	return (
		<>
			<AuthHeader title='Cadastrar Conta' />
			<S.Wrapper>
				<S.Logo>
					<img src={Logo} alt='' />
				</S.Logo>

				{loading ? (
					<S.LoadingWrapper>
						<Spinner size='small' />
					</S.LoadingWrapper>
				) : (
					<>
						<S.Title>E-mail confirmado</S.Title>

						{isApp ? (
							<S.Paragraph>Agora você pode utilizar o app</S.Paragraph>
						) : (
							<S.Paragraph>
								Pronto! Seu e-mail já está ativo. Por enquanto sua solicitação
								de cadastro na plataforma está em andamento.
							</S.Paragraph>
						)}
						{!isApp ? (
							<S.Paragraph>
								Este processo pode demorar alguns minutos, mas em breve você
								será notificado.
							</S.Paragraph>
						) : null}
					</>
				)}

				<S.ButtonWrapper>
					{!isApp ? (
						<Button label='Acessar Minha Conta' onClick={handleNavigate} />
					) : null}
				</S.ButtonWrapper>
			</S.Wrapper>
		</>
	)
}

export { EmailActivationView }
