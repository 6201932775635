import { Dispatch, SetStateAction } from 'react'
import * as S from './styles'

//types
import { IFilter, ISortByValues } from '../SellerListFilter/types'

//icons
import sort from '../../assets/icons/sort.svg'
import sortSelected from '../../assets/icons/sort-selected.svg'

interface IColumnHead {
	name: string
	value: ISortByValues
	filter: IFilter
	setFilter: Dispatch<SetStateAction<IFilter>>
}

function ColumnHead({ name, value, filter, setFilter }: IColumnHead) {
	const { by, order } = filter.sort

	const isSelected = value === by
	const willSpinIcon = isSelected && order === 'desc'

	function handleToggleSort() {
		if (value === by) {
			setFilter({
				...filter,
				sort: { by, order: order === 'asc' ? 'desc' : 'asc' }
			})
			return
		}

		setFilter({
			...filter,
			sort: { by: value, order: 'desc' }
		})
	}

	return (
		<S.TableHeaderItem>
			<S.ItemsContainer>
				{name}
				<S.Sort
					spin={willSpinIcon}
					src={isSelected ? sortSelected : sort}
					onClick={handleToggleSort}
				/>
			</S.ItemsContainer>
		</S.TableHeaderItem>
	)
}

export { ColumnHead }
