import {
	createElement,
	FocusEvent,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import { CreateSellerView } from './view'

//utils
import { yupResolver } from '@hookform/resolvers/yup'
import { getCep, getCities, getState } from '../../utils/handleAddress'
import { createSellerSchema } from './schema'

//hooks
import { FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useStores } from '../../hooks/useStore'

//types
import { ISelectItems } from '../../components/Select/types'
import { ERoutes } from '../../routes/routes'
import {
	IPageProps,
	ICreateSellerForm,
	EAccountType,
	ERegisterType
} from './types'

//utils
import { formatBankCode, getBanks, IBank } from '../../utils/banks'
import { Toaster } from '../../utils/toaster'
import { useDeclineToken } from '../../services/user.service'
import { EGatewayStatus } from '../../types/seller'
import { formatBR } from '../../utils/formatOpeningHour'

function CreateSeller() {
	const [states, setStates] = useState<ISelectItems[]>([])
	const [cities, setCities] = useState<ISelectItems[]>([])
	const [banks, setBanks] = useState<IBank[]>([])

	const { token } = useParams()
	const { createSellerFormStore } = useStores()

	const inputMediaRef = useRef<HTMLFormElement>(null)

	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
		reset,
		resetField
		// trigger
	} = useForm<ICreateSellerForm>({
		resolver: yupResolver(createSellerSchema),
		mode: 'onChange',
		delayError: 1000 * 1, //1 second,
		defaultValues: {
			openingHours: [
				{
					startDay: '',
					endDay: '',
					startTime: '',
					endTime: ''
				}
			]
		}
	})

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'openingHours',
		rules: {
			minLength: 1,
			maxLength: 3
		}
	})

	const { data: sellerData } = useDeclineToken({ token })

	const navigate = useNavigate()

	function goBackToLogin() {
		navigate(ERoutes.LOGIN)
	}

	const submitForm = handleSubmit(async (data: ICreateSellerForm) => {
		if (!data.photo) {
			inputMediaRef.current?.scrollIntoView({ behavior: 'smooth' })
			return Toaster({
				type: 'error',
				title: 'Erro no formulário',
				text: 'A foto do perfil é um campo obrigatório.'
			})
		}

		createSellerFormStore.actions.addCreateSellerForm({
			...data,
			token
		})

		reset()

		navigate(ERoutes.CREATE_SELLER_ACCEPT_TERMS, { replace: true })
	})

	async function searchCEP(e: FocusEvent<HTMLInputElement>) {
		if (e.target.value.length !== 9) return

		const { bairro, localidade, logradouro, uf } = await getCep(e.target.value)

		setValue('address', logradouro)
		setValue('city', localidade)
		setValue('uf', uf)
		setValue('district', bairro)
	}

	function fetchFormFields() {
		getState().then((response) => {
			const parsedStates = response
				.sort((a, b) => {
					return a.sigla.toString().toLowerCase() >
						b.sigla.toString().toLowerCase()
						? 1
						: -1
				})
				.map((state) => ({
					value: state.sigla
				}))

			setStates(parsedStates)
		})

		getBanks().then((response) => {
			const filteredBanks = response.filter((bank) => {
				return bank.code !== null
			})

			const organizedBanks = filteredBanks.sort((a, b) => {
				return a.code < b.code ? -1 : 1
			})
			setBanks(organizedBanks)
		})
	}
	useEffect(fetchFormFields, [])

	const [state] = watch(['uf'])

	function searchCities() {
		if (!state) {
			resetField('city')
		}

		if (state) {
			getCities(state).then((response) => {
				const parsedCities = response.map((state) => ({
					value: state.nome
				}))

				setCities(parsedCities)
			})
		}
	}
	function addOpeningHour(
		fields: FieldArrayWithId<ICreateSellerForm, 'openingHours', 'id'>[]
	) {
		if (fields.length < 3)
			append({
				startDay: '',
				endDay: '',
				startTime: '',
				endTime: ''
			})
	}

	function removeOpeningHour(index: number) {
		remove(index)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(searchCities, [state])

	async function searchByToken() {
		if (token) {
			if (!sellerData) return

			const {
				email,
				firstName,
				lastName,
				personalRegistration,
				phone,
				store: {
					address,
					bio,
					companyName,
					companyRegistration,
					openingHours,
					name,
					type: storeType,
					bankAccount: {
						holderName,
						account,
						agency,
						bankCode,
						accountCheckDigit,
						type
					},
					commercialPhone,
					photoURL: storePhoto
				}
			} = sellerData

			const bank = bankSelectItems.find((item) => {
				return item.value.includes(bankCode.toString())
			})?.value

			state && setValue('uf', state)
			holderName && setValue('account_name', holderName)
			account && setValue('account_number', account)
			agency && setValue('agency', agency)
			bank && setValue('bank', bank)
			personalRegistration && setValue('cpf', personalRegistration)
			email && setValue('email', email)
			firstName && setValue('first_name', firstName)
			lastName && setValue('last_name', lastName)
			phone && setValue('phone', phone)
			// pix && pix.key && setValue('pix_key', pix.key)
			// pix && pix.type && setValue('pix_type', pix.type)
			companyName && setValue('corporate_name', companyName)
			companyRegistration && setValue('document', companyRegistration)
			companyRegistration
				? setValue('account_document', companyRegistration)
				: personalRegistration &&
				  setValue('account_document', personalRegistration)
			name && setValue('store_name', name)
			storePhoto && setValue('photo', storePhoto)
			bio && setValue('store_bio', bio)
			commercialPhone && setValue('commercial_phone', commercialPhone)
			accountCheckDigit && setValue('account_digit', accountCheckDigit) //@ts-ignore
			type && setValue('account_type', EAccountType[type.toUpperCase()])
			storeType && //@ts-ignore
				setValue('registerType', ERegisterType[storeType.toUpperCase()])

			if (address) {
				const {
					city,
					complement,
					neighborhood,
					number,
					postalCode,
					street,
					state
				} = address
				postalCode && setValue('cep', postalCode)
				state && setValue('uf', state)
				street && setValue('address', street)
				number && setValue('number', +number)
				city && setValue('city', city)
				neighborhood && setValue('district', neighborhood)
				complement && setValue('complement', complement)
				openingHours && setValue('openingHours', formatBR(openingHours))
			}
		}
	}

	function populateForm(data: ICreateSellerForm) {
		;(Object.keys(data) as Array<keyof ICreateSellerForm>).forEach((key) => {
			if (key !== 'photo') setValue(key, data[key])
		})
	}

	function checkPersistedForm() {
		if (createSellerFormStore.value) {
			populateForm(createSellerFormStore.value)
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(checkPersistedForm, [])

	useEffect(() => {
		searchByToken()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sellerData, banks])

	const bankSelectItems = useMemo(
		() =>
			banks.map((bank) => ({
				value: formatBankCode(bank.code) + ' - ' + bank.name
			})),
		[banks]
	)

	const isSellerActiveByGateway =
		sellerData?.statusGateway === EGatewayStatus.ACTIVE

	const pageProps: IPageProps = {
		control,
		states,
		cities,
		errors,
		submitForm,
		searchCEP,
		bankSelectItems,
		watch,
		goBackToLogin,
		addOpeningHour,
		removeOpeningHour,
		fields,
		isSellerActiveByGateway,
		inputMediaRef
	}

	return createElement(CreateSellerView, pageProps)
}

export { CreateSeller }
