import styled from 'styled-components'
import { css } from 'styled-components'

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	padding: 48px 0px;
`

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const GoBackContainer = styled.div`
	align-self: flex-start;
	display: flex;
	justify-content: flex-start;
	width: min-content;
	cursor: pointer;
	margin-bottom: 16px;

	:active {
		opacity: 0.8;
	}
`

export const ArrowBack = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`

export const GoBackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const CheckboxLabel = styled.span<{ highlight?: boolean }>`
	${({ theme, highlight = false }) => css`
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 2px;
		color: ${highlight ? theme.COLORS.PRIMARY_COLOR : theme.COLORS.DARK_GRAY};
	`}
`

export const DividingLine = styled.div`
	${({ theme }) => css`
		height: 1px;
		width: 100%;
		background-color: ${theme.COLORS.LIGHT_GRAY};

		margin-bottom: 40px;
	`}
`
