import styled from 'styled-components'

export const Container = styled.div<{ isModalOpen: boolean }>`
	background: rgba(0, 0, 0, 0.56);
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 100;
	display: ${({isModalOpen}) => isModalOpen? 'flex': 'none'};
`

export const Modal = styled.div`
	margin: auto;
	width: 100%;
	max-width: 430px;
	background: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 4px;
	padding: 40px 24px;
`

export const Row = styled.div`
	display: flex;
	margin-bottom: 34px;
`

export const Title = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-right: auto;
`

export const Close = styled.img`
	cursor: pointer;

	:active {
		opacity: 0.6;
	}
`

export const Text = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const ButtonsContainer = styled.div`
	display: flex;
	gap: 40px;
	align-items: center;
	justify-content: center;

	* {
		user-select: none;
		cursor: pointer;

		:active {
			opacity: 0.6;
		}
	}
`

export const Button = styled.button`
	background-color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	color: ${({ theme }) => theme.COLORS.WHITE};
	border-radius: 21px;
	padding: 12px 24px;
	border: none;
`

export const Keep = styled.span`
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
