import styled, { css } from 'styled-components'
import { mediaQuery } from '../../styles/function'

export const Container = styled.div<{ isApp: boolean }>`
	display: grid;
	grid-template-columns: minmax(auto, 200px) auto;
	gap: 16px;

	${mediaQuery('sm')(`
		display: flex;
		flex-direction: column;
    `)}

	${({ isApp, theme }) => {
		return (
			isApp &&
			css`
				background: ${theme.COLORS.WHITE};
				display: flex;

				div {
					margin: 0;
					padding: 0;
				}
			`
		)
	}}
`

export const NavigationLabel = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.COLORS.DARK_GRAY};
	grid-column: 1;
	${mediaQuery('sm')(`
				display: none;
    `)}
`

export const NavigationContainer = styled.div`
	height: min-content;
	width: 100%;
	grid-column: 1;
	border-left: 1px solid #e0e0e0;

	${mediaQuery('sm')(`
		display: flex;
		overflow-y: scroll;
    `)}
`

export const NavItem = styled.div<{ isActive?: boolean }>`
	height: 46px;
	padding: 0 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
	${mediaQuery('sm')(`
				display: flex;
				width: max-content;
    `)}
	:active {
		opacity: 0.8;
	}

	span {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		display: flex;
		align-items: center;
		color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	}

	${({ theme, isActive }) =>
		isActive &&
		css`
			border-left: 2px solid ${theme.COLORS.PRIMARY_COLOR};
		`}

	${({ theme, isActive }) =>
		isActive &&
		css`
			${mediaQuery('sm')(`
				border: none;
				border-bottom: 2px solid ${theme.COLORS.PRIMARY_COLOR};
    `)}
		`}
`
