import { memo, useEffect, useMemo, useState } from 'react'
import * as S from './styles'

// types
import {
	ICategoryAccordionProps,
	IColumnHead,
	IFilterProduct,
	IListItem
} from './types'
import { ERoutes } from '../../routes/routes'

// icons
import trash from '../../assets/icons/trash-icon.svg'
import options from '../../assets/icons/options.svg'
import chevron from '../../assets/icons/chevron-down.svg'
import sortSelected from '../../assets/icons/sort-selected.svg'
import sort from '../../assets/icons/sort.svg'

//components
import { Status } from '../Status'
import { Switch } from '../Switch'
import { Toaster } from '../../utils/toaster'

//hooks
import { useModal } from '../../hooks/useModal'
import { useNavigate } from 'react-router-dom'

//services
import {
	useDeleteProduct,
	useToggleProduct
} from '../../services/products.service'

function CategoryAccordion({
	category,
	products,
	refetch,
	searchQuery
}: ICategoryAccordionProps) {
	const [isAccordionOpen, setIsAccordionOpen] = useState(!!searchQuery)
	const [filter, setFilter] = useState<IFilterProduct>({
		sort: {
			by: 'name',
			order: 'asc'
		}
	})

	function handleToggleAcordeon() {
		setIsAccordionOpen((prev) => !prev)
	}

	const renderItems = useMemo(() => {
		const {
			sort: { by, order }
		} = filter
		let newList = [...products]

		// Sort list
		newList.sort((a, b) => {
			const valA = a[by]?.toString().toLowerCase()
			const valB = b[by]?.toString().toLowerCase()

			if (order === 'asc') {
				return valA.localeCompare(valB)
			}
			if (order === 'desc') {
				return valB.localeCompare(valA)
			}
			return 0
		})

		let filteredList = newList

		if (searchQuery) {
			filteredList = newList.filter((item) =>
				item.name?.toLowerCase().includes(searchQuery.toLowerCase())
			)
		}

		return filteredList
	}, [products, searchQuery, filter])

	useEffect(() => {
		setIsAccordionOpen(!!searchQuery)
	}, [searchQuery])

	return (
		<S.Container
			items={renderItems ? renderItems.length : 0}
			isOpen={isAccordionOpen}
		>
			<S.Head onClick={handleToggleAcordeon}>
				<S.CategoryName>{category.label}</S.CategoryName>
				<S.Counter>{renderItems?.length}</S.Counter>
				<S.Chevron src={chevron} isOpen={isAccordionOpen} />
			</S.Head>
			<S.Body>
				<S.TableContainer>
					<S.Table>
						<thead>
							<S.TableHeader>
								<S.HeaderPadding />

								<ColumnHead
									name='Produto'
									width={60}
									filter={filter}
									value='name'
									setFilter={setFilter}
								/>
								<ColumnHead
									name='Tipo'
									filter={filter}
									value='type'
									setFilter={setFilter}
								/>
								<ColumnHead
									name='Status'
									filter={filter}
									value='status'
									setFilter={setFilter}
								/>
								<ColumnHead
									name='Ativo no Feed?'
									filter={filter}
									value='showInFeed'
									setFilter={setFilter}
								/>

								<S.HeaderPadding />
							</S.TableHeader>
						</thead>

						<tbody>
							{renderItems?.map((item) => (
								<ListItem key={item._id} product={item} refetch={refetch} />
							))}
						</tbody>
					</S.Table>
				</S.TableContainer>
			</S.Body>
		</S.Container>
	)
}

function ColumnHead({ name, width, value, filter, setFilter }: IColumnHead) {
	const { by, order } = filter.sort
	function handleToggleSort() {
		if (value === by) {
			setFilter({
				...filter,
				sort: { by, order: order === 'asc' ? 'desc' : 'asc' }
			})
			return
		}
		setFilter({
			...filter,
			sort: { by: value, order: 'desc' }
		})
	}
	const isSelected = value === by
	const willSpinIcon = isSelected && order === 'desc'
	return (
		<S.TableHeaderItem width={width}>
			<S.ItemsContainer>
				{name}
				<S.Sort
					spin={willSpinIcon}
					src={isSelected ? sortSelected : sort}
					onClick={handleToggleSort}
				/>
			</S.ItemsContainer>
		</S.TableHeaderItem>
	)
}

const ListItem = ({ product, refetch }: IListItem) => {
	const { type, name, status, _id, imagesURL, videoURL, showInFeed } = product

	const [isActive, setIsActive] = useState(showInFeed)

	const navigate = useNavigate()

	const { mutateAsync: deleteAsync } = useDeleteProduct()
	const { mutateAsync: toggleAsync, isLoading } = useToggleProduct()

	const productType = useMemo(() => {
		if (type === 'PRODUCT') return 'Produto'
		if (type === 'SERVICE') return 'Anúncio ou serviço'
		return ''
	}, [type])

	const { Modal, modalProps, toggle } = useModal({
		title: `Excluir ${type === 'PRODUCT' ? 'Produto' : 'Anúncio ou serviço'}`,
		text: `Ao excluir este ${
			type === 'PRODUCT' ? 'Produto' : 'Anúncio ou serviço'
		}, ele não estará mais disponível em sua loja. Deseja confirmar esta ação?`,
		actionButtonLabel: 'Excluir',
		closeButtonLabel: 'Manter',
		onClickActionButton: handleDeleteProduct
	})

	async function handleDeleteProduct() {
		await deleteAsync({ id: _id })

		Toaster({
			type: 'success',
			text: `${
				type === 'PRODUCT' ? 'Produto' : 'Anúncio ou serviço'
			} excluído com sucesso`
		})

		refetch()
		toggle()
	}

	async function handleToggle() {
		if (isLoading) return

		const { showInFeed } = await toggleAsync({ id: _id })

		setIsActive(showInFeed)
	}

	function goToProductDetails() {
		navigate(ERoutes.EDIT_PRODUCT.replace(':id', _id))
	}

	return (
		<>
			<S.TableRow key={_id}>
				<S.BodyPadding />
				<S.TableItem>
					<Modal {...modalProps} />
					<S.ItemsContainer>
						{imagesURL[0] ? (
							<S.ProductImage src={imagesURL[0]} />
						) : (
							<S.ProductVideo src={videoURL} muted={true} />
						)}
						<S.Name>{name}</S.Name>
					</S.ItemsContainer>
				</S.TableItem>

				<S.TableItem>{productType}</S.TableItem>

				<S.TableItem>
					<Status status={status} />
				</S.TableItem>

				<S.TableItem>
					<Switch isActive={isActive} onChange={handleToggle} />
				</S.TableItem>

				<S.TableItem>
					<S.ItemsContainer>
						<S.IconsPadding />
						<S.Icons src={trash} alt='' onClick={toggle} />
						<S.Icons src={options} alt='' onClick={goToProductDetails} />
					</S.ItemsContainer>
				</S.TableItem>
				<S.BodyPadding />
			</S.TableRow>
		</>
	)
}

export default memo(CategoryAccordion)
