import { createElement, useState, useEffect, useMemo, useCallback } from 'react'
import { OrderDetailsView } from './view'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

//services
import { useChangeOrderStatus, useOrder } from '../../services/orders.service'

//types
import { EOrderStatus } from '../../types/orders'
import { IPageProps } from './types'
import { ERoutes } from '../../routes/routes'
import { ISelectItems } from '../../components/Select/types'

//utils
import { Toaster } from '../../utils/toaster'
import {
	availableStatus,
	getStatusLabel,
	getStatusFromLabel
} from '../../utils/status'

export function OrderDetails() {
	const [status, setStatus] = useState<EOrderStatus>()
	const [isModalOpen, setIsModalOpen] = useState(false)

	const navigate = useNavigate()
	const params = useParams()
	const { pathname } = useLocation()

	const { data: order } = useOrder(params.id)

	const { mutateAsync: changeStatusAsync, isLoading } = useChangeOrderStatus()

	const isEditable = pathname.includes('seller')

	const statusOptions: ISelectItems[] = useMemo(() => {
		if (!status) return []

		return availableStatus(status).map((stt) => ({
			value: getStatusLabel(stt)
		}))
	}, [status])

	const handleGoBack = useCallback(() => {
		const route = isEditable ? ERoutes.SELLER_ORDERS : ERoutes.ADMIN_ORDERS_LIST
		navigate(route)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditable])

	const handleOpenAndCloseModal = useCallback(() => {
		setIsModalOpen((prev) => !prev)
	}, [])

	function onStatusChange(stt: any) {
		setStatus(getStatusFromLabel(stt))
	}

	async function handleChangeStatus() {
		if (status && order) {
			if (status === EOrderStatus.CANCELED) {
				setIsModalOpen(true)
				return
			}

			await changeStatusAsync({ id: order._id, status })

			Toaster({
				type: 'success',
				title: 'Status Atualizado',
				text: 'O status do pedido foi atualizado com sucesso!'
			})

			handleGoBack()
		}
	}

	function setCurrentStatus() {
		order?.status && setStatus(order.status)
	}

	useEffect(setCurrentStatus, [order?.status])

	const pageProps: IPageProps = {
		order,
		status,
		isLoading,
		isModalOpen,
		isEditable,
		statusOptions,
		handleGoBack,
		handleChangeStatus,
		handleOpenAndCloseModal,
		onStatusChange
	}
	return createElement(OrderDetailsView, pageProps)
}
