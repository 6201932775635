import { add, format, isBefore, isEqual } from 'date-fns'
import * as yup from 'yup'

import { EFormError } from '../../utils/texts'
import { isHourBefore } from '../../utils/validations'

const productSchema = yup.object({
	name: yup
		.string()
		.typeError(EFormError.REQUIRED)
		.required(EFormError.REQUIRED),
	category: yup
		.string()
		.typeError(EFormError.REQUIRED)
		.required(EFormError.REQUIRED),
	weight: yup
		.string()
		.typeError(EFormError.REQUIRED)
		.required(EFormError.REQUIRED),
	massUnit: yup
		.string()
		.typeError(EFormError.REQUIRED)
		.required(EFormError.REQUIRED),

	quantity: yup
		.number()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),

	description: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED),

	price: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.test('Is greater than zero', EFormError.INVALID_VALUE, (value) => {
			return Number(value?.replace('R$ ', '').replace(',', '.')) > 0
		})
		.required(EFormError.REQUIRED),
	discount: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.test('Is greater than zero', EFormError.INVALID_VALUE, (value) => {
			if (!value) return true
			return Number(value?.replace('R$ ', '').replace(',', '.')) > 0
		})
		.test('discount test', 'Deve ser menor que o valor', (value, ctx) => {
			if (value) {
				if (
					+value.replace('R$ ', '').replace(',', '.') >=
					+ctx.parent.price?.replace('R$ ', '').replace(',', '.')
				) {
					return false
				}
				return true
			}

			return true
		})
		.when(['start_Date', 'start_Hour', 'end_Date', 'end_Hour'], {
			is: (
				start_Date: string,
				start_Hour: string,
				end_Date: string,
				end_Hour: string,
			) => {

				return start_Date || start_Hour || end_Date || end_Hour
			},
			then: yup.string().required(EFormError.REQUIRED)
		}),
	free_delivery_date: yup
		.mixed()
		.test('delivery test', EFormError.REQUIRED, (value, ctx) => {
			if (!value) {
				if (ctx.parent.shipping_type === 'RELATIVE') {
					return false
				}

				return true
			}

			return true
		}),
	scheduled_delivery_date: yup
		.mixed()
		.test('delivery test', EFormError.REQUIRED, (value, ctx) => {
			if (!value) {
				if (ctx.parent.shipping_type === 'FIXED') {
					return false
				}

				return true
			}

			return true
		}),
	scheduled_delivery_start: yup
		.mixed()
		.test('delivery test', EFormError.REQUIRED, (value, ctx) => {
			if (!value) {
				if (ctx.parent.shipping_type === 'FIXED') {
					return false
				}

				return true
			}

			return true
		}),
	scheduled_delivery_end: yup
		.mixed()
		.test('delivery test', EFormError.REQUIRED, (value, ctx) => {
			if (!value) {
				if (ctx.parent.shipping_type === 'FIXED') {
					return false
				}

				return true
			}

			return true
		})
		.test('delivery test', EFormError.INVALID_HOUR, (value, ctx) => {
			if (isHourBefore(value, ctx.parent.scheduled_delivery_start)) {
				return false
			}

			return true
		}),
	video: yup.mixed().required(),
	end_Date: yup.string().test('', EFormError.INVALID_DATE, (value, ctx) => {
		const { start_Date, end_Date } = ctx.parent as {
			start_Date: string
			end_Date: string
		}

		if (!start_Date || !end_Date) return true

		const startDate = new Date(start_Date.split('-').reverse().join('-'))
		const endDate = new Date(end_Date.split('-').reverse().join('-'))
		const endDatePossible = format(
			add(new Date(start_Date), { days: 4 }),
			'yyyy-MM-dd'
		)

		if (String(endDatePossible) < String(value)) return false

		if (end_Date[0] !== '0' && isBefore(endDate, startDate)) {
			return false
		}

		return true
	}),
	start_Date: yup.string().test('', EFormError.INVALID_DATE, (value, ctx) => {
		const { start_Date } = ctx.parent as {
			start_Date: string
		}

		if (!start_Date) {
			return true
		}
		const now = format(new Date(), 'yyyy-MM-dd')

		if (String(value) < String(now)) {
			return false
		}

		return true
	}),
	end_Hour: yup
		.string()
		.when('end_Date', {
			is: (end_Date: string) => end_Date,
			then: yup.string().typeError(EFormError.REQUIRED),
			otherwise: yup.string().nullable()
		})
		.test('', EFormError.INVALID_VALUE, (value, ctx) => {
			const { start_Hour, end_Date, start_Date } = ctx.parent as {
				start_Hour: string

				start_Date: string
				end_Date: string
			}

			if (!start_Date || !end_Date || !start_Hour || !value) return true

			const startDate = new Date(start_Date.split('-').reverse().join('-'))
			const endDate = new Date(end_Date.split('-').reverse().join('-'))

			if (
				(String(endDate) < String(startDate) || isEqual(endDate, startDate)) &&
				isHourBefore(value, start_Hour)
			) {
				return false
			}

			return true
		}),
	image_01: yup.mixed().required(EFormError.REQUIRED)
})

const serviceSchema = yup.object({
	video: yup
		.mixed()
		.typeError(EFormError.REQUIRED)
		.required(EFormError.REQUIRED),
	image_01: yup.mixed().required(EFormError.REQUIRED),
	image_02: yup.mixed().typeError(EFormError.REQUIRED),
	image_03: yup.mixed().typeError(EFormError.REQUIRED),
	name: yup
		.string()
		.typeError(EFormError.REQUIRED)
		.required(EFormError.REQUIRED),
	category: yup
		.string()
		.typeError(EFormError.REQUIRED)
		.required(EFormError.REQUIRED),
	description: yup
		.string()
		.typeError(EFormError.INVALID_VALUE)
		.required(EFormError.REQUIRED)
})

export { productSchema, serviceSchema }
