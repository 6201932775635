import { useState } from 'react'
import * as S from './styles'

//icons
import close from '../../assets/icons/close.svg'

//components
import { Button } from '../Button'
import { InputText } from '../InputText'
import { IChangeProductStatusAction } from '../../types/products'

//types
interface IDeclineProductModalProps {
	changeProductStatus(action: IChangeProductStatusAction, message: string, type?: string): void
	isModalOpen: boolean
	handleOpenAndCloseModal: () => void
	isLoading?: boolean
	type?: string
}

function DeclineProductModal({
	changeProductStatus,
	handleOpenAndCloseModal,
	isModalOpen,
	isLoading,
	type
}: IDeclineProductModalProps) {
	const [message, setMessage] = useState('')

	return (
		<S.Container isModalOpen={isModalOpen}>
			<S.Modal>
				<S.MoldaHead>
					<S.ModalTitle>Recusar Produto</S.ModalTitle>
					<img src={close} alt='' onClick={handleOpenAndCloseModal} />
				</S.MoldaHead>

				<S.ModalParagraph>
					Informe abaixo o motivo pelo qual você está reprovando este produto
					para sinalizarmos ao Seller.
				</S.ModalParagraph>

				<S.InputContainer>
					<InputText
						placeholder='Escreva o motivo'
						onChange={(e) => setMessage(e.target.value)}
					/>
				</S.InputContainer>

				<S.ButtonsContainer>
					<S.Keep onClick={handleOpenAndCloseModal}>Manter</S.Keep>
					<Button
						label='Enviar'
						onClick={() => changeProductStatus('decline', message, type)}
						disabled={!message}
						loading={isLoading}
					/>
				</S.ButtonsContainer>
			</S.Modal>
		</S.Container>
	)
}

export { DeclineProductModal }
