import { useCallback, useEffect, useState } from 'react'
import { createElement } from 'react'
import { AcceptTermsView } from './view'

//types
import { ERoutes } from '../../routes/routes'
import { IPageProps } from './types'

//hooks
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../hooks/useStore'

//services
import { useCreateSeller, useUpdateSeller } from '../../services/seller.service'

// utils
import { formatCreateSellerPayload } from '../../utils/formatPayload'
import { Toaster } from '../../utils/toaster'

function AcceptTerms() {
	const [acceptedTerms, setAcceptedTerms] = useState(false)
	const { createSellerFormStore } = useStores()

	const { mutateAsync: createAsync, isLoading: isLoadingCreate } =
		useCreateSeller()
	const { mutateAsync: updateAsync, isLoading: isLoadingUpdate } =
		useUpdateSeller()

	const navigate = useNavigate()

	const scrollTo = useCallback((direction: 'top' | 'bottom') => {
		window.scrollTo({
			top: direction === 'top' ? 0 : document.documentElement.scrollHeight,
			behavior: 'smooth'
		})
	}, [])

	function goBackToCreateSeller() {
		navigate(ERoutes.CREATE_SELLER, { replace: true })
	}

	const handleChangeAcceptTerms = useCallback(
		(checked: boolean) => {
			setAcceptedTerms(checked)
			checked && scrollTo('bottom')
		},
		[scrollTo]
	)

	async function handleAcceptTerms() {
		if (!createSellerFormStore.value) return

		const { token } = createSellerFormStore.value

		const payload = formatCreateSellerPayload({
			...createSellerFormStore.value,
			termsOfUse: acceptedTerms
		})

		if (token) {
			await updateAsync({ payload, token })
		} else {
			await createAsync(payload)
		}

		Toaster({
			type: 'success',
			title: 'Seller cadastrado com sucesso!'
		})

		const sellerEmail = createSellerFormStore.value.email
		scrollTo('top')

		createSellerFormStore.actions.removeCreateSellerForm()

		navigate(ERoutes.SELLER_REGISTRATION_CONFIRMATION, {
			state: { sellerEmail },
			replace: true
		})
	}

	useEffect(() => {
		scrollTo('top')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const pageProps: IPageProps = {
		goBackToCreateSeller,
		handleChangeAcceptTerms,
		handleAcceptTerms,
		acceptedTerms,
		isLoadingCreate,
		isLoadingUpdate
	}

	return createElement(AcceptTermsView, pageProps)
}

export { AcceptTerms as CreateSeller }
