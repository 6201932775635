import styled from 'styled-components'
import { css } from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 72px;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background: #fafafa;
	gap: 10px;
	padding-left: 16px;
`

export const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	img {
		overflow: hidden;
		object-fit: cover;
		width: 38px;
		height: 38px;
		border-radius: 19px;
	}
`

export const InfosWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 2px;
`

export const CardTitle = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
		color: ${theme.COLORS.DARK_GRAY};
	`}
`

export const CardSbutitle = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px;
		color: ${theme.COLORS.DARK_GRAFFITI};
	`}
`
