import * as S from './styles'

import close from '../../assets/icons/close.svg'
import { IModalProps } from './types'

function Modal({
	isModalOpen,
	onClickButton,
	toggleModal,
	title,
	text,
	actionButtonLabel = 'Action',
	closeButtonLabel = 'Close'
}: IModalProps) {
	return (
		<S.Container isModalOpen={isModalOpen}>
			<S.Modal>
				<S.Row>
					<S.Title>{title}</S.Title>
					<S.Close onClick={toggleModal} src={close} alt='' />
				</S.Row>

				<S.Row>
					<S.Text>{text}</S.Text>
				</S.Row>

				<S.ButtonsContainer>
					<S.Keep onClick={toggleModal}>{closeButtonLabel}</S.Keep>
					<S.Button onClick={onClickButton}>{actionButtonLabel}</S.Button>
				</S.ButtonsContainer>
			</S.Modal>
		</S.Container>
	)
}

export { Modal }
