import { Select } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	padding: 48px 0px;
`

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ContentHead = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

export const GoBackContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
	margin-bottom: 16px;

	:active {
		opacity: 0.8;
	}
`

export const ArrowBack = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 8px;
`

export const GoBackText = styled.span`
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
`

export const Title = styled.h1`
	font-family: Poppins;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 40px;
`

export const TitleWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

export const Subtitle = styled.h1`
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 32px;
`

export const SubtitleWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`

export const NotificationTypeCardsWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	margin-bottom: 42px;
`

export const RadioText = styled.p`
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`
export const RadioCard = styled.div<{ checked: boolean }>`
	${({ theme, checked }) => css`
		width: 100%;
		padding: 27px 48px 27px 24px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background-color: ${theme.COLORS.WHITE};
		border-radius: 4px;

		border: 1px solid
			${!checked ? theme.COLORS.OFF_WHITE : theme.COLORS.PRIMARY_COLOR};

		cursor: pointer;
	`}
`
export const Divider = styled.div`
	${({ theme }) => css`
		position: absolute;
		top: 437px;
		width: 100%;
		height: 1px;
		background-color: ${theme.COLORS.LIGHT_GRAY};
	`}
`

export const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	max-width: 800px;

	@media screen and (max-width: 1000px) {
		max-width: 654px;
	}
`

export const FormSubtitle = styled.h1`
	margin-top: 42px;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
	margin-bottom: 24px;
`

export const InputContainer = styled.div`
	${({ theme }) => css`
		display: flex;
		min-width: 200px;
		flex-direction: column;
		width: 100%;
		gap: 8px;
		margin-bottom: 24px;
		@media screen and (max-width: 768px) {
			min-width: 100px;
		}
	`}
`

export const InputWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		flex: 1;
		width: 100%;
		gap: 16px;
	`}
`

export const InputTitle = styled.h1`
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const EmptyInput = styled.div`
	${({ theme }) => css`
		width: 100%;
	`}
`

export const DividerBottom = styled.div`
	${({ theme }) => css`
		width: 100%;
		height: 1px;
		background-color: ${theme.COLORS.LIGHT_GRAY};
		margin: 42px 0px;
	`}
`

export const SendWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	`}
`

export const SendSubtitle = styled.h1`
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const ButtonsWrapper = styled.div`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		gap: 16px;
	`}
`

export const SketchButton = styled.button`
	${({ theme }) => css`
		display: flex;
		padding: 12px 24px;
		border-radius: 24px;
		background-color: transparent;
		border: none;
		cursor: pointer;
		transition: all 0.3s;
		:hover {
			background-color: ${theme.COLORS.LIGHT_GRAY};
		}
	`}
`

export const SketchButtonText = styled.p`
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const CardsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	margin: 24px 0px;
`

export const MultiSelect = styled(Select)<{ isSelected?: boolean }>`
	${({ theme, disabled, isSelected }) => css`
		display: flex;

		width: 100%;
		.MuiInputBase-input {
			span {
			}
		}

		border: 1px solid
			${disabled
				? theme.COLORS.MEDIUM_GRAY
				: !isSelected
				? '#e0e0e0'
				: theme.COLORS.PRIMARY_COLOR};
		height: 46px;
		border-radius: 50px;
		background-color: ${disabled
			? theme.COLORS.LIGHT_WHITE
			: theme.COLORS.WHITE};

		:hover {
			border: 1px solid ${theme.COLORS.DARK_GRAFFITI};
		}
		padding: 24px 0px 24px 16px;
		color: black;
	`}
`

export const MultiSelectWrapper = styled.div`
	display: flex;
	flex: 1;
	max-width: 390px;
	width: 100%;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 8px;

	@media screen and (max-width: 1000px) {
		max-width: 315px;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: transparent;
	}

	.MuiSvgIcon-root {
		margin-right: 6px;
	}

	.MuiInput-underline {
		:after {
			border-bottom: none;
		}
		:before {
			border-bottom: none;
		}
	}
	.MuiInputBase-root.Mui-disabled {
		:after {
			border-bottom: none;
		}
		:before {
			border-bottom: none;
		}
	}
`

export const MultiSelectTitle = styled.p`
	margin-top: -2px;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: ${({ theme }) => theme.COLORS.DARK_GRAFFITI};
`

export const SelectPlaceHolder = styled.p`
	font-family: Poppins;
	position: absolute;
	margin-top: 49px;
	margin-left: 16px;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	color: ${({ theme }) => theme.COLORS.MEDIUM_GRAY};
`

export const FormSubtitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const TypeSentContainer = styled.div`
	${({ theme }) => css`
		padding: 2px 8px;
		border-radius: 11px;
		background-color: ${theme.COLORS.GREEN_POSITIVE};
	`}
`

export const TypeDraftContainer = styled.div`
	${({ theme }) => css`
		padding: 2px 8px;
		border-radius: 11px;
		background-color: ${theme.COLORS.LIGHT_GRAY};
	`}
`

export const TypeSentTitle = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: ${theme.COLORS.WHITE};
	`}
`
export const TypeSDraftTitle = styled.p`
	${({ theme }) => css`
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: ${theme.COLORS.DARK_GRAY};
	`}
`
