const defaultTheme = {
	COLORS: {
		PRIMARY_COLOR: '#1200D7',
		PRIMARY_COLOR_HOVER: '#0F00B8',
		PINK_HARV: '#FA2769',
		DARK_GRAFFITI: '#3D3D3D',
		DARK_GRAY: '#7B7B7B',
		DARK_GRAY_MENU: '#292929',
		MEDIUM_GRAY: '#B8B8B8',
		LIGHT_GRAY: '#E0E0E0',
		OFF_WHITE: '#F5F5F5',
		GRAYISH_WHITE: '#FAFAFA',
		BLUEISH_WHITE: '#F1F0FF',
		SOFT_BLUE: '#DCD9FF',
		WHITE: '#FFFFFF',
		RED_ERROR: '#D31100',
		GREEN_POSITIVE: '#81D463',
		LIGHT_WHITE: '#EBEBEB',
		GRAY: '#CCCCCC'
	}
}

export { defaultTheme }
