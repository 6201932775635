import { EAboutType } from './types'

export const AboutTexts = {
	[EAboutType.HOME]: {
		title: 'Sobre a Harv',
		text: `A Harv é um social shopping on-line de compartilhamento de vídeos e fotos de produtos ou serviços diversos que permite ao usuário participar de transações de compras individuais. <br /><br />

        Na Harv há a divulgação de diversos anúncios que passam por uma curadoria minuciosa. Nós contamos com um time de especialistas que faz a curadoria dos produtos ou serviços a serem divulgados no aplicativo, potencializando suas vendas e seu negócio! <br /><br />

        Nossa missão é dar maior visibilidade ao seu anúncio através do compartilhamento em diversas redes sociais, permitindo a compra dentro do aplicativo Harv.`
	},
	[EAboutType.DOUBTS]: {
		title: 'Dúvidas Frequentes',
		text: `<strong>1) O que é a Harv?</strong> <br/>
        A Harv é um social shopping on-line de compartilhamento de vídeos e fotos de produtos ou serviços/anúncios diversos que permite ao usuário participar de transações de compras. <br/><br/>

        <strong>2) Onde a Harv está?</strong> <br/>
        Estamos em Indaiatuba/SP. A Harv está expandindo sua rede e em breve chegaremos na sua região! <br/><br/>


        <strong>3) Quer vender na Harv? Descubra como!</strong><br/>
        Acesse o link
        <a href='http://www.harvbrasil.com.br' target='_blank'>http://www.harvbrasil.com.br</a> para saber mais! <br/><br/>



        <strong>4) Como faço para comprar?</strong><br/>
        Na Harv é simples, prático e seguro comprar! Funciona assim: primeiro você deve fazer o download do aplicativo Harv e se cadastrar. Ao finalizar o seu cadastro, você seleciona o produto desejado. Após finalizar o seu pedido, é só aguardar o recebimento do produto no conforto da sua casa! Você também pode compartilhar as ofertas com mais pessoas clicando no botão “Compartilhar”. <br/><br/>



        <strong>5) O que eu posso comprar na Harv?</strong><br/>
        Na Harv há a divulgação de diversos anúncios de produtos que passam por uma curadoria minuciosa. Nós contamos com um time de especialistas que faz a curadoria dos produtos a serem anunciados no aplicativo Harv como: açougue, agro, artigos infantis, beleza e cosméticos, bebidas, confeitaria, congelados, eletrodomésticos, eletrônicos, empório, esportes e lazer, hortifruti, instrumentos musicais, imóveis, itens para casa, itens para escritório/loja, massas e rotisseria, panificação, pescados e frutos do mar, pets, restaurantes e lanchonetes, veículos, vestuário, entre outros.<br/><br/>



        <strong>6) Como funciona o pagamento?</strong><br/>
        O pagamento pode ser feito através de cartão de crédito ou Pix.<br/><br/>



        <strong>7) Como funciona a entrega?</strong><br/>
        A entrega dos produtos será feita na data e horário estabelecidos pelo parceiro Harv conforme a descrição do anúncio. Quem efetua a entrega é o parceiro Harv e o produto será entregue diretamente no endereço indicado por você. E olha só que bacana: na Harv você também pode retirar pessoalmente o seu produto na loja do parceiro!<br/><br/>



        <strong>8) É possível acompanhar o status dos pedidos?</strong><br/>
        Sim! Na aba “Pedidos” do aplicativo Harv você consegue visualizar o status das suas compras através dos campos “Processando”, “Confirmados”, “A caminho”, “Finalizados” e “Cancelados”.<br/><br/>



        <strong>9) É possível adicionar vários produtos de diferentes parceiros numa mesma compra?</strong><br/>
        Não, não é possível. Como são produtos de diferentes parceiros, você conseguirá comprar um produto de cada vez.<br/><br/>



        <strong>10) A Harv é uma rede social?</strong><br/>
        Sim, o aplicativo Harv é uma rede social combinado com ecommerce, ou seja, é um social shopping on-line.<br/><br/>



        <strong>11) É possível interagir na plataforma?</strong><br/>
        Sim! Você consegue interagir com todos os parceiros Harv. No canto esquerdo do aplicativo você encontrará diversos ícones:<br/><br/>

              <div>
                  <img src='/icons/star.png' width='22'/>

                  Este ícone permite favoritar o produto na aba “Favoritos” no aplicativo Harv. Todos os produtos favoritados poderão ser comprados se ainda estiverem com o anúncio ativo.
              </div><br/><br/>

              <div>
                  <img src='/icons/share.png' width='22'/>

                  Se você quiser compartilhar algum produto ou anúncio com um amigo, basta clicar no botão “Compartilhar”.
              </div><br/><br/>

              <div>
                  <img src='/icons/like.png' width='22'>

                  Clicar no ícone fará com que o coração fique branco e isso irá sinalizar que você gostou do vídeo e do produto.
              </div><br/>

        <strong>Seguir:</strong> Você pode seguir um parceiro Harv clicando no botão “Seguir”.<br/><br/>



        <strong>12) Como indicar a Harv para um amigo?</strong><br/>
        Você consegue indicar a Harv através do botão “Compartilhar”.<br/><br/>



        <strong>13) Existe uma lista de produtos ou serviços/anúncios não permitidos no aplicativo Harv?</strong><br/>
        Sim: <br/>
       <li>
       Armas de fogo, armas brancas ou similares, pólvora ou material explosivo, narcóticos, tóxicos e qualquer sorte de drogas ilegais;</li>
        <li>
        Cigarros e outros produtos derivados do tabaco;
        </li>

        <li>
        Medicamentos, qualquer tipo de drogas, produtos ou cosméticos aos quais se atribua efeitos terapêuticos de qualquer natureza;
        </li>

        <li>
        Aparelhos ativos, eletroestimuladores, aparelhos e produtos para utilização em educação física, embelezamento e correção estética, que não estejam devidamente registrados e aprovados perante a Agência Nacional de Vigilância Sanitária e demais órgãos reguladores;
        </li>

        <li>
        Órgãos humanos e/ou de animais, no todo ou em parte;
        </li>

        <li>
        Quaisquer espécies de animais, partes de animais, ou produtos feitos de pele de animais silvestres;
        </li>

        <li>
        Produtos que induzam ou incitem práticas perigosas, de risco ou nocivas para a saúde e para o equilíbrio psíquico;
        </li>

        <li>
        Produtos roubados, furtados ou provenientes de contrabando;
        </li>

        <li>
        Produtos falsificados ou adulterados, bem como cópia ou réplica de produtos de terceiros e produtos que violem o direito autoral, de propriedade industrial ou de privacidade de terceiros;
        </li>

        <li>
        Produtos que sejam ambíguos, ou inexatos, e/ou de alguma forma possam induzir ao erro sobre seu objeto ou sobre as intenções ou propósitos do consumidor;
        </li>

        <li>
        Produtos que, de qualquer maneira, configurem ou possam configurar uma situação de concorrência desleal;
        </li>

        <li>
        Produtos relacionados à prostituição ou similares, material pornográfico, obsceno, que tenham conteúdo ilícito, violento ou que possam atentar contra a honra e a dignidade de terceiro;
        </li>

        <li>
        Produtos que façam apologia ou representem organizações que promovam o ódio, a violência e/ou o cometimento de crimes;
        </li>

        <li>
        Produtos que promovam, façam publicidade, ou incitem a violência, o cometimento de crimes, e/ou a discriminação ou preconceito, baseada em questões de raça, sexo, religião, gênero, orientação sexual, nacionalidade, condição social ou econômica, intelectual, política-ideológica, ou de qualquer outro tipo;
        </li>

        <li>
        Produtos que tenham qualquer tipo de conteúdo de propaganda/campanha político-partidária;
        </li>

        <li>
        Máquinas de cartão de crédito/débito e outros;
        </li>

        <li>
        Produtos destinados às telecomunicações que não estejam devidamente homologados pela Anatel - Agência Nacional de Telecomunicações;
        </li>

        <li>
        Moedas e cédulas, exceto exemplares para fins de coleção;
        </li>

        <li>
        Ações de empresas negociadas em Bolsa de Valores, bilhetes de loteria, listas de correio ou bases de dados pessoais;
        </li>

        <li>
        CDs que contenham música em formato MP3;
        </li>

        <li>
        Bebidas em geral e produtos alimentícios que estejam fora da data de validade;
        </li>

        <li>
        Amostra grátis ou brindes.
        </li>


        <strong>14) Como entrar em contato com um parceiro da Harv?</strong><br/>
        Pelo aplicativo Harv você consegue entrar em contato com o parceiro apenas através dos anúncios divulgados por ele clicando no botão “Entrar em Contato”. Através desse botão, você será direcionado para o WhatsApp do parceiro. No feed, os anúncios são identificados pelo botão “Saiba Mais”.<br/><br/>


        <strong>15) Como entrar em contato com a Harv?</strong><br/>
        Na aba “Perfil” você encontrará o ícone “Saiba mais sobre a Harv”. Basta clicar nesse ícone para ser direcionado ao nosso site <a href='http://www.harvbrasil.com.br' target='_blank'>http://www.harvbrasil.com.br</a> .<br/><br/>`
	},
	[EAboutType.SELL]: {
		title: 'Vender na Harv',
		text: `
        <strong>
        1) Quero ser parceiro Harv. Como faço para vender e divulgar na Harv?
        </strong><br/>

        É muito simples se tornar um parceiro Harv! Funciona da seguinte forma:<br/><br/>

        <li>
        1º passo: você deve entrar no site <a href="http://www.harvbrasil.com.br" target='_blank'>http://www.harvbrasil.com.br</a>;
        </li>

        <li>
        2º passo: em seguida, você deve clicar no ícone “Criar conta” para se cadastrar;
        </li>

        <li>
        3º passo: preencha os campos obrigatórios com os dados cadastrais. Você pode ser pessoa física ou pessoa jurídica;
        </li>

        <li>4º passo: agora é só aguardar a validação do seu cadastro pelo administrador. Você receberá uma notificação por e-mail confirmando a validação do seu cadastro. Após a aprovação, será necessário acessar o site <a href="http://www.harvbrasil.com.br" target='_blank'>http://www.harvbrasil.com.br</a> com os dados de acesso para cadastrar os produtos que serão vendidos e os serviços que serão anunciados no aplicativo;</li>

        <li>5º passo: após cadastrar os produtos ou serviços/anúncios no site, é só aguardar a liberação do anúncio pelo administrador. Verifique se os anúncios respeitam os Termos de Uso, afinal, é de sua responsabilidade todos os produtos ou serviços/anúncios que divulgar no site;</li>

        <li>6º passo: pronto! O seu produto ou serviço/anúncio está disponível no aplicativo Harv e muitos compradores terão acesso a eles!</li>

        <strong>
        2) Quais os benefícios de vender na Harv?
        </strong><br/>

        Vender na Harv é seguro! A segurança nas transações é uma das nossas maiores preocupações. Trabalhamos com um efetivo sistema de segurança que te auxilia a evitar golpes e protege sua venda.
        Os anúncios são grátis! É uma maneira simples, rápida e segura de vender seus produtos e anunciar seus serviços utilizando um aplicativo de forma totalmente gratuita. Além disso, você pode anunciar serviços e vender quantos produtos quiser sem pagar nada a mais por isso.<br/><br/>

        Tenha maior visibilidade do seu produto ou serviço/anúncio através do compartilhamento nas redes sociais. Divulgar na Harv aumenta a visualização e potencializa suas vendas e seu negócio!<br/><br/>

        Na Harv não tem burocracia! Basta você colocar um bom título no seu anúncio, fazer boas fotos, descrever o que está anunciando e seu anúncio estará disponível em poucos minutos para inúmeras pessoas.<br/><br/>

        Credibilidade: seu produto ou serviço/anúncio estará inserido em um aplicativo ao lado de empresas renomadas.<br/><br/>

        <strong>
        3) O material de divulgação no aplicativo passa por uma curadoria da Harv?
        </strong><br/>
        Os produtos ou serviços/anúncios divulgados na Harv passam por uma curadoria na qual são avaliados diversos critérios: apresentação, qualidade, pontualidade na entrega, diferenciais de mercado, audiovisual, entre outros. Portanto, é importante que você parceiro capriche na divulgação dos seus produtos ou serviços para que os anúncios ganhem atenção e sejam valorizados. Dicas: o parceiro Harv não deve utilizar vídeos e fotos retirados da internet, não insira fotos com montagens e/ou com marca d’água, vídeos e fotos sem foco ou com iluminação ruim podem ser reprovados, etc.<br/><br/>

        Tudo isso ajudará os compradores a terem a imagem perfeita do seu produto ou serviço/anúncio e ficarem seguros quanto às suas compras. Anúncios que estiverem em desacordo com os Termos de Uso estão sujeitos a exclusão e sanções.<br/><br/>


        <strong>
        4) Quais produtos ou serviços/anúncios são divulgados na Harv?
        </strong><br/>
        Os produtos ou serviços/anúncios divulgados podem variar de acordo com as categorias existentes: açougue, agro, artigos infantis, beleza e cosméticos, bebidas, confeitaria, congelados, eletrodomésticos, eletrônicos, empório, esportes e lazer, hortifruti, instrumentos musicais, imóveis, itens para casa, itens para escritório/loja, massas e rotisseria, panificação, pescados e frutos do mar, pets, restaurantes e lanchonetes, veículos, vestuário, entre outros.<br/><br/>


        <strong>
        5) É possível apenas anunciar na Harv?
        </strong><br/>

        Sim! O aplicativo dispõe de uma ferramenta que permite ao parceiro Harv divulgar serviços ou anúncios, como se fosse a vitrine de um shopping; por isso a Harv é uma social shopping on-line! Caso o parceiro não queira vender um produto na Plataforma, basta clicar no ícone “Cadastro de Serviço ou Anúncio” para cadastrar um serviço ou apenas fazer um anúncio.<br/><br/>


        <strong>
        6) Quem define o valor do anúncio divulgado na Harv?
        </strong><br/>
        O valor é definido pelo parceiro Harv.<br/><br/>


        <strong>
        7) Qual a porcentagem da comissão repassada para a Harv?
        </strong><br/>
        A porcentagem da comissão repassada para a Harv é de 15% sobre o valor total de cada pedido.<br/><br/>


        <strong>
        8) Quando e como vou receber pelas minhas vendas?
        </strong><br/>
        Os repasses realizados para os parceiros Harv serão feitos única e exclusivamente na conta bancária informada pelo parceiro Harv no momento do cadastro. O prazo para recebimento será mensal para todas as categorias. As vendas realizadas do 1° ao último dia do mês vigente terão seu pagamento no dia 10 do mês subsequente.<br/><br/>


        <strong>
        9) Há alguma mensalidade para manter o perfil ativo na Harv?
        </strong><br/>
        Não, para anunciar na Harv é totalmente grátis!<br/><br/>


        <strong>
        10) Como funciona a entrega? Tem frete grátis?
        </strong><br/>
        A entrega dos produtos será feita na data e horário estabelecidos pelo parceiro Harv conforme a descrição do anúncio. Existem duas modalidades de entrega: entrega livre e entrega programada. Na entrega livre, o parceiro Harv informa em quantos minutos e/ou dias será a previsão de entrega. Na entrega programada, o parceiro Harv define em quais dias da semana e horário será efetuada a entrega.<br/><br/>

        Quem define a taxa de entrega e quem efetua a entrega é o parceiro Harv. Se preferir, o parceiro tem a possibilidade de efetuar a entrega com frete grátis. Para isso, basta selecionar o ícone “Produto com Frete grátis” na aba “Tipo de entrega” no painel de cadastro do produto. <br/><br/>

        Além da modalidade de entrega pelo parceiro, o Usuário/comprador também poderá retirar pessoalmente o produto na loja.<br/><br/>

        <strong>
        11) É possível adicionar vídeos ou fotos no meu feed?
        </strong><br/>
        No feed é possível adicionar apenas vídeos. As fotos ficarão aparentes na descrição no detalhe do anúncio.<br/><br/>

        <strong>
        12) Posso limitar quantidade de produtos para venda?
        </strong><br/>
        Sim, é possível cadastrar quantos produtos desejar e limitar quantidade máxima de cada produto.<br/><br/>


        <strong>
        13) Por quanto tempo os produtos ou serviços/anúncios ficam ativos no aplicativo Harv?
        </strong><br/>
        O parceiro Harv tem a possibilidade de definir 2 tipos de prazos do anúncio:<br/><br/>

        <li>
            1) o parceiro escolhe um prazo máximo de até 94 horas caso deseje limitar o prazo de exibição do anúncio. Atingido esse prazo, o anúncio será desativado. Nesse caso, para ativar o anúncio novamente, basta renová-lo acessando o produto no painel “Cadastro de Novo Produto”;

        </li>

        <li>2) o parceiro não insere nenhum prazo, desde que não preencha o campo “Informe o prazo de exibição deste anúncio”. Nesse caso, o prazo do anúncio será indeterminado. No entanto, ao zerar o estoque disponível, o anúncio irá expirar e não será mais possível visualizá-lo no feed. Para reativar o anúncio, basta acessar o painel e preencher um novo estoque.</li>

        <strong>
        14) Posso desativar o anúncio se nenhum produto foi vendido ainda?
        </strong><br/>
        Sim, basta clicar no ícone “Ativo no feed?” na aba “Produtos” para ativar ou desativar um anúncio.<br/><br/>

        <strong>
        15) Existe uma lista de produtos ou serviços/anúncios não permitidos no aplicativo Harv?
        </strong><br/>
        Sim:<br/>

        <li>
        Armas de fogo, armas brancas ou similares, pólvora ou material explosivo, narcóticos, tóxicos e qualquer sorte de drogas ilegais
        </li>

        <li>
        Cigarros e outros produtos derivados do tabaco
        </li>

        <li>
        Medicamentos, qualquer tipo de drogas, produtos ou cosméticos aos quais se atribua efeitos terapêuticos de qualquer natureza
        </li>

        <li>
        Aparelhos ativos, eletroestimuladores, aparelhos e produtos para utilização em educação física, embelezamento e correção estética, que não estejam devidamente registrados e aprovados perante a Agência Nacional de Vigilância Sanitária e demais órgãos reguladores
        </li>

        <li>
        Órgãos humanos e/ou de animais, no todo ou em parte
        </li>

        <li>
        Quaisquer espécies de animais, partes de animais, ou produtos feitos de pele de animais silvestres
        </li>

        <li>
        Produtos que induzam ou incitem práticas perigosas, de risco ou nocivas para a saúde e para o equilíbrio psíquico
        </li>

        <li>
        Produtos roubados, furtados ou provenientes de contrabando
        </li>

        <li>
        Produtos falsificados ou adulterados, bem como cópia ou réplica de produtos de terceiros e produtos que violem o direito autoral, de propriedade industrial ou de privacidade de terceiros
        </li>

        <li>
        Produtos que sejam ambíguos, ou inexatos, e/ou de alguma forma possam induzir ao erro sobre seu objeto ou sobre as intenções ou propósitos do consumidor
        </li>

        <li>
        Produtos que, de qualquer maneira, configurem ou possam configurar uma situação de concorrência desleal
        </li>

        <li>
        Produtos relacionados à prostituição ou similares, material pornográfico, obsceno, que tenham conteúdo ilícito, violento ou que possam atentar contra a honra e a dignidade de terceiro
        </li>


        <li>
        Produtos que façam apologia ou representem organizações que promovam o ódio, a violência e/ou o cometimento de crimes
        </li>

        <li>
        Produtos que promovam, façam publicidade, ou incitem a violência, o cometimento de crimes, e/ou a discriminação ou preconceito, baseada em questões de raça, sexo, religião, gênero, orientação sexual, nacionalidade, condição social ou econômica, intelectual, política-ideológica, ou de qualquer outro tipo
        </li>


        <li>
        Produtos que tenham qualquer tipo de conteúdo de propaganda/campanha político-partidária
        </li>

        <li>
        Máquinas de cartão de crédito/débito e outros
        </li>

        <li>
        Produtos destinados às telecomunicações que não estejam devidamente homologados pela Anatel - Agência Nacional de Telecomunicações
        </li>

        <li>
        Moedas e cédulas, exceto exemplares para fins de coleção
        </li>

        <li>
        Ações de empresas negociadas em Bolsa de Valores, bilhetes de loteria, listas de correio ou bases de dados pessoais
        </li>

        <li>
        CDs que contenham música em formato MP3
        </li>

        <li>
        Bebidas em geral e produtos alimentícios que estejam fora da data de validade
        </li>

        <li>
        Amostra grátis ou brindes.
        </li>
        `
	},
	[EAboutType.CONTACT]: {
		title: 'Fale Conosco',
		text: `Para esclarecimentos, dúvidas, sugestões e solicitações entre em contato conosco através do nosso Suporte: <br/>
        <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a> ou WhatApp <a href="https://wa.me/19999729216">(19) 99972-9216.</a>`
	},
	[EAboutType.TERMS]: {
		title: 'Termos de Uso',
		text: `Nós somos a <strong>HARVESTER SOLUÇÕES E TECNOLOGIA EIRELI</strong> ("HARV BRASIL"), pessoa jurídica de direito privado, inscrita no Cadastro Nacional da Pessoa Jurídica ("CNPJ/ME") sob o número 21.697.746/0001-76, com sede na cidade de Indaiatuba, estado de São Paulo, na Rua Antonio Brollo, nº 131, Condomínio Residencial Dona Lucilla, Bairro Esplanada, CEP 13.331-494.<br/><br/>

        A Harv é titular de uma plataforma digital que atua como social shopping on-line que intermedia a compra e compartilhamento de produtos e serviços, anunciando ofertas com quantidades e prazos determinados pelos parceiros.<br/><br/>

        Para utilizar a plataforma Harv e ter acesso aos produtos e serviços/anúncios oferecidos, você deve estar de acordo com todos os Termos e Condições de Uso que regem o acesso e o uso por toda e qualquer pessoa que utilize a plataforma Harv em todo o território nacional.<br/><br/>

        Ao se cadastrar, clicar no botão "li e aceito" e começar a plataforma Harv, você declara que leu, entendeu e aceitou estes Termos de Uso. Caso não concorde com estes Termos de Uso, deverá cessar a utilização da plataforma Harv e cancelar o seu cadastro.<br/><br/>

        Os Termos e Condições de Uso da plataforma Harv poderão ser alterados a exclusivo critério da Harv, a qualquer momento. Portanto, é importante que você consulte regularmente os Termos de Uso para conferir se houve alguma mudança. Qualquer dúvida a respeito dos Termos e Condições de Uso ou sobre os serviços oferecidos pela plataforma Harv você deverá entrar em contato com a Harv pelo e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>


        <strong>Aplicativo Harv</strong><br/><br/>

        <strong>
        1) O que é o aplicativo HARV?
        </strong><br/>
        Somos um aplicativo digital que atua como social shopping on-line que intermedia a compra e compartilhamento de produtos e serviços, anunciando ofertas com quantidades e prazos determinados pelos parceiros. A Harv viabiliza, de forma segura, a compra de produtos dentro do período de validade do anúncio.<br/><br/>

        Todas as operações de compra e venda realizadas no aplicativo Harv serão de responsabilidade do parceiro Harv.
        A responsabilidade pela qualidade, garantia e entrega dos produtos é do parceiro Harv.<br/><br/>

        A responsabilidade pelas informações anunciadas nas ofertas é de responsabilidade do parceiro Harv. Na eventualidade de ocorrer qualquer problema, a Harv atuará como facilitador na comunicação entre você e o parceiro Harv.<br/><br/>

        Além disso, a Harv conta com a ajuda dos Usuários para dar sugestões de melhoria para que o nível de satisfação seja mantido e constantemente aperfeiçoado.<br/><br/>

        <strong>
        2) O que acontece quando o Usuário receber um anúncio?
        </strong><br/>
        O Usuário poderá efetuar a compra ou optar em não efetuar a compra do anúncio. Se ele optar pela compra, ele deverá efetuar o cadastro no aplicativo Harv conforme condições descritas no item 3 abaixo, ficando vinculado a estes Termos de Uso e à Política de Privacidade da Harv. <br/><br/>

        Caso o Usuário opte pela compra do produto oferecido, deverá selecionar o produto, a quantidade desejada e finalizar a compra através do pagamento na modalidade “cartão de crédito” ou “Pix”. Caso o Usuário opte por não efetuar nenhuma compra, basta desconsiderar o anúncio.<br/><br/>

        No aplicativo Harv há algumas modalidades de anúncio: venda de produtos, divulgação de serviços ou apenas anúncio - modalidades que não permitem venda.<br/><br/>

        Por meio desses Termos de Uso, o Usuário entende que a Harv realiza a intermediação entre o Usuário, os entregadores e os parceiros. A Harv não produz, não fornece, não comercializa, não distribui e não transporta nenhum dos produtos exibidos na Plataforma. A Harv também não presta serviços logísticos, dessa forma, não transporta ou entrega nenhum destes produtos. Portanto, os parceiros são os únicos responsáveis pelo preparo, execução, acondicionamento, qualidade e entrega dos produtos.<br/><br/>

        Ao adquirir um produto através da Harv, o Usuário declara ter aderido e concorda com o fornecedor daquele produto, aos termos do anúncio e conhece as condições de uso, preço, formas de pagamento, entrega, prazo de validade e garantia de tal produto, isentando a Harv de qualquer responsabilidade. Independentemente se o Usuário estiver interessado ou não na compra, ele conseguirá visualizar todos os detalhes dos anúncios oferecidos no aplicativo Harv.
        <br/><br/>

        No caso de dúvidas e/ou recomendações, o Usuário poderá entrar em contato através do e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>


        <strong>
        3) Regras do uso da conta
        </strong><br/>
        O cadastro na plataforma Harv é gratuito, mediante o preenchimento dos Dados Pessoais ou Dados da Empresa solicitados. A coleta dos Dados pela Harv estará sujeita à nossa Política de Privacidade. Você poderá abrir apenas uma conta por CPF e por CNPJ. Sua conta é aberta no momento em que é realizado o cadastro na plataforma Harv, criando seu login e senha de acesso. O acesso é exclusivo para maiores de 18 anos e residentes em território nacional. Os usuários que queiram vender/anunciar na plataforma Harv e que possuam documentos com restrição nos órgãos de proteção ao crédito não serão aceitos.<br/><br/>

        Fique atento aos dados que não podem ser alterados após a conclusão do cadastro: nome e CPF (pessoa física), razão social e CNPJ (pessoa jurídica), e-mail e dados bancários. É possível alterar os dados bancários para uma conta bancária diferente desde que pertença ao mesmo CPF/CPNJ cadastrado anteriormente. No entanto, no caso de troca de CPF ou CNPJ, será necessário criar um novo cadastro.<br/><br/>

        Ao abrir a conta e aceitar estes Termos de Uso, você declara ser maior de 18 anos e ter capacidade legal para utilizar a plataforma Harv. As informações da sua conta deverão estar corretas, completas e atualizadas. A veracidade e atualização das informações são de exclusiva responsabilidade do Usuário.

       	A conta e senha de acesso são de uso pessoal e intransferível. Se você permitir que terceiros tenham acesso à sua conta, você será responsável por todas as transações realizadas durante a utilização da conta, inclusive o pagamento dos produtos que terceiros venham a comprar. Caso identifique qualquer uso não autorizado ou indevido da sua conta, você se compromete a comunicar a Harv imediatamente, uma vez que o acesso só será possível mediante a posição da senha, cujo conhecimento é exclusivo do Usuário.
 
        A qualquer momento a Harv poderá solicitar a confirmação de seus dados. Em caso de não confirmação, o acesso à plataforma Harv poderá ser suspenso ou cancelado.<br/><br/>


        <strong>
        4)Como o Usuário irá efetuar o pagamento dos produtos adquiridos no aplicativo Harv?

        </strong><br/>
        O pagamento pelos produtos adquiridos será realizado pelo método de pagamento escolhido no momento da finalização das suas compras (cartão de crédito ou Pix).<br/><br/>

        Se a opção escolhida for “cartão de crédito”, o Usuário deverá fornecer todos os dados do cartão que forem solicitados. Tais dados serão compartilhados pela Harv com as empresas que participam da cadeia de autorização da compra.<br/><br/>

        Se a opção escolhida for “Pix”, o Usuário deverá efetuar o pagamento via Pix na chave indicada.
  
        Ao finalizar o pagamento, o Usuário será direcionado para uma página confirmando o pagamento e poderá acompanhar o status da sua compra no aplicativo Harv. Nenhum outro valor adicional será cobrado após a finalização do pagamento.<br/><br/>


        <strong>
        5) Porcentagem da comissão e repasses
        </strong><br/>
        A porcentagem da comissão repassada para a Harv é de 15% sobre o valor total de cada pedido.
 
        Os repasses realizados para os parceiros Harv serão feitos única e exclusivamente na conta bancária informada pelo parceiro Harv no momento do cadastro dos anúncios. O prazo para recebimento será mensal para todas as categorias. As vendas realizadas do 1° ao último dia do mês vigente terão seu pagamento no dia 10 do mês subsequente.<br/><br/>

        Ao utilizar os serviços da plataforma Harv, você estará automaticamente concordando com os Termos e Condições de Uso da forma de repasse da comissão.<br/><br/>

        Para garantir a realização e integridade das transações financeiras, os dados fornecidos por você serão compartilhados apenas com os gestores da plataforma Harv que, por sua vez, se responsabilizam pela segurança e sigilo destes dados e se comprometem a não utilizar/tratar os seus dados para quaisquer finalidades diferentes daqueles estabelecidas nestes Termos de Uso.<br/><br/>


        <strong>
        6) Entrega dos produtos
        </strong><br/>
        A entrega dos produtos será feita na data e horário estabelecidos pelo parceiro Harv. Os produtos serão entregues diretamente no endereço indicado pelo Usuário, para isso, é necessário que o Usuário informe um endereço válido para entrega. Após a finalização do pedido, o endereço não poderá ser alterado. 
  
        Ao concluir um pedido, presumir-se-á que o Usuário concordou com todos os termos e condições da entrega detalhados no anúncio.<br/><br/>

        Se o Usuário notar, ao receber o(s) produto(s), que a caixa/embalagem está extraviada, ele deverá recusar o recebimento da entrega e entrar em contato com o suporte da Harv imediatamente para relatar o ocorrido. Se o Usuário não conseguir recusar o recebimento, recomendamos que não abra a caixa/embalagem e nos avise imediatamente pelo nosso Suporte através do e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>

        Além da entrega efetuada pelo parceiro Harv, o Usuário/Comprador também poderá retirar pessoalmente o seu produto na loja do parceiro. Ao retirar o produto, o Usuário tem que se certificar na hora se está em perfeitas condições.

        O Usuário declara estar ciente que o parceiro Harv é o responsável pela entrega do produto conforme indicado no anúncio e que a Harv não tem acesso aos produtos, bem como não possui qualquer responsabilidade relacionada à entrega. De toda forma, a Harv será o canal de comunicação entre o Usuário e o parceiro para tentativa de resolução de qualquer problema entre Usuário/comprador e parceiro.<br/><br/>

        Caso exista alguma cobrança de frete, o Usuário será informado previamente.
        
        A Harv não se responsabiliza pelas entregas, atrasos ou impossibilidade de executá-las, sendo única e exclusiva responsabilidade do parceiro. Todavia, a Harv dispõe do seguinte canal de comunicação para ajudar na solução de qualquer problema entre o Usuário/comprador e o parceiro Harv: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>


        <strong>
        7) Devoluções de pedidos
        </strong><br/>
        Em caso de devoluções de produtos não perecíveis, o Usuário deverá informar:  (i) o motivo da devolução, (ii) o número do pedido através do e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>

        As devoluções dos produtos não perecíveis poderão ser feitas em até 7 (sete) dias corridos da data de recebimento e a restituição do valor será através do reembolso integral do valor pago.<br/><br/>

        A partir da data da autorização da devolução pela Harv, o Usuário terá 3 (três) dias úteis para efetuar o envio do(s) produto(s) ao endereço indicado pela Harv, utilizando o código de postagem fornecido pela equipe de atendimento da Harv, sendo o Usuário responsável por acompanhar o trâmite da devolução. O Usuário/comprador declara-se ciente de que o descumprimento do prazo de postagem implicará o cancelamento automático da devolução, não sendo possível fazer um novo pedido.<br/><br/>

        Caso o pagamento tenha sido efetuado através do método de pagamento “cartão de crédito”, o valor será estornado no cartão de crédito, dentro do prazo estipulado pela administradora do cartão de crédito utilizado pelo Usuário/comprador no momento da compra. Em caso de pagamento efetuado via “Pix”, o reembolso do valor será efetuado em até 24 horas.<br/><br/>


        <strong>
        8) Das obrigações
        </strong><br/>
        O Usuário/comprador e o parceiro Harv deverão cumprir com todas as condições e obrigações constantes nestes Termos de Uso e declaram-se estar cientes que não poderão:<br/><br/>

        <li>
        Sugerir, persuadir ou incitar, de qualquer forma e por qualquer meio, que a negociação de compra e venda de produtos intermediados pela Harv seja consumada fora da plataforma Harv;
        </li>

        <li>Instigar, ameaçar, ofender, abalar a imagem, ofender a privacidade ou prejudicar qualquer pessoa;</li>

        <li>Desrespeitar a lei, a moral, a boa-fé, as normas de direito autoral e/ou propriedade industrial, os direitos à honra, à vida privada, à imagem, à intimidade pessoal e familiar por meio da plataforma Harv;</li>

        <li>Incitar a prática de atos discriminatórios, seja em razão do sexo, raça, religião, crença, idade ou qualquer outra condição;</li>


        <li>Utilizar dispositivo, software ou outro recurso que venha a interferir nas atividades e operações na plataforma Harv, bem como nos anúncios, descrições, contas ou seus bancos de dados;</li>

        <li>Reproduzir e/ou utilizar o modelo de negócio e práticas comerciais da Harv, bem como informações confidenciais de sua titularidade sem a prévia autorização e consentimento expresso desta.</li>

        Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos de Uso tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por danos causados.<br/><br/>

        A plataforma Harv se reserva no direito de, a exclusivo critério e sem aviso prévio, excluir ou cancelar o Usuário que violar as regras aqui estabelecidas, sem prejuízo da aplicação de outras penalidades previstas nestes Termos de Uso ou que julgar cabíveis.<br/><br/>

        <strong>
        9) Sanções
        </strong><br/>
        
        Sem prejuízo de outras medidas, a Harv poderá advertir, suspender ou cancelar, temporária ou definitivamente, a conta de um Usuário a qualquer tempo, e iniciar as sanções legais e cabíveis se: i) o Usuário não cumprir qualquer dispositivo destes Termos de Uso; (ii) descumprir com seus deveres de Usuário; (iii) praticar atos fraudulentos ou dolosos; (iv) não puder ser verificada a identidade do Usuário ou qualquer informação fornecida por ele esteja incorreta; (v) a Harv entender que qualquer atitude do Usuário tenha causado algum dano a terceiro ou a própria Harv ou tenha a potencialidade de assim o fazer.<br/><br/>

        Na hipótese de inabilitação do cadastro do Usuário, todos os pedidos ativos serão automaticamente cancelados e o Usuário será excluído da plataforma Harv. Se o Usuário for inabilitado pela Harv, não poderá efetuar novo cadastro na plataforma Harv.<br/><br/>

        <strong>
        10) Propriedade intelectual
        </strong><br/>
        O Usuário declara estar ciente de que a marca, nome empresarial ou nome de domínio Harv são de uso exclusivo da Harv, não podendo ser utilizado de qualquer forma por qualquer pessoa não autorizada expressamente para tanto. O código fonte, o layout, o banco de dados, redes e arquivos inerentes à plataforma Harv são de titularidade exclusiva da Harv.<br/><br/>

        O Usuário não adquire, por meio do presente Termos de Uso, nenhum direito de propriedade intelectual ou outros direitos exclusivos, direitos autorais ou direitos sobre informações confidenciais ou segredos de negócio, bem como a base de dados composta por dados de Usuários, os quais são de propriedade exclusiva da Harv.<br/><br/>

        <strong>
        11) Lei aplicável e foro
        </strong><br/>
        Estes Termos de Uso serão regidos pelas leis em vigor na República Federativa do Brasil. As partes elegem o foro da comarca de Indaiatuba, estado de São Paulo, para dirimir qualquer controvérsia oriunda destes Termos de Uso que não tenha sido resolvido pacificamente entre as partes, renunciando, expressamente a qualquer outro, por mais privilegiado que seja.<br/><br/>

        <strong>
        12) Disposições finais
        </strong><br/>
        Você não poderá ceder estes Termos de Uso, total ou parcialmente, ou qualquer de seus direitos e obrigações dele decorrentes sem o prévio consentimento, por escrito da plataforma Harv.<br/><br/>

        Não existe sociedade, relação de emprego ou relação de representação entre você e a plataforma Harv como resultado destes termos ou pelo uso dos serviços.<br/><br/>

        Estes termos constituem o acordo integral entre você e a Harv com relação aos assuntos previstos nestes Termos de Uso e substitui todos e quaisquer contratos e acordos anteriores, verbais ou escritos, celebrados anteriormente em relação aos mesmos assuntos.<br/><br/>

        Dúvidas, elogios e reclamações? O Usuário poderá utilizar o e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a> para fazer qualquer tipo de solicitação, questionamento, elogios e/ou reclamações para a Harv.<br/><br/>

        Atualizado em 18 de maio de 2023.
        `
	},
	[EAboutType.PRIVACY]: {
		title: 'Política de Privacidade',
		text: `<strong>A HARVESTER SOLUÇÕES E TECNOLOGIA EIRELI</strong>, empresa inscrita no CNPJ sob o número 21.697.746/0001-76, com sede na cidade de Indaiatuba, estado de São Paulo, na Rua Antonio Brollo, nº 131, Condomínio Residencial Dona Lucilla, Bairro Esplanada, CEP 13.331-494 (“HARV BRASIL”) está comprometida com a sua privacidade.<br/><br/>

        A Harv é titular de uma plataforma digital que atua como social shopping on-line que intermedia a compra e compartilhamento de produtos e serviços, anunciando ofertas com quantidades e prazos determinados pelos parceiros.<br/><br/>

        Utilizamos o compartilhamento de dados para promover ofertas e anunciar serviços, e ao acessar o nosso site e aplicativo, interagir com a gente e/ou fazer o seu cadastro para utilizar os nossos serviços, você concorda com o tratamento de seus Dados Pessoais conforme descrito nesta Política de Privacidade. Por isso, recomendamos que antes de continuar navegando pela nossa Plataforma e utilizar qualquer serviço ou funcionalidade da HARV, você LEIA, ATENTA E INTEGRALMENTE, AS CONDIÇÕES AQUI DISPOSTAS.<br/><br/>

        Entendemos a importância das suas informações pessoais e estamos comprometidos com a segurança dos seus dados e de sua privacidade. Assim, empregamos nossos melhores esforços para que seus dados sejam tratados de maneira adequada.<br/><br/>

        Pensando nisso, elaboramos a presente Política de Privacidade que esclarece sobre o tratamento de Dados Pessoais realizado pela Harv, de acordo com as disposições da Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), sendo aplicável para utilização dos serviços através do nosso site: <a href="http://www.harvbrasil.com.br" target='_blank'>http://www.harvbrasil.com.br</a> ou pelo nosso aplicativo.
        Ao utilizar os serviços da Harv, o Usuário declara ter lido, compreendido e aceito as disposições desta Política de Privacidade, assim como as disposições dos Termos de Uso da Plataforma.<br/><br/>

        <strong>
        1) Quando esta política de privacidade será aplicável?
        </strong><br/>
        Esta Política de Privacidade é aplicável a todos Usuários (pessoas maiores de 18 anos, capazes nos termos da lei, cadastradas na Plataforma da Harv para utilização dos nossos serviços) e visitantes (pessoas que acessam e utilizam a Plataforma Harv e/ou que interajam conosco por e-mail, WhatsApp, telefone, redes sociais ou qualquer outro canal, interessados em nossos serviços, mesmo sem a realização de cadastro).<br/><br/>

        Esta Política de Privacidade não se aplica aos sites, serviços e soluções ofertadas por outras empresas, mesmo que elas sejam nossos parceiros. Você deverá consultar as políticas de privacidade dessas outras empresas para entender como elas tratam os seus Dados Pessoais.<br/><br/>

        Fique atento e leia as Políticas de Privacidade de cada site de terceiros ou serviço que visitar ou utilizar. A Harv não se responsabiliza por qualquer violação aos direitos de privacidade dos titulares que venha a ser praticada pelos referidos sites.<br/><br/>

        <strong>
        2) Definições
        </strong><br/>
        A fim de facilitar sua leitura, apresentamos abaixo algumas definições úteis para a interpretação desta Política de Privacidade:<br/><br/>

        <li>
        Dados Pessoais ou Dados: dados relativos a uma pessoa física ou jurídica, que seja capaz de identificá-la ou torná-la identificável dentro de determinável contexto, como por exemplo: nome, CPF, RG, CNPJ, razão social, endereço, telefone, e-mail, entre outros.
        </li>

        <li>
        Harv: pessoa jurídica de direito privado, inscrita no Cadastro Nacional da Pessoa Jurídica ("CNPJ/ME") sob o número 21.697.746/0001-76, com sede na cidade de Indaiatuba, estado de São Paulo, na Rua Antonio Brollo, nº 131, Condomínio Residencial Dona Lucilla, Bairro Esplanada, CEP 13.331-494.
        </li>
        
        <li>
        LGPD: Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18).
        </li>

        <li>
        Plataforma: meio pelo qual a Harv oferece ao Usuário os seus serviços, através de site ou aplicativo.
        </li>

        <li>
        Política de Privacidade: é a presente Política de Privacidade.
        </li>

        <li>
        Titular: pessoa a quem pertencem os Dados Pessoais tratados. No contexto desta Política de Privacidade, são os Usuários da Plataforma.
        </li>

        <li>
        Usuário: aquele que utiliza ou acessa a Plataforma Harv.
        </li>

        <li>
        Parceiro: pessoa física ou pessoa jurídica que divulga seus produtos, serviços ou anúncios na Plataforma Harv.
        </li>

        <li>
        Tratamento: toda operação realizada com Dados Pessoais, como as que se referem à coleta, armazenamento, consulta, uso, compartilhamento, transmissão, classificação, reprodução, exclusão e avaliação.
        </li>

        <li>
        Social shopping on-line: modelo de negócio que utiliza a interação social entre os Usuários para promover a venda e divulgação de produtos e serviços, viabilização a interação entre Usuários e parceiros.
        </li>

        <li>
        Aplicativo: aplicação móvel pela qual o Usuário pode acessar os serviços da Harv.
        </li>

        <strong>
        3) Dados pessoais coletados e as finalidades
        </strong><br/>
        Utilizaremos seus Dados Pessoais para prestar o serviço de intermediação de compra e venda de produtos e serviços entre os Usuários da Plataforma e oferecer uma melhor experiência de navegação no nosso site e aplicativo.
        Você será o único e exclusivo responsável pela veracidade dos Dados Pessoais fornecidos à Harv, bem como por eventuais danos decorrentes da inexatidão e/ou desatualização de referidas informações.<br/><br/>

        Você também é responsável por quaisquer Dados Pessoais de terceiros que forem obtidos, publicados ou compartilhados através de nossos serviços e confirma que possui a autorização dos terceiros para fornecer tais dados para a Harv.<br/><br/>

        Formas pelas quais coletamos suas informações:<br/><br/>

        <li>Quando você se cadastra ou fornece informações usando a Plataforma.</li>

        <li>Automaticamente, como quando você navega pelo site ou acessa o aplicativo.</li>

        <li>De fontes confiáveis (prestadores de serviços ou parceiros de negócios com quem trabalhamos, agências de crédito, órgãos públicos, etc).</li>

        Tipos de dados que coletamos:<br/><br/>

        <ul>
        <li>Nome e foto.</li>
        <li>Razão social.</li>
        <li>Dados Pessoais e dados da empresa.</li>
        <li>
            Informações de contato (número de telefone, endereço, e-mail, etc).
            Dados patrimoniais e financeiros.
        </li>
        <li>Meio de pagamento.</li>
        <li>
            Direitos de propriedade intelectual.
            Informações do dispositivo através do qual você acessa e endereço IP.
        </li>
        <li>Lista de contatos.</li>
        <li>Links entre contas e Usuários.</li>
        <li>
            Informações e movimentações transacionais dentro da Plataforma Harv.
            Informações sobre sua localização.
        </li>
        <li>Certas informações sobre a atividade e as preferências dos Usuários e visitantes no nosso site e aplicativo dos nossos serviços.</li>
        </ul><br/>

        Suas informações pessoais estão protegidas. Não vendemos ou comercializamos informações que possam identificá-lo. Também não compartilhamos ou transferimos de nenhuma outra forma suas informações pessoais para terceiros. No entanto, para o correto desenvolvimento das nossas atividades e garantir excelência na prestação dos serviços, podemos compartilhar determinados Dados Pessoais em algumas situações, sempre buscando preservar a sua privacidade e, quando possível, de forma anonimizada.<br/><br/>

        Podemos compartilhar seus Dados com empresas com as quais a Harv mantém um relacionamento de colaboração ou parceria, a fim de realizar atividades financeiras ou comerciais. No entanto, buscamos avaliar cuidadosamente nossos parceiros, além de firmar obrigações contratuais de segurança da informação e proteção de Dados Pessoais com o objetivo de minimizar riscos à proteção de Dados Pessoais.<br/><br/>

        Apesar de tratarmos a segurança dos seus Dados Pessoais com o máximo cuidado e sermos comprometidos com as melhores práticas, é importante que você, como Usuário, também adote algumas medidas fundamentais para manter o ambiente seguro como: (i) cadastrar uma senha forte para proteger sua conta; (ii) nunca compartilhar sua senha e Dados Pessoais com terceiros; e (iii) sair de sua conta logo após a utilização, de forma a prevenir quaisquer danos.<br/><br/>

        <strong>
        4) Segurança da informação
        </strong><br/>
        Assim que coletamos suas informações, utilizaremos procedimentos rígidos e os melhores recursos de segurança e tecnologia para tentar proteger seus Dados Pessoais contra perdas, roubos e acesso, uso e alterações não autorizados, como a criptografia de dados, controle de acesso de dados e utilização de firewalls. Entretanto, apesar de nossos contínuos esforços, você deve ter ciência de que é impossível garantir a totalidade de tal proteção. É uma decisão pessoal a utilização de nossos serviços nessas condições.<br/><br/>

        É importante que você se proteja contra acessos não autorizados à sua senha e aos seus computadores, dispositivos e aplicativos. Certifique-se de encerrar a sessão sempre que terminar de usar um computador compartilhado.<br/><br/>

        <strong>
        5) Cookies
        </strong><br/>
        Cookies são pequenos arquivos que coletam informações sobre seu navegador ou dispositivo. Eles nos permitem saber como e quando o site é visitado, bem como quantas pessoas o acessam.<br/><br/>

        Além disso, também nos permitem:<br/><br/>

        <li>Mostrar publicidade e oportunidades relevantes para você.</li>

        <li>Melhorar nossas ofertas e serviços.</li>

        <li>Possibilitar que você não precise informar seu e-mail, senha ou celular para entrar na sua conta quando acessá-la de um dispositivo que você normalmente usa.</li>

        <li>Reforçar a segurança do nosso site.</li>

        Se você quiser saber quais cookies estão instalados em seu dispositivo, ou se deseja exclui-los ou restringi-los, use a configuração do seu navegador. Se caso você desativar o uso de cookies em nosso site, não podemos garantir o correto funcionamento de todas as aplicações e é provável que certas funções e páginas em nossa Plataforma não funcionem da maneira adequada.<br/><br/>

        <strong>
        6) Armazenamento e descarte de dados
        </strong><br/>
        Os dados e informações coletados serão armazenados em ambiente seguro pelo tempo que for necessário para cumprir com as finalidades para as quais foram coletados.<br/><br/>

        Atingidas as finalidades previstas para o tratamento dos dados, as suas informações poderão ser mantidas para fins de cumprimento de quaisquer obrigações legais, regulatórias, contratuais, exercício regular de direitos em processo administrativo, judicial ou arbitral, entre outras permitidas ou exigidas pela legislação. Nesse sentido, destacamos que só manteremos em nossos bancos os dados estritamente necessários, em linha com as disposições legais.<br/><br/>

        <strong>
        7) Responsabilidades do usuário
        </strong><br/>

        <li>Informações em Anúncios: Qualquer informação que os Usuários compartilharem em anúncios é de sua própria responsabilidade. Os Usuários devem considerar com cautela os riscos de deixar certas informações - especialmente informações pessoais, tais como endereço preciso.</li>
      <li>

          Informações Publicadas: Qualquer informação que Usuários disponibilizarem em perfis publicamente disponíveis é de sua própria responsabilidade. Você deve cuidadosamente considerar os riscos de fazer certas declarações ou publicar informações pessoais - especialmente informações como endereço ou localização precisa. A Harv não tem responsabilidade por mensagens ou atos de terceiros que ocorram em decorrência da informação que você tornou publicamente disponível a terceiro ou que você compartilhou com qualquer pessoa ou com outro Usuário.
      </li>

        <strong>
        8) Direitos do usuário
        </strong><br/>
        Nos termos previstos pela LGPD, você, como Titular de Dados Pessoais, tem direitos. Nós estamos comprometidos com o cumprimento desses direitos e, nessa seção, vamos explicar quais são e como você pode exercê-los. Veja a seguir:<br/><br/>

        <li>Confirmação da existência de Tratamento de seus Dados Pessoais;</li>

        <li>Obter informações sobre como acessar os seus Dados Pessoais;</li>

        <li>Realizar/solicitar a correção de dados incompletos, inexatos ou desatualizados (do mesmo modo que você forneceu seus dados à Harv, você poderá corrigi-los ou atualizá-los);</li>

        <li>Obter informações sobre a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD;</li>

        <li>Obter informações sobre a portabilidade dos seus Dados Pessoais a outro fornecedor de serviço, mediante requisição expressa;</li>

        <li>Solicitar a eliminação dos dados tratados com o seu consentimento, exceto nas hipóteses de guarda legal e outras dispostas em Lei;</li>

        <li>Obter informações sobre as entidades públicas ou privadas com as quais a Harv compartilhou seus dados;</li>

        <li>Obter informações sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre as consequências, em caso de negativa;</li>

        <li>Realizar a revogação do consentimento para o tratamento dos seus Dados Pessoais, excetuando-se as hipóteses previstas na legislação vigente;</li>

        <li>Demais direitos do titular dos Dados Pessoais, conforme legislação vigente.</li>

        Para exercer qualquer um dos seus direitos estabelecidos acima, bem como determinar suas preferências no tratamento e utilização pela Harv de alguns de seus Dados Pessoais, envie um e-mail para o nosso Suporte: <a href="mailto:contato@harvbrasil.com.br" target='_blank'>contato@harvbrasil.com.br</a>.<br/><br/>

        Por motivos de segurança, somente poderemos atender à sua solicitação se tivermos certeza da sua identidade. Sendo assim, poderemos solicitar dados ou informações adicionais para a confirmação da identidade e da autenticidade do solicitante.<br/><br/>

        <strong>
        9) Dados de crianças e adolescentes
        </strong><br/>
        A Harv não coleta ou armazena intencionalmente dados ou informações pessoais de crianças ou adolescentes menores de 18 (dezoito) anos. Se tomarmos conhecimento de que coletamos Dados Pessoais de uma criança ou adolescente, tomaremos as medidas razoáveis para eliminar os Dados Pessoais em questão.<br/><br/>

        <strong>
        10) Término do tratamento
        </strong><br/>
        Esta Política de Privacidade se aplica às circunstâncias acima mencionadas durante todo o período em que a Harv armazenar os seus dados.<br/><br/>

        O término do tratamento de Dados Pessoais ocorrerá nos seguintes casos: (i) quando a finalidade para qual o dado pessoal foi coletado for alcançada, e/ou os Dados Pessoais coletados deixarem de ser necessários ou pertinentes ao alcance de tal finalidade; (ii) quando o Usuário solicitar a exclusão de seus dados; e (iii) quando houver uma determinação legal neste sentido.<br/><br/>

        Nos casos de término de tratamento de Dados Pessoais, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pela presente Política de Privacidade, os Dados Pessoais serão eliminados e esta Política não será mais aplicável ao seu relacionamento com a Harv.<br/><br/>

        <strong>
        11) Alteração na política de privacidade
        </strong><br/>
        Com a constante evolução do modelo de negócio, a Harv reserva-se o direito de alterar esta Política de Privacidade a qualquer momento, mediante publicação da versão atualizada na Plataforma.<br/><br/>

        Caso exista uma alteração material no tratamento dos Dados Pessoais, você será informado e terá a oportunidade de analisar a política revisada antes de decidir continuar usando nossos serviços. De qualquer maneira, recomendamos a visita periódica desta página para que você acompanhe as modificações.<br/><br/>

        <strong>
        12) Legislação aplicável
        </strong><br/>
        Essa Política de Privacidade é regida pelas Leis da República Federativa do Brasil.<br/><br/>

        <strong>
        13) Contato
        </strong><br/>
        Para esclarecimentos, dúvidas, sugestões e solicitações relacionadas ao conteúdo desta Política de Privacidade, entre em contato conosco através do nosso Suporte: <a href="mailto:contato@harvbrasil.com.br" target='_blank'>contato@harvbrasil.com.br</a>. Temos uma equipe dedicada à proteção de Dados Pessoais e estamos à disposição para colocar você no controle dos seus Dados Pessoais.
        Atualizada em 06 de fevereiro de 2023.<br/><br/>

        <strong>HARVESTER SOLUÇÕES E TECNOLOGIA EIRELI ("HARV BRASIL")</strong><br/>
        CNPJ/ME sob o nº 21.697.746/0001-76<br/>
        Rua Antonio Brollo, nº 131, Condomínio Residencial Dona Lucilla, Bairro Esplanada, CEP 13.331-494, Indaiatuba/SP.`
	},
	app: {
		title: 'Termos de Uso',
		text: `Nós somos a <strong>HARVESTER SOLUÇÕES E TECNOLOGIA EIRELI</strong> ("HARV BRASIL"), pessoa jurídica de direito privado, inscrita no Cadastro Nacional da Pessoa Jurídica ("CNPJ/ME") sob o número 21.697.746/0001-76, com sede na cidade de Indaiatuba, estado de São Paulo, na Rua Antonio Brollo, nº 131, Condomínio Residencial Dona Lucilla, Bairro Esplanada, CEP 13.331-494.<br/><br/>

        A Harv é titular de uma plataforma digital que atua como social shopping on-line que intermedia a compra e compartilhamento de produtos e serviços, anunciando ofertas com quantidades e prazos determinados pelos parceiros.<br/><br/>

        Para utilizar a plataforma Harv e ter acesso aos produtos e serviços/anúncios oferecidos, você deve estar de acordo com todos os Termos e Condições de Uso que regem o acesso e o uso por toda e qualquer pessoa que utilize a plataforma Harv em todo o território nacional.<br/><br/>

        Ao se cadastrar, clicar no botão "li e aceito" e começar a plataforma Harv, você declara que leu, entendeu e aceitou estes Termos de Uso. Caso não concorde com estes Termos de Uso, deverá cessar a utilização da plataforma Harv e cancelar o seu cadastro.<br/><br/>

        Os Termos e Condições de Uso da plataforma Harv poderão ser alterados a exclusivo critério da Harv, a qualquer momento. Portanto, é importante que você consulte regularmente os Termos de Uso para conferir se houve alguma mudança. Qualquer dúvida a respeito dos Termos e Condições de Uso ou sobre os serviços oferecidos pela plataforma Harv você deverá entrar em contato com a Harv pelo e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>


        <strong>Aplicativo Harv</strong><br/><br/>

        <strong>
        1) O que é o aplicativo HARV?
        </strong><br/>
        Somos um aplicativo digital que atua como social shopping on-line que intermedia a compra e compartilhamento de produtos e serviços, anunciando ofertas com quantidades e prazos determinados pelos parceiros. A Harv viabiliza, de forma segura, a compra de produtos dentro do período de validade do anúncio.<br/><br/>

        Todas as operações de compra e venda realizadas no aplicativo Harv serão de responsabilidade do parceiro Harv.
        A responsabilidade pela qualidade, garantia e entrega dos produtos é do parceiro Harv.<br/><br/>

        A responsabilidade pelas informações anunciadas nas ofertas é de responsabilidade do parceiro Harv. Na eventualidade de ocorrer qualquer problema, a Harv atuará como facilitador na comunicação entre você e o parceiro Harv.<br/><br/>

        Além disso, a Harv conta com a ajuda dos Usuários para dar sugestões de melhoria para que o nível de satisfação seja mantido e constantemente aperfeiçoado.<br/><br/>

        <strong>
        2) O que fazer ao receber um anúncio?
        </strong><br/>
        O Usuário poderá efetuar a compra ou optar em não efetuar a compra do anúncio. Se você optar pela compra, você deverá efetuar o cadastro no aplicativo Harv conforme condições descritas no item 3 abaixo, ficando vinculado a estes Termos de Uso e à Política de Privacidade da Harv. <br/><br/>

        Caso o Usuário opte pela compra do produto oferecido, deverá selecionar o produto, a quantidade desejada e finalizar a compra através do pagamento na modalidade “cartão de crédito” ou “Pix”. Caso o Usuário opte por não efetuar nenhuma compra, basta desconsiderar o anúncio.<br/><br/>

        No aplicativo Harv há algumas modalidades de anúncio: venda de produtos, divulgação de serviços ou apenas anúncio - modalidades que não permitem venda.<br/><br/>

        Por meio desses Termos de Uso, o Usuário entende que a Harv realiza a intermediação entre o Usuário, os entregadores e os parceiros. A Harv não produz, não fornece, não comercializa, não distribui e não transporta nenhum dos produtos exibidos na Plataforma. A Harv também não presta serviços logísticos, dessa forma, não transporta ou entrega nenhum destes produtos. Portanto, os parceiros são os únicos responsáveis pelo preparo, execução, acondicionamento, qualidade e entrega dos produtos.<br/><br/>

        Ao adquirir um produto através da Harv, o Usuário declara ter aderido e concorda com o fornecedor daquele produto, aos termos do anúncio e conhece as condições de uso, preço, formas de pagamento, entrega, prazo de validade e garantia de tal produto, isentando a Harv de qualquer responsabilidade. 	
        
        Independentemente se você estiver interessado ou não na compra, você conseguirá visualizar todos os detalhes dos anúncios oferecidos no aplicativo Harv.
        <br/><br/>

        No caso de dúvidas e/ou recomendações, o Usuário poderá entrar em contato através do e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>


        <strong>
        3) Regras do uso da conta
        </strong><br/>
        O cadastro na plataforma Harv é gratuito, mediante o preenchimento dos Dados Pessoais ou Dados da Empresa solicitados. A coleta dos Dados pela Harv estará sujeita à nossa Política de Privacidade. Você poderá abrir apenas uma conta por CPF e por CNPJ. Sua conta é aberta no momento em que é realizado o cadastro na plataforma Harv, criando seu login e senha de acesso. O acesso é exclusivo para maiores de 18 anos e residentes em território nacional. Os usuários que queiram vender/anunciar na plataforma Harv e que possuam documentos com restrição nos órgãos de proteção ao crédito não serão aceitos.<br/><br/>

        Fique atento aos dados que não podem ser alterados após a conclusão do cadastro: nome e CPF (pessoa física), razão social e CNPJ (pessoa jurídica), e-mail e dados bancários. É possível alterar os dados bancários para uma conta bancária diferente desde que pertença ao mesmo CPF/CPNJ cadastrado anteriormente. No entanto, no caso de troca de CPF ou CNPJ, será necessário criar um novo cadastro.<br/><br/>

        Ao abrir a conta e aceitar estes Termos de Uso, você declara ser maior de 18 anos e ter capacidade legal para utilizar a plataforma Harv. As informações da sua conta deverão estar corretas, completas e atualizadas. A veracidade e atualização das informações são de exclusiva responsabilidade do Usuário.

       	A conta e senha de acesso são de uso pessoal e intransferível. Se você permitir que terceiros tenham acesso à sua conta, você será responsável por todas as transações realizadas durante a utilização da conta, inclusive o pagamento dos produtos que terceiros venham a comprar. Caso identifique qualquer uso não autorizado ou indevido da sua conta, você se compromete a comunicar a Harv imediatamente, uma vez que o acesso só será possível mediante a posição da senha, cujo conhecimento é exclusivo do Usuário.
 
        A qualquer momento a Harv poderá solicitar a confirmação de seus dados. Em caso de não confirmação, o acesso à plataforma Harv poderá ser suspenso ou cancelado.<br/><br/>


        <strong>
        4)Como efetuar o pagamento dos produtos adquiridos no aplicativo Harv?

        </strong><br/>
        O pagamento pelos produtos adquiridos será realizado pelo método de pagamento escolhido no momento da finalização das suas compras (cartão de crédito ou Pix).<br/><br/>

        Se a opção escolhida for “cartão de crédito”, você deverá fornecer todos os dados do cartão que forem solicitados. Tais dados serão compartilhados pela Harv com as empresas que participam da cadeia de autorização da compra.<br/><br/>

        Se a opção escolhida for “Pix”, você deverá efetuar o pagamento via Pix na chave indicada.
  
        Ao finalizar o pagamento, você será direcionado para uma página confirmando o pagamento e poderá acompanhar o status da sua compra no aplicativo Harv. Nenhum outro valor adicional será cobrado após a finalização do pagamento.<br/><br/>

        <strong>
        5) Entrega dos produtos
        </strong><br/>
        A entrega dos produtos será feita na data e horário estabelecidos pelo parceiro Harv. Os produtos serão entregues diretamente no endereço indicado por você, para isso, é necessário que você informe um endereço válido para entrega. Após a finalização do pedido, o endereço não poderá ser alterado.  
  
        Ao concluir um pedido, presumir-se-á que o Usuário concordou com todos os termos e condições da entrega detalhados no anúncio.<br/><br/>

        Se você notar, ao receber o(s) produto(s), que a caixa/embalagem está extraviada, você deverá recusar o recebimento da entrega e entrar em contato com o suporte da Harv imediatamente para relatar o ocorrido. Se você não conseguir recusar o recebimento, recomendamos que não abra a caixa/embalagem e nos avise imediatamente pelo nosso Suporte através do e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>

        Além da entrega efetuada pelo parceiro Harv, o Usuário/Comprador também poderá retirar pessoalmente o seu produto na loja do parceiro. Ao retirar o seu produto, certifique-se na hora se está em perfeitas condições.
 
        O Usuário declara estar ciente que o parceiro Harv é o responsável pela entrega do produto conforme indicado no anúncio e que a Harv não tem acesso aos produtos, bem como não possui qualquer responsabilidade relacionada à entrega. De toda forma, a Harv será o canal de comunicação entre o Usuário e o parceiro para tentativa de resolução de qualquer problema entre Usuário/comprador e parceiro.<br/><br/>

        Caso exista alguma cobrança de frete, o Usuário será informado previamente.
        
        A Harv não se responsabiliza pelas entregas, atrasos ou impossibilidade de executá-las, sendo única e exclusiva responsabilidade do parceiro. Todavia, a Harv dispõe do seguinte canal de comunicação para ajudar na solução de qualquer problema entre o Usuário/comprador e o parceiro Harv: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>


        <strong>
        6) Devoluções de pedidos
        </strong><br/>
        Em caso de devoluções de produtos não perecíveis, o Usuário deverá informar: (i) o motivo da devolução, (ii) o número do pedido através do e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a>.<br/><br/>

        As devoluções dos produtos não perecíveis poderão ser feitas em até 7 (sete) dias corridos da data de recebimento e a restituição do valor será através do reembolso integral do valor pago.<br/><br/>

        A partir da data da autorização da devolução pela Harv, o Usuário terá 3 (três) dias úteis para efetuar o envio do(s) produto(s) ao endereço indicado pela Harv, utilizando o código de postagem fornecido pela equipe de atendimento da Harv, sendo o Usuário responsável por acompanhar o trâmite da devolução. O Usuário/comprador declara-se ciente de que o descumprimento do prazo de postagem implicará o cancelamento automático da devolução, não sendo possível fazer um novo pedido.<br/><br/>

        Caso o pagamento tenha sido efetuado através do método de pagamento “cartão de crédito”, o valor será estornado no cartão de crédito, dentro do prazo estipulado pela administradora do cartão de crédito utilizado pelo Usuário/comprador no momento da compra. Em caso de pagamento efetuado via “Pix”, o reembolso do valor será efetuado em até 24 horas.<br/><br/>


        <strong>
        7) Das obrigações
        </strong><br/>
        O Usuário/comprador e o parceiro Harv deverão cumprir com todas as condições e obrigações constantes nestes Termos de Uso e declaram-se estar cientes que não poderão:<br/><br/>

        <li>
        Sugerir, persuadir ou incitar, de qualquer forma e por qualquer meio, que a negociação de compra e venda de produtos intermediados pela Harv seja consumada fora da plataforma Harv;
        </li>

        <li>Instigar, ameaçar, ofender, abalar a imagem, ofender a privacidade ou prejudicar qualquer pessoa;</li>

        <li>Desrespeitar a lei, a moral, a boa-fé, as normas de direito autoral e/ou propriedade industrial, os direitos à honra, à vida privada, à imagem, à intimidade pessoal e familiar por meio da plataforma Harv;</li>

        <li>Incitar a prática de atos discriminatórios, seja em razão do sexo, raça, religião, crença, idade ou qualquer outra condição;</li>


        <li>Utilizar dispositivo, software ou outro recurso que venha a interferir nas atividades e operações na plataforma Harv, bem como nos anúncios, descrições, contas ou seus bancos de dados;</li>

        <li>Reproduzir e/ou utilizar o modelo de negócio e práticas comerciais da Harv, bem como informações confidenciais de sua titularidade sem a prévia autorização e consentimento expresso desta.</li>

        Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos de Uso tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por danos causados.<br/><br/>

        A plataforma Harv se reserva no direito de, a exclusivo critério e sem aviso prévio, excluir ou cancelar o Usuário que violar as regras aqui estabelecidas, sem prejuízo da aplicação de outras penalidades previstas nestes Termos de Uso ou que julgar cabíveis.<br/><br/>

        <strong>
        9) Sanções
        </strong><br/>
        
        Sem prejuízo de outras medidas, a Harv poderá advertir, suspender ou cancelar, temporária ou definitivamente, a conta de um Usuário a qualquer tempo, e iniciar as sanções legais e cabíveis se: i) o Usuário não cumprir qualquer dispositivo destes Termos de Uso; (ii) descumprir com seus deveres de Usuário; (iii) praticar atos fraudulentos ou dolosos; (iv) não puder ser verificada a identidade do Usuário ou qualquer informação fornecida por ele esteja incorreta; (v) a Harv entender que qualquer atitude do Usuário tenha causado algum dano a terceiro ou a própria Harv ou tenha a potencialidade de assim o fazer.<br/><br/>

        Na hipótese de inabilitação do cadastro do Usuário, todos os pedidos ativos serão automaticamente cancelados e o Usuário será excluído da plataforma Harv. Se o Usuário for inabilitado pela Harv, não poderá efetuar novo cadastro na plataforma Harv.<br/><br/>

        <strong>
        10) Propriedade intelectual
        </strong><br/>
        O Usuário declara estar ciente de que a marca, nome empresarial ou nome de domínio Harv são de uso exclusivo da Harv, não podendo ser utilizado de qualquer forma por qualquer pessoa não autorizada expressamente para tanto. O código fonte, o layout, o banco de dados, redes e arquivos inerentes à plataforma Harv são de titularidade exclusiva da Harv.<br/><br/>

        O Usuário não adquire, por meio do presente Termos de Uso, nenhum direito de propriedade intelectual ou outros direitos exclusivos, direitos autorais ou direitos sobre informações confidenciais ou segredos de negócio, bem como a base de dados composta por dados de Usuários, os quais são de propriedade exclusiva da Harv.<br/><br/>

        <strong>
        11) Lei aplicável e foro
        </strong><br/>
        Estes Termos de Uso serão regidos pelas leis em vigor na República Federativa do Brasil. As partes elegem o foro da comarca de Indaiatuba, estado de São Paulo, para dirimir qualquer controvérsia oriunda destes Termos de Uso que não tenha sido resolvido pacificamente entre as partes, renunciando, expressamente a qualquer outro, por mais privilegiado que seja.<br/><br/>

        <strong>
        12) Disposições finais
        </strong><br/>
        Você não poderá ceder estes Termos de Uso, total ou parcialmente, ou qualquer de seus direitos e obrigações dele decorrentes sem o prévio consentimento, por escrito da plataforma Harv.<br/><br/>

        Não existe sociedade, relação de emprego ou relação de representação entre você e a plataforma Harv como resultado destes termos ou pelo uso dos serviços.<br/><br/>

        Estes termos constituem o acordo integral entre você e a Harv com relação aos assuntos previstos nestes Termos de Uso e substitui todos e quaisquer contratos e acordos anteriores, verbais ou escritos, celebrados anteriormente em relação aos mesmos assuntos.<br/><br/>

        Dúvidas, elogios e reclamações? O Usuário poderá utilizar o e-mail: <a href="mailto:contato@harvbrasil.com.br">contato@harvbrasil.com.br</a> para fazer qualquer tipo de solicitação, questionamento, elogios e/ou reclamações para a Harv.<br/><br/>
        
        Atualizado em 18 de maio de 2023.
        `
	}
}
