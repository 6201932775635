import { createElement, useCallback } from 'react'
import { ListProductsView } from './view'

//hooks
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'

//types
import { ERoutes } from '../../routes/routes'
import { IPageProps } from './types'

//services
import { useProducts } from '../../services/products.service'

function ListProducts() {
	const [searchQuery, setSearchQuery] = useState('')

	const navigate = useNavigate()

	const {
		user: { store }
	} = useTypedSelector(['user'])

	const { data: products, refetch, isLoading } = useProducts(store._id)

	const moveToRegister = useCallback(() => {
		navigate(ERoutes.CREATE_PRODUCT.replace(':type', 'product'))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const pageProps: IPageProps = {
		searchQuery,
		setSearchQuery,
		moveToRegister,
		products,
		refetch,
		isLoading
	}

	return createElement(ListProductsView, pageProps)
}

export { ListProducts }
