import * as yup from 'yup'

const createNotificationSchema = yup.object().shape({
	reason: yup.string().required('Motivo é obrigatório'),
	seller: yup.string(),
	product: yup.string(),
	city: yup.string().required('Cidade é obrigatória'),
	title: yup.string().required('Título da notificação é obrigatório'),
	description: yup.string()
})

export { createNotificationSchema }
